import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export interface UseQueryParamsOptions {
  once?: boolean;
}

export function useQueryParams(
  options: UseQueryParamsOptions = {},
): URLSearchParams & { keys(): string[] } {
  const { search } = useLocation();
  const { once = false } = options;

  return useMemo(
    () => new URLSearchParams(search),
    [once ? null : search],
  ) as URLSearchParams & { keys(): string[] };
}
