import { configureStore } from '@reduxjs/toolkit';
import { createDispatchHook, createSelectorHook } from 'react-redux';

import { reducer as identityCheckReducer } from './slices/identity-check.slice';

export interface CreateStoreParams {
  debug?: boolean;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const createStore = ({ debug = false }: CreateStoreParams) =>
  configureStore({
    reducer: {
      identityCheck: identityCheckReducer,
    },
    devTools: debug,
  });

export type Store = ReturnType<typeof createStore>;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<Store['getState']>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = Store['dispatch'];

export const useDispatch = createDispatchHook<RootState>();
export const useSelector = createSelectorHook();
