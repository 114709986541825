import { Suspense, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Loader } from '@hedgehog/ui/loaders';
import { depth } from '@hedgehog/utils/sizes';

import Footbar from '../Footbar/Footbar';
import { LoadingContainer } from '../LoadingContainer/LoadingContainer';
import Topbar from '../topbar/topbar.component';

const StickyFooter = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${depth.layout};
`;

export type MainLayoutProps = {
  brandLogotypeUrl?: string | null;
  brandLoading?: boolean;
  topbarLinks?: ReactNode | ReactNode[];
  children?: ReactNode | ReactNode[];
  fixToTop?: ReactNode | ReactNode[];
  isInvestingEnabled?: boolean;
  isWhitelabel?: boolean;
  learnSectionEnabled?: boolean;
};

export const MainLayout = ({
  brandLogotypeUrl,
  brandLoading,
  topbarLinks,
  children,
  fixToTop,
  isWhitelabel = false,
  isInvestingEnabled = true,
  learnSectionEnabled = true,
}: MainLayoutProps): JSX.Element => {
  const location = useLocation();
  const hideHeaderOnPages = [
    '/',
    '/signup',
    '/signup_confirm',
    '/login',
    '/forgot-password',
    '/unsubscribe',
  ];
  const showHeader =
    hideHeaderOnPages.find((path) => location.pathname === path) === undefined;
  const showFootbar = !(
    location.pathname === '/' ||
    location.pathname === '/signup' ||
    location.pathname === '/signup_confirm' ||
    location.pathname === '/login' ||
    location.pathname === '/unsubscribe' ||
    location.pathname === '/notifications' ||
    location.pathname === '/forgot-password' ||
    location.pathname.includes('/assets') ||
    location.pathname.includes('/buy') ||
    location.pathname.includes('/order') ||
    location.pathname.includes('/kyc') ||
    location.pathname.includes('/sponsors') ||
    location.pathname.includes('/learn/courses') ||
    (location.pathname.includes('/explore') && !isInvestingEnabled)
  );

  return (
    <>
      <Suspense
        fallback={
          <LoadingContainer>
            <Loader />
          </LoadingContainer>
        }
      >
        <Topbar
          navigation={showHeader}
          logotypeLoading={brandLoading}
          logotypeUrl={brandLogotypeUrl}
          beforeAll={fixToTop}
          links={topbarLinks}
          border={true}
        />
        {children}
        {showFootbar && (
          <Footbar
            isWhitelabel={isWhitelabel}
            learnSectionEnabled={learnSectionEnabled}
          />
        )}
      </Suspense>
      <StickyFooter id="layout-footer" />
    </>
  );
};
