import { QueryHookOptions } from '@apollo/client';

import { useAuthenticatedQuery } from '@hedgehog/data-access/contexts';
import {
  GetOrderQuery as GetOrder,
  GetOrderQueryVariables as GetOrderVariables,
  GET_ORDER,
} from '@hedgehog/data-access/graphql';

export const useUserOrderForRound = (
  assetRoundId: string,
  queryOptions?: QueryHookOptions,
): {
  data?: GetOrder;
  error?: Error;
  loading: boolean;
} => {
  return useAuthenticatedQuery<GetOrder, GetOrderVariables>(
    GET_ORDER,
    {
      assetRoundId,
    },
    queryOptions,
  );
};
