import { useUser } from '@hedgehog/browser/investors/kyc/data-access';
import {
  OnboardingActions,
  OnboardingFooter,
  OnboardingPrompt,
  OnboardingWorkflowActions,
} from '@hedgehog/browser/investors/kyc/feature-onboarding';
import { useWorkflows } from '@hedgehog/browser/investors/workflows/onboarding';
import { isKycOnly } from '@hedgehog/data-access/graphql';
import { HeaderInfoButton } from '@hedgehog/ui/buttons';
import { Header, HeaderWithBack } from '@hedgehog/ui/headers';
import { LayeredIcons } from '@hedgehog/ui/icons';
import { LoadingContainer } from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';
import { Heading } from '@hedgehog/ui/typography';

export const KycWorkflow = (): JSX.Element => {
  const { data: userData, loading } = useUser();
  const { data } = useWorkflows();

  if (!userData?.user || loading) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }

  const {
    user: { firstName },
  } = userData;

  const preInvestWorkflows = data?.onboardingWorkflows?.preInvest || [];
  const isStandalone = preInvestWorkflows.length < 1;

  return (
    <>
      {/* NB. Not sure this level of granularity can/should be configured, might be best to remove completely? */}
      {!isStandalone && (
        <HeaderWithBack to={{ hash: '' }}>
          <Heading level="h5">Let’s get to know you</Heading>
        </HeaderWithBack>
      )}
      {isStandalone && (
        <>
          <Header
            left={<LayeredIcons icons={['hedgehog']} />}
            right={<HeaderInfoButton />}
          />
          <Heading level="h5">
            Hey {firstName},<br /> let's verify you
          </Heading>
        </>
      )}

      <OnboardingPrompt />
      <OnboardingWorkflowActions />
      <OnboardingFooter />
    </>
  );
};

// TODO: 🔍 KYC-only onboarding tech-debt - remove me -  https://linear.app/hedgehog-invest/issue/HOG-2898/%F0%9F%92%A1-get-rid-of-all-the-oakglen-if-statements
// NB. The whole logic of this component may need to be changed/removed
export const StandaloneOrDefaultKyc = (): JSX.Element => {
  const { data: userData, loading } = useUser();

  if (!userData?.user || loading) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }

  const {
    user: { firstName },
    partnerSettings,
  } = userData;
  // TODO: 🔍 KYC-only onboarding tech-debt - remove me -  https://linear.app/hedgehog-invest/issue/HOG-2898/%F0%9F%92%A1-get-rid-of-all-the-oakglen-if-statements
  const isStandalone = isKycOnly(partnerSettings);
  return (
    <>
      {/* TODO: 🔍 KYC-only onboarding tech-debt - remove me -  https://linear.app/hedgehog-invest/issue/HOG-2898/%F0%9F%92%A1-get-rid-of-all-the-oakglen-if-statements */}
      {/* NB. Not sure this level of granularity can/should be configured, might be best to remove completely? */}
      {!isStandalone && (
        <HeaderWithBack to={{ hash: '' }}>
          <Heading level="h5">Let’s get to know you</Heading>
        </HeaderWithBack>
      )}

      {/* TODO: 🔍 KYC-only onboarding tech-debt - remove me -  https://linear.app/hedgehog-invest/issue/HOG-2898/%F0%9F%92%A1-get-rid-of-all-the-oakglen-if-statements */}
      {isStandalone && (
        <>
          <Header
            left={<LayeredIcons icons={['hedgehog']} />}
            right={<HeaderInfoButton />}
          />
          <Heading level="h5">
            Hey {firstName},<br /> let's verify you
          </Heading>
        </>
      )}

      <OnboardingPrompt />
      <OnboardingActions />
      <OnboardingFooter />
    </>
  );
};
