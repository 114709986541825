import React from 'react';
import styled from 'styled-components';

import { useAnalyticsTrack } from '@hedgehog/browser/investors/shared/analytics';
import { AnyFunction } from '@hedgehog/shared/types';
import { ActionCard } from '@hedgehog/ui/cards';
import { HeaderWithClose } from '@hedgehog/ui/headers';
import { BottomSheet } from '@hedgehog/ui/modals';
import { Heading } from '@hedgehog/ui/typography';

import { useBrowserAbout } from '../hooks/use-browser-about.hook';

const templateSocialMedia = (link: string): string =>
  'I’ve signed up to Hedgehog, an investment platform for making fractional investments in real-world assets alongside the existing owners.\n' +
  `\n\nCheck it out: ${link}`;

const templateEmailSubject = (): string => `Check out Hedgehog!`;
const templateEmailBody = (link: string): string =>
  'Hey, I’ve signed up to Hedgehog, an investment platform for making fractional investments in real-world assets alongside the existing owners.\n' +
  `\nI thought you might find it interesting. Here is my invitation link to check it out: https://${link}`;

const ModalContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  padding: 2rem 1.5rem;

  ${HeaderWithClose} {
    margin-bottom: 1.5rem;
  }
`;

const ShareOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export interface AccountReferralOptionsProps {
  link?: string;
  onClick?: React.MouseEventHandler<Element> | AnyFunction;
  isClosing?: boolean;
  closeModal: () => void;
}

type ShareType =
  | 'copy_link'
  | 'email'
  | 'twitter'
  | 'linkedin'
  | 'facebook'
  | 'whatsapp';

export const AccountReferralOptions = ({
  link,
  isClosing,
  onClick,
  closeModal,
}: AccountReferralOptionsProps): JSX.Element => {
  const track = useAnalyticsTrack();
  const about = useBrowserAbout();
  const socialMediaMessage = encodeURIComponent(
    templateSocialMedia(link || ''),
  );

  const handleClick = async (
    event: React.MouseEvent<Element>,
    type: ShareType,
  ): Promise<void> => {
    if (!link) return;

    track('ReferralShareWith', 'Clicked', { type });

    if (type === 'copy_link') {
      await navigator.clipboard.writeText(link);
    }
    if (onClick) onClick(event);
  };

  return (
    <BottomSheet closing={isClosing}>
      <ModalContent>
        <HeaderWithClose onClose={closeModal}>
          <Heading level="h5">Share your link</Heading>
        </HeaderWithClose>
        <ShareOptions>
          <ActionCard
            title="Copy link"
            trailingIcon="copy"
            onClick={(event): Promise<void> => handleClick(event, 'copy_link')}
          />

          <ActionCard
            title="Email"
            trailingIcon="mail"
            onClick={(event): Promise<void> => handleClick(event, 'email')}
            href={`mailto:?subject=${encodeURIComponent(
              templateEmailSubject(),
            )}&body=${encodeURIComponent(templateEmailBody(link ?? ''))}`}
            target="_blank"
          />

          <ActionCard
            title="Twitter"
            trailingIcon="twitter"
            onClick={(event): Promise<void> => handleClick(event, 'twitter')}
            href={`https://twitter.com/intent/tweet?text=${socialMediaMessage}`}
            target="_blank"
          />

          <ActionCard
            title="Linkedin"
            trailingIcon="linkedin"
            onClick={(event): Promise<void> => handleClick(event, 'linkedin')}
            href={`https://www.linkedin.com/sharing/share-offsite/?url=https://${
              link ?? ''
            }`}
            target="_blank"
          />

          <ActionCard
            title="Facebook"
            trailingIcon="facebook"
            onClick={(event): Promise<void> => handleClick(event, 'facebook')}
            href={`https://www.facebook.com/sharer/sharer.php?u=https://${
              link ?? ''
            }`}
            target="_blank"
          />

          <ActionCard
            title="Whatsapp"
            trailingIcon="whatsapp"
            onClick={(event): Promise<void> => handleClick(event, 'whatsapp')}
            href={
              about.mobile
                ? `whatsapp://send?text=${socialMediaMessage}`
                : `https://web.whatsapp.com/send?text=${socialMediaMessage}`
            }
            target="_blank"
            data-action="share/whatsapp/share"
          />
        </ShareOptions>
      </ModalContent>
    </BottomSheet>
  );
};
