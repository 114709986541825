import styled from 'styled-components';

import { useSidebarRef } from '@hedgehog/browser/shared/router';
import { AutoscrollPage } from '@hedgehog/ui/autoscroll';

import { AssessmentQuiz } from '../../containers';

const Page = styled(AutoscrollPage)``;

export interface AssessmentPageProps {
  upperNavigation?: boolean;
}

export const AssessmentPage = ({
  ...props
}: AssessmentPageProps): JSX.Element => {
  const sidebarRef = useSidebarRef();
  return (
    <Page sidebarPage align="center" containerRef={sidebarRef}>
      <AssessmentQuiz {...props} comments />
    </Page>
  );
};
