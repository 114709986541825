import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useIsInvestingEnabled } from '@hedgehog/browser/investors/kyc/data-access';
import { useAnalyticsPage } from '@hedgehog/browser/investors/shared/analytics';
import { Loader } from '@hedgehog/ui/loaders';
import { SaveScrollState } from '@hedgehog/ui/routing';

import { KycCompleted } from '../components';
import { AssetRoundsList } from '../containers';

export const AssetsPage = (): JSX.Element => {
  useAnalyticsPage('Top Level', 'Explore', { tab: 'invest' });
  const location = useLocation();

  // save scroll state
  useLayoutEffect(() => {
    const handleScroll = (): void => {
      SaveScrollState({
        key: location.pathname,
        y: window.scrollY,
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const { enabled: isInvestingEnabled, loading: investingEnabledLoading } =
    useIsInvestingEnabled();

  if (investingEnabledLoading) {
    return <Loader center />;
  }

  if (!isInvestingEnabled) {
    return <KycCompleted />;
  }

  return <AssetRoundsList />;
};
