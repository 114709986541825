import { FormEvent, useEffect, useState } from 'react';
import styled from 'styled-components';

import { PrimaryButton } from '@hedgehog/ui/buttons';
import { EmailInput, TextInput } from '@hedgehog/ui/inputs';
import { Note } from '@hedgehog/ui/typography';
import {
  createEmailValidation,
  InvalidEmailReason,
} from '@hedgehog/utils/validation';

import { SignupTermsCheckbox } from '../components';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const FormSubmitButton = styled(PrimaryButton)`
  align-self: center;
`;

const SignupErrorText = styled.p`
  font-weight: 600;
  margin: 0;
`;

const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export interface SignUpFormData {
  email?: string;
  firstName?: string;
  lastName?: string;
  accepted?: boolean;
}

export interface SignUpFormProps {
  onSubmit?: (formData: Required<SignUpFormData>) => void;
  onFormChange?: (formData: SignUpFormData) => void;
  className?: string;
  defaultForm?: SignUpFormData;
  errors?: string[];
  loading?: boolean;
  termsUrl?: string;
  privacyPolicyUrl?: string;
}

export const SignUpForm = ({
  defaultForm,
  errors,
  loading,
  onSubmit,
  onFormChange,
  termsUrl,
  privacyPolicyUrl,
  className,
}: SignUpFormProps): JSX.Element => {
  const [form, updateForm] = useState<SignUpFormData>({
    email: defaultForm?.email || '',
    firstName: defaultForm?.firstName || '',
    lastName: defaultForm?.lastName || '',
    accepted: defaultForm?.accepted || false,
  });
  const validateEmail = createEmailValidation({
    subaddress: process.env['REACT_APP_ENV'] === 'production',
  });
  const [emailErrors, setEmailErrors] = useState<InvalidEmailReason[]>([]);
  const isFormValid =
    Object.values(form).every(Boolean) && emailErrors.length === 0;

  useEffect(() => {
    if (onFormChange) onFormChange(form);
  }, [form, onFormChange]);

  useEffect(() => {
    setEmailErrors([]);
  }, [form.email]);

  const handleSubmit = (event: FormEvent): void => {
    event.preventDefault();
    if (isFormValid) {
      if (onSubmit) onSubmit(form as Required<SignUpFormData>);
    }
  };

  const handleEmailChange = (value: string): void =>
    updateForm((state) => ({ ...state, email: value }));
  const handleEmailBlur = (): void => {
    setEmailErrors(validateEmail(form.email || ''));
  };

  const handleFirstNameChange = (value: string): void =>
    updateForm((state) => ({ ...state, firstName: value }));
  const handleLastNameChange = (value: string): void =>
    updateForm((state) => ({ ...state, lastName: value }));
  const handleAcceptedChange = (value: boolean): void =>
    updateForm((state) => ({ ...state, accepted: value }));

  return (
    <Form onSubmit={handleSubmit} className={className}>
      <FormSection>
        <TextInput
          name="firstName"
          value={form.firstName}
          placeholder="First name"
          label="First name"
          onChange={handleFirstNameChange}
        />
        <TextInput
          name="lastName"
          value={form.lastName}
          placeholder="Last name"
          label="Last name"
          onChange={handleLastNameChange}
        />
        <EmailInput
          name="email"
          value={form.email}
          errors={emailErrors}
          placeholder="Email address"
          label="Email address"
          onChange={handleEmailChange}
          onBlur={handleEmailBlur}
        />
      </FormSection>

      <FormSection>
        <SignupTermsCheckbox
          name="accepted"
          value={form.accepted || false}
          onChange={handleAcceptedChange}
          termsUrl={termsUrl}
          privacyPolicyUrl={privacyPolicyUrl}
        />
      </FormSection>

      {errors?.length !== 0 && (
        <SignupErrorText>
          Error occurred while creating your account:&nbsp;
          {errors}
        </SignupErrorText>
      )}

      <FormSubmitButton
        fluid
        type="submit"
        disabled={!isFormValid}
        loading={loading}
      >
        Proceed
      </FormSubmitButton>

      <Note color="grey400" align="center">
        Currently only available to residents of the US, UK and Switzerland.
      </Note>
    </Form>
  );
};
