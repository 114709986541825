import { ContractIssuance } from '@hedgehog/shared/blockchain/resident-token';

export interface CountIssuancesInput {
  issuances: ContractIssuance[];
  predicate: (issuance: ContractIssuance) => boolean;
}

export interface CountIssuancesOutput {
  tokensAmount: number;
  count: number;
}

export function countIssuances({
  issuances,
  predicate,
}: CountIssuancesInput): CountIssuancesOutput {
  return issuances.filter(predicate).reduce(
    (acc, issuance) => ({
      tokensAmount: acc.tokensAmount + issuance.amount,
      count: acc.count + 1,
    }),
    {
      tokensAmount: 0,
      count: 0,
    },
  );
}
