import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { CSSObject } from 'styled-components';

import {
  useOnboardingStatus,
  useUser,
} from '@hedgehog/browser/investors/kyc/data-access';
import { useHasCompletedWorkflows } from '@hedgehog/browser/investors/workflows/onboarding';
import {
  hasCompletedOnboarding,
  useUserAction,
} from '@hedgehog/data-access/contexts';
import { FeatureFlagNames, isKycOnly } from '@hedgehog/data-access/graphql';
import { useFeature } from '@hedgehog/data-access/hooks';
import { Loader } from '@hedgehog/ui/loaders';

import {
  KycWorkflow,
  StandaloneOrDefaultKyc,
} from '../containers/standalone-or-default-kyc.container';

const KycWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  ${({ theme }): CSSObject => ({ gap: theme.spacing.xlarge })}
`;

export const KycPage = (): JSX.Element => {
  const flag = useFeature(FeatureFlagNames.pre_invest_workflows);
  const { data, loading } = useUser();

  const useWorkflows =
    flag.isEnabled && flag.value.includes?.(data?.user?.partner?.slug);

  if (loading) {
    return <Loader />;
  }

  if (useWorkflows) {
    return <KycWorkflowPage />;
  }

  return <LegacyKycPage />;
};

export const KycWorkflowPage = (): JSX.Element => {
  const { data: user } = useUser();
  const navigate = useNavigate();
  const { complete } = useHasCompletedWorkflows();
  useEffect(() => {
    if (
      // TODO: This should be driven by a KYC-only workflow.
      // As far as I remember that means showing our usual preInvest steps as postSignup steps.
      // If we're already doing that for KYC-only flows it should be straightforward. Maybe.
      isKycOnly(user?.partnerSettings) &&
      complete
    ) {
      navigate('/explore');
    }
  }, [complete, user, navigate]);
  return (
    <KycWrapper>
      <KycWorkflow />
    </KycWrapper>
  );
};

/** @deprecated */
export const LegacyKycPage = (): JSX.Element => {
  const { data: onboarding } = useOnboardingStatus();
  const { data: userActionData } = useUserAction();
  const { data: user } = useUser();
  const navigate = useNavigate();
  useEffect(() => {
    const onboardingProgress = onboarding?.onboardingProgress;
    if (
      onboardingProgress &&
      // TODO: 🔍 KYC-only onboarding tech-debt - remove me -  https://linear.app/hedgehog-invest/issue/HOG-2898/%F0%9F%92%A1-get-rid-of-all-the-oakglen-if-statements
      isKycOnly(user?.partnerSettings) &&
      hasCompletedOnboarding(userActionData, onboardingProgress?.status)
    ) {
      navigate('/explore');
    }
  }, [onboarding, user, userActionData, navigate]);
  return (
    <KycWrapper>
      <StandaloneOrDefaultKyc />
    </KycWrapper>
  );
};
