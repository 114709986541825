import { FormEventHandler, useState } from 'react';
import styled, { CSSObject } from 'styled-components';

import { SecondaryButton } from '@hedgehog/ui/buttons';
import { HeaderWithClose } from '@hedgehog/ui/headers';
import { TextArea } from '@hedgehog/ui/inputs';
import { VSpace } from '@hedgehog/ui/layouts';
import { BottomSheet } from '@hedgehog/ui/modals';
import { Heading } from '@hedgehog/ui/typography';

export interface AddCommentModalProps {
  initialComment?: string;
  submit: (comment?: unknown) => void;
  cancel: () => void;
}

const AddCommentWrapper = styled(VSpace)`
  flex: 1 1 100%;
  display: flex;
  flex-flow: column nowrap;

  ${({ theme }): CSSObject => ({ padding: theme.spacing.large })}
`;

const StyledTextArea = styled(TextArea)`
  height: 0;
  min-height: 2.5rem;
  line-height: 1.5rem;
  padding: 0.5rem 1rem;
`;

export const AddCommentModal = ({
  initialComment = '',
  submit,
  cancel,
}: AddCommentModalProps): JSX.Element => {
  const [comment, updateComment] = useState(initialComment);

  const preventDefault: FormEventHandler = (event) => event.preventDefault();

  const handleSubmit = (): void => {
    submit(comment);
  };

  return (
    <BottomSheet>
      <AddCommentWrapper spacing="xs">
        <HeaderWithClose onClose={cancel}>
          <Heading level="h7">Add comment</Heading>
        </HeaderWithClose>

        <form onSubmit={preventDefault}>
          <StyledTextArea
            autosize
            placeholder="Type your comment"
            maxLength={255}
            onChange={updateComment}
            autoFocus
            value={comment}
          />
        </form>

        <SecondaryButton onClick={handleSubmit}>Done</SecondaryButton>
      </AddCommentWrapper>
    </BottomSheet>
  );
};
