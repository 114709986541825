import styled from 'styled-components';

import { AssetHighlights } from '@hedgehog/browser/shared/assets';
import { AssetRoundClassMetric } from '@hedgehog/data-access/graphql';
import { ToastLayout } from '@hedgehog/ui/toasts';

const RelaxedRadiusToast = styled(ToastLayout)`
  border-radius: 1rem;
`;

type AssetHighlightsBlockProps = {
  hasTaxLeakage: boolean;
  metrics?: AssetRoundClassMetric[];
};

export const AssetHighlightsBlock = ({
  hasTaxLeakage,
  metrics,
}: AssetHighlightsBlockProps): JSX.Element => {
  return (
    <RelaxedRadiusToast>
      <AssetHighlights hasTaxLeakage={hasTaxLeakage} metrics={metrics} />
    </RelaxedRadiusToast>
  );
};
