import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { useUser } from '@hedgehog/browser/investors/kyc/data-access';
import { useAccountAbstraction } from '@hedgehog/browser/shared/account-abstraction';
import { useAuth } from '@hedgehog/data-access/contexts';
import { sendMessageToApp } from '@hedgehog/data-access/native-mobile';
import { LoadingContainer } from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';

export const LogoutPage = () => {
  const { signout } = useAuth();
  const { data, loading } = useUser();
  const { clear: clearWeb3Data } = useAccountAbstraction();

  useEffect(() => {
    if (loading) return;
    clearWeb3Data();
    signout();
    sendMessageToApp('logout');
  }, [loading]);

  if (loading) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }

  const slug = data?.user?.partner?.slug;
  return (
    <Navigate
      to={slug && slug !== 'hedgehog' ? `/${slug}/login` : '/login'}
      replace
    />
  );
};
