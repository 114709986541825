import { MutationTuple, useMutation } from '@apollo/client';

import {
  GET_USER,
  UPDATE_USER_DETAILS,
  UpdateUserDetailsMutation as UpdateUserDetails,
  UpdateUserDetailsMutationVariables as UpdateUserDetailsVariables,
} from '@hedgehog/data-access/graphql';

export const useUserUpdate = (): MutationTuple<
  UpdateUserDetails,
  UpdateUserDetailsVariables
> =>
  useMutation<UpdateUserDetails, UpdateUserDetailsVariables>(
    UPDATE_USER_DETAILS,
    {
      refetchQueries: [{ query: GET_USER }],
    },
  );
