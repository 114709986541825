import styled from 'styled-components';

import { useUser } from '@hedgehog/browser/investors/kyc/data-access';
import { useAuthenticatedQuery } from '@hedgehog/data-access/contexts';
import {
  GET_AVAILABLE_ASSETS,
  GetAvailableAssetsQuery as GetAvailableAssets,
  GetAvailableAssetsQueryVariables as GetAvailableAssetsVariables,
} from '@hedgehog/data-access/graphql';
import { AssetRoundClassStatus } from '@hedgehog/shared/types';
import { EmptyStateContainer, Grid } from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';
import { Heading6 } from '@hedgehog/ui/typography';

import { AssetRoundCard } from '../../components';
import { groupAssetRounds } from '../../helpers';

const SectionHeading = styled(Heading6)`
  margin-top: 1rem;
`;

export const AssetRoundsList = styled((): JSX.Element => {
  const { data: userData, loading: userLoading } = useUser();
  const { data, error, loading } = useAuthenticatedQuery<
    GetAvailableAssets,
    GetAvailableAssetsVariables
  >(
    GET_AVAILABLE_ASSETS,
    {
      status: [
        AssetRoundClassStatus.COMING_SOON,
        AssetRoundClassStatus.FUNDED,
        AssetRoundClassStatus.LIVE,
        AssetRoundClassStatus.ONGOING,
        AssetRoundClassStatus.PAID_OUT,
        AssetRoundClassStatus.REGISTER_INTEREST,
      ],
    },
    { fetchPolicy: 'cache-and-network' },
  );

  if (loading || userLoading) {
    return <Loader />;
  }

  if (error || !data) {
    return (
      <EmptyStateContainer>
        Something went wrong, please try again later
      </EmptyStateContainer>
    );
  }

  const { promoted, live, upcoming, ongoing, paidout } = groupAssetRounds({
    assets: data.availableAssets,
    partnerSlug: userData?.user?.partner?.slug,
  });

  const liveOrUpcoming = [...live, ...upcoming];
  const ongoingOrPaidout = [...ongoing, ...paidout];

  return (
    <>
      {promoted.length > 0 && (
        <Grid minColumnWidth="18rem">
          {promoted.map((asset) => (
            <AssetRoundCard key={asset.id} asset={asset} type="large" />
          ))}
        </Grid>
      )}
      {liveOrUpcoming.length > 0 && (
        <>
          <SectionHeading>Live & Coming soon</SectionHeading>
          <Grid minColumnWidth="18rem">
            {liveOrUpcoming.map((asset) => (
              <AssetRoundCard key={asset.id} asset={asset} type="large" />
            ))}
          </Grid>
        </>
      )}
      {ongoingOrPaidout.length > 0 && (
        <>
          <SectionHeading>Paid out & Ongoing</SectionHeading>
          <Grid minColumnWidth="18rem">
            {ongoingOrPaidout.map((asset) => (
              <AssetRoundCard key={asset.id} asset={asset} type="compact" />
            ))}
          </Grid>
        </>
      )}
    </>
  );
})``;
