import { ContractIssuance } from '@hedgehog/shared/blockchain/resident-token';

import { countIssuances } from './count-issuances.helper';

export interface CalculateLeaseRewardsRecentInput {
  issuances: ContractIssuance[];
}

export function calculateLeaseRewardsRecent({
  issuances,
}: CalculateLeaseRewardsRecentInput): number {
  const { tokensAmount: leaseRentRewardsInTokensAmount } = countIssuances({
    issuances,
    predicate: (issuance): issuance is ContractIssuance<'lease:renew'> => {
      const { data } = issuance as ContractIssuance<'lease:renew'>;
      return data.type === 'lease:renew';
    },
  });

  return leaseRentRewardsInTokensAmount;
}
