import { ReactNode } from 'react';
import styled from 'styled-components';

import { screens } from '@hedgehog/utils/sizes';

const StyledGrid = styled.div<{ minColumnWidth?: string }>`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(${({ minColumnWidth }): string => minColumnWidth || '100%'}, 1fr)
  );
  grid-auto-flow: dense;
  grid-gap: 1.5rem 1.5rem;

  @media only screen and (max-width: ${screens.medium}px) {
    grid-gap: 1rem 1rem;
  }
`;

type GridProps = {
  minColumnWidth?: string;
  className?: string;
  children?: ReactNode | ReactNode[];
};

export const Grid = ({
  children,
  className,
  minColumnWidth: minWidth,
}: GridProps): JSX.Element => {
  return (
    <StyledGrid minColumnWidth={minWidth} className={className}>
      {children}
    </StyledGrid>
  );
};
