import { NREResidentToken } from '@hedgehoginvest/nre-resident-token';
import { ethers } from 'ethers';
import { UserOperationEventEvent } from 'userop/dist/typechain/EntryPoint';

import { useAccountAbstraction } from '@hedgehog/browser/shared/account-abstraction';
import {
  LazyQueryResultTuple,
  QueryResult,
  useQueryResult,
} from '@hedgehog/browser/shared/utils';
import { useEnvironment } from '@hedgehog/ui/environment';

export interface SignSubscriptionParams {
  documentHash: string;
}

export type SignSubscriptionFunc = ({
  documentHash,
}: SignSubscriptionParams) => Promise<QueryResult<UserOperationEventEvent>>;

export const useLazySignSubscription = (): LazyQueryResultTuple<
  UserOperationEventEvent,
  SignSubscriptionParams
> => {
  const {
    tenantToken: { address: residentTokenAddress },
  } = useEnvironment();
  const { jsonRpcProvider, sendUserOperation } = useAccountAbstraction();
  const [state, controls] = useQueryResult<UserOperationEventEvent>();

  const signSubscription = async ({
    documentHash,
  }: SignSubscriptionParams): Promise<QueryResult<UserOperationEventEvent>> => {
    const contract = new ethers.Contract(
      residentTokenAddress,
      NREResidentToken.abi,
      jsonRpcProvider,
    );
    try {
      controls.setLoading(true);
      const response = await sendUserOperation((account) =>
        account.execute(
          residentTokenAddress,
          0,
          contract.interface.encodeFunctionData('signSubscription', [
            [documentHash],
          ]),
        ),
      );

      controls.setError(null);
      controls.setData(response);
      return { data: response, error: null, loading: false };
    } catch (err) {
      controls.setData(null);
      controls.setError(err as Error);
      return { data: null, error: err, loading: false };
    } finally {
      controls.setLoading(false);
    }
  };

  return [signSubscription, state];
};
