import styled from 'styled-components';

import { AssetCategory, OrderStatus } from '@hedgehog/data-access/graphql';
import { Paragraph } from '@hedgehog/ui/typography';
import { StandardProps } from '@hedgehog/ui/utils';

import { OrderStatusNoteContent } from './order-status-note-content.component';

export type OrderStatusNoteProps = StandardProps<
  {
    status: OrderStatus;
    themes: AssetCategory[];
    quantity?: number;
  },
  never
>;

const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

export const OrderStatusNote = styled(
  ({ themes, quantity, status }: OrderStatusNoteProps): JSX.Element => {
    const isTenantToken = themes.includes(AssetCategory.TENANT_TOKEN);
    return (
      <ContentWrapper>
        <OrderStatusNoteContent
          status={status}
          quantity={quantity}
          isTenantToken={isTenantToken}
          defaultNote={
            <Paragraph small color="grey400">
              Investment status
            </Paragraph>
          }
        />
      </ContentWrapper>
    );
  },
)``;
