import { useLazyQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import styled, { CSSObject } from 'styled-components';

import {
  GET_PARTNER,
  GetPartnerQuery as GetPartner,
} from '@hedgehog/data-access/graphql';
import { LinkButton } from '@hedgehog/ui/buttons';
import { LayeredIcons } from '@hedgehog/ui/icons';
import { Page } from '@hedgehog/ui/layouts';
import { ThemeColorName } from '@hedgehog/ui/themes';
import { Heading, Paragraph } from '@hedgehog/ui/typography';
import { screens } from '@hedgehog/utils/sizes';

import { SetPasswordForm, SetPasswordFormData } from '../../containers';
import { deepLinkParams, useJwtPayload, useSignup } from '../../hooks';
import { termsUrl, privacyPolicyUrl } from '../content';

const AuthPage = styled(Page)`
  overflow: unset;
  max-width: 28rem;
  margin: auto;
`;

const Layout = styled.div`
  @media only screen and (min-width: ${screens.medium}px) {
    padding: 1rem;
  }
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

const CenterLayout = styled.div`
  flex-direction: row;
  display: flex;
  margin: auto;
`;

const DesktopColumn = styled.div`
  @media only screen and (max-width: ${screens.medium}px) {
    display: none;
  }
  width: 50%;
`;

const MobileColumn = styled.div`
  @media only screen and (min-width: ${screens.medium}px) {
    width: 50%;
  }
  margin: auto;
  padding-top: 4rem;
`;

const AccentJumbotron = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media only screen and (min-width: ${screens.medium}px) {
    gap: 1.5rem;
    padding: 3.75rem;
  }
`;

const AccentIllustration = styled.img`
  flex: 0 1 auto;
  width: 100%;
  margin-top: 3rem;
  max-height: 15rem;
  max-width: 15rem;
`;

const AccentContent = styled.div<{ accentColor: ThemeColorName }>`
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  padding: 2rem 2rem 4rem;
  box-sizing: border-box;
  background-color: ${({ theme, accentColor }): string =>
    theme.colors[accentColor]};
  background-image: linear-gradient(
    ${({ theme, accentColor }): string => theme.colors[accentColor]},
    #f3ecff
  );
  @media only screen and (min-width: ${screens.medium}px) {
    height: calc(100vh - 4rem);
    border-radius: 1.5rem;
  }
`;

const FormColumn = styled.div`
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media only screen and (min-width: ${screens.medium}px) {
    align-items: center;
    flex: 1 1 50%;
  }
`;

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media only screen and (min-width: ${screens.medium}px) {
    max-width: 19.375rem;
  }
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.5rem 0;
`;

const Strong = styled.strong`
  ${({ theme }): CSSObject => ({ color: theme.colors.black })}
`;

export const PartnerKYCSignupPage = (): JSX.Element => {
  const [passwordData, setPasswordData] = useState<SetPasswordFormData>({
    password: '',
    confirmPassword: '',
  });
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [searchParams] = useSearchParams();
  const { data: jwt } = useJwtPayload();
  const [getPartner, { data, loading: partnerLoading }] =
    useLazyQuery<GetPartner>(GET_PARTNER);
  const [signup, { loading }] = useSignup(jwt);

  const prefilledEmail =
    searchParams.get('email') ||
    searchParams.get(deepLinkParams['email']) ||
    '';
  const prefilledFirstName =
    searchParams.get('firstName') ||
    searchParams.get(deepLinkParams['firstName']);

  const partner = data?.partner;
  const partnerKey = location.pathname.split('/')[1];
  useEffect(() => {
    if (!partnerKey) return;
    getPartner({ variables: { slug: partnerKey } });
  }, [partnerKey, getPartner]);

  const onPasswordSubmit = async (
    formData: Required<SetPasswordFormData>,
  ): Promise<void> => {
    try {
      await signup({
        email: prefilledEmail,
        password: formData.password,
        isPartnerClient: true,
      });
      navigate('/explore?source=signup');
    } catch (err: unknown) {
      Sentry.captureException(err);
    }
  };

  const landingPageImgSrc =
    'https://assets.hedgehog-invest.com/images/secondary_icon.png';

  return (
    <Layout>
      <CenterLayout>
        <DesktopColumn>
          <AccentContent accentColor="secondary">
            <AccentJumbotron>
              <AccentIllustration src={landingPageImgSrc} />
              <Heading level="h5">
                We are Hedgehog, we take care of KYC for our partners so that
                you have a smooth and easy process
              </Heading>
              <Paragraph align="center" color="grey500">
                If you can't sign up, please contact contact@hedgehog-invest.com
              </Paragraph>
            </AccentJumbotron>
          </AccentContent>
        </DesktopColumn>
        <MobileColumn>
          <AuthPage backgroundColor="transparent">
            <HeaderRow>
              <LayeredIcons
                icons={[
                  'hedgehog',
                  { src: partner?.resources?.logotypeLightUrl || '' },
                ]}
              />
              <LinkButton to="/login">Sign in</LinkButton>
            </HeaderRow>
            <FormColumn>
              <FormContainer>
                <Heading level="h4">
                  Hey {prefilledFirstName}, let's verify you
                </Heading>
                <Paragraph color="grey500">
                  Please set a password for <Strong>{prefilledEmail}</Strong> to
                  secure your account
                </Paragraph>
                <SetPasswordForm
                  loading={loading || partnerLoading}
                  defaultForm={passwordData}
                  onFormChange={setPasswordData}
                  onSubmit={onPasswordSubmit}
                  errors={state?.error ? [state.error.message] : undefined}
                  termsUrl={termsUrl}
                  privacyPolicyUrl={privacyPolicyUrl}
                />
              </FormContainer>
            </FormColumn>
          </AuthPage>
        </MobileColumn>
      </CenterLayout>
    </Layout>
  );
};

export default PartnerKYCSignupPage;
