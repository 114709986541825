import { MutationTuple, useMutation } from '@apollo/client';

import {
  CREATE_DECLARATION,
  CreateDeclarationMutation as CreateDeclaration,
  CreateDeclarationMutationVariables as CreateDeclarationVariables,
  GET_ONBOARDING_WORKFLOWS,
  GET_USER_ACTION_DATA,
} from '@hedgehog/data-access/graphql';

export const useCreateDeclaration = (): MutationTuple<
  CreateDeclaration,
  CreateDeclarationVariables
> =>
  useMutation<CreateDeclaration, CreateDeclarationVariables>(
    CREATE_DECLARATION,
    {
      refetchQueries: [
        { query: GET_USER_ACTION_DATA },
        { query: GET_ONBOARDING_WORKFLOWS },
      ],
      awaitRefetchQueries: true,
    },
  );
