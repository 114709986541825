import { createContext } from 'react';

export interface AssessmentRouterSettingsContextType {
  redirectTo: string;
}

export const AssessmentRouterSettingsContext =
  createContext<AssessmentRouterSettingsContextType>({
    redirectTo: '',
  });
