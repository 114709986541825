import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const DEFAULT_BODY_ID = 'body';

const scrollToEl = (elId: string): void => {
  const target = document.getElementById(elId);
  if (target) {
    target.scrollIntoView(true);
  }
};

export const useScrollToBody = (bodyId = DEFAULT_BODY_ID): void => {
  const location = useLocation();

  useEffect((): void => {
    if (location.hash.includes('body')) {
      scrollToEl(bodyId);
    }
  }, [location.pathname, location.hash, bodyId]);
};
