import { Suspense } from 'react';
import { Routes, Route, Outlet, Navigate } from 'react-router-dom';
import styled from 'styled-components';

import {
  Asset,
  AssetRoundClassStatus,
  GetAssetQuery,
} from '@hedgehog/data-access/graphql';
import { LoadingContainer } from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';
import { screens } from '@hedgehog/utils/sizes';

import {
  AssetPageTab,
  ReturnsTab,
  InvestmentTab,
  MarketAnalysisTab,
  DocumentsTab,
  HowItWorksTab,
  OverviewTab,
  CustomTab,
} from '../../components';

export interface AssetArticleProps {
  // asset: GetAsset_asset;
  asset: GetAssetQuery['asset'];
  className?: string;
}

const Container = styled.article`
  min-height: 100vh;
  @media (min-width: ${screens.medium}px) {
    min-height: unset;
  }
`;

export const AssetTabs = (props: AssetArticleProps): JSX.Element => (
  <Container className={props.className} id="body">
    <Routes>
      <Route
        element={
          <Suspense
            fallback={
              <AssetPageTab>
                <LoadingContainer>
                  <Loader />
                </LoadingContainer>
              </AssetPageTab>
            }
          >
            <AssetPageTab>
              <Outlet />
            </AssetPageTab>
          </Suspense>
        }
      >
        <Route index element={<Navigate to="overview" />} />
        <Route
          path="overview"
          element={<OverviewTab asset={props.asset as Asset} />}
        />
        <Route
          path="investment"
          element={<InvestmentTab asset={props.asset as Asset} />}
        />
        <Route
          path="area"
          element={<MarketAnalysisTab asset={props.asset as Asset} />}
        />

        <Route
          path="how-it-works"
          element={<HowItWorksTab asset={props.asset} />}
        />

        {props.asset.currentRound &&
          props.asset.currentRound.roundClass.status !==
            AssetRoundClassStatus.PAID_OUT && (
            <Route
              path="docs"
              element={
                <DocumentsTab
                  asset={props.asset}
                  assetRoundClassId={props.asset.currentRound.roundClass.id}
                />
              }
            />
          )}

        {props.asset.currentRound &&
          props.asset.currentRound.roundClass.status ===
            AssetRoundClassStatus.PAID_OUT && (
            <Route
              path="returns"
              element={<ReturnsTab asset={props.asset as Asset} />}
            />
          )}

        <Route
          path=":slug"
          element={<CustomTab asset={props.asset as Asset} />}
        />
      </Route>
    </Routes>
  </Container>
);
