import { useMemo } from 'react';

import { StandardProps } from '@hedgehog/ui/utils';

import { AssessmentRouterSettingsContext } from './assessment-router-settings.context';

export type AssessmentRouterSettingsProps = StandardProps<{
  redirectTo?: string;
}>;

export const AssessmentRouterSettings = ({
  redirectTo = '/kyc',
  children,
}: AssessmentRouterSettingsProps): JSX.Element => {
  const context = useMemo(() => ({ redirectTo }), [redirectTo]);
  return (
    <AssessmentRouterSettingsContext.Provider value={context}>
      {children}
    </AssessmentRouterSettingsContext.Provider>
  );
};
