import { useLocation, Link } from 'react-router-dom';
import styled, { CSSProp } from 'styled-components';

import { NativeMobileExperiences } from '@hedgehog/data-access/native-mobile';
import { Icon, Chunk } from '@hedgehog/ui/icons';
import { screens } from '@hedgehog/utils/sizes';
import { depth } from '@hedgehog/utils/sizes';

import { NativeMobileExperience } from '../NativeMobileExperience/NativeMobileExperience';

const FootbarNest = styled.nav`
  margin-top: 6rem;
  @media (min-width: ${screens.medium}px) {
    display: none;
    visibility: hidden;
    margin-top: 0;
  }
`;

const FootbarContainer = styled.div`
  z-index: ${depth.footbar};
  position: fixed;
  bottom: 0;
  width: 100%;
`;

const CustomHedgehogIcon = styled(Icon)`
  ${Chunk} {
    width: 1.375rem;
  }
`;

const FootbarInnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  max-height: 2.5rem;
  padding: 1rem 2.25rem 2.5rem;
  border-top: 1px solid;
  ${({ theme }): CSSProp => ({
    borderTopColor: theme.colors.grey100,
    backgroundColor: theme.colors.white,
  })};
`;

type MenuItem = {
  icon: string;
  path: string;
  label: string;
};

type FootbarProps = {
  isWhitelabel: boolean;
  learnSectionEnabled: boolean;
};

export const Footbar = ({
  isWhitelabel,
  learnSectionEnabled,
}: FootbarProps): JSX.Element => {
  const location = useLocation();
  const menuItems: Array<MenuItem | undefined> = [
    {
      icon: isWhitelabel ? 'home' : 'hedgehog',
      path: '/explore',
      label: 'Explore',
    },
    {
      icon: 'chart',
      path: '/portfolio',
      label: 'Portfolio',
    },
    learnSectionEnabled
      ? {
          icon: 'book',
          path: '/learn',
          label: 'Learn',
        }
      : undefined,
    {
      icon: 'user',
      path: '/account',
      label: 'Profile',
    },
  ];

  return (
    <NativeMobileExperience
      experience={NativeMobileExperiences.FOOT_BAR_COMPONENT}
    >
      <FootbarNest data-cy="footbar">
        <FootbarContainer>
          <FootbarInnerWrapper>
            {menuItems.map((item) => {
              if (!item) return null;
              const { icon, label, path } = item;
              const active = location.pathname.startsWith(path);
              const IconComponent =
                icon === 'hedgehog' ? CustomHedgehogIcon : Icon;
              return (
                <Link
                  key={path}
                  to={path}
                  aria-label={label}
                  data-active={active}
                >
                  <IconComponent
                    icon={icon}
                    size="m"
                    color={active ? 'primary' : 'grey300'}
                    backgroundColor="white"
                  />
                </Link>
              );
            })}
          </FootbarInnerWrapper>
        </FootbarContainer>
      </FootbarNest>
    </NativeMobileExperience>
  );
};

export default Footbar;
