import { To } from 'react-router-dom';

import { GetUserActionDataQuery as GetUserActionData } from '@hedgehog/data-access/graphql';

export interface Guard {
  name: string;
  priority: number;
  validate: (context: GetUserActionData) => To | null;
}

export type GuardAction =
  | {
      type: 'guard:mount';
      payload: Guard;
    }
  | {
      type: 'guard:unmount';
      payload: Pick<Guard, 'name'>;
    };

export interface GuardsState {
  guards: Guard[];
}

export const guardsReducer = (
  state: GuardsState,
  action: GuardAction,
): GuardsState => {
  switch (action.type) {
    case 'guard:unmount': {
      const index = state.guards.findIndex(
        ({ name }) => action.payload.name === name,
      );
      return {
        ...state,
        guards: [
          ...state.guards.slice(0, index),
          ...state.guards.slice(index + 1),
        ],
      };
    }
    case 'guard:mount': {
      return {
        ...state,
        guards: [...state.guards, action.payload],
      };
    }
    default: {
      return state;
    }
  }
};
