import styled from 'styled-components';

const Container = styled.ol`
  display: flex;
  align-items: center;

  list-style: none;
  margin: 0;
  padding: 1.5rem 0;

  button {
    margin-right: 1rem;
  }

  & > li {
    margin-right: 1rem;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export interface TopbarLinkListProps {
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
}

export const TopbarLinkList = (props: TopbarLinkListProps): JSX.Element => (
  <Container className={props.className}>{props.children}</Container>
);
