import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { CSSObject } from 'styled-components';

import { useInvestorConfirmationCreate } from '@hedgehog/browser/investors/kyc/data-access';
import { SecondaryButton } from '@hedgehog/ui/buttons';
import { CheckboxInput } from '@hedgehog/ui/inputs';
import { Paragraph } from '@hedgehog/ui/typography';

const Article = styled.article`
  display: flex;
  flex-direction: column;
  ${({ theme }): CSSObject => ({
    '& > *:not(:last-child)': {
      marginBotton: theme.spacing.normal,
    },
  })}
`;

const LabelHolder = styled.div`
  display: flex;
  gap: 1rem;
`;

export const InvestorConfirmation = (): JSX.Element => {
  const navigate = useNavigate();
  const [declare, { loading }] = useInvestorConfirmationCreate();
  const [declarationChecked, setDeclarationChecked] = useState(false);

  const handleAccept = async (): Promise<void> => {
    await declare();

    navigate('/kyc');
  };
  return (
    <>
      <Article>
        <Paragraph color="grey400">
          Tokens may only be purchased by Qualified Investors in Switzerland.
          According to Art. 10 Para. 3 of the Swiss Federal Collective
          Investment Schemes Act (CISA), &apos;Qualified Investors&apos;
          includes high-net-worth individuals who declare in writing that they
          wish to be deemed Qualified Investors. A &apos;high-net-worth
          individual&apos; is any natural person who at the time of subscribing
          to the tokens confirms they meet one of the following conditions:
        </Paragraph>
        <LabelHolder>
          <Paragraph weight="700">1</Paragraph>
          <Paragraph color="grey400">
            holds financial assets of at least CHF 500,000 or equivalent and has
            the knowledge and experience required to understand investment risks
            associated with the tokens by reason of education and experience,{' '}
            <b>or</b>
          </Paragraph>
        </LabelHolder>
        <LabelHolder>
          <Paragraph weight="700">2</Paragraph>
          <Paragraph color="grey400">
            holds financial assets of at least CHF 2 million or equivalent,
          </Paragraph>
        </LabelHolder>
      </Article>
      <CheckboxInput
        align="top"
        name="AcceptDeclaration"
        label={
          <Paragraph>
            I certify that I meet <b>one</b> of the above conditions for a
            &apos;high-net worth individual&apos; and wish to be treated as a
            Qualified Investor for the purposes of CISA
          </Paragraph>
        }
        value={declarationChecked}
        onChange={(): void => setDeclarationChecked(!declarationChecked)}
      />
      <SecondaryButton
        loading={loading}
        disabled={!declarationChecked}
        onClick={handleAccept}
      >
        Accept
      </SecondaryButton>
    </>
  );
};
