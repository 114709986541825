import {
  OverrideKycStatus,
  ParseKycCheckParams,
  isLegacy,
  parseInvestorType,
} from '@hedgehog/browser/investors/kyc/data-access';
import { StandardProps } from '@hedgehog/ui/utils';

import { OnboardingAction } from '../../components';

export type OnboardingAssessmentActionProps = StandardProps<
  ParseKycCheckParams | OverrideKycStatus,
  never
>;

export const OnboardingInvestorTypeAction = (
  kycCheckParams: OnboardingAssessmentActionProps,
): JSX.Element => {
  return (
    <OnboardingAction
      title="Investor Type"
      status={
        isLegacy(kycCheckParams)
          ? parseInvestorType(kycCheckParams)
          : kycCheckParams.statusOverride
      }
      messages={{
        inprogress: 'Verifying, please wait...',
        pending: '2 mins',
      }}
      to="#kyc/investor-type"
      state={{ redirectTo: { hash: '#kyc/investor-type/confirm' } }}
    />
  );
};
