import { Link } from 'react-router-dom';
import styled, { CSSProp } from 'styled-components';

import { VisibleAtProps, visibleAtStyle } from '@hedgehog/ui/utils';

export const AnchorLoginLink = styled(Link)<VisibleAtProps>`
  display: flex;
  margin-top: 2.5rem;
  justify-content: center;
  ${visibleAtStyle}
  ${({ theme: { colors, typography, spacing } }): CSSProp => ({
    marginRight: spacing.normal,
    color: colors.shadow400,
    fontSize: typography.body_small.fontSize,
    textDecoration: 'underline',
  })}
`;
