import { useNavigate } from 'react-router-dom';
import styled, { CSSObject } from 'styled-components';

import { useAuthUser } from '@hedgehog/browser/investors/account/data-access';
import {
  useAnalyticsPage,
  useAnalyticsTrack,
} from '@hedgehog/browser/investors/shared/analytics';
import {
  termsUrl as defaultTermsUrl,
  privacyPolicyUrl as defaultPrivacyPolicyUrl,
} from '@hedgehog/browser/shared/auth';
import { FeatureFlagNames } from '@hedgehog/data-access/graphql';
import { useFeatureToggle } from '@hedgehog/data-access/hooks';
import { ActionCard } from '@hedgehog/ui/cards';
import { VSpace } from '@hedgehog/ui/layouts';
import { Page } from '@hedgehog/ui/layouts';
import { Heading, Note } from '@hedgehog/ui/typography';
import { screens } from '@hedgehog/utils/sizes';

import { AccountReferrals } from '../containers/referrals-card.container';

const GreyNote = styled(Note)`
  ${({ theme }): CSSObject => ({ color: theme.colors.grey400 })}
`;

const SmallNote = styled(Note)`
  ${({ theme }): CSSObject => ({
    fontSize: theme.typography.body_small.fontSize,
  })}
`;

const StyledPage = styled(Page)`
  @media only screen and (max-width: ${screens.medium}px) {
    max-width: 30.5rem;
  }
  @media only screen and (max-width: ${screens.small}px) {
    max-width: 25.5rem;
  }
  margin: auto;
`;

const AccountTop = styled.div`
  display: flex;
  justify-content: space-between;
  @media only screen and (min-width: ${screens.medium}px) {
    flex-direction: column;
    justify-content: start;
  }
`;

const MemberSince = styled.div`
  @media only screen and (min-width: ${screens.medium}px) {
    margin-top: 1.5rem;
  }
`;

const AccountTermsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-top: auto;
  @media only screen and (min-width: ${screens.medium}px) {
    align-items: start;
    margin-top: 3rem;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-gap: 1rem 1.5rem;
  @media only screen and (min-width: ${screens.medium}px) {
    grid-auto-flow: column;
    grid-template-columns: 22.5rem auto 22.5rem;
  }
`;

const LastGrid = styled.div`
  @media only screen and (min-width: ${screens.medium}px) {
    order: 3;
    max-width: 22.5rem;
  }
`;

const AccountTermsAndConditions = ({
  className,
}: {
  className?: string;
}): JSX.Element | null => {
  const user = useAuthUser();
  if (!user) return null;

  // For the tenant token project, USA users are consider all residents. This implementation will need to be
  // changed at some point to check if the user is specifically a resident because USA users can also just be
  // normal investors in the future.
  const termsUrl =
    user?.partner?.resources?.termsAgreementsUrl || defaultTermsUrl;
  const privacyUrl =
    user?.partner?.resources?.privacyPolicyUrl || defaultPrivacyPolicyUrl;

  return (
    <AccountTermsContainer className={className}>
      <SmallNote>
        <a href={termsUrl} target="_blank" rel="noreferrer">
          Terms & conditions
        </a>
      </SmallNote>
      <SmallNote>
        <a href={privacyUrl} target="_blank" rel="noreferrer">
          Privacy policy
        </a>
      </SmallNote>
    </AccountTermsContainer>
  );
};

const MobileTermsAndConditions = styled(AccountTermsAndConditions)`
  @media only screen and (min-width: ${screens.medium}px) {
    display: none;
  }
`;

const DesktopTermsAndConditions = styled(AccountTermsAndConditions)`
  @media only screen and (max-width: ${screens.medium}px) {
    display: none;
  }
`;

export const AccountPage = (): JSX.Element => {
  useAnalyticsPage('Top Level', 'Account');
  const navigate = useNavigate();
  const track = useAnalyticsTrack();
  const user = useAuthUser();
  const featureToggle = useFeatureToggle();
  const referralsEnabled = featureToggle.hasFeature(FeatureFlagNames.referrals);

  const memberSince = (): string => {
    if (!user?.createdAt) return 'Unknown';
    const date = new Date(user.createdAt);
    return `${date.toLocaleString('default', {
      month: 'long',
    })} ${date.getFullYear()}`;
  };

  const logout = (): void => {
    track('LogoutButton', 'Clicked');
    navigate('/logout');
  };

  return (
    <StyledPage>
      <Grid>
        <AccountTop>
          <Heading level="h5">Hello {user?.firstName}.</Heading>
          <MemberSince>
            <GreyNote>Member since</GreyNote>
            <GreyNote>{memberSince()}</GreyNote>
          </MemberSince>
          <DesktopTermsAndConditions />
        </AccountTop>
        {referralsEnabled && (
          <LastGrid>
            <AccountReferrals />
          </LastGrid>
        )}
        <VSpace spacing="tiny">
          <ActionCard
            title="Personal Details"
            trailingIcon="user"
            to="#personal-details"
          />

          <ActionCard
            href={
              user?.partner?.resources?.supportUrl ||
              `https://wzjg76g519h.typeform.com/to/RizOzE5D?email=${user?.email}&name=${user?.firstName}`
            }
            title="Contact Support"
            trailingIcon="help"
            target="_blank"
          />

          <ActionCard title="Logout" trailingIcon="logout" onClick={logout} />
        </VSpace>
        <MobileTermsAndConditions />
      </Grid>
    </StyledPage>
  );
};

export default AccountPage;
