import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAuth } from '@hedgehog/data-access/contexts';
import { useEnvironment } from '@hedgehog/ui/environment';
import { LoadingContainer } from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';

import { exchangeCognitoAuthCode } from '../../utils';

export const LoginSuccessPage = (): JSX.Element => {
  const { baseUrl, cognito } = useEnvironment();
  const navigate = useNavigate();
  const auth = useAuth();
  const [searchParams] = useSearchParams();

  const cognitoUserPoolUrl = cognito.userPoolUrl;
  const cognitoClientId = cognito.clientId;

  useEffect(() => {
    const getTokens = async (): Promise<void> => {
      const token = searchParams.get('token');
      const code = searchParams.get('code');
      const redirectToUrl = searchParams.get('redirectToUrl');

      if (code && baseUrl && cognitoClientId && cognitoUserPoolUrl) {
        const tokensResponse = await exchangeCognitoAuthCode({
          appUrl: baseUrl,
          redirectUrl: 'auth/signin/callback',
          cognitoClientId,
          cognitoUserPoolUrl,
          cognitoAuthCode: code,
        });

        try {
          auth.setTokens({
            // use the id token as it contains a lot more claims e.g email, firstname, lastname etc
            accessToken: tokensResponse.id_token,
            refreshToken: tokensResponse.refresh_token,
          });

          navigate(redirectToUrl || '/explore');
        } catch (err) {
          // setTokens has failed because the user doesn't exist, redirect them to the signup confirm page
          //
          // TODO: check for this error specifically and handle other errors
          //
          // We still want to add the id_token so that the completeSignup mutation can use it to create the
          // user (validate and extract claims from it).
          auth.setTokens({
            accessToken: tokensResponse.id_token,
            refreshToken: tokensResponse.refresh_token,
          });
          navigate(
            `/signup_confirm?access_token=${tokensResponse.access_token}&id_token=${tokensResponse.id_token}`,
          );
        }
      } else if (token) {
        auth.setTokens({
          accessToken: token,
          refreshToken: '', // TODO: Should we not have a refresh token here?
        });

        navigate(redirectToUrl || '/explore');
      }
    };
    getTokens();
  }, []);

  return (
    <LoadingContainer>
      <Loader />
    </LoadingContainer>
  );
};
