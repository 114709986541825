import { MutationTuple, useMutation } from '@apollo/client';

import {
  GET_NOTIFICATIONS,
  MarkNotificationAsOpenMutation as MarkNotificationAsOpen,
  MarkNotificationAsOpenMutationVariables as MarkNotificationAsOpenVariables,
  MARK_NOTIFICATION_AS_OPEN,
} from '@hedgehog/data-access/graphql';

export function useMarkAsOpen(): MutationTuple<
  MarkNotificationAsOpen,
  MarkNotificationAsOpenVariables
> {
  return useMutation<MarkNotificationAsOpen, MarkNotificationAsOpenVariables>(
    MARK_NOTIFICATION_AS_OPEN,
    {
      refetchQueries: [{ query: GET_NOTIFICATIONS }],
    },
  );
}
