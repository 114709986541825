import { createContext, ReactNode, useContext, useState } from 'react';

type TSidebarRoutingContext = {
  hash?: string;
  setRoutingHash: (hash: string) => void;
};

export const SidebarRoutingContext = createContext<TSidebarRoutingContext>({
  setRoutingHash: (hash) => null,
});

export type SidebarRoutingProviderProps = {
  children?: ReactNode | ReactNode[];
};

export const useSidebarRouting = (): TSidebarRoutingContext => {
  return useContext(SidebarRoutingContext);
};

export const SidebarRoutingProvider = ({
  children,
}: SidebarRoutingProviderProps): JSX.Element => {
  const [hash, setHash] = useState<string>();
  return (
    <SidebarRoutingContext.Provider
      value={{
        setRoutingHash: setHash,
        hash,
      }}
    >
      {children}
    </SidebarRoutingContext.Provider>
  );
};
