import { FetchResult, MutationTuple, useMutation } from '@apollo/client';

import {
  CREATE_DECLARATION,
  CreateDeclarationMutation as CreateDeclaration,
  CreateDeclarationMutationVariables as CreateDeclarationVariables,
  DeclarationType,
} from '@hedgehog/data-access/graphql';

export const useInvestorConfirmationCreate = (): MutationTuple<
  CreateDeclaration,
  CreateDeclarationVariables
> => {
  const [fetch, declaration] = useMutation<
    CreateDeclaration,
    CreateDeclarationVariables
  >(CREATE_DECLARATION);
  return [
    (): Promise<FetchResult<CreateDeclaration>> =>
      fetch({ variables: { type: DeclarationType.INVESTOR_CONFIRMATION } }),
    declaration,
  ];
};
