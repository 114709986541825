import { ForwardedRef, forwardRef } from 'react';
import styled from 'styled-components';

import {
  AssetRoundClassStatus,
  GetAvailableAssetsQuery,
  OrderStatus,
} from '@hedgehog/data-access/graphql';
import { AssetCard, AssetCardProps } from '@hedgehog/ui/assets';
import { StandardProps } from '@hedgehog/ui/utils';

import { StandardFootnote } from '../standard-footnote/standard-footnote.component';
import { TenantTokenFootnote } from '../tenant-token-footnote/tenant-token-footnote.component';

type GetAvailableAssets_availableAssets =
  GetAvailableAssetsQuery['availableAssets'][number];

export type AssetRoundCardProps = StandardProps<
  {
    asset: Omit<GetAvailableAssets_availableAssets, 'path'> & {
      path?: string | null;
    };
  } & Pick<AssetCardProps, 'type'>,
  never
>;

export const AssetRoundCard = styled(
  forwardRef(
    (
      { asset, ...cardProps }: AssetRoundCardProps,
      ref: ForwardedRef<HTMLAnchorElement | HTMLDivElement>,
    ): JSX.Element => {
      const themes = asset.themes?.map(({ category }) => category) ?? [];
      const isTenantToken = asset.isTenantToken && asset.order;
      const thumbnails =
        asset.thumbnails
          .slice(0, asset.underlyingAssetCount)
          .map(({ thumbnailSrc, src }) => thumbnailSrc || src || '') ?? [];

      const pathname =
        asset.status === AssetRoundClassStatus.PAID_OUT
          ? `/assets/${asset.path}/returns`
          : `/assets/${asset.path}/overview`;
      const to =
        asset.status === 'COMING_SOON'
          ? `/assets/${asset.path}/coming-soon`
          : pathname;

      return (
        <AssetCard
          ref={ref}
          title={asset.title}
          to={to}
          categories={themes}
          thumbnails={thumbnails}
          partners={
            asset.isTenantToken
              ? [
                  {
                    id: 'uno',
                    src: 'https://assets.hedgehog-invest.com/partners/uno/logotype.svg',
                    name: 'UNO',
                  },
                ]
              : []
          }
          body={
            isTenantToken ? (
              <TenantTokenFootnote
                claimed={asset.order?.status === OrderStatus.CLAIMED}
                amount={asset.order?.quantity || 0}
              />
            ) : (
              <StandardFootnote
                status={asset.status || AssetRoundClassStatus.UPCOMING}
                amount={
                  asset.minimumInvestmentAmount !== null
                    ? asset.minimumInvestmentAmount
                    : undefined
                }
                countdown={
                  asset.fundedAt ? new Date(asset.fundedAt) : undefined
                }
                currency={asset.currency || undefined}
              />
            )
          }
          {...cardProps}
        />
      );
    },
  ),
)``;
