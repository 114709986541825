import {
  OverrideKycStatus,
  ParseKycCheckParams,
  isLegacy,
  parseIdentityCheckStatus,
} from '@hedgehog/browser/investors/kyc/data-access';
import { StandardProps } from '@hedgehog/ui/utils';

import { OnboardingAction } from '../../components';

export type OnboardingIdentityCheckActionProps = StandardProps<
  ParseKycCheckParams | OverrideKycStatus,
  never
>;

export const OnboardingIdentityCheckAction = (
  kycCheckParams: OnboardingIdentityCheckActionProps,
): JSX.Element => {
  return (
    <OnboardingAction
      title="Personal information"
      to="#kyc/identity-check/personal"
      messages={{
        inprogress: 'Verifying, please wait...',
        retry: 'Please check your details',
        pending: '2 mins',
        blocked: '2 mins',
      }}
      icons={{
        inprogress: 'clock',
      }}
      status={
        isLegacy(kycCheckParams)
          ? parseIdentityCheckStatus(kycCheckParams)
          : kycCheckParams.statusOverride
      }
    />
  );
};
