import { useMutation } from '@apollo/client';
import { MutationTuple } from '@apollo/client/react/types/types';

import {
  UPDATE_TOPIC_PROGRESS,
  UpdateTopicProgressMutation as UpdateTopicProgress,
  UpdateTopicProgressMutationVariables as UpdateTopicProgressVariables,
  GET_COURSE,
  GET_TOPIC,
} from '@hedgehog/data-access/graphql';

export const useCourseTopicUpdate = (): MutationTuple<
  UpdateTopicProgress,
  UpdateTopicProgressVariables
> => {
  return useMutation<UpdateTopicProgress, UpdateTopicProgressVariables>(
    UPDATE_TOPIC_PROGRESS,
    {
      refetchQueries: [GET_COURSE, GET_TOPIC],
    },
  );
};
