import { useEffect } from 'react';
import { UserOperationEventEvent } from 'userop/dist/typechain/EntryPoint';

import { useAccountAbstraction } from '@hedgehog/browser/shared/account-abstraction';
import { QueryResult } from '@hedgehog/browser/shared/utils';

import {
  SignSubscriptionParams,
  useLazySignSubscription,
} from './use-lazy-sign-subscription.hook';

export const useSignSubscription = ({
  documentHash,
}: SignSubscriptionParams): QueryResult<UserOperationEventEvent> => {
  const { address, jsonRpcProvider } = useAccountAbstraction();
  const [signAgreement, returnData] = useLazySignSubscription();

  useEffect(() => {
    // Wait for the private key to be generated
    if (!address) return;
    if (!jsonRpcProvider) return;
    signAgreement({ documentHash });
  }, [address, jsonRpcProvider]);

  return returnData;
};
