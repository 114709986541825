import {
  PartnerSettingName,
  isPartnerSettingEnabled,
} from '@hedgehog/data-access/graphql';

import { usePartner } from '../providers';

export const usePartnerSetting = (
  settingName: `${PartnerSettingName}`,
): { enabled: boolean | null; loading: boolean } => {
  const { partner, loading } = usePartner();
  const enabled = partner?.settings
    ? isPartnerSettingEnabled(partner?.settings, settingName)
    : null;
  return { enabled, loading };
};
