import Cookies from 'js-cookie';

export const useSourceTrackingTags = (): Record<string, string | undefined> => {
  const marketingSource = Cookies.get('marketingsource');
  const initialReferrer = Cookies.get('initialreferrer');

  const allCookies = Cookies.get();

  const utmKeys = Object.keys(allCookies).filter((key) =>
    key.startsWith('utm_'),
  );

  const utmCookies: Record<string, string> = {};

  utmKeys.forEach((key) => {
    utmCookies[key] = allCookies[key];
  });

  return {
    ...utmCookies,
    marketingSource,
    initialReferrer,
  };
};
