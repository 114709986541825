import { ApolloError } from '@apollo/client';

import { useAuthenticatedQuery } from '@hedgehog/data-access/contexts';
import {
  GET_SUBSCRIPTION_AGREEMENT,
  SubscriptionAgreementQuery,
  SubscriptionAgreementQueryVariables as SubscriptionAgreementVariables,
} from '@hedgehog/data-access/graphql';

type Agreement =
  SubscriptionAgreementQuery['subscriptionAgreement']['agreement'];

interface UseSubscriptionAgreementParams {
  orderId: string;
}
interface UseSubscriptionAgreementReturn {
  agreement: Agreement | null;
  subscriptionAgreementUrl?: string;
  subscriptionAgreementLoading: boolean;
  error: ApolloError | undefined;
}
export const useSubscriptionAgreement = ({
  orderId,
}: UseSubscriptionAgreementParams): UseSubscriptionAgreementReturn => {
  const { data, loading, error } = useAuthenticatedQuery<
    SubscriptionAgreementQuery,
    SubscriptionAgreementVariables
  >(GET_SUBSCRIPTION_AGREEMENT, { orderId });

  const url = data?.subscriptionAgreement.subscriptionAgreementUrl;
  const agreement = data?.subscriptionAgreement.agreement || null;

  return {
    agreement,
    subscriptionAgreementUrl: url,
    subscriptionAgreementLoading: loading,
    error,
  };
};
