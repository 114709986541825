import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { ReactEventHandler, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import {
  useIdentityCheck,
  useUser,
  useUserUpdate,
} from '@hedgehog/browser/investors/kyc/data-access';
import { useAnalyticsPage } from '@hedgehog/browser/investors/shared/analytics';
import {
  getPersonalInformation,
  getTaxInformation,
  updateTaxInformation,
  useDispatch,
  useSelector,
} from '@hedgehog/browser/investors/shared/redux';
import { countriesLongCodeToName, mask } from '@hedgehog/browser/shared/utils';
import {
  CREATE_DECLARATION,
  CreateDeclarationMutation as CreateDeclaration,
  CreateDeclarationMutationVariables as CreateDeclarationVariables,
} from '@hedgehog/data-access/graphql';
import { DeclarationType } from '@hedgehog/data-access/graphql';
import { SecondaryButton } from '@hedgehog/ui/buttons';
import {
  MenuButtonInput,
  RadioInput,
  RadioInputGroup,
  TextInput,
} from '@hedgehog/ui/inputs';
import { VSpace } from '@hedgehog/ui/layouts';
import { useModal } from '@hedgehog/ui/modals';
import { Heading } from '@hedgehog/ui/typography';

import { TaxDeclarationModal } from '../../components';

const CountriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem 1.5rem;
  gap: 1rem;
  flex: 1;
`;

const taxIdentifierLabelByCountry: Record<string, string> = {
  GBR: 'National Insurance Number',
  USA: 'Social Security Number',
  CHE: 'OASI Number',
};

export const TaxInformation = (): JSX.Element => {
  useAnalyticsPage('KYC', 'Tax Information');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const personal = useSelector(getPersonalInformation);
  const tax = useSelector(getTaxInformation);
  const { data: userData } = useUser();
  const [updateUser] = useUserUpdate();
  const [
    startIdentityCheck,
    {
      data: identityCheckData,
      loading: identityCheckLoading,
      error: identityCheckError,
    },
  ] = useIdentityCheck();

  const [shouldMask, setShouldMask] = useState(true);
  const toggleShouldMask = (): void => setShouldMask(!shouldMask);

  const { openAndResolveWithSubmitValue: waitForConsent } = useModal(
    ({ submitModal }) => (
      <TaxDeclarationModal
        firstName={userData?.user?.firstName || ''}
        lastName={userData?.user?.lastName || ''}
        dob={personal.dob || ''}
        taxIdentifier={tax.taxIdentifier || ''}
        taxResidency={tax.taxResidency || ''}
        onConfirm={submitModal}
      />
    ),
  );

  const [declareToFATCA, { loading }] = useMutation<
    CreateDeclaration,
    CreateDeclarationVariables
  >(CREATE_DECLARATION);

  const askForFATCAConsent = async (): Promise<void> => {
    try {
      await waitForConsent();
    } catch (err) {
      // NOOP
    }
    const variables = { type: DeclarationType.FATCA };
    await declareToFATCA({ variables });
  };

  const handleStartIdentityCheck: ReactEventHandler = async (
    event,
  ): Promise<void> => {
    event.preventDefault();
    try {
      await askForFATCAConsent();
      await updateUser({
        variables: {
          ...personal,
          ...tax,
        },
      });
      await startIdentityCheck();
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  useEffect(() => {
    if (!userData?.user) return;
    dispatch(
      updateTaxInformation({
        taxResidency: userData.user.taxResidency || '',
        taxIdentifier: userData.user.taxIdentifier || '',
      }),
    );
  }, [userData]);

  useEffect(() => {
    if (identityCheckData) {
      return navigate('#kyc');
    }
    if (identityCheckError) {
      return navigate('#kyc/identity-check/personal');
    }
  }, [identityCheckData, identityCheckError]);

  const isNotFulfilled = !(tax.taxResidency && tax.taxIdentifier);
  const taxIdentifierName =
    taxIdentifierLabelByCountry[tax.taxResidency] || 'Tax identifier';

  return (
    <>
      <VSpace spacing="tiny">
        <MenuButtonInput
          name="taxResidency"
          data-testid="taxResidency"
          value={countriesLongCodeToName[tax.taxResidency]}
          placeholder="E.g. United States of America"
          label="Tax resident in"
        >
          {(close: () => void) => (
            <CountriesContainer>
              <Heading level="h5">I am a tax resident in:</Heading>
              <RadioInputGroup
                onChange={(checked, values): void => {
                  const [check] = checked;
                  const taxResidency = values[check];
                  dispatch(
                    updateTaxInformation({
                      ...tax,
                      taxResidency,
                    }),
                  );
                  close();
                }}
              >
                <RadioInput
                  name="us"
                  id="us"
                  label="United States of America"
                  value="USA"
                />
                <RadioInput
                  name="uk"
                  id="uk"
                  label="United Kingdom"
                  value="GBR"
                />
                <RadioInput name="ch" id="ch" label="Switzerland" value="CHE" />
              </RadioInputGroup>
            </CountriesContainer>
          )}
        </MenuButtonInput>

        <TextInput
          name="taxIdentifier"
          data-testid="taxIdentifier"
          label={taxIdentifierName}
          placeholder={`Type your ${taxIdentifierName}`}
          onChange={(value): void => {
            dispatch(
              updateTaxInformation({
                ...tax,
                taxIdentifier: value,
              }),
            );
          }}
          value={shouldMask ? mask(tax.taxIdentifier) : tax.taxIdentifier}
          onFocus={toggleShouldMask}
          onBlur={toggleShouldMask}
        />
      </VSpace>
      <SecondaryButton
        disabled={isNotFulfilled}
        loading={loading || identityCheckLoading}
        onClick={handleStartIdentityCheck}
      >
        Confirm & Proceed
      </SecondaryButton>
    </>
  );
};
