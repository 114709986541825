import { EnvironmentVariables } from './environment.type';

export const environment: EnvironmentVariables = {
  production: false,
  type: 'staging',
  baseUrl: 'https://app.qa.hedgehog-invest.com',
  ssoUrl: 'https://app.qa.hedgehog-invest.com/signup_confirm',
  api: {
    url: 'https://api.qa.hedgehog-invest.com',
  },
  ios: {
    clientId: 'com.hedgehog.invest.qa',
  },
  segment: {
    writeKey: 'YwjmDHnujHCJlEoW7Y69M4E13oplB5EA',
  },
  sentry: {
    dsn: 'https://4edcc3b2ddfe41a4ac8973dc49b474e5@o1179604.ingest.sentry.io/6291872',
  },
  docusign: {
    accreditation: { powerFormId: '78b6a8ed-4ba9-45c2-81a1-234c633d206e' },
  },
  flagsmith: {
    id: 'Y3DjGz5zvMsxVgUkMBff8j',
  },
  veriff: {
    url: 'https://api.veriff.me/v1',
    apiKey: '604d16d9-5d79-45b3-9adc-b27ecedd7478',
  },
  postcoder: {
    apiKey: 'PCWCE-37UTW-ZKQCN-4E6ER',
    useMocks: false,
  },
  cognito: {
    userPoolId: 'eu-west-2_qQ9NZN9g7',
    clientId: '33b1u13gesest6np0qbgct0rsa',
    userPoolUrl: 'https://qa-hedgehog-invest.auth.eu-west-2.amazoncognito.com',
  },
  web3auth: {
    clientId:
      'BGHgVlPl02ohGPj9Q6zXnh2fi9a9bAsHMK4__w4GufkyfHpHOR34vrtnrUgIUiRms1O2dZIodDoGmBlnBHEsPf8',
    network: 'testnet',
    verifier: 'hedgehog-invest-browser-qa',
  },
  blockchain: {
    explorer: 'https://sepolia.etherscan.io/',
    chainId: '0xaa36a7',
    rpcUrl:
      'https://eth-sepolia.g.alchemy.com/v2/1EYNZY_LZsbNpzUzfFOguhtdHEzFHUCL',
  },
  erc4337: {
    entryPointAddress: '0x5FF137D4b0FDCD49DcA30c7CF57E578a026d2789',
    accountFactoryAddress: '0xb3A5e2ADae7974619BA1aE8aF03397D8311bf7c8',
    bundlerUrl:
      'https://api.stackup.sh/v1/node/ba880279b535e13806abfe109641d5f119ac11b8e66095225bb506452a4d4646',
    paymasterUrl:
      'https://api.stackup.sh/v1/paymaster/ba880279b535e13806abfe109641d5f119ac11b8e66095225bb506452a4d4646',
  },
  tenantToken: {
    address: '0x15d884529cf6C29E0dD35A0851F08B3941391377',
  },
  contracts: {
    tokenIncome: { address: '0x092e82532b0cED85cbC627b277f103ED345f2a69' },
  },
  signInWithGoogle: {
    clientId:
      '470428818475-s0fpi16o3oclbkjjrrj461gkci8rsbr5.apps.googleusercontent.com',
  },
};
