export const validatePostcode = (
  countryCode: string,
  postcodeValue: string,
): string[] => {
  let errors: string[] = [];
  if (!postcodeValue) {
    return ['Postcode looks empty'];
  }
  if (countryCode === 'GBR') {
    const ukPostcodeRegex =
      /^([a-zA-Z]{1,2}[a-zA-Z\d]{1,2})\s?(\d[a-zA-Z]{2})$/;
    errors = ukPostcodeRegex.test(postcodeValue)
      ? []
      : ['Please write a valid postal code. Example: SE15 3YN'];
  }
  return errors;
};
