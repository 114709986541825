import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import styled, { CSSObject } from 'styled-components';

import { useAuthenticatedLazyQuery } from '@hedgehog/data-access/contexts';
import {
  GetAssetFeedbackQuery as GetAssetFeedback,
  GetAssetFeedbackQueryVariables as GetAssetFeedbackVariables,
  GET_ASSET_FEEDBACK,
  UpdateAssetFeedbackMutationVariables as UpdateAssetFeedbackVariables,
  UPDATE_ASSET_FEEDBACK,
  REMOVE_ASSET_FEEDBACK,
  RemoveAssetFeedbackMutationVariables as RemoveAssetFeedbackVariables,
} from '@hedgehog/data-access/graphql';
import { Icon } from '@hedgehog/ui/icons';
import { Loader } from '@hedgehog/ui/loaders';
import { Label } from '@hedgehog/ui/typography';

export interface AssetFeedbackProps {
  slug: string;
  size?: 'regular' | 'small';
}

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FeedbackButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

const FeedbackButton = styled(Icon)<{ active: boolean }>`
  ${({ active, theme }): CSSObject => ({
    color: active ? theme.colors.grey100 : theme.colors.black,
    backgroundColor: active ? theme.colors.black : theme.colors.grey100,
  })}
`;

export const AssetFeedback = ({
  slug,
  size = 'regular',
}: AssetFeedbackProps): JSX.Element => {
  const [fetchFeedback, { data: feedback, loading }] =
    useAuthenticatedLazyQuery<GetAssetFeedback, GetAssetFeedbackVariables>(
      GET_ASSET_FEEDBACK,
    );
  const [updateFeedback, { loading: updateLoading }] =
    useMutation<UpdateAssetFeedbackVariables>(UPDATE_ASSET_FEEDBACK, {
      refetchQueries: [GET_ASSET_FEEDBACK],
    });
  const [removeFeedback, { loading: removeLoading }] =
    useMutation<RemoveAssetFeedbackVariables>(REMOVE_ASSET_FEEDBACK, {
      refetchQueries: [GET_ASSET_FEEDBACK],
    });
  const isLoading = loading && !feedback;
  const isNotLoading = !isLoading;
  const isChanging = updateLoading || removeLoading;
  const score = feedback?.assetFeedback?.score;
  const isPositive = score === 1;
  const isNegative = score === 0;

  const handleRemoveFeedback = async (): Promise<void> => {
    await removeFeedback({
      variables: {
        slug,
      },
    });
  };

  const handleFeedback = async (newScore: number): Promise<void> => {
    if (score === newScore) {
      await handleRemoveFeedback();
      return;
    }
    await updateFeedback({
      variables: {
        slug,
        score: newScore,
      },
    });
  };

  useEffect(() => {
    fetchFeedback({ variables: { slug } });
  }, []);

  return (
    <>
      <TextWrapper>
        <Label color="grey300">Interested in this token?</Label>
      </TextWrapper>

      {isLoading && <Loader />}

      {isNotLoading && (
        <FeedbackButtonWrapper>
          <FeedbackButton
            icon="thumbs-up"
            size="m"
            onClick={isChanging ? undefined : () => handleFeedback(1)}
            active={isChanging ? false : isPositive}
            disabled={isChanging}
          />
          <FeedbackButton
            icon="thumbs-down"
            size="m"
            onClick={isChanging ? undefined : () => handleFeedback(0)}
            active={isChanging ? false : isNegative}
            disabled={isChanging}
          />
        </FeedbackButtonWrapper>
      )}
    </>
  );
};
