import styled, { CSSObject } from 'styled-components';

import { useAnalyticsPage } from '@hedgehog/browser/investors/shared/analytics';
import { HeaderWithBack } from '@hedgehog/ui/headers';
import { Heading } from '@hedgehog/ui/typography';

import { Veriff } from '../containers';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  ${({ theme }): CSSObject => ({ gap: theme.spacing.xlarge })};
`;

export const VerificationPage = (): JSX.Element => {
  useAnalyticsPage('KYC', 'Verification');

  return (
    <Wrapper>
      <HeaderWithBack>
        <Heading level="h5">Get verified</Heading>
      </HeaderWithBack>
      <Veriff />
    </Wrapper>
  );
};
