import styled, { CSSObject } from 'styled-components';

import { useUser } from '@hedgehog/browser/investors/kyc/data-access';
import {
  PreInvestHelper,
  useWorkflowHelper,
} from '@hedgehog/browser/investors/workflows/onboarding';
import {
  GetOnboardingWorkflowsQuery,
  WorkflowStep,
} from '@hedgehog/data-access/graphql';
import { ActionCard } from '@hedgehog/ui/cards';
import { StandardProps } from '@hedgehog/ui/utils';

import { OnboardingAccreditationAction } from '../onboarding-accreditation-action/onboarding-accreditation-action.container';
import { OnboardingAssessmentAction } from '../onboarding-assessment-action/onboarding-assessment-action.container';
import { OnboardingIdentityCheckAction } from '../onboarding-identity-check-action/onboarding-identity-check-action.container';
import { OnboardingInvestorConfirmationAction } from '../onboarding-investor-confirmation-action/onboarding-investor-confirmation-action.container';
import { OnboardingInvestorTypeAction } from '../onboarding-investor-type-action/onboarding-investor-type-action.container';
import { OnboardingTaxFormAction } from '../onboarding-tax-form-action/onboarding-tax-form-action.container';
import { OnboardingVerificationAction } from '../onboarding-verification-action/onboarding-verification-action.container';

type GetOnboardingWorkflows_onboardingWorkflows_preInvest =
  GetOnboardingWorkflowsQuery['onboardingWorkflows']['preInvest'][number];

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  ${({ theme }): CSSObject => ({ gap: theme.spacing.small })}
`;

export type OnboardingWorkflowActionsProps = StandardProps<object, never>;

const getStep = (
  helper: PreInvestHelper,
  workflow: GetOnboardingWorkflows_onboardingWorkflows_preInvest | null,
  country: string,
): JSX.Element | null => {
  switch (workflow?.name) {
    case WorkflowStep.assessment:
      return (
        <OnboardingAssessmentAction
          statusOverride={helper.assessmentStatus()}
          country={country}
          key="assessment"
        />
      );
    case WorkflowStep.accreditation:
      return (
        <OnboardingAccreditationAction
          statusOverride={helper.identityCheckStatus()}
          country={country}
          key="accreditation"
        />
      );
    case WorkflowStep.identity_check:
      return (
        <OnboardingIdentityCheckAction
          statusOverride={helper.identityCheckStatus()}
          country={country}
          key="identity_check"
        />
      );
    case WorkflowStep.investor_type_declaration:
      return (
        <OnboardingInvestorTypeAction
          statusOverride={helper.investorTypeStatus()}
          country={country}
          key="investor_type_declaration"
        />
      );
    case WorkflowStep.tax_form:
      return (
        <OnboardingTaxFormAction
          statusOverride={helper.taxFormStatus()}
          country={country}
          skipFetchTaxForm={helper.identityCheckStatus() === 'pending'}
          key="tax_form"
        />
      );
    case WorkflowStep.verification:
      return (
        <OnboardingVerificationAction
          statusOverride={helper.verificationStatus()}
          country={country}
          key="verification"
        />
      );
    case WorkflowStep.investor_confirmation_declaration:
      return (
        <OnboardingInvestorConfirmationAction
          statusOverride={helper.investorConfirmationStatus()}
          country={country}
          key="investor_confirmation_declaration"
        />
      );
    default:
      return null;
  }
};

export const OnboardingWorkflowActions = ({
  className,
}: OnboardingWorkflowActionsProps): JSX.Element => {
  const { data: userData, loading: userLoading } = useUser();
  const { helper, loading: workflowLoading } = useWorkflowHelper();

  if (!userData?.user || userLoading || workflowLoading) {
    return (
      <Wrapper className={className}>
        <ActionCard loading title="placeholder" caption="placeholder" />
      </Wrapper>
    );
  }

  return (
    <Wrapper className={className}>
      {helper.workflow.map((workflow) =>
        getStep(helper, workflow, userData?.user?.country || ''),
      )}
    </Wrapper>
  );
};
