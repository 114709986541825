import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  useAssessment,
  useAssessmentHelpRequest,
  useAssessmentSubmit,
} from '@hedgehog/browser/investors/kyc/data-access';
import { useNextStep } from '@hedgehog/browser/investors/workflows/onboarding';
import { useUserAction } from '@hedgehog/data-access/contexts';
import {
  Assessment,
  AssessmentPage,
  KycCheckStatus,
  WorkflowStep,
} from '@hedgehog/data-access/graphql';
import { useAutoscrollControls } from '@hedgehog/ui/autoscroll';
import { OutlineButton } from '@hedgehog/ui/buttons';
import { Header } from '@hedgehog/ui/headers';
import {
  EmptyStateContainer,
  LoadingContainer,
  Page,
  VSpace,
} from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';
import { Heading, Paragraph } from '@hedgehog/ui/typography';

import { AssessmentContent } from '../../components';
import {
  AssessmentAnswers,
  AssessmentComments,
  AssessmentQuizProvider,
  TAssessment,
  TAssessmentResponse,
} from '../../contexts/assessment-quiz';
import { useAssessmentRouterSettings } from '../../hooks';

export interface AssessmentQuizProps {
  comments?: boolean;
}

export const AssessmentQuiz = ({
  comments: areCommentsActive = false,
}: AssessmentQuizProps): JSX.Element => {
  const { redirectTo } = useAssessmentRouterSettings();
  const [status, setStatus] = useState<KycCheckStatus>();
  const navigate = useNavigate();
  const { data, error, loading } = useAssessment();
  const { data: userActionData } = useUserAction();
  const [submitAssessment, { data: submitResponse, error: submitError }] =
    useAssessmentSubmit();
  const { scrollTop } = useAutoscrollControls();
  const [sendRequestHelp, { called }] = useAssessmentHelpRequest();
  const { to } = useNextStep(WorkflowStep.assessment);

  useEffect(() => {
    if (!userActionData) return;
    setStatus(userActionData.assessmentProgress.status);
  }, [userActionData?.assessmentProgress.status]);

  useEffect(() => {
    if (!submitResponse) return;
    const { success, status } = submitResponse.saveResponse;
    if (success) {
      return;
    }
    scrollTop(0);
    setStatus(status);
  }, [submitResponse]);

  useEffect(() => {
    if (status !== KycCheckStatus.approved) return;
    // Navigate the user back to the KYC page using replace so that they cannot click back.
    navigate(redirectTo || to || '/', { replace: true });
  }, [status]);

  if (error || submitError) {
    return (
      <Page>
        <EmptyStateContainer>
          Something went wrong, please try again later
        </EmptyStateContainer>
      </Page>
    );
  }

  if (loading || !data?.getAssessment || !userActionData) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }

  const handleSubmit = async (
    answers: AssessmentAnswers,
    comments: AssessmentComments,
  ): Promise<void> => {
    if (!data.getAssessment) return;
    // Build the submit request from the submitted answers.
    const invalid = Object.values(answers).find((questionsMap) =>
      Object.values(questionsMap).find((answer) => answer.invalid),
    );

    const request: TAssessmentResponse = {
      assessmentId: data.getAssessment.id,
      valid: !invalid,
      response: Object.keys(answers).map((questionId: string) => {
        return {
          questionId,
          comment: areCommentsActive ? comments[questionId] : undefined,
          response: Object.keys(answers[questionId]).map((answerId) => {
            return {
              id: answerId,
              value: answers[questionId][answerId]?.value,
            };
          }),
        };
      }),
    };

    // Submit the assessment response
    submitAssessment({
      variables: {
        args: request,
      },
    });
  };

  if (status === KycCheckStatus.rejected) {
    return (
      <>
        <Header>
          <Heading level="h5">Investor assessment</Heading>
        </Header>
        <Paragraph color="grey400">
          Thank you for taking the time to fill out the investor assessment.
          Sadly, based on your responses, we feel this investment is not right
          for you at this time.
        </Paragraph>
        <Paragraph color="grey400">
          You may inquire below regarding your assessment or check back in with
          us when your investment circumstances have changed.
        </Paragraph>
        <VSpace spacing="tiny">
          <OutlineButton onClick={sendRequestHelp} disabled={called}>
            Request more information
          </OutlineButton>
          <Paragraph small color="grey400" align="center">
            We’ll aim to reply within 3 working days.
          </Paragraph>
        </VSpace>
      </>
    );
  }

  return (
    <AssessmentQuizProvider
      comments={areCommentsActive}
      assessment={data.getAssessment as TAssessment}
      onSubmit={handleSubmit}
    >
      <AssessmentContent error={status === KycCheckStatus.retry} />
    </AssessmentQuizProvider>
  );
};
