import { parseISO } from 'date-fns';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { IncomeReport } from '@hedgehog/data-access/graphql';

import { OrderTransactionItemReport } from '../order-transaction-item/order-transaction-item-report.component';

type OrderReportActionCardProps = {
  report: IncomeReport;
};

export const OrderReportActionCard = styled(
  ({ report }: OrderReportActionCardProps): JSX.Element => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    searchParams.append('reportId', report.id);

    return (
      <Link to={`${location.pathname}?${searchParams.toString()}#order/income`}>
        <OrderTransactionItemReport
          timestamp={parseISO(report.createdAt)}
          title={report.title || 'Investment report'}
        />
      </Link>
    );
  },
)``;
