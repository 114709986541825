import {
  Navigate,
  To,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom';

export interface OrderNavigateProps {
  to: To;
  replace?: boolean;
}

export const OrderNavigate = ({
  to,
  replace = false,
}: OrderNavigateProps): JSX.Element => {
  const { orderId } = useParams<{ orderId: string }>();

  const location = useLocation();
  const [searchParams] = useSearchParams();

  if (orderId) searchParams.set('orderId', orderId);
  return (
    <Navigate
      to={{
        ...location,
        ...(typeof to === 'string' ? { pathname: to } : to),
        search: searchParams.toString(),
      }}
      replace={replace}
    />
  );
};
