import { useEffect } from 'react';
import {
  Outlet,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import styled, { CSSObject } from 'styled-components';

import { useOrder } from '@hedgehog/browser/investors/order/data-access';
import { parsePadding } from '@hedgehog/ui/themes';
import { StandardProps } from '@hedgehog/ui/utils';

import { OrderHeader, OrderStatusBar } from '../containers';

const LayoutWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;

  ${({ theme }): CSSObject => ({
    gap: theme.spacing.normal,
    padding: parsePadding({
      x: theme.spacing.xlarge,
      y: theme.spacing.xxlarge,
    }),
  })};
`;

const LayoutHeader = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: stretch;
  ${({ theme }): CSSObject => ({
    gap: theme.spacing.normal,
    paddingBottom: theme.spacing.xlarge,
  })}
`;

const LayoutContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

export type OrderOverviewLayoutProps = StandardProps;

export const OrderPage = ({
  className,
  children,
}: OrderOverviewLayoutProps): JSX.Element => {
  const { orderId: paramsOrderId } = useParams<{ orderId: string }>();
  const [searchParams] = useSearchParams();
  const orderId = paramsOrderId || searchParams.get('orderId') || '';

  return (
    <LayoutWrapper className={className}>
      <LayoutHeader>
        <OrderHeader orderId={orderId} />
        <OrderStatusBar orderId={orderId} />
      </LayoutHeader>
      <LayoutContent>{children || <Outlet />}</LayoutContent>
    </LayoutWrapper>
  );
};
