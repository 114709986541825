import { format } from 'date-fns';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { CSSObject } from 'styled-components';

import {
  useIdentityCheckDecision,
  useUser,
} from '@hedgehog/browser/investors/kyc/data-access';
import { useAnalyticsPage } from '@hedgehog/browser/investors/shared/analytics';
import {
  PersonalInformationFormValues,
  getErrors,
  getPersonalInformation,
  updatePersonalInformation,
  useDispatch,
  useSelector,
} from '@hedgehog/browser/investors/shared/redux';
import { KycCheckStatus, SourceOfFunds } from '@hedgehog/data-access/graphql';
import { SecondaryButton } from '@hedgehog/ui/buttons';
import {
  AddressInput,
  DateInput,
  MenuButtonInput,
  RadioInput,
  RadioInputGroup,
} from '@hedgehog/ui/inputs';
import {
  EmptyStateContainer,
  LoadingContainer,
  VSpace,
} from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';
import { PlainToast } from '@hedgehog/ui/toasts';
import { Heading, Paragraph } from '@hedgehog/ui/typography';
import { formatSourceOfFunds, validatePostcode } from '@hedgehog/utils/formats';

const SourceOfFundsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem 1.5rem;
  gap: 1rem;
  flex: 1;
`;

const availableSourcesOfFund = [
  SourceOfFunds.SALARY,
  SourceOfFunds.SAVINGS,
  SourceOfFunds.PENSION,
  SourceOfFunds.INHERITANCE_AND_GIFTS,
  SourceOfFunds.BUSINESS_PROFITS,
  SourceOfFunds.INVESTMENT_PROCEEDS,
  SourceOfFunds.BANK_LOAN,
];

interface UserPersonalInfoLabels {
  dateOfBirthLabel: string;
  addressLine1Label: string;
  addressLine2Label: string;
  cityLabel: string;
  postcodeLabel: string;
}

const WarningMessage = styled(PlainToast)`
  ${({ theme }): CSSObject => ({
    color: theme.colors.black,
    backgroundColor: theme.colors.earthLight,
  })}
`;

export const PersonalInformation = (): JSX.Element => {
  useAnalyticsPage('KYC', 'Personal Information');
  const navigate = useNavigate();
  const { data: userData } = useUser();
  const {
    data,
    loading: decisionLoading,
    error: decisionError,
  } = useIdentityCheckDecision();

  const dispatch = useDispatch();
  const personal = useSelector(getPersonalInformation);
  const errors = useSelector(getErrors);

  const validAddress = Boolean(
    personal.addressLine1 &&
      personal.town &&
      !validatePostcode(
        userData?.user?.country || '',
        personal.postcode || userData?.user?.postcode || '',
      ).length,
  );
  const isNotFulfilled =
    userData?.user?.country === 'USA'
      ? !(personal.dob && validAddress)
      : !(personal.dob && validAddress && personal.sourceOfFunds);
  const status = data?.identityCheckProgress?.status;

  const handleConfirmForm = async (): Promise<void> => {
    if (!personal.addressLine1) return;
    if (!personal.town) return;
    if (!personal.postcode) return;

    navigate('#kyc/identity-check/tax');
  };

  // This should be replaced with localization when the time comes
  // See: https://linear.app/hedgehog-invest/issue/HOG-475
  const defaultPersonalInfoLabels = {
    dateOfBirthLabel: 'Date of birth',
    addressLine1Label: 'Address Line 1',
    addressLine2Label: 'Address Line 2',
    cityLabel: 'City',
    postcodeLabel: 'Postcode',
  };

  const personalInfoLabelsByCountry: Record<string, UserPersonalInfoLabels> = {
    USA: {
      ...defaultPersonalInfoLabels,
      postcodeLabel: 'ZIP code',
    },
    CHE: defaultPersonalInfoLabels,
    GBR: defaultPersonalInfoLabels,
  };

  const getUserPersonalInfoLabels = (): UserPersonalInfoLabels => {
    if (!userData?.user) return defaultPersonalInfoLabels;
    const labels = personalInfoLabelsByCountry[userData.user.country];
    if (!labels) return defaultPersonalInfoLabels;
    return personalInfoLabelsByCountry[userData.user.country];
  };

  const userPersonalInfoLabels: UserPersonalInfoLabels =
    getUserPersonalInfoLabels();

  const validateDateOfBirth = (value: Date): boolean => {
    const now = new Date();
    const year = value.getUTCFullYear();
    // Ensure that the entered value is not older than 1900 nor is the date in the future.
    return year > 1900 && value < now;
  };

  useEffect(() => {
    if (!userData?.user) return;
    dispatch(
      updatePersonalInformation({
        ...personal,
        dob: personal.dob || userData.user.dob || '',
        addressLine1: personal.addressLine1 || userData.user.addressLine1 || '',
        addressLine2: personal.addressLine2 || userData.user.addressLine2 || '',
        town: personal.town || userData.user.town || '',
        postcode: personal.postcode || userData.user.postcode || '',
      }),
    );
  }, [userData]);

  if (decisionError) {
    return (
      <EmptyStateContainer>
        Something went wrong, please try again later.
      </EmptyStateContainer>
    );
  }

  let validationErrors = null;
  if (errors.length > 0) {
    validationErrors = (
      <Paragraph color="primary">
        Please review the following data and try again <br />
        {errors}
      </Paragraph>
    );
  }

  if (!data || !userData || decisionLoading) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }

  if (
    ![KycCheckStatus.not_started, KycCheckStatus.retry].includes(
      data?.identityCheckProgress.status,
    )
  ) {
    return <></>;
  }

  const createFormControl = (name: keyof PersonalInformationFormValues) => ({
    name,
    'data-testid': name,
    value: personal[name],
    onChange: (value: string): void => {
      dispatch(updatePersonalInformation({ ...personal, [name]: value }));
    },
  });

  return (
    <>
      {status === KycCheckStatus.retry && (
        <WarningMessage
          description={
            "We couldn't match your details to any public records. Please check you have entered your details correctly."
          }
        />
      )}
      {validationErrors}
      <VSpace spacing="tiny">
        <DateInput
          label={userPersonalInfoLabels.dateOfBirthLabel}
          extraValidation={validateDateOfBirth}
          {...createFormControl('dob')}
          onChange={(value: Date): void => {
            const dateOfBirth = format(value, 'yyyy-MM-dd');
            dispatch(
              updatePersonalInformation({ ...personal, dob: dateOfBirth }),
            );
          }}
          onClear={(): void => {
            dispatch(updatePersonalInformation({ ...personal, dob: '' }));
          }}
        />

        <AddressInput
          name="address"
          value={personal}
          onChange={(address): void => {
            dispatch(updatePersonalInformation({ ...personal, ...address }));
          }}
          onClear={() => {
            dispatch(
              updatePersonalInformation({
                ...personal,
                addressLine1: '',
                addressLine2: '',
                town: '',
                postcode: '',
              }),
            );
          }}
          countryCode={userData.user!.country}
          title="Your Address"
          label="Your Address"
          placeholder={
            userData.user!.country === 'USA'
              ? 'Enter your address'
              : 'Type your postcode or address'
          }
          checked={validAddress}
        />

        {/* Only show this for non-US users for tenant token project. Will need to be updated to policy based KYC  */}
        {userData.user?.country !== 'USA' && (
          <MenuButtonInput
            name="sourceOfFunds"
            value={
              personal.sourceOfFunds
                ? formatSourceOfFunds(personal.sourceOfFunds)
                : ''
            }
            placeholder="Select an option"
            label="How are you funding your investments?"
          >
            {(close: () => void): JSX.Element => (
              <SourceOfFundsContainer>
                <Heading level="h5">
                  How are you funding your investments?
                </Heading>
                <RadioInputGroup
                  onChange={(checked, values): void => {
                    const [check] = checked;
                    dispatch(
                      updatePersonalInformation({
                        ...personal,
                        sourceOfFunds: values[check] as SourceOfFunds,
                      }),
                    );
                    close();
                  }}
                >
                  {availableSourcesOfFund.map((source) => (
                    <RadioInput
                      id={source.toLowerCase()}
                      key={source.toLowerCase()}
                      name={source}
                      value={source}
                      label={formatSourceOfFunds(source)}
                    />
                  ))}
                </RadioInputGroup>
              </SourceOfFundsContainer>
            )}
          </MenuButtonInput>
        )}
      </VSpace>

      <SecondaryButton onClick={handleConfirmForm} disabled={isNotFulfilled}>
        Confirm & Proceed
      </SecondaryButton>
    </>
  );
};
