import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { useState } from 'react';

import {
  RESET_PASSWORD,
  ResetPasswordMutation as ResetPassword,
  ResetPasswordMutationVariables as ResetPasswordVariables,
} from '@hedgehog/data-access/graphql';
import { HeaderWithBack } from '@hedgehog/ui/headers';
import { EmptyStateContainer, LoadingContainer } from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';
import { Heading } from '@hedgehog/ui/typography';

import { ForgotPasswordForm, ForgotPasswordFormData } from '../../containers';

export const ForgotPasswordPage = (): JSX.Element => {
  const [resetPassword, { loading, error }] = useMutation<
    ResetPassword,
    ResetPasswordVariables
  >(RESET_PASSWORD);
  const [emailSent, setEmailSent] = useState(false);
  const [formData, setFormData] = useState<ForgotPasswordFormData>({
    email: '',
  });

  const handleSubmit = async (): Promise<void> => {
    const { email } = formData;
    try {
      await resetPassword({ variables: { email } });
    } catch (err) {
      /* resetPassword returns { success: false } for any error occurred on backend side */
      Sentry.captureException(err);
    } finally {
      const params = new URLSearchParams();
      params.append('email', email);
      setEmailSent(true);
    }
  };

  if (error) {
    return (
      <>
        <HeaderWithBack />
        <EmptyStateContainer>
          Something went wrong, please try again later
        </EmptyStateContainer>
      </>
    );
  }

  if (loading) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }

  return (
    <>
      <HeaderWithBack>
        <Heading level="h5">Forgot Password</Heading>
      </HeaderWithBack>

      <ForgotPasswordForm
        sent={emailSent}
        data={formData}
        onChange={setFormData}
        onSubmit={handleSubmit}
      />
    </>
  );
};
