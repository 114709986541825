import { useEffect, useMemo, useState } from 'react';

import { useLazyAnalyticsPage } from '@hedgehog/browser/investors/shared/analytics';
import {
  ContentBlockType,
  ContentQuestionBlock,
} from '@hedgehog/data-access/graphql';
import { useModal } from '@hedgehog/ui/modals';
import { StandardProps } from '@hedgehog/ui/utils';

import { AddCommentModal } from '../../containers/add-comment-modal/add-comment-modal.container';

import { AssessmentContext } from './assessment-quiz.context';
import { getFirstPage } from './assessment-quiz.helper';
import {
  AddCommentClickHandler,
  AnswerOptionSelectHandler,
  AssessmentAnswers,
  AssessmentComments,
  TAssessment,
  TAssessmentPage,
} from './assessment-quiz.types';

export type AssessmentQuizProviderProps = StandardProps<{
  assessment: TAssessment;
  comments?: boolean;
  onSubmit?: (answers: AssessmentAnswers, comments: AssessmentComments) => void;
}>;

export const AssessmentQuizProvider = ({
  assessment,
  comments: areCommentsActive = false,
  children,
  onSubmit,
}: AssessmentQuizProviderProps): JSX.Element => {
  const trackPage = useLazyAnalyticsPage();
  const [redirectTo, setRedirectTo] = useState<string | undefined>();
  const [comments, setComments] = useState<AssessmentComments>({});
  const { openAndResolveWithSubmitValue: addComment } = useModal<
    string,
    {
      question: ContentQuestionBlock['question'];
    }
  >(
    ({ question, submitModal, cancelModal }) => (
      <AddCommentModal
        initialComment={comments[question.id] || ''}
        submit={submitModal}
        cancel={cancelModal}
      />
    ),
    { throwOnAbort: false },
  );

  const pages: Record<string, TAssessmentPage> = assessment.pages.reduce(
    (values, page) => ({
      ...values,
      [page.id]: page,
    }),
    {},
  );
  const [currentPageId, setCurrentPageId] = useState<string>(
    getFirstPage(assessment.pages) || '',
  );
  const [answers, setAnswers] = useState<AssessmentAnswers>({});
  const pageCompleted: boolean = useMemo(() => {
    const page = pages[currentPageId];
    if (!page) {
      return true;
    }

    // Find all questions belonging to the current page
    const questions = page.content
      .filter(({ type }) => type === ContentBlockType.Question)
      .map((block) => {
        return (block.data as ContentQuestionBlock).question;
      });

    // Find a question that has not been fully answered.
    const pendingQuestion = questions.find((question) => {
      const questionId = question.id;
      // If any answers have no value, the page is not complete.
      const unanswered = question.answers.find((answer) => {
        const questionAnswers = answers[questionId];
        if (!questionAnswers) {
          return true;
        }
        return !questionAnswers[answer.id];
      });
      return !!unanswered;
    });

    // If a question has not been fully answered, the page is not finished yet.
    return !pendingQuestion;
  }, [pages, currentPageId, answers]);

  const setAnswer: AnswerOptionSelectHandler = (questionId, answerId, option) =>
    setAnswers((prevState) => ({
      ...prevState,
      [questionId]: {
        ...prevState[questionId],
        [answerId]: {
          value: option.value,
          invalid: !!option.invalid,
        },
      },
    }));

  useEffect(() => {
    const currentPage = pages[currentPageId];
    if (currentPage && trackPage) {
      trackPage('KYC', 'Investor Assessment', {
        step: currentPage.name,
        jurisdiction: assessment?.country,
      });
    }
  }, [currentPageId, trackPage]);

  const setPageId = (id: string): void => {
    setCurrentPageId(id);
    window.scrollTo(0, 0);
  };

  const getNextPageId = (): string | null => {
    const currentPage = pages[currentPageId];
    if (currentPage.nextAssessmentPageId) {
      return currentPage.nextAssessmentPageId;
    }
    return null;
  };

  const getPrevPageId = (): string | null => {
    const currentPage = pages[currentPageId];
    if (currentPage) {
      const previousPage = Object.values(pages).find(
        (candidate) => candidate.nextAssessmentPageId === currentPage.id,
      );
      return previousPage ? previousPage.id : null;
    }
    return null;
  };

  const handleSubmit = (): void => {
    if (onSubmit) {
      onSubmit(answers, comments);
    }
  };

  const handleAddCommentClick: AddCommentClickHandler = async (question) => {
    const comment = await addComment({ question });
    setComments((state) => ({ ...state, [question.id]: comment }));
  };

  const contextValue = useMemo(
    () => ({
      answers,
      comments,
      areCommentsActive,
      redirectTo,
      setRedirectTo,
      setAnswer,
      setPageId,
      getNextPageId,
      getPrevPageId,
      onSubmit: handleSubmit,
      onAddCommentClick: handleAddCommentClick,
      pageCompleted,
      currentPage: pages[currentPageId],
    }),
    [answers, pages, pageCompleted, currentPageId, areCommentsActive],
  );
  return (
    <AssessmentContext.Provider value={contextValue}>
      {children}
    </AssessmentContext.Provider>
  );
};
