import jwtDecode, { JwtPayload } from 'jwt-decode';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { QueryResult, useQueryResult } from '@hedgehog/browser/shared/utils';
import { useEnvironment } from '@hedgehog/ui/environment';

import { exchangeCognitoAuthCode } from '../utils';

export interface JwtIdentityPayload extends JwtPayload {
  jwtType: 'identity';
  email: string;
  sso: boolean;
  email_address?: string; // Legacy. Not sure if we need this. The JWT seems to have "email" for both apple and google.
  given_name?: string; // On Apple, only available on the first login. On Google, always available.
  family_name?: string; // On Apple, only available on the first login. On Google, always available.
}

export interface JwtBasicPayload extends JwtPayload {
  jwtType: 'basic';
  sso: boolean;
  firstName?: string;
  lastName?: string;
}

export interface JwtTokenPayload {
  token: string;
  refreshToken?: string;
  payload: JwtIdentityPayload | JwtBasicPayload;
}

export const deepLinkParams = {
  code: 'deep_link_sub1',
  email: 'deep_link_sub2',
  firstName: 'deep_link_sub3',
};

export const useJwtPayload = (): QueryResult<JwtTokenPayload> => {
  const {
    cognito: { userPoolUrl: cognitoUserPoolUrl, clientId: cognitoClientId },
    baseUrl: appUrl,
  } = useEnvironment();
  const [state, controls] = useQueryResult<JwtTokenPayload>();
  const [searchParams] = useSearchParams();

  const getJwtPayload = async (): Promise<JwtTokenPayload> => {
    if (searchParams.get('token')) {
      const token = searchParams.get('token')!;
      const decodedAccessToken = jwtDecode<JwtBasicPayload>(token);
      return {
        token: token,
        payload: { ...decodedAccessToken, jwtType: 'basic' },
      };
    }

    if (searchParams.get('id_token') && searchParams.get('access_token')) {
      const idToken = searchParams.get('id_token')!;
      const decodedAccessToken = jwtDecode<JwtIdentityPayload>(idToken);
      return {
        token: idToken,
        payload: { ...decodedAccessToken, jwtType: 'identity' },
      };
    }

    const cognitoAuthCode =
      (searchParams.get('code') || searchParams.get(deepLinkParams['code'])) ??
      '';
    const { id_token: idToken, refresh_token: refreshToken } =
      await exchangeCognitoAuthCode({
        appUrl,
        redirectUrl: 'signup_confirm',
        cognitoClientId,
        cognitoUserPoolUrl,
        cognitoAuthCode,
      });
    const decodedAccessToken = jwtDecode<JwtIdentityPayload>(idToken);
    return {
      token: idToken,
      refreshToken,
      payload: { ...decodedAccessToken, jwtType: 'identity' },
    };
  };

  const handle = async (): Promise<QueryResult<JwtTokenPayload>> => {
    try {
      controls.setLoading(true);

      const payload = await getJwtPayload();

      controls.setError(null);
      controls.setData(payload);
    } catch (err) {
      controls.setError(err);
      controls.setData(null);
    } finally {
      controls.setLoading(false);
    }

    return state;
  };

  useEffect(() => {
    handle();
  }, []);

  return state;
};
