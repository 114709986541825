import {
  OverrideKycStatus,
  ParseKycCheckParams,
  isLegacy,
  parseAccreditationStatus,
} from '@hedgehog/browser/investors/kyc/data-access';
import { StandardProps } from '@hedgehog/ui/utils';

import { OnboardingAction } from '../../components';

export type OnboardingAccreditationActionProps = StandardProps<
  ParseKycCheckParams | OverrideKycStatus,
  never
>;

export const OnboardingAccreditationAction = ({
  ...kycCheckParams
}: OnboardingAccreditationActionProps): JSX.Element => {
  return (
    <OnboardingAction
      title="Investor accreditation"
      href="#kyc/accreditation"
      messages={{
        failed: 'Declined',
        inprogress: 'Verifying, 1-2days',
        pending: 'Document upload',
      }}
      status={
        isLegacy(kycCheckParams)
          ? parseAccreditationStatus(kycCheckParams)
          : kycCheckParams.statusOverride
      }
    />
  );
};
