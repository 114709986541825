import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Loader } from '@hedgehog/ui/loaders';

import { useNextStep, WorkflowPaths } from './use-next-step.hook';

const isIgnored = (pathname: string, hash: string): boolean =>
  Object.values(WorkflowPaths).some(
    (path) => hash.includes(path) || pathname.includes(path),
  );

export const PostSignupWorkflowController = (): JSX.Element | null => {
  const navigate = useNavigate();
  const location = useLocation();
  const { to, loading } = useNextStep();

  useEffect(() => {
    if (to && !isIgnored(location.pathname, location.hash)) {
      navigate(to, { replace: true });
    }
  }, [to, location.pathname, location.hash]);

  return loading ? <Loader /> : <Outlet />;
};
