import { useContractIssuances } from '@hedgehog/browser/investors/account-abstraction/data-access';
import { useIncomeReports } from '@hedgehog/browser/investors/income-report/data-access';
import { useOrders } from '@hedgehog/browser/investors/order/data-access';
import { OrderStatus } from '@hedgehog/data-access/graphql';
import { calculateCurrentTotalRewards } from '@hedgehog/shared/resident-token/utils-estimations';
import { useEnvironment } from '@hedgehog/ui/environment';

import { PortfolioSummary } from '../../components';

export const PortfolioBalance = (): JSX.Element => {
  const {
    tenantToken: { address: tenantTokenAddress },
  } = useEnvironment();
  const { data, loading: ordersLoading } = useOrders();
  const { data: issuances, loading: issuancesLoading } = useContractIssuances();
  const { data: reports, loading: reportsLoading } =
    useIncomeReports(tenantTokenAddress);

  const totalAmount = (data?.orders || []).reduce(
    (acc, order) => {
      if ([OrderStatus.ISSUED].includes(order.status)) {
        return { ...acc, settled: acc.settled + order.amount };
      }

      if (
        [
          OrderStatus.AGREED,
          OrderStatus.FULFILLED,
          OrderStatus.SYNCHRONISED,
          OrderStatus.CLAIMED,
        ].includes(order.status)
      ) {
        return { ...acc, pending: acc.pending + order.amount };
      }

      return {
        ...acc,
      };
    },
    { settled: 0, pending: 0 },
  );

  const { currentTotalRewardsInTokensAmount } = calculateCurrentTotalRewards(
    issuances || [],
  );

  const totalIncomeReported =
    reports?.reduce((total, report) => total + report.amount, 0) / 100 || 0;

  const totalAmountInvested =
    totalAmount.settled +
    currentTotalRewardsInTokensAmount +
    totalIncomeReported;

  return (
    <PortfolioSummary
      loading={ordersLoading || issuancesLoading || reportsLoading}
      totalAmountInvested={totalAmountInvested ?? 0}
      totalAmountPending={totalAmount.pending ?? 0}
    />
  );
};
