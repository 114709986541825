import { createGlobalStyle } from 'styled-components';

export const GlobalDisableInteraction = createGlobalStyle`
  * {
    -webkit-touch-callout: none;
    user-drag: none;
  }

  // Disable selecting except for inputs.
  *:not(input) {
    user-select: none;
  }
`;

export const NativePreventInteraction = (): JSX.Element => {
  return <GlobalDisableInteraction />;
};
