import React, { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { depth } from '@hedgehog/utils/sizes';
import { screens } from '@hedgehog/utils/sizes';

import { ShadowModeContext } from './ShadowModeContext';

export interface ShadowModeProps {
  children?: React.ReactNode | React.ReactNode[];
}

const ShadowModeWatermark = styled.span`
  position: fixed;
  top: 0;
  right: 0;
  margin: 1rem 1rem 0 0;
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.18);

  @media (min-width: ${screens.medium}px) {
    top: 4.5rem;
  }

  z-index: ${depth.watermark};
  pointer-events: none;
`;

export const ShadowModeProvider = ({
  children,
}: ShadowModeProps): JSX.Element => {
  const [searchParams] = useSearchParams();
  const isQueryEnabled = searchParams.get('shadow_mode') === 'true';
  const isSessionEnabled = Boolean(sessionStorage.getItem('shadow_mode'));

  const [enabled] = useState(isQueryEnabled || isSessionEnabled);
  const contextValue = useMemo(() => ({ enabled }), [enabled]);

  if (enabled) {
    sessionStorage.setItem('shadow_mode', `${enabled}`);
  }

  return (
    <ShadowModeContext.Provider value={contextValue}>
      {children}
      {enabled && <ShadowModeWatermark>Shadow Mode</ShadowModeWatermark>}
    </ShadowModeContext.Provider>
  );
};
