import { AssetCategory, OrderStatus } from '@hedgehog/data-access/graphql';
import { StatusBadge } from '@hedgehog/ui/badges';

export interface OrderStatusBadgeProps {
  status: OrderStatus;
  quantity: number;
  transferConfirmed?: boolean;
  category?: AssetCategory;
}

const isTenantToken = (
  category?: AssetCategory,
): category is AssetCategory.TENANT_TOKEN =>
  category === AssetCategory.TENANT_TOKEN;

export const OrderStatusBadge = ({
  status,
  quantity,
  category,
  transferConfirmed = false,
}: OrderStatusBadgeProps): JSX.Element => {
  switch (status) {
    case OrderStatus.OPEN:
      if (isTenantToken(category)) {
        return <StatusBadge text="Available to you" />;
      }
      return <StatusBadge text="Sign agreement" icon="clock" />;
    case OrderStatus.AGREED:
      return (
        <StatusBadge
          text={transferConfirmed ? 'Pending' : 'Transfer'}
          icon="clock"
        />
      );
    case OrderStatus.FULFILLED:
    case OrderStatus.SYNCHRONISED:
      return (
        <StatusBadge
          text={`Issuing token${quantity > 1 ? 's' : ''}`}
          icon="token"
        />
      );
    case OrderStatus.ISSUED:
      return <StatusBadge text="Ongoing" emoji=":sparkles:" />;
    case OrderStatus.REFUNDED:
      return <StatusBadge text="Refunded" emoji=":thumbsup:" />;
    case OrderStatus.COMPLETED:
      return <StatusBadge text="Paid out" emoji=":tada:" />;

    default:
      return <StatusBadge text={status} />;
  }
};
