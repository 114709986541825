import { useAuthenticatedQuery } from '@hedgehog/data-access/contexts';
import {
  GET_USER_REFERRALS,
  GetUserReferralsQuery as GetUserReferrals,
} from '@hedgehog/data-access/graphql';

export const usePersonalReferrals = (): {
  data?: GetUserReferrals;
  error?: Error;
  loading: boolean;
} => {
  return useAuthenticatedQuery(GET_USER_REFERRALS);
};
