import { useContext } from 'react';

import { GuardsContext, GuardsContextValue } from '../contexts';

export const useGuardsContext = (): GuardsContextValue => {
  const context = useContext(GuardsContext);

  if (!context) {
    throw new Error(
      'useGuardsContext must be used within a UserActionProvider, might be that you forgot to wrap your guard with UserActionGuards container.',
    );
  }

  return context;
};
