import { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import styled, { CSSObject } from 'styled-components';

import { useCourse } from '@hedgehog/browser/investors/learn/data-access';
import { Card, CardContent } from '@hedgehog/ui/cards';
import { HeaderWithBack } from '@hedgehog/ui/headers';
import { Loader } from '@hedgehog/ui/loaders';
import { Heading, Paragraph } from '@hedgehog/ui/typography';

import { CourseTopicActionCard } from './course-topic-action-card.component';

const CourseTopbar = styled(HeaderWithBack)`
  padding-left: 0;
  padding-right: 0;
`;

const CourseOverviewWrapper = styled(Card)`
  display: flex;
  flex-flow: column nowrap;

  ${({ theme }): CSSObject => ({
    padding: theme.spacing.large,
    backgroundColor: theme.colors.white,
    [`${CardContent}`]: {
      gap: theme.spacing.large,
    },
  })}
`;

const CourseThumbnail = styled.div<{ src?: string }>`
  display: block;
  background-size: contain;
  background-position: center;
  ${({ src, theme }): CSSObject => ({
    backgroundImage: src ? `url(${src})` : 'none',
    borderRadius: theme.radius.normal,
  })}
  width: 100%;
  height: 100%;
  max-width: 100%;
  min-height: 15rem;
  max-height: 15rem;
`;

const CourseTopicList = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }): CSSObject => ({ gap: theme.spacing.xsmall })}
`;

export interface CourseOverviewProps {
  courseSlug?: string;
  topicSlug?: string;
  mobile?: boolean;
  className?: string;
}

export const CourseOverview = ({
  courseSlug,
  topicSlug,
  className,
  mobile = false,
}: CourseOverviewProps): JSX.Element => {
  const { data, loading, error } = useCourse(courseSlug || '');
  const navigate = useNavigate();

  useEffect(() => {
    if (!data) return;
    if (topicSlug) return;
    if (mobile) return;
    const { topics, upNext } = data.course;
    const nextSlug = upNext ? upNext.slug : topics[0].slug;
    navigate(nextSlug, { replace: true });
  }, [data, topicSlug, mobile]);

  if (error) {
    return (
      <CourseOverviewWrapper>
        <Paragraph small>
          Something went wrong, please try again later
        </Paragraph>
      </CourseOverviewWrapper>
    );
  }

  if (loading) {
    return (
      <CourseOverviewWrapper>
        <Loader />
      </CourseOverviewWrapper>
    );
  }

  if (!data) {
    return <Navigate to="/learn" />;
  }

  const { course } = data;

  return (
    <CourseOverviewWrapper className={className}>
      <CourseTopbar to=".." right>
        <Heading align="center" level="h5">
          {course.title}
        </Heading>
      </CourseTopbar>
      <CourseThumbnail src={course.imageUrl} />
      <CourseTopicList>
        {course.topics.map((topic) => (
          <CourseTopicActionCard
            key={topic.id}
            active={topic.slug === topicSlug}
            title={topic.title}
            caption={`${topic.durationInMinutes} mins`}
            progress={topic.progress}
            to={`/learn/courses/${courseSlug}/${topic.slug}`}
          />
        ))}
      </CourseTopicList>
    </CourseOverviewWrapper>
  );
};
