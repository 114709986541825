import { ContractIssuance } from '@hedgehog/shared/blockchain/resident-token';

import { countIssuances } from './count-issuances.helper';

export interface CalculateRentRewardsRecentInput {
  issuances: ContractIssuance[];
}

export function calculateRentRewardsRecent({
  issuances,
}: CalculateRentRewardsRecentInput): number {
  const { tokensAmount: recentRentRewardsInTokensAmount } = countIssuances({
    issuances,
    predicate: (issuance): issuance is ContractIssuance<'rent:paid'> => {
      const { data } = issuance as ContractIssuance<'rent:paid'>;
      return data.type === 'rent:paid';
    },
  });

  return recentRentRewardsInTokensAmount;
}
