import { useMutation } from '@apollo/client';

import {
  useAnalyticsPage,
  useAnalyticsTrack,
} from '@hedgehog/browser/investors/shared/analytics';
import { useScrollToBody } from '@hedgehog/browser/shared/utils';
import { useAuthenticatedQuery } from '@hedgehog/data-access/contexts';
import {
  GetAssetQuery,
  AssetDocumentDownloadedMutation as AssetDocumentDownloaded,
  AssetDocumentDownloadedMutationVariables as AssetDocumentDownloadedVariables,
  ASSET_DOCUMENT_DOWNLOADED,
  GET_ASSET_PAGE,
  GetAssetPageQueryVariables as GetAssetPageVariables,
  GetAssetPageQuery as GetAssetPage,
} from '@hedgehog/data-access/graphql';
import { ContentDownloadCardsBlockItem, UUID } from '@hedgehog/shared/types';
import { CMSDocumentClickProvider, ContentCMS } from '@hedgehog/ui/cms';
import { EmptyStateContainer, LoadingContainer } from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';

type GetAsset_asset = GetAssetQuery['asset'];

type DocsTabProps = {
  asset: GetAsset_asset;
  assetRoundClassId: UUID;
};

export const DocumentsTab = ({
  asset,
  assetRoundClassId,
}: DocsTabProps): JSX.Element => {
  useAnalyticsPage('Asset', 'Docs', { asset_name: asset.path });
  useScrollToBody();
  const track = useAnalyticsTrack();
  const { data, loading, error } = useAuthenticatedQuery<
    GetAssetPage,
    GetAssetPageVariables
  >(GET_ASSET_PAGE, {
    assetId: asset.id,
    slug: 'docs',
  });

  const [sendAssetDocumentDownloaded] = useMutation<
    AssetDocumentDownloaded,
    AssetDocumentDownloadedVariables
  >(ASSET_DOCUMENT_DOWNLOADED);

  const handleDocumentClick = async (
    entity: ContentDownloadCardsBlockItem,
  ): Promise<void> => {
    try {
      sendAssetDocumentDownloaded({
        variables: {
          assetRoundClassId,
          fileName: entity.title,
          fileUrl: entity.url,
        },
      });
    } catch (err) {
      /*NOOP*/
    }
  };

  if (error) {
    return (
      <EmptyStateContainer>
        Something went wrong, please try again later
      </EmptyStateContainer>
    );
  }

  if (loading || !data) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }

  return (
    <CMSDocumentClickProvider onDocumentClick={handleDocumentClick}>
      <ContentCMS content={data.assetPage.content} onAnalyticsTrack={track} />
    </CMSDocumentClickProvider>
  );
};
