import styled, { CSSObject } from 'styled-components';

import { HeaderWithBack } from '@hedgehog/ui/headers';
import { Heading } from '@hedgehog/ui/typography';

import { TaxInformation } from '../containers';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  ${({ theme }): CSSObject => ({ gap: theme.spacing.large })}
`;

export const TaxInformationPage = (): JSX.Element => {
  return (
    <Wrapper>
      <HeaderWithBack to="#kyc/identity-check/personal">
        <Heading level="h5">Tax information</Heading>
      </HeaderWithBack>
      <TaxInformation />
    </Wrapper>
  );
};
