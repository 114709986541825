import { useEffect, useState } from 'react';

import { useAuthenticatedQuery } from '@hedgehog/data-access/contexts';
import {
  GET_INCOME_REPORTS,
  GetIncomeReportsQuery as GetIncomeReports,
  GetIncomeReportsQueryVariables as GetIncomeReportsVariables,
  IncomeReport,
} from '@hedgehog/data-access/graphql';

type UseIncomeReportsResponse = {
  data: IncomeReport[];
  loading: boolean;
};

export const useIncomeReports = (
  tokenAddress: string,
): UseIncomeReportsResponse => {
  const [data, setData] = useState<GetIncomeReports | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const result = useAuthenticatedQuery<
    GetIncomeReports,
    GetIncomeReportsVariables
  >(GET_INCOME_REPORTS, {
    tokenAddress,
  });

  useEffect(() => {
    setData(result.data || null);
    setLoading(result.loading);
  }, [result]);

  return {
    data: data?.incomeReports || [],
    loading,
  };
};
