import { ContractIssuance } from '@hedgehog/shared/blockchain/resident-token';

import { countIssuances } from './count-issuances.helper';

export interface CalculateSignOnRewardsRecentInput {
  issuances: ContractIssuance[];
}

export const calculateSignOnRewardsRecent = ({
  issuances,
}: CalculateSignOnRewardsRecentInput): number => {
  const { tokensAmount } = countIssuances({
    issuances,
    predicate: (issuance: ContractIssuance) => issuance.data.type === 'sign-on',
  });
  return tokensAmount;
};
