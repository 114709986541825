import { QueryResult } from '@apollo/client';

import { useAuthenticatedQuery } from '@hedgehog/data-access/contexts';
import {
  GET_ACCREDITATION,
  GetAccreditationQuery as GetAccreditation,
} from '@hedgehog/data-access/graphql';

export const useAccreditation = (): QueryResult<GetAccreditation> =>
  useAuthenticatedQuery<GetAccreditation>(GET_ACCREDITATION, undefined, {
    fetchPolicy: 'no-cache',
  });
