import { createContext } from 'react';

import {
  AddCommentClickHandler,
  AnswerOptionSelectHandler,
  AssessmentAnswers,
  AssessmentComments,
  TAssessmentPage,
} from './assessment-quiz.types';

export interface AssessmentContextType {
  answers: AssessmentAnswers;
  comments: AssessmentComments;
  areCommentsActive: boolean;
  currentPage?: TAssessmentPage;
  pageCompleted: boolean; // Indicates whether all the answers on the page have been answered.
  redirectTo?: string;
  setRedirectTo?: (url: string) => void;
  setAnswer: AnswerOptionSelectHandler;
  setPageId: (id: string) => void;
  getPrevPageId: () => string | null;
  getNextPageId: () => string | null;
  onSubmit?: () => void;
  onAddCommentClick?: AddCommentClickHandler;
}

export const AssessmentContext = createContext<AssessmentContextType>({
  answers: {},
  comments: {},
  areCommentsActive: false,
  currentPage: undefined,
  pageCompleted: false,
  setAnswer: () => null,
  setPageId: () => null,
  setRedirectTo: () => null,
  getPrevPageId: () => null,
  getNextPageId: () => null,
  onSubmit: () => null,
  onAddCommentClick: () => null,
});
