import { useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  useAddressAutoComplete,
  retrieveAddressesFromPrediction,
  useDebounce,
} from '@hedgehog/data-access/hooks';
import { TAddress, TAddressPrediction } from '@hedgehog/shared/types';
import { SecondaryButton } from '@hedgehog/ui/buttons';
import { useEnvironment } from '@hedgehog/ui/environment';
import { HeaderWithClose } from '@hedgehog/ui/headers';
import { LoadingContainer } from '@hedgehog/ui/layouts';
import { List } from '@hedgehog/ui/lists';
import { Loader } from '@hedgehog/ui/loaders';
import { Heading, Paragraph } from '@hedgehog/ui/typography';
import { validatePostcode } from '@hedgehog/utils/formats';
import { screens } from '@hedgehog/utils/sizes';

import { TextInput } from '../TextInput/TextInput';

import { AddressItem } from './address-item.component';
import { AddressPredictionItem } from './address-prediction-item.component';
import { AddressSearchInput } from './address-search.input';
import { CancelAddressPrediction } from './cancel-address-prediction.component';

const ClickableHeading = styled(Heading)`
  cursor: pointer;
`;

const FullHeightContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100vw; // The bottom sheet parent component limits the max width

  @media (max-width: ${screens.medium}px) {
    min-height: 85vh;
  }
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2.5rem 1.5rem;
  gap: 1rem;
  flex: 1;

  @media (min-width: ${screens.medium}px) {
    min-height: unset;
  }
`;

const FullWidthSecondaryButton = styled(SecondaryButton)`
  max-width: none;
`;

const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  gap: 1rem;

  @media (max-width: ${screens.medium}px) {
    padding-bottom: 2rem;
  }
`;

type AddressLookupModalProps = {
  initialValue?: TAddress;
  title?: string;
  placeholder?: string;
  onSubmit?: (value?: TAddress) => void;
  countryCode: string;
  closeModal: () => void;
};

export const AddressLookupModal = ({
  initialValue,
  title = 'Address Search',
  placeholder,
  onSubmit,
  countryCode,
  closeModal,
}: AddressLookupModalProps): JSX.Element => {
  const environment = useEnvironment();
  const [fetchingAddresses, setFetchingAddresses] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 600);
  const [pathFilter, setPathFilter] = useState<string>();
  const [addresses, setAddresses] = useState<TAddress[]>([]);
  const [postcodeErrors, setPostcodeErrors] = useState<string[]>([]);
  const [prediction, setPrediction] = useState<TAddressPrediction>();
  const [predictions, loading, shouldUseAutoComplete] = useAddressAutoComplete({
    searchTerm: debouncedSearchTerm.length >= 3 ? debouncedSearchTerm : '',
    countryCode,
    pathFilter,
  });
  const [value, setValue] = useState<TAddress | undefined>(
    // Determine the initial value. When a value exists, the user is shown inputs to edit it. This by-passes the
    // autocomplete functionality. For non-UK countries, we should do this because Postcoder does not support other
    // countries.
    initialValue || undefined,
  );
  const isAddressValid =
    value &&
    value.addressLine1 &&
    value.town &&
    value.postcode &&
    !postcodeErrors.length;

  useEffect(() => {
    if (initialValue && value?.postcode) {
      setPostcodeErrors(validatePostcode(countryCode, value.postcode || ''));
    }
  }, [initialValue, countryCode, value?.postcode]);

  useEffect(() => {
    onSubmit && onSubmit(value);
  }, [onSubmit, value]);

  const handleChange = (name: keyof TAddress) => {
    return (newValue: string) => {
      setValue((prevState) => ({
        ...prevState,
        [name]: newValue,
      }));
    };
  };

  const createNewValue = (): void => {
    setValue({
      addressLine1: searchTerm,
    });
  };

  const handlePredictionClick = async (
    prediction: TAddressPrediction,
  ): Promise<void> => {
    // From the prediction, the system must now query the actual address.
    try {
      setPrediction(prediction);

      // For predictions with more than 1 address, we add the path filter to predict more addresses.
      if (prediction.addressCount > 1) {
        setPathFilter(prediction.id);
        return;
      }

      setFetchingAddresses(true);
      const addresses = await retrieveAddressesFromPrediction({
        predictionId: prediction.id,
        countryCode,
        searchTerm,
        apiKey: environment['postcoder']?.apiKey,
        useMocks: environment['postcoder']?.useMocks,
      });

      // If no addresses are retrieved, allow the user to enter a manual address
      if (addresses.length === 0) {
        createNewValue();
        return;
      }

      // Handle the case where only one address is retrieved.
      if (addresses.length === 1) {
        setValue(addresses[0]);
        return;
      }

      // More than one address can be returned if the prediction is a street. The user must choose the exact address.
      setAddresses(addresses);
    } finally {
      setFetchingAddresses(false);
    }
  };

  const renderModalContent = (): JSX.Element | null => {
    // Handle the case where the user needs to select an address from the list of predictions.
    if (addresses.length > 1) {
      return (
        <List>
          {addresses.map((address) => (
            <AddressItem
              key={address.addressLine1}
              address={address}
              onClick={setValue}
            />
          ))}
        </List>
      );
    }

    // Handle the case where the user has selected an address from the list of predictions (or an address is pre-populated) and they need to confirm it.
    // Or handle the case where auto complete should not be used, so the manual inputs are shown instead
    if (value?.addressLine1 || !shouldUseAutoComplete) {
      return (
        <>
          <TextInput
            name="address_line_1"
            label="Address Line 1"
            placeholder="Type your address"
            onChange={handleChange('addressLine1')}
            value={value?.addressLine1}
            autoComplete="address-level3"
          />
          <TextInput
            name="address_line_2"
            label="Address Line 2"
            placeholder=""
            onChange={handleChange('addressLine2')}
            value={value?.addressLine2}
            autoComplete="address-level4"
          />
          <TextInput
            name="town"
            label="City"
            placeholder="Type your city"
            onChange={handleChange('town')}
            value={value?.town}
            autoComplete="address-level2"
          />
          <TextInput
            name="postcode"
            label={countryCode === 'USA' ? 'Zip code' : 'Postcode'}
            placeholder={`Type your ${
              countryCode === 'USA' ? 'zip code' : 'postcode'
            }`}
            onChange={handleChange('postcode')}
            errors={postcodeErrors}
            value={value?.postcode}
            autoComplete="postal-code"
          />
          <FullWidthSecondaryButton
            onClick={closeModal}
            disabled={!isAddressValid}
          >
            Confirm
          </FullWidthSecondaryButton>
        </>
      );
    }

    // Handle the case where the user has not selected an address from the list of predictions and needs to search.
    if (searchTerm.length === 0 || debouncedSearchTerm.length === 0) {
      return null;
    }

    // Handle the cases where the user has started typing but the search term is not long enough.
    if (searchTerm.length < 3 || debouncedSearchTerm.length < 3) {
      return (
        <ClickableHeading level="h6" onClick={createNewValue}>
          Keep typing for suggestions...{' '}
          <Paragraph color="primary">Add it manually?</Paragraph>
        </ClickableHeading>
      );
    }

    // Handle the loading case
    if (loading || fetchingAddresses) {
      return (
        <LoadingContainer>
          <Loader />
        </LoadingContainer>
      );
    }

    // Handle the case where the user has typed a search term but no predictions are returned.
    if (debouncedSearchTerm && predictions.length === 0) {
      return (
        <Heading level="h6" onClick={createNewValue}>
          We could not find an address for that.{' '}
          <Paragraph color="primary">Add it manually?</Paragraph>
        </Heading>
      );
    }

    return (
      <>
        <List>
          {pathFilter && prediction && (
            <CancelAddressPrediction
              prediction={prediction}
              onClick={(): void => setPathFilter(undefined)}
            />
          )}
          {predictions.map((prediction) => (
            <AddressPredictionItem
              key={prediction.summary}
              prediction={prediction}
              onClick={handlePredictionClick}
            />
          ))}
        </List>
        <ClickableHeading level="h6" onClick={createNewValue}>
          <Paragraph inline color="primary">
            Add it manually
          </Paragraph>{' '}
          {searchTerm}
        </ClickableHeading>
      </>
    );
  };

  return (
    <FullHeightContainer>
      <Modal>
        <HeaderWithClose onClose={closeModal}>
          <Heading level="h5">{title}</Heading>
        </HeaderWithClose>
        {!value?.addressLine1 && shouldUseAutoComplete && (
          <AddressSearchInput
            value={searchTerm}
            placeholder={placeholder}
            onChange={setSearchTerm}
          />
        )}
        <ScrollContainer>{renderModalContent()}</ScrollContainer>
      </Modal>
    </FullHeightContainer>
  );
};
