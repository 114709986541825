import {
  Navigate,
  Path,
  createSearchParams,
  useLocation,
} from 'react-router-dom';

export type NavigateWithSearchParamsProps = {
  to: Partial<Path>;
  include?: string[];
  exclude?: string[];
};

export const NavigateWithSearchParams = ({
  to,
  include,
  exclude,
}: NavigateWithSearchParamsProps): JSX.Element => {
  const location = useLocation();
  const locationSearchParams = createSearchParams(location.search);
  const targetSearchParams = createSearchParams(to.search);

  locationSearchParams.forEach((value, key) => {
    if (include && !include.includes(key)) return;
    if (exclude && exclude.includes(key)) return;
    targetSearchParams.append(key, value);
  });

  return (
    <Navigate to={{ ...to, search: `?${targetSearchParams.toString()}` }} />
  );
};
