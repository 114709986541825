import { OrderStatus } from '@hedgehog/data-access/graphql';

export const orderStatusToPath: Record<
  Exclude<
    OrderStatus,
    OrderStatus.CANCELLED | OrderStatus.REFUNDED | OrderStatus.EXPIRED
  >,
  string
> = {
  [OrderStatus.OPEN]: 'open',
  [OrderStatus.AGREED]: 'instructions',
  [OrderStatus.FULFILLED]: 'issuing',
  [OrderStatus.CLAIMED]: 'summary',
  [OrderStatus.SYNCHRONISED]: 'summary',
  [OrderStatus.ISSUED]: 'summary',
  [OrderStatus.COMPLETED]: 'summary',
};
