import { useParams, useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { useCourseTopicUpdate } from '@hedgehog/browser/investors/learn/data-access';
import { GetTopicQuery, TopicProgress } from '@hedgehog/data-access/graphql';
import { useMediaQuery } from '@hedgehog/data-access/hooks';

import { CourseTopic } from './course-topic.container';

type GetTopic_topic_upNext = GetTopicQuery['topic']['upNext'];

export const CourseTopicPage = (): JSX.Element => {
  const { breakpoints } = useTheme();
  const { courseSlug, topicSlug } = useParams();
  const navigate = useNavigate();
  const [updateTopicProgress, { loading }] = useCourseTopicUpdate();
  const query = useMediaQuery(
    `only screen and (min-width: ${breakpoints.normal}px)`,
  );
  const isDesktop = query.matches;
  const isMobile = !isDesktop;

  const handleStarted = async (currentProgress: TopicProgress) => {
    if (!topicSlug) return;
    if (loading) return;
    if (TopicProgress.NOT_STARTED === currentProgress) {
      await updateTopicProgress({
        variables: { slug: topicSlug, progress: TopicProgress.STARTED },
      });
    }
  };

  const handleComplete = async (currentProgress: TopicProgress) => {
    if (!topicSlug) return;
    if (TopicProgress.STARTED === currentProgress) {
      await updateTopicProgress({
        variables: { slug: topicSlug, progress: TopicProgress.COMPLETED },
      });
    }
  };

  const returnToLearnPage = (): void => {
    if (isMobile && courseSlug) {
      return navigate(`/learn/courses/${courseSlug}`);
    }
    return navigate('/learn');
  };

  const handleNextTopic = (upNext: GetTopic_topic_upNext | null): void => {
    if (!upNext) {
      // No topics in the course remaining to learn. Return the user to the learn page
      return returnToLearnPage();
    }
    navigate(`/learn/courses/${courseSlug}/${upNext.slug}`);
  };
  return (
    <CourseTopic
      slug={topicSlug}
      onStarted={handleStarted}
      onComplete={handleComplete}
      onNext={handleNextTopic}
      onBack={returnToLearnPage}
    />
  );
};

export default CourseTopicPage;
