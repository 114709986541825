import styled, { css } from 'styled-components';

import { screens, spacing } from '@hedgehog/utils/sizes';

import { Media } from '../assets.types';

const ThumbnailFluid = css`
  height: 100%;
  max-height: 100%;
`;

const ThumbnailSquashed = css`
  min-height: 5.25rem;
  max-height: 7.25rem;
  @media only screen and (min-width: ${screens.medium}px) {
    min-height: 7.5rem;
    max-height: 8.5rem;
  }
`;

const Thumbnails = styled.div<{ fluid?: boolean }>`
  display: flex;
  flex-direction: row;

  justify-content: stretch;
  align-items: stretch;
  flex: 1 0 auto;
  border-radius: 0.5rem;

  ${({ fluid }) => (fluid ? ThumbnailFluid : ThumbnailSquashed)}

  > *:not(:last-child) {
    margin-right: ${spacing.xxxs};
  }
`;

const ThumbnailInnerWrapper = styled.div`
  flex: 1 1 100%;
  position: relative;
`;

const Thumbnail = styled.img`
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
`;

export interface AssetThumbnailsProps {
  className?: string;
  fluid?: boolean;
  thumbnails: Media[];
}

export const AssetThumbnails = ({
  className,
  thumbnails,
  fluid,
}: AssetThumbnailsProps) => (
  <Thumbnails className={className} fluid={fluid}>
    {thumbnails?.map((resource, i) => (
      <ThumbnailInnerWrapper key={`${resource?.alt}-${i}`}>
        <Thumbnail
          src={resource?.thumbnailSrc || resource?.src || ''}
          alt={resource?.alt || ''}
        />
      </ThumbnailInnerWrapper>
    ))}
  </Thumbnails>
);
