import styled from 'styled-components';

import { HedgehogAuthLogo } from './hedgehog-auth-logo.component';

const Header = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 6rem;
  max-height: 14rem;
`;

const HedgehogLogoWrapper = styled.div`
  max-width: 13.125rem;
  width: 100%;
`;

export const AuthPageLogo = (): JSX.Element => {
  return (
    <Header>
      <HedgehogLogoWrapper>
        <HedgehogAuthLogo />
      </HedgehogLogoWrapper>
    </Header>
  );
};

export default AuthPageLogo;
