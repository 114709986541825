import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { CSSObject } from 'styled-components';

import { useAuthUser } from '@hedgehog/browser/investors/account/data-access';
import {
  useAnalyticsPage,
  useAnalyticsTrack,
} from '@hedgehog/browser/investors/shared/analytics';
import { useNextStep } from '@hedgehog/browser/investors/workflows/onboarding';
import {
  DeclarationType,
  GET_ONBOARDING_WORKFLOWS,
  GET_USER_ACTION_DATA,
  InvestorType,
  WorkflowStep,
} from '@hedgehog/data-access/graphql';
import { SecondaryButton } from '@hedgehog/ui/buttons';
import { Header } from '@hedgehog/ui/headers';
import { Page } from '@hedgehog/ui/layouts';
import { parsePadding } from '@hedgehog/ui/themes';
import { Heading } from '@hedgehog/ui/typography';

import {
  JerseyInvestorLetter,
  SwissJurisdictionWarning,
  UnitedKingdomInvestorLetter,
  UnitedStatesJurisdictionWarning,
} from '../../components';
import { useCreateDeclaration } from '../../hooks';

const Wrapper = styled(Page)`
  padding-bottom: 0;
`;

const Article = styled.article`
  position: relative;

  & > * {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Controls = styled.div`
  width: 100%;
  box-sizing: border-box;
  ${({ theme }): CSSObject => ({
    padding: parsePadding({ x: theme.spacing.xlarge, y: theme.spacing.xlarge }),
    paddingTop: theme.spacing.xsmall,
    background: theme.colors.white,
  })};

  & > * {
    margin: 0 auto;
    max-width: 59rem;
  }
`;

const workflowStep = (country: string): WorkflowStep => {
  switch (country) {
    case 'GBR':
      return WorkflowStep.oakglen_investor_letter_gbr;
    case 'JEY':
      return WorkflowStep.oakglen_investor_letter_jey;

    // TODO: Separate workflow steps for USA and CHE
    case 'CHE':
    case 'USA':
    default:
      return WorkflowStep.jurisdiction_warning_declaration;
  }
};

export const JurisdictionPage = (): JSX.Element => {
  const navigate = useNavigate();
  const user = useAuthUser();

  // Analytics
  useAnalyticsPage('Authentication', 'Jurisdiction');
  const track = useAnalyticsTrack();

  const articleRef = useRef(null);

  const [createDeclaration, { loading }] = useCreateDeclaration();
  const { to } = useNextStep(workflowStep(user?.country ?? ''));

  const handleAccept = async (): Promise<void> => {
    if (loading) return;

    track('JurisdictionAcceptButton', 'Clicked');

    // If the user's country is 'JEY', create an additional declaration of type 'INVESTOR_TYPE'
    if (user?.country === 'JEY') {
      await createDeclaration({
        variables: {
          type: DeclarationType.INVESTOR_TYPE,
          investorType: InvestorType.ELECTIVE_PROFESSIONAL,
        },
        refetchQueries: [
          { query: GET_USER_ACTION_DATA },
          { query: GET_ONBOARDING_WORKFLOWS },
        ],
      });
    }

    // Create a declaration of type 'JURISDICTION_WARNING' regardless of the user's country
    await createDeclaration({
      variables: { type: DeclarationType.JURISDICTION_WARNING },
      refetchQueries: [
        { query: GET_USER_ACTION_DATA },
        { query: GET_ONBOARDING_WORKFLOWS },
      ],
    });
    navigate(to || '/explore', { replace: true });
  };

  return (
    <Wrapper>
      <Header>
        <Heading level="h5">
          {user?.country === 'GBR' && 'Investor Letter'}
          {user?.country === 'JEY' && 'Investor Letter'}
          {user?.country === 'USA' && 'Jurisdiction warning'}
          {user?.country === 'CHE' && 'Important information'}
        </Heading>
      </Header>

      <Article ref={articleRef}>
        {user?.country === 'GBR' && <UnitedKingdomInvestorLetter />}
        {user?.country === 'USA' && <UnitedStatesJurisdictionWarning />}
        {user?.country === 'CHE' && <SwissJurisdictionWarning />}
        {user?.country === 'JEY' && <JerseyInvestorLetter />}
      </Article>

      <Controls>
        <SecondaryButton fluid loading={loading} onClick={handleAccept}>
          Accept
        </SecondaryButton>
      </Controls>
    </Wrapper>
  );
};
