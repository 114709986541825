import React, { ForwardedRef, forwardRef } from 'react';
import { createPortal } from 'react-dom';
import { Helmet } from 'react-helmet';
import styled, { CSSObject } from 'styled-components';

import { ButtonBase } from '@hedgehog/ui/buttons';
import { LoadingContainer } from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';
import { StandardProps } from '@hedgehog/ui/utils';
import { depth, screens } from '@hedgehog/utils/sizes';

const Sidebar = styled.aside<{ background?: string; hidden?: boolean }>`
  z-index: ${depth.sidebar};
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  display: block;
  height: 100vh;
  width: 100%;
  overflow: auto;

  ${({ theme, background, hidden }): CSSObject => ({
    backgroundColor: theme.colors.white,
    maxWidth: hidden ? '0rem' : '100%',
    boxShadow: `0 0 0 100vw ${
      hidden || background ? 'rgba(0,0,20,0)' : 'rgba(0,0,20,0.6)'
    }`,
  })};

  @media only screen and (min-width: ${screens.medium}px) {
    ${({ hidden }): CSSObject => ({
      transition: `250ms max-width ease-in-out, 100ms box-shadow ease-in ${
        hidden ? '150ms' : '0ms'
      }`,
      maxWidth: hidden ? '0rem' : '28.75rem',
    })}
  }
`;

const SidebarInnerWrapper = styled.div`
  display: flex;
  padding-bottom: 2rem;

  @media only screen and (min-width: ${screens.medium}px) {
    min-width: 28.75rem;
    max-width: 28.75rem;

    ${ButtonBase} {
      max-width: unset;
    }
  }
`;

const SidebarBackdrop = styled.div<{
  backgroundUrl?: string;
  backgroundSize?: string;
  backgroundPosition?: string;
  hidden?: boolean;
}>`
  z-index: ${depth.sidebar};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  background-size: auto;
  background-repeat: repeat;
  ${({ backgroundUrl, backgroundSize, backgroundPosition }): CSSObject => ({
    background: 'none',
    [`@media only screen and (min-width: ${screens.medium}px)`]: {
      background: backgroundUrl ? `url(${backgroundUrl})` : 'none',
      backgroundSize,
      backgroundPosition,
    },
  })};
`;

export type SidebarLayoutProps = StandardProps<{
  backgroundUrl?: string;
  backgroundPosition?: string;
  backgroundSize?: string;
  hidden?: boolean;
  onBackdropClick?: React.MouseEventHandler;
}>;

export const SidebarLayout = forwardRef(
  (
    {
      backgroundUrl,
      backgroundPosition,
      backgroundSize,
      hidden,
      children,
      onBackdropClick,
    }: SidebarLayoutProps,
    ref: ForwardedRef<HTMLDivElement>,
  ): JSX.Element | null => {
    const portalElement = document.getElementById('sidebar');
    return portalElement
      ? createPortal(
          <>
            <SidebarBackdrop
              backgroundUrl={backgroundUrl}
              backgroundPosition={backgroundPosition}
              backgroundSize={backgroundSize}
              hidden={hidden}
              onClick={onBackdropClick}
              data-testid="sidebar-backdrop"
            />
            <Sidebar
              hidden={hidden}
              data-testid="sidebar"
              background={backgroundUrl}
              ref={ref}
            >
              {!hidden && (
                <Helmet>
                  <style>{`body { overflow: hidden; }`}</style>
                </Helmet>
              )}
              <SidebarInnerWrapper>
                <React.Suspense
                  fallback={
                    <LoadingContainer>
                      <Loader />
                    </LoadingContainer>
                  }
                >
                  {children}
                </React.Suspense>
              </SidebarInnerWrapper>
            </Sidebar>
          </>,
          portalElement,
        )
      : null;
  },
);
