import { Avatar } from '@hedgehog/ui/icons';

import { useTimer } from '../hooks/use-timer.hook';
import {
  GuideCard,
  GuideCardDescription,
  GuideCardHeading,
  GuideCardInnerWrapper,
  LockComponent,
  LockWrapper,
  TimerText,
} from '../styles/styles';

const Timer = ({ to }: { to: Date }): JSX.Element => (
  <TimerText level="h3" color="black">
    {useTimer({ to })}
  </TimerText>
);

export const CooldownCard = ({
  unlockDate,
}: {
  unlockDate: Date | null;
}): JSX.Element => {
  return (
    <GuideCard>
      <LockWrapper>
        <LockComponent showTimer={!!unlockDate}>
          <Avatar
            icon="lock"
            size="l"
            color="white"
            backgroundColor="secondary"
          />
          {unlockDate ? <Timer to={unlockDate} /> : null}
        </LockComponent>
      </LockWrapper>
      <GuideCardInnerWrapper>
        <GuideCardHeading level="h3" color="black">
          Thank you for completing registration.
        </GuideCardHeading>
        <GuideCardDescription color="black">
          Before we can let you in, The FCA require us to give you 24 hours to
          consider the{' '}
          <a href="https://www.hedgehog-invest.com/risk-warnings">
            <strong>risks involved</strong>
          </a>{' '}
          in real asset investing. We'll send you an email when the 24 hours are
          up.
        </GuideCardDescription>
      </GuideCardInnerWrapper>
    </GuideCard>
  );
};
