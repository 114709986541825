import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { useState } from 'react';
import { To, useNavigate, useSearchParams } from 'react-router-dom';

import {
  useAnalyticsPage,
  useAnalyticsTrack,
} from '@hedgehog/browser/investors/shared/analytics';
import {
  BEGIN_SIGNUP_USER,
  BeginSignupMutation as BeginSignup,
  BeginSignupMutationVariables as BeginSignupVariables,
  FeatureFlagNames,
  SignUpPlatform,
} from '@hedgehog/data-access/graphql';
import { useFeatureToggle } from '@hedgehog/data-access/hooks';
import {
  NativeMobileExperiences,
  useIsNativeMobileExperience,
} from '@hedgehog/data-access/native-mobile';
import { HeaderWithBack } from '@hedgehog/ui/headers';
import { Heading } from '@hedgehog/ui/typography';

import { SignUpForm, SignUpFormData } from '../../containers';

export interface SignupPageProps {
  redirectTo?: To;
}

export const SignupPage = ({
  redirectTo = '/signup/confirm-email',
}: SignupPageProps): JSX.Element => {
  useAnalyticsPage('Authentication', 'Begin Signup');
  const track = useAnalyticsTrack();
  const navigate = useNavigate();
  const featureToggle = useFeatureToggle();
  const [searchParams] = useSearchParams();
  const nativeSignUpEnabled = featureToggle.hasFeature(
    FeatureFlagNames.native_signup,
  );
  const [beginSignup, { loading }] = useMutation<
    BeginSignup,
    BeginSignupVariables
  >(BEGIN_SIGNUP_USER);
  const iosDevice = useIsNativeMobileExperience(
    NativeMobileExperiences.DEVICE_IOS,
  );
  const [errors, setErrors] = useState<string[]>([]);

  const handleSubmit = async (
    formData: Required<SignUpFormData>,
  ): Promise<void> => {
    track('BeginSignupButton', 'Clicked');
    try {
      await beginSignup({
        variables: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          platform:
            iosDevice && nativeSignUpEnabled
              ? SignUpPlatform.IOS
              : SignUpPlatform.WEB,
        },
      });
      navigate(redirectTo, { state: { email: formData.email } });
    } catch (err) {
      setErrors([(err as Error).message || 'ERROR_UNKNOWN']);
      Sentry.captureException(err);
    }
  };

  return (
    <>
      <HeaderWithBack>
        <Heading level="h5">Sign up to Hedgehog</Heading>
      </HeaderWithBack>
      {featureToggle.hasFeature(FeatureFlagNames.create_account) && (
        <SignUpForm
          defaultForm={{
            firstName: decodeURIComponent(searchParams.get('firstName') || ''),
            lastName: decodeURIComponent(searchParams.get('lastName') || ''),
            email: decodeURIComponent(searchParams.get('email') || ''),
          }}
          loading={loading}
          errors={errors}
          onSubmit={handleSubmit}
        />
      )}
    </>
  );
};
