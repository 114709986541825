import { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import styled, { CSSObject, CSSProp } from 'styled-components';

import { useAnalyticsTrack } from '@hedgehog/browser/investors/shared/analytics';
import { SecondaryButton } from '@hedgehog/ui/buttons';
import { HideBreakpoint } from '@hedgehog/ui/layouts';
import { parseBorder } from '@hedgehog/ui/themes';
import { Heading, Paragraph } from '@hedgehog/ui/typography';

import { Card } from '../card/card.component';

const ShrinkedLinkCard = styled(Card)`
  padding-bottom: 1rem;
  ${({ theme }): CSSProp => ({ backgroundColor: theme.colors.grey100 })};
`;

const SponsorHeader = styled(HideBreakpoint)`
  display: flex;
  flex-direction: column;
  min-height: 2.25rem;
  margin-bottom: 1rem;

  ${({ theme }): CSSProp => ({
    color: theme.colors.black,
  })}
  gap: 1rem;
`;

const SponsorTitle = styled.div`
  position: relative;

  ${({ theme }): CSSProp => ({
    color: theme.colors.black,
  })}

  margin: -1rem;
  margin-top: 0;
  padding: 1rem;
  padding-top: 0;
  background: linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 1rem,
    rgba(255, 255, 255, 1) 3.25rem,
    rgba(255, 255, 255, 1) 100%
  );

  & > p {
    text-transform: uppercase;
    font-size: 0.75rem;
  }

  & > h3 {
    font-weight: 500;
  }
`;

const SponsorIcon = styled.img`
  width: 100%;
  height: 100%;
  max-width: 3.5rem;
  max-height: 3.5rem;
  border-radius: 50%;

  ${({ theme }): CSSObject => ({
    border: parseBorder(theme.border.normal),
    borderColor: theme.colors.shadow100,
    backgroundColor: theme.colors.white,
  })}
`;

const SponsorBody = styled.div<{ backgroundSrc?: string }>`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  min-height: 12.5rem;
  padding: 1rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: -1rem;
  overflow: hidden;

  ${({ theme, backgroundSrc }): CSSObject => ({
    border: parseBorder(theme.border.normal),
    borderColor: theme.colors.shadow100,
    backgroundImage: backgroundSrc ? `url(${backgroundSrc})` : 'none',
    backgroundColor: theme.colors.grey200,
  })}
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export interface SponsorCardProps {
  slug: string;
  className?: string;
  iconSrc: string;
  bodySrc?: string;
  title?: ReactNode | ReactNode[];
  subtitle?: ReactNode | ReactNode[];
  topbar?: ReactNode | ReactNode[];
}

export const SponsorCard = (props: SponsorCardProps): JSX.Element => {
  const track = useAnalyticsTrack();
  const { pathname } = useLocation();

  const handleLearnMore = (): void => {
    track('SponsorLearnMoreButton', 'Clicked', {
      sponsor_name: props.slug,
      coming_from: pathname,
    });
  };

  return (
    <ShrinkedLinkCard
      className={props.className}
      to={`?sponsorId=${props.slug}#sponsors`}
    >
      <FlexContainer>
        <SponsorHeader>
          <SponsorIcon src={props.iconSrc} />
          <Heading level="h4">{props.topbar}</Heading>
        </SponsorHeader>
        <SponsorBody backgroundSrc={props.bodySrc}>
          <SponsorTitle>
            <Heading level="h2">{props.title}</Heading>
            <Paragraph color="grey400" small>
              {props.subtitle}
            </Paragraph>
          </SponsorTitle>
        </SponsorBody>
      </FlexContainer>
      <SecondaryButton fluid onClick={handleLearnMore}>
        Learn more
      </SecondaryButton>
    </ShrinkedLinkCard>
  );
};
