import jwtDecode, { JwtPayload } from 'jwt-decode';
import {
  Navigate,
  Outlet,
  useLocation,
  useSearchParams,
} from 'react-router-dom';

import { useAuth } from './auth.provider';

export const RequireNoAuth = (): JSX.Element => {
  const { accessToken } = useAuth();
  const location = useLocation();
  const [params] = useSearchParams();

  // Only display children if the token has expired, if this is the /auth/signin/callback page (SocialLoginSuccessPage)
  // we never want to navigate to /explore because we need to handle situations where the user is attempting to login
  // but doesn't have an account yet.
  const excludedPaths = [
    '/signup',
    '/auth/signin/callback',
    '/auth/partner-referrals/social/callback',
  ];
  if (
    accessToken &&
    !params.get('unlockWallet') &&
    !params.get('partner_created') &&
    !excludedPaths.find((path) => location.pathname.includes(path))
  ) {
    const decoded = jwtDecode(accessToken) as JwtPayload;
    if (decoded.exp && decoded.exp * 1000 > Date.now()) {
      // token is still active, redirect to explore page
      return <Navigate to="/explore" />;
    }
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <Outlet />;
};
