import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { FormValues } from '../types';

export type IdentityCheckState = {
  errors: string[];
} & FormValues;

const initialState: IdentityCheckState = {
  errors: [],
  personal: {
    dob: '',
    postcode: '',
    addressLine1: '',
    addressLine2: '',
    town: '',
    sourceOfFunds: undefined,
  },
  tax: {
    taxResidency: '',
    taxIdentifier: '',
  },
};

const identityCheckSlice = createSlice({
  name: 'identityCheck',
  initialState,
  reducers: {
    updatePersonalInformation(
      state,
      action: PayloadAction<FormValues['personal']>,
    ) {
      state.personal = { ...state.personal, ...action.payload };
    },
    updateTaxInformation(state, action: PayloadAction<FormValues['tax']>) {
      state.tax = { ...state.tax, ...action.payload };
    },
  },
});

export const {
  actions: { updatePersonalInformation, updateTaxInformation },
  reducer,
} = identityCheckSlice;
