import { differenceInMonths, differenceInYears } from 'date-fns';

import { calculateLeaseReward } from './calculate-lease-reward.helper';
import { calculateRentReward } from './calculate-rent-rewards.helper';
import { calculateSignUpReward } from './calculate-sign-up-reward.helper';

export interface CalculateMaximumTokenAllocationInput {
  from: number | Date;
  to: number | Date;
  recentRent: number;
}

export function calculateMaximumTokenAllocation({
  from,
  to,
  recentRent,
}: CalculateMaximumTokenAllocationInput): number {
  const months = differenceInMonths(to, from);
  const years = differenceInYears(to, from);

  const signUpRewardsInTokensAmount = calculateSignUpReward(recentRent);
  const rentRewardsInTokensAmount = calculateRentReward(recentRent) * months;
  const leaseRewardsInTokensAmount = calculateLeaseReward(recentRent) * years;

  return (
    signUpRewardsInTokensAmount +
    rentRewardsInTokensAmount +
    leaseRewardsInTokensAmount
  );
}
