import { TAddress } from '@hedgehog/shared/types';

import { AddressListItem } from './address-list-item.component';

type AddressItemProps = {
  address: TAddress;
  onClick: (value: TAddress) => void;
};

export const AddressItem = ({
  address,
  onClick,
}: AddressItemProps): JSX.Element => {
  return (
    <AddressListItem
      key={address.addressLine1}
      text={`${address.addressLine1}, ${address.town}, ${address.postcode}`}
      data-address={address.addressLine1}
      onClick={(): void => onClick(address)}
    />
  );
};
