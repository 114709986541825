import { ContractIssuance } from '@hedgehog/shared/blockchain/resident-token';

import { RENT_ON_TIME_BONUS_RATIO_PER_MONTH } from './calculate-rent-rewards-estimation.helper';

export interface CalculateRentRecentInput {
  issuances: ContractIssuance[];
}

export const SIGN_ON_BONUS_RATIO = 0.0025;

export const calculateRentRecent = ({
  issuances,
}: CalculateRentRecentInput): number => {
  const paidRents = issuances.filter(
    (issuance): issuance is ContractIssuance<'rent:paid'> =>
      issuance.data.type === 'rent:paid',
  );
  const recentRentPaymentInTokensAmount =
    paidRents[paidRents.length - 1]?.amount ?? 0;
  const recentSignOnBonusInTokensAmount =
    issuances.find((issuance) => issuance.data.type === 'sign-on')?.amount ?? 0;

  if (recentRentPaymentInTokensAmount) {
    return recentRentPaymentInTokensAmount / RENT_ON_TIME_BONUS_RATIO_PER_MONTH;
  }

  if (recentSignOnBonusInTokensAmount) {
    return recentSignOnBonusInTokensAmount / 12 / SIGN_ON_BONUS_RATIO;
  }

  return 0;
};
