import styled, { CSSProp } from 'styled-components';

const LogoGroup = styled.g`
  ${({ theme }): CSSProp => ({ color: theme.colors.primary })}
`;

const BrandGroup = styled.g`
  ${({ theme }): CSSProp => ({ color: theme.colors.black })}
`;

export interface HedgehogAuthLogoProps {
  className?: string;
}

export const HedgehogAuthLogo = ({
  className,
}: HedgehogAuthLogoProps): JSX.Element => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 780 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <LogoGroup>
        <path
          d="M88.7872 20H72.0589V59.8463L57.0548 23.4151L41.5999 29.8535L56.7563 66.6546L29.043 38.7821L17.2143 50.6787L45.2267 78.852L9.00264 63.7613L2.601 79.305L39.197 94.5506H0V111.375H81.4547V111.371H84.0108C85.1302 111.371 86.1386 110.691 86.5633 109.649L119.412 29.0633L103.935 22.6157L88.7872 59.3309V20Z"
          fill="currentColor"
        />
        <path
          d="M131.719 84.6691C131.719 89.7746 127.603 93.9135 122.527 93.9135C117.451 93.9135 113.335 89.7746 113.335 84.6691C113.335 79.5636 117.451 75.4248 122.527 75.4248C127.603 75.4248 131.719 79.5636 131.719 84.6691Z"
          fill="currentColor"
        />
        <path
          d="M160.828 92.47H144.898C144.34 92.47 143.838 92.8079 143.624 93.3261L136.979 109.453C136.602 110.366 137.27 111.371 138.253 111.371H154.182C154.74 111.371 155.243 111.033 155.456 110.515L162.102 94.3884C162.478 93.475 161.811 92.47 160.828 92.47Z"
          fill="currentColor"
        />
      </LogoGroup>

      <BrandGroup>
        <path
          d="M270.77 55.2448H227.692V20H211.083V111.038H227.692V71.1115H270.77V111.038H287.379V20H270.77V55.2448Z"
          fill="currentColor"
        />
        <path
          d="M324.993 49.0022C306.179 49.0022 293.852 62.0077 293.852 81.1257C293.852 100.244 306.568 112.989 326.161 112.989C338.747 112.989 346.792 107.137 352.112 102.195L344.067 90.6197C340.694 94.0011 334.725 98.8131 326.161 98.8131C316.949 98.8131 310.201 93.4809 309.293 84.247H354.058V81.1257C354.058 60.5771 343.159 49.0022 324.993 49.0022ZM324.215 61.8776C332 61.8776 337.45 66.2995 337.969 73.9727H309.682C310.98 65.6492 316.559 61.8776 324.215 61.8776Z"
          fill="currentColor"
        />
        <path
          d="M388.839 112.989C398.57 112.989 405.058 108.827 408.821 102.585H409.08V111.038H424.91V20H409.08V59.4066H408.821C405.058 53.164 398.57 49.0022 388.839 49.0022C373.139 49.0022 360.033 60.447 360.033 80.9956C360.033 101.544 373.139 112.989 388.839 112.989ZM392.861 97.9027C383.259 97.9027 376.642 91.1399 376.642 80.9956C376.642 70.8514 383.259 64.0885 392.861 64.0885C402.463 64.0885 409.08 70.8514 409.08 80.9956C409.08 91.1399 402.463 97.9027 392.861 97.9027Z"
          fill="currentColor"
        />
        <path
          d="M466.444 139C484.35 139 496.287 132.367 496.547 111.038V50.953H480.716V59.4066H480.457C476.694 53.164 470.206 49.0022 460.475 49.0022C444.775 49.0022 431.669 60.447 431.669 80.9956C431.669 101.284 444.645 112.599 460.345 112.599C470.466 112.599 476.694 108.177 480.457 102.585H480.716V108.177C480.716 120.142 476.435 123.914 465.795 123.914C460.734 123.914 453.598 123.003 444.904 120.922L442.05 136.139C450.743 137.829 459.696 139 466.444 139ZM464.497 97.9027C454.895 97.9027 448.278 91.1399 448.278 80.9956C448.278 70.8514 454.895 64.0885 464.497 64.0885C474.099 64.0885 480.716 70.8514 480.716 80.9956C480.716 91.1399 474.099 97.9027 464.497 97.9027Z"
          fill="currentColor"
        />
        <path
          d="M534.521 49.0022C515.707 49.0022 503.38 62.0077 503.38 81.1257C503.38 100.244 516.096 112.989 535.689 112.989C548.275 112.989 556.32 107.137 561.64 102.195L553.595 90.6197C550.221 94.0011 544.253 98.8131 535.689 98.8131C526.476 98.8131 519.729 93.4809 518.821 84.247H563.586V81.1257C563.586 60.5771 552.687 49.0022 534.521 49.0022ZM533.743 61.8776C541.528 61.8776 546.978 66.2995 547.497 73.9727H519.21C520.508 65.6492 526.087 61.8776 533.743 61.8776Z"
          fill="currentColor"
        />
        <path
          d="M570.266 20V111.038H586.226V81.6459C586.226 68.6405 592.065 63.8284 600.11 63.8284C608.025 63.8284 612.566 67.9902 612.566 77.7443V111.038H628.526V73.9727C628.526 56.2853 618.924 49.0022 605.949 49.0022C597.125 49.0022 590.638 52.9038 586.486 59.5366H586.226V20H570.266Z"
          fill="currentColor"
        />
        <path
          d="M668.842 49.0022C649.768 49.0022 635.625 62.1377 635.625 80.9956C635.625 99.8536 649.768 112.989 668.842 112.989C687.916 112.989 702.059 99.8536 702.059 80.9956C702.059 62.1377 687.916 49.0022 668.842 49.0022ZM668.842 64.0885C678.574 64.0885 685.58 70.7213 685.58 80.9956C685.58 91.2699 678.574 97.9027 668.842 97.9027C659.111 97.9027 651.974 91.2699 651.974 80.9956C651.974 70.7213 659.111 64.0885 668.842 64.0885Z"
          fill="currentColor"
        />
        <path
          d="M742.334 139C760.24 139 772.178 132.367 772.437 111.038V50.953H756.607V59.4066H756.348C752.585 53.164 746.097 49.0022 736.366 49.0022C720.665 49.0022 707.56 60.447 707.56 80.9956C707.56 101.284 720.536 112.599 736.236 112.599C746.357 112.599 752.585 108.177 756.348 102.585H756.607V108.177C756.607 120.142 752.325 123.914 741.685 123.914C736.625 123.914 729.489 123.003 720.795 120.922L717.94 136.139C726.634 137.829 735.587 139 742.334 139ZM740.388 97.9027C730.786 97.9027 724.169 91.1399 724.169 80.9956C724.169 70.8514 730.786 64.0885 740.388 64.0885C749.99 64.0885 756.607 70.8514 756.607 80.9956C756.607 91.1399 749.99 97.9027 740.388 97.9027Z"
          fill="currentColor"
        />
      </BrandGroup>
    </svg>
  );
};
