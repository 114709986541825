import { useMutation, ApolloError } from '@apollo/client';
import { MouseEvent, useEffect, useState } from 'react';

import { useAuthenticatedQuery } from '@hedgehog/data-access/contexts';
import {
  AssetRound,
  AssetRoundClass,
  OrderType,
} from '@hedgehog/data-access/graphql';
import {
  GetAssetQuery as GetAsset,
  GetAssetQueryVariables as GetAssetVariables,
  GET_ASSET,
  CreateOrderMutation as CreateOrder,
  CreateOrderMutationVariables as CreateOrderVariables,
  CREATE_ORDER,
  UpdateOrderMutation as UpdateOrder,
  UpdateOrderMutationVariables as UpdateOrderVariables,
  UPDATE_ORDER,
} from '@hedgehog/data-access/graphql';
import { LoadingContainer } from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';
import { BottomSheet } from '@hedgehog/ui/modals';

import { CreateNotTokenisedOrderForm } from '../../components/create-not-tokenised-order-form.component';
import { CreateTokenisedOrderForm } from '../../components/create-tokenised-order-form.component';

export interface AssetQuantityModalProps<T = unknown> {
  path: string;
  submitModal: (value: T) => void;
  closeModal: () => void;
}

export const CreateOrderModal = ({
  path,
  submitModal,
  closeModal,
}: AssetQuantityModalProps): JSX.Element | null => {
  const { data: assetPayload, ...assetState } = useAuthenticatedQuery<
    GetAsset,
    GetAssetVariables
  >(GET_ASSET, { path: path || '' }, { fetchPolicy: 'no-cache' });

  const [remainingTokens, setRemainingTokens] = useState(1);
  const [isSoldOut, setIsSoldOut] = useState(false);
  const [quantity, setQuantity] = useState<number>();
  const isLoading = assetState.loading;
  const [updateOrder, updateOrderState] = useMutation<
    UpdateOrder,
    UpdateOrderVariables
  >(UPDATE_ORDER, {
    refetchQueries: ['Order'],
  });
  const [createOrder, createOrderState] = useMutation<
    CreateOrder,
    CreateOrderVariables
  >(CREATE_ORDER, {
    refetchQueries: ['Order'],
  });
  const hasError =
    assetState.error || updateOrderState.error || createOrderState.error;
  const currentRound = assetPayload?.asset.currentRound;

  useEffect(() => {
    const { asset, order } = assetPayload || {};
    if (!asset) return;

    const { currentRound } = asset;
    if (!currentRound) return;

    // Extract the investment limits
    const {
      settings: {
        investorLimitPct: ORDER_BUY_LIMIT_PCT,
        investorLimitAmount: ORDER_BUY_LIMIT_AMOUNT,
      },
    } = currentRound;

    const roundTokensLeft = Math.floor(
      (currentRound.totalAmount - currentRound.fundedTotalAmount) /
        currentRound.roundClass.pricePerToken,
    );

    const limitByPrice = Math.floor(
      ORDER_BUY_LIMIT_AMOUNT / currentRound.roundClass?.pricePerToken,
    );
    const limitBySize =
      Math.floor(currentRound.totalAmount * ORDER_BUY_LIMIT_PCT) /
      currentRound.roundClass?.pricePerToken;

    const hardLimit = Math.floor(Math.min(limitBySize, limitByPrice));
    setRemainingTokens(Math.min(roundTokensLeft, hardLimit));

    if (order) {
      setQuantity(Math.min(roundTokensLeft, hardLimit, order.quantity));
    }
  }, [assetPayload]);

  const handleInvest = async (): Promise<void> => {
    if (createOrderState.loading || updateOrderState.loading) return;
    if (!assetPayload) return;
    if (!quantity) return;

    const { asset, order } = assetPayload;

    if (
      !asset ||
      !asset.currentRound ||
      !asset.currentRound.roundClass?.minimumInvestmentAmount ||
      !asset.currentRound.roundClass?.pricePerToken
    ) {
      return;
    }

    const { currentRound } = asset;

    if (order) {
      const { data: response } = await updateOrder({
        variables: {
          id: order.id,
          quantity,
        },
      });
      submitModal(response?.updateOrder.id);
      return;
    }

    if (currentRound) {
      try {
        const { data: response } = await createOrder({
          variables: {
            type: OrderType.BUY,
            assetRoundClassId: currentRound.roundClass.id,
            quantity,
          },
        });
        submitModal(response?.createOrder.id);
      } catch (error) {
        // No need to rethrow the error as it is already
        // handled/shown under createOrderState.error
        if (
          error instanceof ApolloError &&
          error.message.includes(`INVESTMENT_ROUND_OVERSUBSCRIBED_US`)
        ) {
          setIsSoldOut(true);
        }
      }
    }
  };

  const handleBack = (event: MouseEvent): void => {
    event.preventDefault();
    closeModal();
  };

  if (!currentRound?.roundClass) {
    return (
      <BottomSheet>
        <LoadingContainer>
          <Loader />
        </LoadingContainer>
      </BottomSheet>
    );
  }

  return (
    <BottomSheet>
      {currentRound.roundClass.isTokenized ? (
        <CreateTokenisedOrderForm
          isLoading={isLoading}
          submitting={createOrderState.loading || updateOrderState.loading}
          currentRound={currentRound as AssetRound}
          hasError={Boolean(hasError)}
          isSoldOut={isSoldOut}
          quantity={quantity}
          setQuantity={setQuantity}
          remainingTokens={remainingTokens}
          onSubmit={handleInvest}
        />
      ) : (
        <CreateNotTokenisedOrderForm
          isLoading={isLoading}
          submitting={createOrderState.loading || updateOrderState.loading}
          currentRound={currentRound as AssetRound}
          hasError={Boolean(hasError)}
          isSoldOut={isSoldOut}
          quantity={quantity}
          setQuantity={setQuantity}
          remainingTokens={remainingTokens}
          onSubmit={handleInvest}
          onBack={handleBack}
        />
      )}
    </BottomSheet>
  );
};
