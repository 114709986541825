import React, {
  RefObject,
  createRef,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useLocation } from 'react-router-dom';

export interface SidebarRouterContextType {
  currentHash?: string;
  sidebarRef: RefObject<HTMLDivElement>;
}

export const SidebarRouterContext =
  React.createContext<SidebarRouterContextType>({
    currentHash: undefined,
    sidebarRef: createRef<HTMLDivElement>(),
  });

export const useSidebarRouter = (): SidebarRouterContextType =>
  useContext(SidebarRouterContext);

export const useSidebarRouterContext = (
  hostRef: RefObject<HTMLDivElement>,
): SidebarRouterContextType => {
  const location = useLocation();
  const [currentHash, updateHash] = React.useState<string>();

  useEffect(() => {
    updateHash(location.hash);
  }, [location.hash]);

  return useMemo(
    () => ({ currentHash, sidebarRef: hostRef }),
    [currentHash, hostRef],
  );
};

export const useSidebarRef = (): RefObject<HTMLDivElement> => {
  const { sidebarRef } = useSidebarRouter();

  return sidebarRef;
};
