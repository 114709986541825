import styled, { CSSObject } from 'styled-components';

import {
  ParseKycCheckParams,
  useOnboardingStatusPolling,
  useUser,
} from '@hedgehog/browser/investors/kyc/data-access';
import { useUserAction } from '@hedgehog/data-access/contexts';
import {
  AccreditationStatus,
  KycCheckStatus,
  OnboardingDeclarationStatus,
  isKycOnly,
} from '@hedgehog/data-access/graphql';
import { ActionCard } from '@hedgehog/ui/cards';
import { StandardProps } from '@hedgehog/ui/utils';

import { OnboardingAssessmentAction } from '../onboarding-assessment-action/onboarding-assessment-action.container';
import { OnboardingIdentityCheckAction } from '../onboarding-identity-check-action/onboarding-identity-check-action.container';
import { OnboardingInvestorConfirmationAction } from '../onboarding-investor-confirmation-action/onboarding-investor-confirmation-action.container';
import { OnboardingInvestorTypeAction } from '../onboarding-investor-type-action/onboarding-investor-type-action.container';
import { OnboardingTaxFormAction } from '../onboarding-tax-form-action/onboarding-tax-form-action.container';
import { OnboardingVerificationAction } from '../onboarding-verification-action/onboarding-verification-action.container';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  ${({ theme }): CSSObject => ({ gap: theme.spacing.small })}
`;

export type OnboardingActionsProps = StandardProps<object, never>;

/** @deprecated */
export const OnboardingActions = ({
  className,
}: OnboardingActionsProps): JSX.Element => {
  const { data: userData, loading: userLoading } = useUser();
  const { data: userActionData, loading: userActionLoading } = useUserAction();
  const { data: onboardingData, loading: onboardingLoading } =
    useOnboardingStatusPolling();

  if (
    !userData?.user ||
    userLoading ||
    onboardingLoading ||
    userActionLoading ||
    !userActionData
  ) {
    return (
      <Wrapper className={className}>
        <ActionCard loading title="placeholder" caption="placeholder" />
      </Wrapper>
    );
  }

  const { value: investorType } = userActionData.investorTypeDeclaration ?? {
    value: null,
  };
  const status: ParseKycCheckParams['status'] = {
    accreditation:
      onboardingData?.onboardingProgress.status.accreditation ??
      AccreditationStatus.NOT_STARTED,
    assessment:
      onboardingData?.onboardingProgress.status.assessmentCheck ??
      KycCheckStatus.not_started,
    identityCheck:
      onboardingData?.onboardingProgress.status.identityCheck ??
      KycCheckStatus.not_started,
    taxForm:
      onboardingData?.onboardingProgress.status.taxForm ??
      KycCheckStatus.not_started,
    verification:
      onboardingData?.onboardingProgress.status.verification ??
      KycCheckStatus.not_started,
    declaration:
      onboardingData?.onboardingProgress.status.declaration ??
      OnboardingDeclarationStatus.NOT_ACCEPTED,
    investorType,
  };

  return (
    <Wrapper className={className}>
      {/* TODO: 🔍 KYC-only onboarding tech-debt - remove me - https://linear.app/hedgehog-invest/issue/HOG-2898/%F0%9F%92%A1-get-rid-of-all-the-oakglen-if-statements */}
      {isKycOnly(userActionData?.partnerSettings) && (
        <OnboardingInvestorTypeAction
          status={status}
          country={userData.user.country}
        />
      )}
      {/* TODO: 🪙 Tenant Token onboarding tech-debt - remove me - https://linear.app/hedgehog-invest/issue/HOG-2898/%F0%9F%92%A1-get-rid-of-all-the-oakglen-if-statements */}
      {/*HOG-2243: Enabled assessment for tenant token project*/}
      {userData.user.country === 'USA' && (
        <OnboardingAssessmentAction
          status={status}
          country={userData.user.country}
        />
      )}
      <OnboardingVerificationAction
        status={status}
        country={userData.user.country}
      />
      <OnboardingIdentityCheckAction
        status={status}
        country={userData.user.country}
      />
      {/* {country === 'USA' && <OnboardingAccreditationAction status={status} />} */}
      {/* TODO: 🌎 onboarding tech-debt - remove me - https://linear.app/hedgehog-invest/issue/HOG-2898/%F0%9F%92%A1-get-rid-of-all-the-oakglen-if-statements */}
      {userData.user.country === 'CHE' && (
        <OnboardingInvestorConfirmationAction
          status={status}
          country={userData.user.country}
        />
      )}
      <OnboardingTaxFormAction
        status={status}
        country={userData.user.country}
      />
    </Wrapper>
  );
};
