import styled, { CSSObject } from 'styled-components';

import { useArticle } from '@hedgehog/browser/investors/learn/data-access';
import { ButtonBase } from '@hedgehog/ui/buttons';
import { Card, CardContent, CardThumbnail } from '@hedgehog/ui/cards';
import { HeaderWithBack, HeaderWithClose } from '@hedgehog/ui/headers';
import { Loader } from '@hedgehog/ui/loaders';
import { Heading, Paragraph } from '@hedgehog/ui/typography';

const ArticleDesktopHeader = styled(Card)`
  display: flex;
  flex-flow: column nowrap;

  visibility: visible;
  ${({ theme }): CSSObject => ({
    [`${CardContent}`]: {
      gap: theme.spacing.large,
    },
    [`@media screen and (max-width: ${theme.breakpoints.normal - 1}px)`]: {
      display: 'none',
      visibility: 'hidden',
    },
  })}

  ${HeaderWithBack} {
    padding: 0;
    background: transparent;
    ${ButtonBase} {
      background: ${({ theme }): string => theme.colors.white};
    }
  }
`;

const Container = styled.div`
  ${({ theme }): CSSObject => ({
    maxWidth: theme.breakpoints.small,
  })}
`;

const ArticleMobileHeader = styled(Container)`
  display: none;
  visibility: hidden;
  flex-flow: column nowrap;

  ${({ theme }): CSSObject => ({
    gap: theme.spacing.xxlarge,
    [`${HeaderWithClose}`]: {
      flex: '0 0 auto',
      alignItems: 'flex-start',
      padding: 0,
    },
    [`@media screen and (max-width: ${theme.breakpoints.normal - 1}px)`]: {
      display: 'flex',
      visibility: 'visible',
    },
  })}
`;

const Author = styled.span`
  &:not(:last-child):after {
    content: '•';
    padding: 0 0.5rem;
  }
`;
const Tag = styled.span`
  &:not(:last-child):after {
    content: ', ';
  }
`;

export interface ArticleHeaderProps {
  id?: string;
}

export const ArticleHeader = ({
  id: articleId,
}: ArticleHeaderProps): JSX.Element => {
  const { data, error, loading } = useArticle(articleId || '');

  if (error) {
    return (
      <Paragraph small>Something went wrong, please try again later</Paragraph>
    );
  }

  if (loading || !data) {
    return <Loader />;
  }

  return (
    <>
      <ArticleMobileHeader>
        <HeaderWithClose to="../..">
          <Heading level="h4">{data.article.title}</Heading>
        </HeaderWithClose>

        <Paragraph small color="grey400">
          <Author>{data.article.author}</Author>
          {data.article.tags.map(({ tag }) => (
            <Tag key={tag}>{tag}</Tag>
          ))}
        </Paragraph>

        {data.article.imageUrl && <CardThumbnail src={data.article.imageUrl} />}
      </ArticleMobileHeader>

      <ArticleDesktopHeader>
        <HeaderWithBack to="../.." />

        <Heading level="h4">{data.article.title}</Heading>

        <Paragraph small color="grey400">
          <Author>{data.article.author}</Author>
          {data.article.tags.map(({ tag }) => (
            <Tag key={tag}>{tag}</Tag>
          ))}
        </Paragraph>

        {data.article.imageUrl && <CardThumbnail src={data.article.imageUrl} />}
      </ArticleDesktopHeader>
    </>
  );
};
