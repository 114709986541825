import { MouseEventHandler, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import styled, { CSSObject } from 'styled-components';

import { useCourseTopic } from '@hedgehog/browser/investors/learn/data-access';
import { useAnalyticsTrack } from '@hedgehog/browser/investors/shared/analytics';
import { GetTopicQuery, TopicProgress } from '@hedgehog/data-access/graphql';
import { useOnScreen } from '@hedgehog/data-access/hooks';
import { ContentCMS } from '@hedgehog/ui/cms';
import { HeaderWithClose } from '@hedgehog/ui/headers';
import { Avatar } from '@hedgehog/ui/icons';
import { EmptyStateContainer, LoadingContainer } from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';
import { Heading } from '@hedgehog/ui/typography';

import { CourseTopicNavbar } from './course-topic-navbar.component';

type GetTopic_topic_upNext = GetTopicQuery['topic']['upNext'];

const CustomCourseTopicNavbar = styled(CourseTopicNavbar)`
  margin-top: 2.5rem;
`;

const CourseTopicHeader = styled(HeaderWithClose)`
  background: transparent;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 2.5rem;

  ${Avatar} {
    ${({ theme }): CSSObject => ({ backgroundColor: theme.colors.white })}
  }
`;

export interface CourseTopicProps {
  slug?: string;
  onNext?: (upNext: GetTopic_topic_upNext | null) => void;
  onBack?: () => void;
  onStarted?: (current: TopicProgress) => void;
  onComplete?: (current: TopicProgress) => void;
}

export const CourseTopic = (props: CourseTopicProps): JSX.Element => {
  const { courseSlug, topicSlug: slug } = useParams();
  const controllerRef = useRef<HTMLDivElement>(null);
  const { data, error, loading } = useCourseTopic(slug || '');
  const completed = useOnScreen(controllerRef, [data]);
  const track = useAnalyticsTrack();

  useEffect(() => {
    if (data) {
      props.onStarted && props.onStarted(data.topic.progress);
    }
  }, [data]);

  useEffect(() => {
    if (data && completed) {
      props.onComplete && props.onComplete(data.topic.progress);
    }
  }, [completed, data]);

  if (error) {
    return (
      <EmptyStateContainer>
        Something went wrong, please try again later
      </EmptyStateContainer>
    );
  }

  if (!data || (loading && !data)) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }

  const handleNext: MouseEventHandler = () => {
    props.onNext?.(data.topic.upNext);
  };

  return (
    <>
      <CourseTopicHeader to={'..'}>
        <Heading level="h5">{data.topic.title}</Heading>
      </CourseTopicHeader>
      <ContentCMS content={data.topic.content} onAnalyticsTrack={track} />
      <CustomCourseTopicNavbar
        ref={controllerRef}
        courseSlug={courseSlug || ''}
        nextTopic={data.topic.upNext}
        onClick={handleNext}
      />
    </>
  );
};
