import { Helmet } from 'react-helmet';
import { Outlet, useLocation } from 'react-router-dom';
import styled, { CSSObject } from 'styled-components';

import { Header, HeaderWithClose } from '@hedgehog/ui/headers';
import { Avatar } from '@hedgehog/ui/icons';
import { ThemeColorName } from '@hedgehog/ui/themes';
import { Heading } from '@hedgehog/ui/typography';
import { StandardProps } from '@hedgehog/ui/utils';

const OrderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
  width: 100%;
`;

const OrderHeader = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;

  border-bottom: 1px solid transparent;
  ${({ theme }): CSSObject => ({
    borderBottomColor: theme.colors.shadow100,
    gap: theme.spacing.normal,
    paddingBottom: theme.spacing.normal,
  })}
`;

const OrderContent = styled.div``;

export type OrderStepperLayoutProps = StandardProps<{
  title?: string;
}>;

export const OrderStepperLayout = ({
  children,
}: OrderStepperLayoutProps): JSX.Element => {
  const location = useLocation();
  const order = ['#order/open', '#order/subscribe', '#order/minting'];
  const currentStep = order.indexOf(location.hash);
  const titleByHash = {
    '#order/open': 'Agreement',
    '#order/subscribe': 'Agreement',
    '#order/minting': 'Minting tokens',
  }[location.hash];

  const applyFontColor = (step: number): ThemeColorName =>
    currentStep >= step ? 'white' : 'black';

  const applyBackgroundColor = (step: number): ThemeColorName =>
    currentStep >= step
      ? currentStep === step
        ? 'black'
        : 'success'
      : 'grey200';

  const HeaderLeftComponent = (
    <>
      <Avatar
        icon={currentStep > 1 ? 'check-circle' : '1'}
        size="s"
        color={applyFontColor(0)}
        backgroundColor={applyBackgroundColor(0)}
      />
      <Avatar
        icon={currentStep > 2 ? 'check-circle' : '2'}
        size="s"
        color={applyFontColor(2)}
        backgroundColor={applyBackgroundColor(2)}
      />
    </>
  );

  return (
    <>
      <Helmet>
        <link
          rel="preload"
          as="image"
          href="https://assets.hedgehog-invest.com/video/mint-progress-460x460.gif"
        />
        <link
          rel="preload"
          as="image"
          href="https://assets.hedgehog-invest.com/video/mint-success-460x460.gif"
        />
      </Helmet>
      <OrderWrapper>
        <OrderHeader>
          {location.hash === '#order/minting' ? (
            <Header left={HeaderLeftComponent} />
          ) : (
            <HeaderWithClose
              left={HeaderLeftComponent}
              to={-(currentStep || 1)}
            />
          )}
          <Heading level="h5">{titleByHash}</Heading>
        </OrderHeader>
        <OrderContent>{children ? children : <Outlet />}</OrderContent>
      </OrderWrapper>
    </>
  );
};
