import {
  OverrideKycStatus,
  ParseKycCheckParams,
  isLegacy,
  parseAssessmentStatus,
} from '@hedgehog/browser/investors/kyc/data-access';
import { StandardProps } from '@hedgehog/ui/utils';

import { OnboardingAction } from '../../components';

export type OnboardingAssessmentActionProps = StandardProps<
  ParseKycCheckParams | OverrideKycStatus,
  never
>;

export const OnboardingAssessmentAction = (
  kycCheckParams: OnboardingAssessmentActionProps,
): JSX.Element => {
  return (
    <OnboardingAction
      title="Eligibility assessment"
      status={
        isLegacy(kycCheckParams)
          ? parseAssessmentStatus(kycCheckParams)
          : kycCheckParams.statusOverride
      }
      messages={{
        inprogress: 'Verifying, please wait...',
        pending: '5 quick questions',
      }}
      to="#kyc/assessment"
    />
  );
};
