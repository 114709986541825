import { FormEvent, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import { PrimaryButton } from '@hedgehog/ui/buttons';
import { PasswordInput } from '@hedgehog/ui/inputs';
import { Paragraph } from '@hedgehog/ui/typography';

import { SignupTermsCheckbox } from '../components';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const SignupErrorText = styled.p`
  font-weight: 600;
  margin: 0;
`;

const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const PasswordHelpText = styled(Paragraph)`
  font-size: 0.75rem;
  color: ${({ theme }): string => theme.colors.grey500};
`;

export interface SetPasswordFormData {
  password?: string;
  confirmPassword?: string;
  accepted?: boolean;
}

export interface SetPasswordFormProps {
  onSubmit?: (formData: Required<SetPasswordFormData>) => void;
  onFormChange?: (formData: SetPasswordFormData) => void;
  className?: string;
  defaultForm?: SetPasswordFormData;
  errors?: string[];
  loading?: boolean;
  termsUrl?: string;
  privacyPolicyUrl?: string;
}

export const SetPasswordForm = ({
  defaultForm,
  errors,
  loading,
  onSubmit,
  onFormChange,
  termsUrl,
  privacyPolicyUrl,
  className,
}: SetPasswordFormProps): JSX.Element => {
  const [form, updateForm] = useState<SetPasswordFormData>({
    accepted: false,
    password: defaultForm?.password || '',
    confirmPassword: defaultForm?.confirmPassword || '',
  });

  const isFormValid = form.password === form.confirmPassword && form.accepted;

  useEffect(() => {
    if (onFormChange) onFormChange(form);
  }, [form, onFormChange]);

  const handleSubmit = (event: FormEvent): void => {
    event.preventDefault();
    if (isFormValid) {
      if (onSubmit) onSubmit(form as Required<SetPasswordFormData>);
    }
  };

  const handlePasswordChange = (value: string): void =>
    updateForm((state) => ({ ...state, password: value }));
  const handleConfirmPasswordChange = (value: string): void =>
    updateForm((state) => ({ ...state, confirmPassword: value }));
  const handleAcceptedChange = (value: boolean): void =>
    updateForm((state) => ({ ...state, accepted: value }));

  return (
    <Form onSubmit={handleSubmit} className={className}>
      <FormSection>
        <div>
          <PasswordInput
            name="password"
            value={form.password}
            placeholder="Password"
            label="Password"
            onChange={handlePasswordChange}
          />
          <PasswordHelpText>
            At least 8 characters, one capital, one number
          </PasswordHelpText>
        </div>
        <PasswordInput
          name="confirmPassword"
          value={form.confirmPassword}
          placeholder="Confirm password"
          label="Confirm password"
          onChange={handleConfirmPasswordChange}
        />
      </FormSection>

      <FormSection>
        <SignupTermsCheckbox
          name="accepted"
          value={form.accepted || false}
          onChange={handleAcceptedChange}
          termsUrl={termsUrl}
          privacyPolicyUrl={privacyPolicyUrl}
        />
      </FormSection>

      {errors?.length !== 0 && <SignupErrorText>{errors}</SignupErrorText>}

      <PrimaryButton
        fluid
        type="submit"
        disabled={!isFormValid}
        loading={loading}
      >
        Proceed
      </PrimaryButton>
    </Form>
  );
};
