import { Navigate, useParams } from 'react-router-dom';
import styled, { CSSObject, RuleSet, css } from 'styled-components';

import { CardThumbnail } from '@hedgehog/ui/cards';
import { Column, ColumnLayout } from '@hedgehog/ui/layouts';

import { ArticleHeader } from './article-header.component';
import { Article } from './article.container';

const ArticleGrid = styled(ColumnLayout)`
  margin: 0 auto;
  ${({ theme }): CSSObject => ({
    gridGap: '6.25rem',
    [`@media only screen and (max-width: ${theme.breakpoints.normal}px)`]: {
      gridGap: '3rem',
      gridTemplateColumns: '100%',
    },
  })}

  ${CardThumbnail} {
    min-height: 12rem;
    background-size: contain;
  }
`;

const HiddenStyle = css`
  display: none;
  visibility: hidden;
`;

const Container = styled.div`
  ${({ theme }): CSSObject => ({
    maxWidth: theme.breakpoints.small,
  })}
`;
const ArticleHeaderColumn = styled(Column)<{ hidden?: true }>`
  ${({ hidden }): RuleSet<object> | undefined =>
    hidden ? HiddenStyle : undefined}
`;

export const ArticlePage = (): JSX.Element => {
  const { articleId } = useParams();

  if (!articleId) {
    return <Navigate to="/learn" />;
  }

  return (
    <ArticleGrid>
      <ArticleHeaderColumn size="minmax(min-content, 25rem)">
        <ArticleHeader id={articleId} />
      </ArticleHeaderColumn>
      <Column>
        <Container>
          <Article id={articleId} />
        </Container>
      </Column>
    </ArticleGrid>
  );
};

export default ArticlePage;
