import { useEffect, useState } from 'react';
import styled, { CSSObject } from 'styled-components';

import { usePersonalReferrals } from '@hedgehog/browser/investors/account/data-access';
import { useAnalyticsTrack } from '@hedgehog/browser/investors/shared/analytics';
import { SecondaryButton } from '@hedgehog/ui/buttons';
import { GenericCard } from '@hedgehog/ui/cards';
import { defaultAnimationTimeMs, useModal } from '@hedgehog/ui/modals';

import { AccountReferralOptions } from '../components/referral-options.component';

const ReferalCard = styled(GenericCard)`
  ${({ theme: { colors } }): CSSObject => ({
    backgroundColor: colors.grey100,
  })}
`;

export const AccountReferrals = (): JSX.Element => {
  const track = useAnalyticsTrack();
  const [link, setLink] = useState('');
  const { data } = usePersonalReferrals();
  const { openAndResolveWithSubmitValue } = useModal(
    ({ submitModal, closing }) => (
      <AccountReferralOptions
        link={link}
        onClick={submitModal}
        isClosing={closing}
        closeModal={submitModal}
      />
    ),
    { delayCloseMs: defaultAnimationTimeMs },
  );

  useEffect(() => {
    if (link) return;
    if (!data) return;
    if (!data.referrals) return;

    const { referrals } = data;
    setLink(`hedgehog-invest.com/ref?code=${referrals.code}`);
  }, [data, link]);

  const handleShareClick = async (): Promise<void> => {
    try {
      track('ReferralShareLink', 'Clicked', {
        coming_from: 'profile',
      });
      await openAndResolveWithSubmitValue();
    } catch (error) {
      /* NOOP */
    }
  };

  const amount = data?.referrals?.amount ?? 0;

  const referralText =
    amount > 0
      ? `You have referred ${amount} friend${amount > 1 ? 's' : ''}!`
      : undefined;

  return (
    <ReferalCard
      title="Help friends discover Hedgehog's benefits"
      imageSrc="https://assets.hedgehog-invest.com/profile/referrals.png"
      actionButton={
        <SecondaryButton onClick={handleShareClick}>
          Share your link
        </SecondaryButton>
      }
      infoText={referralText}
    />
  );
};

export default AccountReferrals;
