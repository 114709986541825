import EventEmitter from 'events';
interface TargetPostMessage {
  addEventListener(
    eventType: 'message',
    callback: (event: MessageEvent) => void,
  ): void;
  postMessage(message: string | Record<string, unknown>): void;
}
declare global {
  interface Window {
    webkit_broadcaster?: EventEmitter;
    webkit?: {
      messageHandlers?: { nativeAppMessageHandler?: TargetPostMessage };
    };
  }
}

export function register(): void {
  window.webkit_broadcaster = new EventEmitter();
}
