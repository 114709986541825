import { PartnerSettingName } from '../types';

type PartnerSetting = {
  name: PartnerSettingName;
  enabled: boolean;
};

export function isPartnerSettingEnabled(
  settings: PartnerSetting[],
  settingName: keyof typeof PartnerSettingName,
): boolean {
  return settings.some(
    (setting) => setting.name === settingName && setting.enabled,
  );
}

export function isPartnerSettingDisabled(
  settings: PartnerSetting[],
  settingName: keyof typeof PartnerSettingName,
): boolean {
  return !isPartnerSettingEnabled(settings, settingName);
}

// TODO: 🔍 KYC-only tech-debt - remove me - https://linear.app/hedgehog-invest/issue/HOG-2898/%F0%9F%92%A1-get-rid-of-all-the-oakglen-if-statements
// NB. This function may have a purpose in a post-configurable onboarding world, but should be treated as suspect until proven otherwise.
// Even if we keep it, its implementation will probably have to be updated.
export function isKycOnly(settings?: PartnerSetting[]): boolean {
  if (!settings) return false;
  return (
    isPartnerSettingEnabled(settings, 'kyc') &&
    isPartnerSettingDisabled(settings, 'investments')
  );
}

export function isNDA(settings?: PartnerSetting[]): boolean {
  if (!settings) return false;
  return isPartnerSettingEnabled(settings, 'nda');
}
