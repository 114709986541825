import React from 'react';
import { Helmet } from 'react-helmet';
import { Outlet, useParams } from 'react-router-dom';
import styled, { CSSObject, RuleSet, css, useTheme } from 'styled-components';

import { useAnalyticsPage } from '@hedgehog/browser/investors/shared/analytics';
import { useMediaQuery } from '@hedgehog/data-access/hooks';
import { Column, ColumnLayout } from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';
import { screens } from '@hedgehog/utils/sizes';

import { CourseOverview } from './course-overview.container';

const CourseTopicGrid = styled(ColumnLayout)`
  ${({ theme }): CSSObject => ({
    gridGap: '6.25rem',
    [`@media only screen and (max-width: ${theme.breakpoints.normal}px)`]: {
      gridTemplateColumns: '100%',
    },
  })}

  padding-bottom: calc(
    4rem + 3.25rem
  ); // Additional space at the bottom for the floating topic controller button
`;

const HiddenStyle = css`
  display: none;
  visibility: hidden;
`;

const CourseOverviewColumn = styled(Column)<{ hidden?: true }>`
  ${({ hidden }): RuleSet<object> | undefined =>
    hidden ? HiddenStyle : undefined}
`;

export const CoursePage = (): JSX.Element => {
  const { colors } = useTheme();
  const { courseSlug, topicSlug } = useParams();
  const query = useMediaQuery(
    `only screen and (min-width: ${screens.medium}px)`,
  );
  useAnalyticsPage('Learn', 'Course Topic', {
    course: courseSlug,
    topic: topicSlug,
  });

  const isDesktop = query.matches;
  const isMobile = !isDesktop;

  return (
    <>
      <Helmet>
        <meta name="theme-color" content={colors.grey100} />
        <style>
          {`body {
            background: ${colors.grey100};
          }`}
        </style>
      </Helmet>
      <CourseTopicGrid>
        <CourseOverviewColumn
          data-testid="overview"
          size="minmax(min-content, 25rem)"
          hidden={
            isDesktop && topicSlug
              ? undefined
              : isMobile && topicSlug
              ? true
              : undefined
          }
        >
          <CourseOverview
            courseSlug={courseSlug}
            topicSlug={topicSlug}
            mobile={isMobile}
          />
        </CourseOverviewColumn>
        {topicSlug && (
          <Column data-testid="content" size="minmax(min-content, 40rem)">
            <React.Suspense fallback={<Loader />}>
              <Outlet />
            </React.Suspense>
          </Column>
        )}
      </CourseTopicGrid>
    </>
  );
};

export default CoursePage;
