import { MutationTuple, useMutation } from '@apollo/client';

import {
  COMPLETE_SIGNUP_USER,
  CompleteSignupMutation as CompleteSignup,
  CompleteSignupMutationVariables as CompleteSignupVariables,
} from '@hedgehog/data-access/graphql';

export const useCompleteSignup = (): MutationTuple<
  CompleteSignup,
  CompleteSignupVariables
> => useMutation<CompleteSignup, CompleteSignupVariables>(COMPLETE_SIGNUP_USER);
