import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation, Outlet } from 'react-router-dom';

import { useAuthUser } from '@hedgehog/browser/investors/account/data-access';
import { UnsubscribePage } from '@hedgehog/browser/investors/notifications/feature-unsubscribe';
import {
  useAnalytics,
  useSourceTracking,
} from '@hedgehog/browser/investors/shared/analytics';
import { AccountAbstractionProvider } from '@hedgehog/browser/shared/account-abstraction';
import { LogoutPage } from '@hedgehog/browser/shared/auth';
import { LocalStorageAuthKeys } from '@hedgehog/data-access/contexts';
import {
  UPDATE_WALLET,
  UpdateWalletMutation as UpdateWallet,
  UpdateWalletMutationVariables as UpdateWalletVariables,
} from '@hedgehog/data-access/graphql';
import { sendMessageWithPayloadToApp } from '@hedgehog/data-access/native-mobile';

import { authorisedRoutes } from './authorised/routes';
import { unauthorisedRoutes } from './unauthorised/routes';

export const AppRouter = (): JSX.Element => {
  useSourceTracking();
  const analytics = useAnalytics();
  const user = useAuthUser();
  const location = useLocation();
  const [updateWallet] = useMutation<UpdateWallet, UpdateWalletVariables>(
    UPDATE_WALLET,
  );

  useEffect(() => {
    if (!user) return;
    window.localStorage.setItem(
      LocalStorageAuthKeys.User,
      JSON.stringify(user),
    );
    sendMessageWithPayloadToApp('setUser', {
      user: user,
    });
    analytics.identify(user.id);
  }, [user]);

  const handleAddressChange = (address?: string): void => {
    if (!address) return;
    updateWallet({ variables: { address } });
  };

  return (
    <Routes location={location}>
      <Route
        element={
          <AccountAbstractionProvider onAddressChange={handleAddressChange}>
            <Outlet />
          </AccountAbstractionProvider>
        }
      >
        {authorisedRoutes}
      </Route>
      {unauthorisedRoutes}

      <Route path="logout" element={<LogoutPage />} />
      <Route path="unsubscribe" element={<UnsubscribePage />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default AppRouter;
