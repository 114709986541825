import { ReactNode } from 'react';
import styled, { CSSProp } from 'styled-components';

import { ListItem } from '@hedgehog/ui/lists';
import { Paragraph } from '@hedgehog/ui/typography';

const Item = styled(ListItem)`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  border: none !important;
  cursor: pointer;
  border-radius: 0.5rem;

  &,
  &:first-child,
  &:last-child {
    padding: 1rem 0.5rem !important;
  }

  &:hover {
    transition: 200ms background-color ease-in-out;

    ${({ theme }): CSSProp => ({
      backgroundColor: theme.colors.grey100,
    })}
  }
`;

type AddressListItemProps = {
  text?: string;
  children?: ReactNode | ReactNode[];
  onClick: () => void;
};

export const AddressListItem = ({
  text,
  onClick,
  children,
  ...otherProps
}: AddressListItemProps): JSX.Element => {
  return (
    <Item {...otherProps} onClick={onClick}>
      {text && <Paragraph>{text}</Paragraph>}
      {children}
    </Item>
  );
};
