import { format } from 'date-fns';
import styled from 'styled-components';

import { TransactionPill, TransactionPillProps } from '@hedgehog/ui/pills';
import { Heading, Label } from '@hedgehog/ui/typography';
import { StandardProps } from '@hedgehog/ui/utils';

const Row = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`;

const RowContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
`;

type OrderTransactionItemType = 'unvested' | 'vested' | 'upcoming';

export type OrderTransactionItemProps = StandardProps<{
  timestamp: number;
  title: string;
  amount: TransactionPillProps['quantity'];
  type?: OrderTransactionItemType;
}>;

export const OrderTransactionItem = styled(
  ({
    amount,
    timestamp,
    title,
    type = 'unvested',
  }: OrderTransactionItemProps) => {
    const labelColor = type === 'upcoming' ? 'grey300' : 'grey400';
    const pillColor = type === 'vested' ? 'success' : 'secondary';
    const iconType = type === 'vested' ? 'arrow-down' : 'token';

    return (
      <Row>
        <RowContent>
          <Label color={labelColor}>{format(timestamp, 'MMM dd, yyyy')}</Label>
          <Heading level="h6">{title}</Heading>
        </RowContent>

        <TransactionPill
          currency="USD"
          quantity={amount}
          icon={iconType}
          color={pillColor}
        />
      </Row>
    );
  },
)``;
