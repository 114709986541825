import React, { ReactNode } from 'react';
import styled, { CSSObject } from 'styled-components';

import { useAnalyticsTrack } from '@hedgehog/browser/investors/shared/analytics';
import { IconType, Avatar } from '@hedgehog/ui/icons';
import { Paragraph } from '@hedgehog/ui/typography';

import {
  LinkBlock,
  LinkBlockAnchorOrRouterProps,
} from '../link-block/link-block.component';

const Title = styled(Paragraph)`
  ${({ theme }): CSSObject => ({
    fontSize: theme.typography.body_small.fontSize,
    lineHeight: theme.typography.body.lineHeight,
  })}
  margin-right: 0.5rem;
  margin-bottom: 0.25rem;
  overflow: hidden;
`;

const Description = styled(Paragraph)`
  ${({ theme }): CSSObject => ({
    fontSize: theme.typography.body_small.fontSize,
    lineHeight: theme.typography.body.lineHeight,
  })}
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-right: 0.5rem;
`;

const Column = styled.div`
  flex: 1 1 0;
  overflow: hidden;

  &:last-child {
    flex: 0 0 auto;
  }
`;

const NotificationWrapper = styled(LinkBlock)`
  transition: background-color 3000ms ease-in-out;
  align-items: start;

  &.unread {
    ${({ theme }): CSSObject => ({
      background: theme.colors.primary,
    })}
  }
`;

const CustomAvatar = styled(Avatar)<{ backgroundImage?: string }>`
  ${({ backgroundImage }): string =>
    backgroundImage
      ? `background: url(${backgroundImage}); background-size: cover;`
      : ''}
`;

export type NotificationVariant = 'image' | 'icon' | 'image-and-icon';

export type NotificationType = 'default' | 'discount';

export interface NotificationWithIconProps {
  icon: IconType;
  variant: 'icon';
  imageSrc?: never;
}

export interface NotificationWithImageProps {
  imageSrc: string;
  variant: 'image';
  icon?: never;
}

export interface NotificationWithImageAndIconProps {
  variant: 'image-and-icon';
  icon: IconType;
  imageSrc: string;
}

export interface NotificationListItemBaseProps {
  className?: string;
  type?: NotificationType;
  unread?: boolean;
  title?: ReactNode | ReactNode[];
  description?: ReactNode | ReactNode[];
  onClick?: React.MouseEventHandler;
}

export type NotificationListItemProps = (
  | NotificationWithIconProps
  | NotificationWithImageProps
  | NotificationWithImageAndIconProps
) &
  NotificationListItemBaseProps &
  LinkBlockAnchorOrRouterProps;

export const NotificationListItem = styled(
  ({
    title,
    description,
    icon,
    imageSrc,
    variant,
    type,
    unread,
    className,
    onClick,
    ...linkProps
  }: NotificationListItemProps): JSX.Element => {
    const track = useAnalyticsTrack();
    const classNames = [className];
    if (unread) {
      classNames.push('unread');
    }

    const handleClick: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
      track('Notification', 'Opened', {
        title,
      });
      onClick && onClick(event);
    };

    return (
      <NotificationWrapper
        notify
        className={classNames.join(' ')}
        data-testid="notification-list-item"
        {...linkProps}
        onClick={handleClick}
      >
        <Column>
          {title && <Title weight="bold">{title}</Title>}
          {description && <Description>{description}</Description>}
        </Column>
        <Column>
          {variant === 'icon' && (
            <CustomAvatar
              data-testid="notification-thumbnail"
              icon={icon}
              size="m"
              backgroundColor={'secondary'}
            />
          )}
          {variant === 'image' && (
            <CustomAvatar
              data-testid="notification-thumbnail"
              icon=""
              size="m"
              backgroundImage={imageSrc}
            />
          )}
          {variant === 'image-and-icon' && (
            <CustomAvatar
              data-testid="notification-thumbnail"
              icon={icon}
              size="m"
              backgroundImage={imageSrc}
            />
          )}
        </Column>
      </NotificationWrapper>
    );
  },
)``;
