import styled, { CSSProp } from 'styled-components';

import { useAuthUser } from '@hedgehog/browser/investors/account/data-access';
import { usePartnerSetting } from '@hedgehog/browser/investors/kyc/data-access';

import { RiskWarningText } from './risk-warning-text.component';

const RiskWarningContainer = styled.div`
  text-align: center;

  ${({ theme: { colors, breakpoints } }): CSSProp => ({
    color: colors.grey300,
    backgroundColor: colors.black,
    a: {
      color: colors.white,
    },
    padding: '1rem 2rem',
    [`@media only screen and (max-width: ${breakpoints.normal}px)`]: {
      color: colors.grey400,
      fontSize: '0.625rem',
      lineHeight: '0.875rem',
      a: {
        color: colors.primary,
      },
      padding: '0.75rem 1.5rem',
      backgroundColor: 'white',
      borderBottom: `1px solid ${colors.shadow100}`,
    },
  })}
`;

export const RiskWarning = (): JSX.Element | null => {
  const user = useAuthUser();
  const { enabled: isRiskWarningEnabled } =
    usePartnerSetting('uk_risk_warning');
  const { enabled: isInvestingEnabled } = usePartnerSetting('investments');

  if (user?.country !== 'GBR') return null;
  if (!isRiskWarningEnabled) return null;
  if (!isInvestingEnabled) return null;

  return (
    <RiskWarningContainer>
      <RiskWarningText />
    </RiskWarningContainer>
  );
};
