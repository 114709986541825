import {
  GetOnboardingWorkflowsQuery,
  WorkflowStatus,
  WorkflowStep,
} from '@hedgehog/data-access/graphql';

type GetOnboardingWorkflows_onboardingWorkflows_preInvest =
  GetOnboardingWorkflowsQuery['onboardingWorkflows']['preInvest'][0];

type WorkflowStepStyle =
  | 'completed'
  | 'failed'
  | 'retry'
  | 'inprogress'
  | 'stale'
  | 'pending'
  | 'blocked';

const toStyleCategory = (status?: WorkflowStatus): WorkflowStepStyle | null => {
  switch (status) {
    case WorkflowStatus.complete:
      return 'completed';
    case WorkflowStatus.started:
      return 'inprogress';
    case WorkflowStatus.failed:
      return 'failed';
    case WorkflowStatus.not_started:
      return 'pending';
    default:
      return null;
  }
};

export interface PreInvestHelper {
  assessmentStatus(): WorkflowStepStyle;
  identityCheckStatus(): WorkflowStepStyle;
  investorTypeStatus(): WorkflowStepStyle;
  accreditationStatus(): WorkflowStepStyle;
  taxFormStatus(): WorkflowStepStyle;
  verificationStatus(): WorkflowStepStyle;
  investorConfirmationStatus(): WorkflowStepStyle;
  workflow: (GetOnboardingWorkflows_onboardingWorkflows_preInvest | null)[];
}

export const PreInvestWorkflow = (
  workflow: (GetOnboardingWorkflows_onboardingWorkflows_preInvest | null)[],
): PreInvestHelper => {
  const getStep = (
    name: WorkflowStep,
  ): GetOnboardingWorkflows_onboardingWorkflows_preInvest | null | undefined =>
    workflow.find((step) => step?.name === name);

  const getStatus = (name: WorkflowStep): WorkflowStatus | undefined =>
    getStep(name)?.status;

  const otherSteps = (
    ...names: WorkflowStep[]
  ): (GetOnboardingWorkflows_onboardingWorkflows_preInvest | null)[] =>
    workflow.filter((step) => step && !names.includes(step?.name));

  return {
    workflow,
    assessmentStatus(): WorkflowStepStyle {
      return toStyleCategory(getStatus(WorkflowStep.assessment)) || 'pending';
    },
    identityCheckStatus(): WorkflowStepStyle {
      const verificationStatus = getStatus(WorkflowStep.verification);
      const status = getStatus(WorkflowStep.identity_check);

      if (
        verificationStatus &&
        verificationStatus !== WorkflowStatus.complete &&
        verificationStatus !== WorkflowStatus.started
      ) {
        return 'blocked';
      }

      return toStyleCategory(status) || 'pending';
    },
    investorTypeStatus(): WorkflowStepStyle {
      return (
        toStyleCategory(getStatus(WorkflowStep.investor_type_declaration)) ||
        'pending'
      );
    },
    accreditationStatus(): WorkflowStepStyle {
      return (
        toStyleCategory(getStatus(WorkflowStep.accreditation)) || 'pending'
      );
    },
    taxFormStatus(): WorkflowStepStyle {
      const identityCheckStatus = getStatus(WorkflowStep.identity_check);
      const identityCheckStuck =
        identityCheckStatus === WorkflowStatus.not_started ||
        identityCheckStatus === WorkflowStatus.failed;

      const otherStepsComplete = otherSteps(
        WorkflowStep.tax_form,
        WorkflowStep.identity_check,
      ).every((step) => step?.status === WorkflowStatus.complete);

      if (identityCheckStuck || !otherStepsComplete) {
        return 'blocked';
      }

      return toStyleCategory(getStatus(WorkflowStep.tax_form)) || 'pending';
    },
    verificationStatus(): WorkflowStepStyle {
      const eligibilityAssessmentStatus = getStatus(WorkflowStep.assessment);

      if (
        eligibilityAssessmentStatus === WorkflowStatus.not_started ||
        eligibilityAssessmentStatus === WorkflowStatus.failed
      ) {
        return 'blocked';
      }

      return toStyleCategory(getStatus(WorkflowStep.verification)) || 'pending';
    },
    investorConfirmationStatus(): WorkflowStepStyle {
      return (
        toStyleCategory(
          getStatus(WorkflowStep.investor_confirmation_declaration),
        ) || 'pending'
      );
    },
  };
};
