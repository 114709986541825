import axios from 'axios';

interface VeriffResponse {
  status: string;
  verification: {
    id: string;
    url: string;
    sessionToken: string;
    status: string;
  };
}

interface VerificationSessionPayload {
  firstName: string;
  lastName: string;
  userId: string | null;
}

export async function createVerificationSession(
  { firstName, lastName, userId }: VerificationSessionPayload,
  veriff: { url: string; apiKey: string },
): Promise<string> {
  const timestamp = new Date().toISOString();
  const VERIFF_URL = veriff.url;
  const VERIFF_API_KEY = veriff.apiKey || '';

  const headers = {
    'X-AUTH-CLIENT': VERIFF_API_KEY,
    'Content-Type': 'application/json',
  };

  const veriffClient = axios.create({
    baseURL: VERIFF_URL,
    headers,
  });

  const response = await veriffClient.post<VeriffResponse>('/sessions', {
    verification: {
      person: {
        firstName,
        lastName,
      },
      vendorData: userId,
      timestamp,
    },
  });

  if (response.data.status !== 'success') {
    throw new Error('Failed to create verification session');
  }

  return response.data.verification.url;
}
