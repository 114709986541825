import { LazyQueryResultTuple, OperationVariables } from '@apollo/client';

import {
  GET_USER,
  GetUserQuery as GetUser,
} from '@hedgehog/data-access/graphql';

import { useAuthenticatedLazyQuery } from './use-authenticated-lazy-query.hook';

export const useLazyUser = (): LazyQueryResultTuple<
  GetUser,
  OperationVariables
> => {
  return useAuthenticatedLazyQuery<GetUser>(GET_USER);
};
