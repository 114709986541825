import { stringify } from 'query-string';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useAuth } from './auth.provider';
import { hasTokenExpired } from './jwt.helpers';

export const RequireAuth = (): JSX.Element => {
  const { accessToken } = useAuth();
  const location = useLocation();

  // Check if user is authenticated
  if (!accessToken || hasTokenExpired(accessToken)) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected.
    const searchParams = stringify({
      redirectToUrl: location.pathname,
    });
    return <Navigate to={`/login?${searchParams}`} />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <Outlet />;
};
