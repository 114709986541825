import { QueryHookOptions, QueryResult } from '@apollo/client';

import { useAuthenticatedQuery } from '@hedgehog/data-access/contexts';
import {
  GetOnboardingStatusQuery as GetOnboardingStatus,
  GET_ONBOARDING_STATUS,
  GetOnboardingStatusQueryVariables as GetOnboardingStatusVariables,
} from '@hedgehog/data-access/graphql';

export const useOnboardingStatus = (
  queryOptions?: QueryHookOptions<
    GetOnboardingStatus,
    GetOnboardingStatusVariables
  >,
): QueryResult<GetOnboardingStatus, GetOnboardingStatusVariables> =>
  useAuthenticatedQuery<GetOnboardingStatus>(
    GET_ONBOARDING_STATUS,
    {},
    queryOptions,
  );
