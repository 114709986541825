import React from 'react';
import styled from 'styled-components';

import { Header, HeaderButton } from '@hedgehog/ui/headers';
import { Heading, Paragraph } from '@hedgehog/ui/typography';

const transitionDurationMs = '300ms';

const StyledHeader = styled(Header)`
  background-color: transparent;
`;

const HeaderContent = styled.div<{ collapsed: boolean }>`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  flex: 1 1 0;
  position: relative;
  margin-top: ${({ collapsed }): string => (collapsed ? '0rem' : '2rem')};
  padding-right: ${({ collapsed }): string => (collapsed ? '3rem' : '0rem')};
  transition: all ${transitionDurationMs} ease-in-out;
`;

const HeaderImage = styled.div`
  overflow: hidden;
  width: 2.5rem;
  height: 2.5rem;
`;

const HeaderTitle = styled(Heading)<{ collapsed: boolean }>`
  font-size: ${({ collapsed }): string => (collapsed ? '1.25rem' : '2rem')};
  line-height: ${({ collapsed }): string => (collapsed ? '1.5rem' : '2.25rem')};

  white-space: ${({ collapsed }): string => (collapsed ? 'nowrap' : 'inherit')};
  text-overflow: ${({ collapsed }): string =>
    collapsed ? 'ellipsis' : 'unset'};

  transition: all ${transitionDurationMs} ease-in-out;
`;

const HeaderCaption = styled(Paragraph)<{ collapsed: boolean }>`
  margin-top: ${({ collapsed }): string => (collapsed ? '0.25rem' : '1rem')};
  font-weight: 400;

  white-space: ${({ collapsed }): string => (collapsed ? 'nowrap' : 'inherit')};
  text-overflow: ${({ collapsed }): string =>
    collapsed ? 'ellipsis' : 'unset'};

  overflow: hidden;
  transition: all ${transitionDurationMs} ease-in-out;
`;

interface InnerHeaderProps {
  isCollapsed?: boolean;
  headerIcon?: React.ReactNode;
  shouldHideBackBtn?: boolean;
  backPath?: string;
  headerTitle?: React.ReactNode;
  headerCaption?: React.ReactNode;
  className?: string;
}

export const InnerHeader = ({
  isCollapsed = false,
  headerIcon,
  shouldHideBackBtn,
  backPath,
  headerTitle,
  headerCaption,
  className,
}: InnerHeaderProps): JSX.Element => {
  return (
    <div className={className}>
      <StyledHeader
        left={headerIcon && <HeaderImage>{headerIcon}</HeaderImage>}
        right={
          !shouldHideBackBtn && (
            <HeaderButton
              icon="cross"
              to={backPath || -1}
              backgroundColor="white"
            />
          )
        }
      />

      {/* Content fragment */}
      {(headerTitle || headerCaption) && (
        <HeaderContent collapsed={isCollapsed}>
          {headerTitle && (
            <HeaderTitle level="h3" collapsed={isCollapsed}>
              {headerTitle}
            </HeaderTitle>
          )}
          {headerCaption && (
            <HeaderCaption small collapsed={isCollapsed}>
              {headerCaption}
            </HeaderCaption>
          )}
        </HeaderContent>
      )}
    </div>
  );
};
