import { createVeriffFrame, MESSAGES } from '@veriff/incontext-sdk';
import { useEffect } from 'react';

import { StandardProps } from '@hedgehog/ui/utils';

export type VeriffFrameProps = StandardProps<{
  sessionUrl?: string;
  refetchVerification: () => void;
}>;

export const VeriffModal = ({
  sessionUrl,
  refetchVerification,
}: VeriffFrameProps): JSX.Element => {
  useEffect(() => {
    if (!sessionUrl) return;

    const frame = createVeriffFrame({
      url: sessionUrl,
      lang: 'en',
      onEvent(msg) {
        if ([MESSAGES.FINISHED, MESSAGES.CANCELED].includes(msg)) {
          refetchVerification();
        }
      },
    });

    return () => {
      frame.close();
    };
  }, [sessionUrl]);

  return <div id="veriff-root"></div>;
};
