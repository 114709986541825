import { NREResidentToken } from '@hedgehoginvest/nre-resident-token';
import { Route, Outlet } from 'react-router-dom';

import { AccountPage } from '@hedgehog/browser/investors/account/feature-account';
import { ContractProvider } from '@hedgehog/browser/investors/account-abstraction/data-access';
import { NDAGuard } from '@hedgehog/browser/investors/entry/feature-nda';
import { ComingSoonPage } from '@hedgehog/browser/investors/explore/feature-asset-detail';
import { AssetPage } from '@hedgehog/browser/investors/explore/feature-asset-detail';
import { CooldownPage } from '@hedgehog/browser/investors/explore/feature-cooldown';
import { useUser } from '@hedgehog/browser/investors/kyc/data-access';
import { learnRoutes } from '@hedgehog/browser/investors/learn/shell';
import { PortfolioPage } from '@hedgehog/browser/investors/order/feature-portfolio';
import { OrderNavigate } from '@hedgehog/browser/investors/order/shell';
import { PostSignupWorkflowController } from '@hedgehog/browser/investors/workflows/onboarding';
import { NavigateWithSearchParams } from '@hedgehog/browser/shared/router';
import {
  CooldownGuard,
  InvestorTypeGuard,
  RequireAuth,
  StandaloneKycGuard,
  CascadeGuards,
  AssessmentGuard,
  JurisdictionWarningGuard,
} from '@hedgehog/data-access/contexts';
import { FeatureFlagNames } from '@hedgehog/data-access/graphql';
import { useFeature } from '@hedgehog/data-access/hooks';
import { NotificationCentrePage } from '@hedgehog/features/notification';
import { Loader } from '@hedgehog/ui/loaders';
import { ScrollToTop } from '@hedgehog/ui/routing';

import { environment } from '../../environments/environment';
import { AppLayout } from '../layouts';
import { SidebarInvestorRouter } from '../sidebar.router';

import { exploreRoutes } from './explore.routes';

// TODO: 🌳 🔍 🌎 onboarding tech-debt -
// https://linear.app/hedgehog-invest/issue/HOG-2898/%F0%9F%92%A1-get-rid-of-all-the-oakglen-if-statements
const GuardsOrWorkflow = (): JSX.Element => {
  const flag = useFeature(FeatureFlagNames.post_signup_workflows);
  const { data, loading } = useUser();

  if (loading) return <Loader />;

  return flag.isEnabled && flag.value.includes?.(data?.user?.partner?.slug) ? (
    <PostSignupWorkflowController />
  ) : (
    <CascadeGuards order="desc">
      <StandaloneKycGuard priority={100} />
      <NDAGuard priority={90} />
      <JurisdictionWarningGuard priority={50} />
      <InvestorTypeGuard priority={40} />
      <AssessmentGuard priority={30} />
      <CooldownGuard priority={20} />
    </CascadeGuards>
  );
};

export const authorisedRoutes = (
  <Route element={<RequireAuth />}>
    <Route element={<Outlet />}>
      <Route
        element={
          <ContractProvider
            abi={NREResidentToken.abi}
            address={environment.tenantToken.address}
          >
            <SidebarInvestorRouter />
            <Outlet />
          </ContractProvider>
        }
      >
        <Route path="cooldown" element={<CooldownPage />} />

        <Route element={<GuardsOrWorkflow />}>
          <Route
            element={
              <AppLayout>
                <Outlet />
              </AppLayout>
            }
          >
            {exploreRoutes}

            <Route
              element={
                <ScrollToTop>
                  <Outlet />
                </ScrollToTop>
              }
            >
              <Route path="portfolio" element={<PortfolioPage />} />

              <Route path="assets/:slug">
                <Route path="coming-soon" element={<ComingSoonPage />} />
                <Route path="*" element={<AssetPage />} />
              </Route>

              <Route path="kyc">
                <Route
                  index
                  path="*"
                  element={
                    <NavigateWithSearchParams
                      to={{ pathname: '/explore', hash: 'kyc' }}
                    />
                  }
                />
              </Route>

              <Route path="buy/:orderId">
                <Route
                  index
                  element={
                    <OrderNavigate
                      replace
                      to={{ pathname: '/portfolio', hash: '#order' }}
                    />
                  }
                />
                <Route
                  path="*"
                  element={
                    <OrderNavigate
                      replace
                      to={{ pathname: '/portfolio', hash: '#order' }}
                    />
                  }
                />
              </Route>

              <Route path="order/:orderId">
                <Route
                  index
                  element={
                    <OrderNavigate
                      replace
                      to={{ pathname: '/portfolio', hash: '#order' }}
                    />
                  }
                />
                <Route
                  path="*"
                  element={
                    <OrderNavigate
                      replace
                      to={{ pathname: '/portfolio', hash: '#order' }}
                    />
                  }
                />
              </Route>

              {learnRoutes}

              <Route path="account" element={<AccountPage />} />

              <Route
                path="notifications"
                element={<NotificationCentrePage />}
              />
            </Route>
          </Route>
        </Route>
      </Route>
    </Route>
  </Route>
);
