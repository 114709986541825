import { useEffect } from 'react';

import { useAccountAbstraction } from '@hedgehog/browser/shared/account-abstraction';

import { ContractContextType } from '../providers';

import { useContract } from './use-contract.hook';

export const useContractIssuances = (): ContractContextType['issuances'] => {
  const { jsonRpcProvider } = useAccountAbstraction();
  const { contract, issuances, getIssuances } = useContract();

  useEffect(() => {
    if (!jsonRpcProvider) return;
    getIssuances();
  }, [jsonRpcProvider]);

  return {
    ...issuances,
    loading: contract.loading || issuances.loading,
  };
};
