import {
  ContentBlockType,
  Course,
  Topic,
  ContentTitleBodyBlock,
  TopicProgress,
} from '@hedgehog/data-access/graphql';

const contentData: ContentTitleBodyBlock = {
  __typename: 'ContentTitleBodyBlock',
  title: 'Content title',
  description: 'Content description',
};

const topic: Topic = {
  __typename: 'Topic',
  id: 'topicId',
  courseId: 'courseId',
  content: [
    {
      __typename: 'ContentBlock',
      type: ContentBlockType.TitleAndBody,
      data: contentData,
    },
  ],
  durationInMinutes: 3,
  progress: TopicProgress.NOT_STARTED,
  slug: 'topic_slug',
  title: 'Topic title',
  upNext: null,
  createdAt: '2021-01-01T00:00:00.000Z',
  updatedAt: '2021-01-01T00:00:00.000Z',
};

const course: Course = {
  __typename: 'Course',
  id: 'courseId',
  imageUrl: '/assets/images/example.png',
  durationInMinutes: 3,
  slug: 'course_slug',
  title: 'Course title',
  topics: [
    {
      ...topic,
      __typename: 'CourseTopic',
      summary: '',
    },
  ],
  upNext: null,
  createdAt: '2021-01-01T00:00:00.000Z',
  updatedAt: '2021-01-01T00:00:00.000Z',
};

export const fixtures = { course, topic, contentData };
