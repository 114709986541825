import styled, { CSSProp } from 'styled-components';

import { Card, CardContent } from '@hedgehog/ui/cards';
import { Header, HeaderWithBack } from '@hedgehog/ui/headers';
import { Heading, Paragraph } from '@hedgehog/ui/typography';

import { AccreditationFlow } from '../../containers';
import { useAssessmentQuiz, useAssessmentRouterSettings } from '../../hooks';

export interface AccreditationContentProps {
  error?: boolean;
}

const Alert = styled(Card)`
  ${({ theme }): CSSProp => ({
    color: theme.colors.grey500,
    backgroundColor: theme.colors.errorLight,
    [`${CardContent}`]: {
      gap: theme.spacing.xxxsmall,
    },
  })}
`;

export const AssessmentContent = ({
  error,
}: AccreditationContentProps): JSX.Element => {
  const { redirectTo } = useAssessmentRouterSettings();
  const { currentPage, setPageId, getPrevPageId } = useAssessmentQuiz();

  const handleClick = (): void => {
    const prevPageId = getPrevPageId();
    if (prevPageId) {
      setPageId(prevPageId);
      return;
    }
  };

  const headerTitle = (
    <Heading level="h5">{currentPage?.name || 'Investor assessment'}</Heading>
  );

  return (
    <>
      {redirectTo ? (
        <HeaderWithBack to={redirectTo} onBack={handleClick}>
          {headerTitle}
        </HeaderWithBack>
      ) : (
        <Header>{headerTitle}</Header>
      )}
      <AccreditationFlow
        error={
          error ? (
            <Alert>
              <Heading color="black" level="h7">
                Oops!
              </Heading>
              <Paragraph small>
                Based on your responses, acquiring this investment may be
                inappropriate for you. Please review the responses to your
                assessment again
              </Paragraph>
            </Alert>
          ) : undefined
        }
      />
    </>
  );
};
