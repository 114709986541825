import { useRef } from 'react';
import styled, { CSSObject } from 'styled-components';

import { AssetRound } from '@hedgehog/data-access/graphql';
import { PrimaryButton } from '@hedgehog/ui/buttons';
import { QuantityInput } from '@hedgehog/ui/inputs';
import { LoadingContainer, VSpace } from '@hedgehog/ui/layouts';
import { StepTracker } from '@hedgehog/ui/lists';
import { Loader } from '@hedgehog/ui/loaders';
import { Heading5, Label } from '@hedgehog/ui/typography';
import { currencies } from '@hedgehog/utils/formats';
import { screens } from '@hedgehog/utils/sizes';

import { getSteps } from '../utils/get-steps';

import { OrderError } from './order-error.component';

const TokenisedOrderContainer = styled.div`
  ${({ theme }): CSSObject => ({
    borderRadius: `${theme.spacing.large} ${theme.spacing.large} 0 0`,
    paddingBottom: theme.spacing.large,
  })};

  flex: 1 1 100%;
  display: flex;
  flex-flow: column nowrap;
  ${({ theme }): CSSObject => ({ padding: theme.spacing.large })};

  @media (min-width: ${screens.medium}px) {
    border-radius: 1.5rem;
    overflow: hidden;
  }
`;

const StyledStepTracker = styled(StepTracker)`
  margin: -1.5rem;
  padding-bottom: 3rem;
  border-radius: ${({ theme }): string => theme.radius.xlarge};
`;

const OrderControls = styled.div`
  background-color: ${({ theme }): string => theme.colors.white};
  border-radius: 1.5rem;
  padding: 1.5rem 1.5rem 0;
  margin-left: -1.5rem;
  margin-right: -1.5rem;

  // Custom shadow styling to get a shadow along the top edge only
  box-shadow: 0 -5px 13px -8px ${({ theme }): string => theme.colors.shadow100};
`;

const OrderSummary = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`;

type CreateTokenisedOrderFormProps = {
  isLoading: boolean;
  hasError: boolean;
  submitting: boolean;
  isSoldOut: boolean;
  quantity?: number;
  currentRound: AssetRound;
  remainingTokens: number;
  setQuantity: (newQuantity?: number) => void;
  onSubmit: () => void;
};

export const CreateTokenisedOrderForm = ({
  isLoading,
  submitting,
  hasError,
  isSoldOut,
  currentRound,
  quantity,
  remainingTokens,
  setQuantity,
  onSubmit,
}: CreateTokenisedOrderFormProps): JSX.Element => {
  const { current: steps } = useRef(getSteps('Asset'));
  const { roundClass } = currentRound;
  const { pricePerToken, minimumInvestmentAmount = 1 } = roundClass;
  const minimumTokens = minimumInvestmentAmount / pricePerToken;
  const quantityAboveMinimum = quantity && quantity >= minimumTokens;
  const quantityBelowRemaining = quantity && quantity <= remainingTokens;
  const formValid = Boolean(quantityAboveMinimum && quantityBelowRemaining);

  return (
    <TokenisedOrderContainer>
      <StyledStepTracker steps={steps} />
      <VSpace spacing="small">
        <OrderControls>
          <QuantityInput
            min={minimumTokens}
            max={remainingTokens}
            value={quantity}
            onChange={setQuantity}
            placeholder="Number of tokens"
            suffix={quantity === 1 ? ' token' : ' tokens'}
            noteFormat={(): string => {
              return currencies.formatMoney(pricePerToken * (quantity || 0), {
                currency: roundClass.currency || 'USD',
              });
            }}
          />
        </OrderControls>
        <OrderSummary>
          {isLoading && (
            <LoadingContainer>
              <Loader />
            </LoadingContainer>
          )}

          {hasError && !isSoldOut && (
            <OrderError>Something went wrong, try again later.</OrderError>
          )}

          {isSoldOut && (
            <OrderError>Oops! Investment recently closed!</OrderError>
          )}

          {!(isLoading || hasError || isSoldOut) && (
            <>
              <div>
                <Label color="grey300">Token price</Label>
                <Heading5>
                  {currencies.formatMoney(minimumInvestmentAmount, {
                    currency: roundClass.currency || 'USD',
                  })}
                </Heading5>
              </div>
              <PrimaryButton
                type="button"
                className="primary"
                loading={submitting}
                onClick={onSubmit}
                disabled={!formValid}
              >
                Proceed
              </PrimaryButton>
            </>
          )}
        </OrderSummary>
      </VSpace>
    </TokenisedOrderContainer>
  );
};
