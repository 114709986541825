import styled, { CSSObject } from 'styled-components';

import { Avatar } from '@hedgehog/ui/icons';
import { ListItem } from '@hedgehog/ui/lists';
import { Heading, Label } from '@hedgehog/ui/typography';

const ClipboardAvatar = styled(Avatar)`
  cursor: pointer;
  flex: 0 0 auto;

  ${({ theme }): CSSObject => ({
    backgroundColor: theme.colors.shadow100,
    '&:hover': {
      backgroundColor: theme.colors.shadow200,
    },
    '&:active': {
      backgroundColor: theme.colors.shadow400,
    },
  })}
`;

const Content = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

const ListItemWrapper = styled(ListItem)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ theme }): CSSObject => ({ gap: theme.spacing.normal })};
`;

export interface OrderInstructionItemProps {
  label: string;
  content: string;
}

export const OrderPaymentInstructionItem = ({
  label,
  content,
}: OrderInstructionItemProps): JSX.Element => {
  const handleCopyClick = (): void => {
    navigator.clipboard.writeText(content);
  };

  return (
    <ListItemWrapper>
      <Content>
        <Label uppercase color="grey500">
          {label}
        </Label>
        <Heading level="h6">{content}</Heading>
      </Content>

      <ClipboardAvatar icon="copy" size="m" onClick={handleCopyClick} />
    </ListItemWrapper>
  );
};
