import { useAnalyticsPage } from '@hedgehog/browser/investors/shared/analytics';
import { SponsorCard } from '@hedgehog/ui/cards';

import { PartnersJoiningSoon } from '../components/partners-joining-soon.component';

export const PartnersPage = (): JSX.Element => {
  useAnalyticsPage('Top Level', 'Explore', { tab: 'partners' });
  return (
    <>
      <SponsorCard
        slug="national-resources"
        iconSrc="https://assets.hedgehog-invest.com/sponsors/nre/nre_logos.jpg"
        bodySrc="https://assets.hedgehog-invest.com/sponsors/nre/nre_map.png"
        topbar="National Resources"
        title="$2bn"
        subtitle="in developed projects"
      />

      <PartnersJoiningSoon />
    </>
  );
};
