import { QueryResult } from '@apollo/client';

import {
  GET_USER_ACTION_DATA,
  GetUserActionDataQuery,
} from '@hedgehog/data-access/graphql';

import { useAuthenticatedQuery } from '../../Auth/hooks';

export const useUserAction = (): QueryResult<GetUserActionDataQuery> =>
  useAuthenticatedQuery<GetUserActionDataQuery>(GET_USER_ACTION_DATA, {});
