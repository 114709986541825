import { Paragraph } from '@hedgehog/ui/typography';

export const SwissJurisdictionWarning = (): JSX.Element => (
  <>
    <Paragraph>
      The access to this website and information and documents relating to
      tokens does not constitute an offer, invitation or solicitation to utilize
      a service, buy or sell financial instruments, or participate in a specific
      trading or investment strategy in Switzerland. None of Hedgehog Invest
      Limited, Hedgehog Manager LLC or any of their affiliates are a financial
      services provider within the meaning of the Swiss Financial Services Act
      of 15 June 2018, as amended (&quot;FinSA&quot;). By continuing, you
      understand that you will not benefit from any protection or information
      right provided for in FinSA.
    </Paragraph>
    <Paragraph>
      The information on the website is exclusively directed at &apos;Qualified
      Investors&apos; within the meaning of the Federal Act on Collective
      Investment Schemes (&apos;CISA&apos;) and its implementing ordinance as
      well as according to the most recent interpretation of the Swiss Financial
      Market Supervisory Authority FINMA with domicile in Switzerland.
    </Paragraph>
    <Paragraph>
      No information contained on this website nor any other offering or
      marketing material relating to the tokens may be publicly distributed or
      otherwise made publicly available in Switzerland. No offering or marketing
      material relating to the tokens or any Hedgehog entity have been or will
      be filed with or approved by any Swiss regulatory authority. In
      particular, no offering documentation will not be filed with, and the
      offer of tokens will not be supervised by FINMA, and the offer of tokens
      has not been and will not be authorized under CISA. The investor
      protection afforded to acquirers of interests in collective investment
      schemes under the CISA does not extend to acquirers of tokens.
    </Paragraph>
  </>
);
