import { Avatar } from '@hedgehog/ui/icons';
import { Heading, Paragraph } from '@hedgehog/ui/typography';

import { OrderDetailsCard } from '../../components';

export const OrderDetailsIssue = (): JSX.Element => {
  return (
    <OrderDetailsCard>
      <Avatar size="l" icon="timer" backgroundColor="white" color="grey300" />
      <Heading level="h4">
        Your investment is secure and we will issue your tokens soon.
      </Heading>
      <Paragraph color="grey400">
        We issue all tokens at the same time, and you will be promptly notified
        when it happens. Meanwhile you can search for other investment.
      </Paragraph>
    </OrderDetailsCard>
  );
};
