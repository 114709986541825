import styled, { CSSObject } from 'styled-components';

import { TopicProgress } from '@hedgehog/data-access/graphql';
import { Avatar, AvatarProps, Chunk } from '@hedgehog/ui/icons';

const CircleAvatar = styled(Avatar)<
  AvatarProps & Pick<CourseTopicCircleProps, 'progress'>
>`
  ${({ progress, theme }): CSSObject => {
    return {
      borderColor:
        progress !== TopicProgress.NOT_STARTED
          ? theme.colors.primary
          : theme.colors.grey200,
    };
  }}
`;

type CourseTopicCircleProps = {
  appearance: 'avatar' | 'chunk';
  progress: TopicProgress;
  className?: string;
};

export const CourseTopicCircle = ({
  appearance,
  progress,
  className,
}: CourseTopicCircleProps): JSX.Element => {
  const completed = progress === TopicProgress.COMPLETED;
  return appearance === 'avatar' ? (
    <CircleAvatar
      size="s"
      icon={completed ? 'check-circle' : 'radio-button'}
      color={completed ? 'primary' : 'grey400'}
      backgroundColor="grey200"
      progress={progress}
      className={className}
    />
  ) : (
    <Chunk
      size="m"
      icon={completed ? 'check-circle' : 'radio-button'}
      color={completed ? 'primary' : 'grey200'}
      className={className}
    />
  );
};
