import styled from 'styled-components';

import { Card, CardContent } from '@hedgehog/ui/cards';
import { Heading, Paragraph } from '@hedgehog/ui/typography';

const WarningCard = styled(Card)`
  margin-left: -1rem;
  margin-right: -1rem;
  ${CardContent} {
    gap: 0.5rem;
  }
`;

export const UnitedKingdomInvestorLetter = (): JSX.Element => (
  <>
    <WarningCard>
      <Heading level="h7">Warning</Heading>
      <Paragraph small color="grey500">
        The content of this promotion has not been approved by an authorised
        person within the meaning of the Financial Services and Markets Act
        2000. Reliance on this promotion for the purpose of engaging in any
        investment activity may expose an individual to a significant risk of
        losing all of the property or other assets invested.
      </Paragraph>
    </WarningCard>
    <Paragraph>
      This document is exempt from section 21 of the Financial Services and
      Markets Act 2000 (FSMA 2000) on the grounds that it is made only to
      certified high net worth individuals and self-certified sophisticated
      investors.
    </Paragraph>
    <Paragraph>
      A certified high net worth individual is an individual who has signed
      (within the 12 months ending on the day on which the document is made) a
      statement complying with Part I of Schedule 5 of the Financial Services
      and Markets Act 2000 (Financial Promotion) Order 2005 (SI 2005/1529)
      (FPO), a version of which is set out in Annex I of this document.
    </Paragraph>
    <Paragraph>
      A self–certified sophisticated investor is an individual who has signed
      (within the 12 months ending on the day on which the document is made) a
      statement complying with Part II of Schedule 5 of SI 2005/1529, FPO, a
      version of which is set out in Annex II of this document.
    </Paragraph>
    <Paragraph>
      This document is provided to its recipients in strict confidence.
      Recipients are not permitted, unless required to do so by law, to use the
      information in this document for any purpose except that of investing in
      private markets opportunities presented to you in the platform (the
      Company).
    </Paragraph>
    <Paragraph>
      This investment may not be suitable for the recipient. No personal
      recommendation is being made to you by the Company or its Directors. As
      either a certified high net worth individual or self–certified
      sophisticated investor the recipient is considered to have sufficient
      experience, expertise and knowledge to make its own investment decisions
      and to understand the risks involved.
    </Paragraph>
    <Paragraph>
      If recipients are in any doubt about whether the investment described in
      this document is suitable for them, they should seek advice from an
      authorised person specialising in advising on investments of this kind.
    </Paragraph>
    <Paragraph>
      The price, value or income of or from securities may fall and you may lose
      all of the capital that you invest in the Company. Investments in private
      companies are not readily realisable or transferable and you may not be
      able to realise your investment when you want to do so.
    </Paragraph>
    <Paragraph>
      This document may contain forecasts and information on past performance.
      Neither past performance nor forecasts are a reliable indicator of future
      results. No representation or warranty is made by the Company (or any of
      their directors, officers, employees or agents) as to the information and
      opinions contained in this document.
    </Paragraph>
  </>
);
