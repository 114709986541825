import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import styled, { CSSProp } from 'styled-components';

import { useIsKycOnly } from '@hedgehog/browser/investors/kyc/data-access';
import {
  useAnalyticsPage,
  useAnalyticsTrack,
} from '@hedgehog/browser/investors/shared/analytics';
import { ActionCard } from '@hedgehog/ui/cards';
import { Header, HeaderWithBack } from '@hedgehog/ui/headers';
import { LoadingContainer, Page } from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';
import { Heading, Paragraph } from '@hedgehog/ui/typography';

import { hedgehogSupportedInvestors, types } from '../content';
import { encodePath } from '../helpers';

const CustomPage = styled(Page)`
  width: 100%;
  height: 100%;
  margin: 0;
  ${({ theme }): CSSProp => ({ background: theme.colors.white })}

  overflow: hidden;
  overflow-y: auto;
`;

const PageContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 1.5rem;

  padding-bottom: 5rem;
`;

const QuestionBlock = styled(ActionCard)`
  cursor: pointer;
`;

export const StatusPage = (): JSX.Element => {
  useAnalyticsPage('Authentication', 'Investor Status');
  const track = useAnalyticsTrack();

  const navigate = useNavigate();
  const { hash, state } = useLocation();
  const [searchParams] = useSearchParams();

  // TODO: 🔍 KYC-only onboarding tech-debt - remove me - https://linear.app/hedgehog-invest/issue/HOG-2898/%F0%9F%92%A1-get-rid-of-all-the-oakglen-if-statements
  const { isKycOnly, loading } = useIsKycOnly();

  if (loading) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }

  const isRedeclaringInvestorType = searchParams.get('redeclare');

  const handleClick = (type: string): void => {
    const sanitizedType = encodePath(type);
    track('InvestorStatusContinueButton', 'Clicked', {
      investor_type: sanitizedType,
    });
    searchParams.set('type', sanitizedType);

    navigate(
      {
        hash: `${hash}/confirm`,
        ...(state?.redirectTo || {}),
        search: `?${searchParams.toString()}`,
      },
      {
        state,
      },
    );
  };

  // TODO: 🔍 KYC-only onboarding tech-debt - remove me - https://linear.app/hedgehog-invest/issue/HOG-2898/%F0%9F%92%A1-get-rid-of-all-the-oakglen-if-statements
  const investorTypeOptions = isKycOnly ? types : hedgehogSupportedInvestors;

  return (
    <CustomPage sidebarPage>
      {isRedeclaringInvestorType ? (
        <Header>
          <Heading level="h5">Investor Profile</Heading>
        </Header>
      ) : (
        <HeaderWithBack>
          <Heading level="h5">Investor Profile</Heading>
        </HeaderWithBack>
      )}
      <PageContent>
        <Paragraph>
          To activate your account, you'll need to self-certify as one of the
          following.
        </Paragraph>

        {investorTypeOptions.map((type) => {
          return (
            <QuestionBlock
              key={type}
              trailingIcon="chevron-right"
              title={type}
              onClick={(): void => handleClick(type)}
            />
          );
        })}
      </PageContent>
    </CustomPage>
  );
};
