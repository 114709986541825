import { CSSProp, css } from 'styled-components';

export const BadgeBase = css`
  position: relative;
  display: flex;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0.25rem;
    right: -0.75rem;
    transform: translate(-50%, -50%) scale(0);

    max-width: 0.5rem;
    width: 0.5rem;
    max-height: 0.5rem;
    height: 0.5rem;

    background-color: transparent;
    border: 0.25rem solid transparent;
    border-radius: 50%;

    transition: transform 200ms ease-in-out;
  }

  &.active-badge::after {
    transform: translate(-50%, -50%) scale(1);
    ${({ theme }): CSSProp => ({
      backgroundColor: theme.colors.primary,
      borderColor: theme.colors.white,
    })}
    transition: transform 200ms;
  }

  &[data-disabled]:not([data-disabled='false'])::after {
    background-color: transparent;
    border-color: transparent;
  }
`;
