import { Guard } from '../contexts/guards/guards.reducer';

const defaultPriority = 0;

type Order = 'asc' | 'desc';

export const orderByPriority =
  (order: Order = 'desc') =>
  (
    { priority: a = defaultPriority }: Guard,
    { priority: b = defaultPriority }: Guard,
  ): number =>
    order === 'asc' ? a - b : b - a;
