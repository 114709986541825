import React, { ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styled, { CSSProp, css } from 'styled-components';

import {
  NativeMobileExperiences,
  sendMessageWithPayloadToApp,
  useIsNativeMobileExperience,
} from '@hedgehog/data-access/native-mobile';
import { styles } from '@hedgehog/ui/buttons';

const containerAppearance = css`
  display: flex;
  ${({ theme }): CSSProp => ({
    color: theme.colors.black,
    backgroundColor: theme.colors.grey100,
  })}
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-radius: 1rem;
  max-width: 100%;
  overflow: hidden;
  box-sizing: content-box;

  user-select: none;
  cursor: pointer;

  &:hover,
  &:focus {
    ${styles.hoverAppearance}
  }
`;

const AnchorContainer = styled.a`
  ${containerAppearance};
`;

const NoClickContainer = styled.span`
  ${containerAppearance};
`;

const LinkContainer = styled(Link)`
  ${containerAppearance};
`;

export interface LinkBlockAsAnchorProps {
  href?: string;
  to?: never;
}

export interface LinkBlockAsRouterProps extends LinkProps {
  href?: never;
}

export type LinkBlockAnchorOrRouterProps =
  | LinkBlockAsAnchorProps
  | LinkBlockAsRouterProps;

export interface LinkBlockBaseProps {
  className?: string;
  notify?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  children?: ReactNode | ReactNode[];
}

export type LinkBlockProps = LinkBlockBaseProps & LinkBlockAnchorOrRouterProps;

export const LinkBlock = ({
  to = '',
  href,
  notify,
  children,
  className,
  onClick,
  ...linkProps
}: LinkBlockProps): JSX.Element => {
  const shouldNotify = useIsNativeMobileExperience(
    NativeMobileExperiences.LINK_NOTIFY,
  );

  const handleClick: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
    onClick && onClick(event);
    if (shouldNotify && notify) {
      // For the native mobile app, delegate navigation control.
      event.preventDefault();
      return sendMessageWithPayloadToApp('navigate', to || href);
    }
  };

  const commonProps = {
    to,
    className,
    'data-testid': 'link-block',
    children,
    ...linkProps,
  };
  return to ? (
    <LinkContainer {...commonProps} to={to} onClick={handleClick} />
  ) : href ? (
    <AnchorContainer {...commonProps} href={href} onClick={handleClick} />
  ) : (
    <NoClickContainer {...commonProps} onClick={handleClick} />
  );
};
