import { NREResidentToken } from '@hedgehoginvest/nre-resident-token';
import { ethers } from 'ethers';
import { useState } from 'react';

import { useAccountAbstraction } from '@hedgehog/browser/shared/account-abstraction';
import { useEnvironment } from '@hedgehog/ui/environment';

export interface GetDocumentParams {
  name: string;
}

export type UseGetDocumentReturn = {
  loading: boolean;
  uri: string | null;
  documentHash: string | null;
  error: Error | null;
};

export type GetDocumentFunc = ({ name }: GetDocumentParams) => Promise<void>;

export const useLazyGetDocument = (): [
  GetDocumentFunc,
  UseGetDocumentReturn,
] => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [uri, setUri] = useState<string | null>(null);
  const [documentHash, setDocumentHash] = useState<string | null>(null);

  const {
    tenantToken: { address: residentTokenAddress },
  } = useEnvironment();
  const { jsonRpcProvider } = useAccountAbstraction();

  const getDocument = async ({ name }: GetDocumentParams): Promise<void> => {
    const contract = new ethers.Contract(
      residentTokenAddress,
      NREResidentToken.abi,
      jsonRpcProvider,
    );

    try {
      setLoading(true);
      const response = await contract.getDocument(
        ethers.utils.formatBytes32String(name),
      );
      setError(null);
      setUri(response[0]);
      setDocumentHash(response[1]);
    } catch (err) {
      setUri(null);
      setDocumentHash(null);
      setError(err as Error);
    } finally {
      setLoading(false);
    }
  };

  return [getDocument, { uri, documentHash, loading, error }];
};
