import { Paragraph } from '@hedgehog/ui/typography';

export const UnitedStatesJurisdictionWarning = (): JSX.Element => (
  <>
    <Paragraph>
      None of the materials to which this platform gives access constitutes a
      public offering of securities under the Securities Act or any United
      States state securities laws. Any offer or invitation to purchase tokens
      made through this platform in the United States will only be made pursuant
      to an exemption under the Securities Act or other applicable law. Neither
      Hedgehog Invest Limited, its affiliates nor any of the token issuers
      appearing on the platform have authorised, or will authorise, the making
      of any offer or invitation of tokens in circumstances which could be
      deemed a public offering.
    </Paragraph>
    <Paragraph>
      The investments disclosed on this platform have not been and will not be
      registered under the United States Securities Act of 1933, as amended (the
      Securities Act), or any United States state securities laws or the laws of
      any foreign jurisdiction. Any investment disclosed on the platform will be
      offered and sold under an exemption provided by the Securities Act and /
      or other exemptions in the laws of the states and other jurisdictions
      where the offering will be made.
    </Paragraph>
    <Paragraph>
      All of the investments described herein are subject to restrictions on
      transferability and resale and may not be transferred or resold except as
      permitted under the relevant governing agreement of the investment and the
      Securities Act, applicable state securities laws, pursuant to registration
      or exemption therefrom and other applicable securities laws. Investors
      should be aware that they will be required to bear the financial risks of
      this investment for an indefinite period of time.
    </Paragraph>
  </>
);
