import { useParams, useSearchParams } from 'react-router-dom';

import { useOrder } from '@hedgehog/browser/investors/order/data-access';
import { useAnalyticsPage } from '@hedgehog/browser/investors/shared/analytics';
import { EmptyStateContainer, LoadingContainer } from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';

import { OrderPaymentConfirmedContent } from '../components';

export const OrderPaymentConfirmedPage = (): JSX.Element => {
  useAnalyticsPage('Invest', 'Payment Confirmed');
  const { orderId: paramsOrderId } = useParams<{ orderId: string }>();
  const [searchParams] = useSearchParams();
  const orderId = paramsOrderId || searchParams.get('orderId') || '';
  const { data, loading, error } = useOrder(orderId);

  if (loading) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }

  if (error) {
    return (
      <EmptyStateContainer>
        Something went wrong, please try again later.
      </EmptyStateContainer>
    );
  }

  return <OrderPaymentConfirmedContent orderAmount={data!.order!.amount} />;
};
