import { useLocation } from 'react-router-dom';
import styled, { CSSObject } from 'styled-components';

import { useUser } from '@hedgehog/browser/investors/kyc/data-access';
import { useOrders } from '@hedgehog/browser/investors/order/data-access';
import { useAnalyticsPage } from '@hedgehog/browser/investors/shared/analytics';
import {
  AssetCategory,
  GetOrdersQuery,
  OrderStatus,
} from '@hedgehog/data-access/graphql';
import { useIsMobile } from '@hedgehog/data-access/hooks';
import { PrimaryLinkButton } from '@hedgehog/ui/buttons';
import { ActionCard } from '@hedgehog/ui/cards';
import { Avatar } from '@hedgehog/ui/icons';
import {
  Column,
  ColumnLayout,
  EmptyStateContainer,
  LoadingContainer,
  Page,
} from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';
import { Heading } from '@hedgehog/ui/typography';

import { PortfolioAssets, PortfolioBalance } from '../containers';

type GetOrders_orders = GetOrdersQuery['orders'][number];

const PortfolioLayout = styled(ColumnLayout)`
  gap: 2rem;
`;

const EmptyPortfolioLayout = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1.5rem;
  text-align: left;
  align-items: center;

  > img {
    border-radius: ${({ theme }): string => theme.radius.xlarge};
  }

  ${({ theme }): CSSObject => ({
    [`@media only screen and (min-width: ${theme.breakpoints.normal}px)`]: {
      position: 'static',
      margin: theme.spacing.normal,
      textAlign: 'center',
    },
  })}
`;

const StyledImage = styled.img`
  width: 330px;
`;

const StyledHeading = styled(Heading)`
  padding: 0 1.5rem;
`;

export const PortfolioPage = (): JSX.Element => {
  useAnalyticsPage('Top Level', 'Portfolio');
  const isMobile = useIsMobile();
  const { data, error, loading } = useOrders();
  const { data: userData, loading: userLoading } = useUser();
  const { state } = useLocation();

  if (error) {
    return (
      <Page>
        <EmptyStateContainer>
          Something went wrong, please try again later
        </EmptyStateContainer>
      </Page>
    );
  }

  if (loading || userLoading || !data) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }

  const isOpenTenantTokenOrder = (order: GetOrders_orders): boolean => {
    const categories = order.asset?.themes?.map((theme) => theme.category);
    return (
      order.status === OrderStatus.OPEN &&
      categories?.includes(AssetCategory.TENANT_TOKEN)
    );
  };

  // Filter out open tenant token orders and order with zero amount, as these should not be shown in portfolio
  const filteredOrders = data.orders.filter(
    (order) => !isOpenTenantTokenOrder(order) && order.amount > 0,
  );

  const hasNoOrders = filteredOrders.length === 0;

  if (hasNoOrders)
    return (
      <EmptyPortfolioLayout>
        <StyledHeading level="h4">
          You have no holdings in your portfolio yet.
        </StyledHeading>

        <StyledImage
          alt=""
          src={
            userData?.user?.partner?.resources?.pages?.portfolio
              ?.placeholderUrl ??
            'https://assets.hedgehog-invest.com/images/App.png'
          }
        />

        <PrimaryLinkButton href="/explore">Start investing</PrimaryLinkButton>
      </EmptyPortfolioLayout>
    );

  return (
    <Page>
      {isMobile && <Heading level="h5">Portfolio</Heading>}
      <PortfolioLayout>
        <Column size="minmax(min-content, 19.375rem)">
          <PortfolioBalance />
          {state?.claimed && (
            <ActionCard
              title="Tokens claimed"
              caption="Tenant token for UNO"
              trailing={
                <Avatar icon="check-mark" size="m" backgroundColor="success" />
              }
            />
          )}
        </Column>
        <Column>
          <PortfolioAssets />
        </Column>
      </PortfolioLayout>
    </Page>
  );
};
