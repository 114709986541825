import {
  GetAvailableAssetsQuery,
  AssetCategory,
  AssetRoundClassStatus,
  OrderStatus,
} from '@hedgehog/data-access/graphql';

type GetAvailableAssets_availableAssets =
  GetAvailableAssetsQuery['availableAssets'][number];

type GroupAssetRoundsParams = {
  assets: GetAvailableAssets_availableAssets[];
  partnerSlug?: string;
};

export function groupAssetRounds({
  assets,
  partnerSlug,
}: GroupAssetRoundsParams): Record<
  'promoted' | 'live' | 'upcoming' | 'ongoing' | 'paidout',
  GetAvailableAssets_availableAssets[]
> {
  return assets.reduce(
    (acc, asset) => {
      const { status, themes, order } = asset;

      // TODO: 🎨 UI Configurability tech debt: https://linear.app/hedgehog-invest/issue/HOG-2898/%F0%9F%92%A1-get-rid-of-all-the-oakglen-if-statements
      // For clients belonging to the oakglen partner, only show assets with an existing order
      if (partnerSlug === 'oakglen' && !order) {
        return { ...acc };
      }

      if (
        themes?.some(({ category }) => category === AssetCategory.TENANT_TOKEN)
      ) {
        if (!order) {
          return { ...acc };
        }
        switch (order.status) {
          case OrderStatus.CLAIMED:
            return { ...acc, ongoing: [...acc.ongoing, asset] };
          default:
            return { ...acc, promoted: [...acc.promoted, asset] };
        }
      }

      switch (status || '') {
        case AssetRoundClassStatus.COMING_SOON: {
          return { ...acc, upcoming: [...acc.upcoming, asset] };
        }
        case AssetRoundClassStatus.ONGOING: {
          return { ...acc, ongoing: [...acc.ongoing, asset] };
        }
        case AssetRoundClassStatus.PAID_OUT: {
          return { ...acc, paidout: [...acc.paidout, asset] };
        }
        default: {
          return { ...acc, live: [...acc.live, asset] };
        }
      }
    },
    {
      promoted: [],
      live: [],
      upcoming: [],
      paidout: [],
      ongoing: [],
    } as Record<
      'promoted' | 'live' | 'upcoming' | 'paidout' | 'ongoing',
      GetAvailableAssets_availableAssets[]
    >,
  );
}
