import styled, { CSSObject } from 'styled-components';

import { usePersonalDetails } from '@hedgehog/browser/investors/account/data-access';
import { useCountries } from '@hedgehog/browser/shared/auth';
import { HeaderWithBack } from '@hedgehog/ui/headers';
import {
  Page,
  EmptyStateContainer,
  LoadingContainer,
} from '@hedgehog/ui/layouts';
import { List, BulletListItem } from '@hedgehog/ui/lists';
import { Loader } from '@hedgehog/ui/loaders';
import { Heading, Label } from '@hedgehog/ui/typography';

const StyledList = styled(List)`
  ${({ theme }): CSSObject => ({
    marginTop: theme.spacing.xxlarge,
  })}
`;

export const AccountPersonalDetailsPage = (): JSX.Element => {
  const { data, loading, error } = usePersonalDetails();
  const {
    error: countriesError,
    loading: countriesLoading,
    getCountryForCode,
  } = useCountries();

  if (error || countriesError) {
    return (
      <Page>
        <HeaderWithBack />
        <EmptyStateContainer>
          Something went wrong, please try again later
        </EmptyStateContainer>
      </Page>
    );
  }

  if (loading || !data || countriesLoading) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }

  return (
    <Page sidebarPage>
      <HeaderWithBack>
        <Heading level="h5">Personal details</Heading>
      </HeaderWithBack>
      <StyledList>
        <BulletListItem icon="user">
          <Label color="grey400">First name</Label>
          <Heading level="h6">{data.user?.firstName}</Heading>
        </BulletListItem>
        <BulletListItem icon="user">
          <Label color="grey400">Last name</Label>
          <Heading level="h6">{data.user?.lastName}</Heading>
        </BulletListItem>
        <BulletListItem icon="mail">
          <Label color="grey400">Email address</Label>
          <Heading level="h6">{data.user?.email}</Heading>
        </BulletListItem>
        <BulletListItem icon="flag">
          <Label color="grey400">Country of residence</Label>
          <Heading level="h6">
            {getCountryForCode(data.user?.country ?? '')?.name}
          </Heading>
        </BulletListItem>
      </StyledList>
    </Page>
  );
};
export default AccountPersonalDetailsPage;
