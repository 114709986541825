import { Outlet } from 'react-router-dom';
import styled, { CSSObject } from 'styled-components';

import { Column, ColumnLayout } from '@hedgehog/ui/layouts';
import { parsePadding } from '@hedgehog/ui/themes';
import { StandardProps } from '@hedgehog/ui/utils';

export type LayoutProps = StandardProps<{ pattern?: string }>;

const ContentColumn = styled(Column)`
  ${({ theme }): CSSObject => ({
    padding: parsePadding({
      x: theme.spacing.xxlarge,
      y: theme.spacing.xlarge,
    }),
    paddingBottom: 0,
  })}
`;

export const Layout = ({ children }: LayoutProps): JSX.Element => {
  return (
    <ColumnLayout>
      <ContentColumn>{children ? children : <Outlet />}</ContentColumn>
    </ColumnLayout>
  );
};
