import { NavLinkProps } from 'react-router-dom';
import styled from 'styled-components';

import { NotificationButton } from '../notification-button/notification-button.component';

const ListItem = styled.li`
  display: flex;
`;

export interface NotificationListItemProps
  extends Omit<NavLinkProps, 'children'> {
  className?: string;
  badged?: boolean;
  disabled?: true;
}

export const NotificationListItem = ({
  className,
  ...buttonProps
}: NotificationListItemProps): JSX.Element => {
  return (
    <ListItem className={className}>
      <NotificationButton {...buttonProps} />
    </ListItem>
  );
};
