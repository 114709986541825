import { Context } from '@segment/analytics-next';
import React, { useEffect } from 'react';

import { AnalyticsWindow } from '@hedgehog/browser/shared/utils';
import { useShadowMode } from '@hedgehog/data-access/contexts';
import {
  NativeMobileExperiences,
  useIsNativeMobileExperience,
} from '@hedgehog/data-access/native-mobile';
import { useEnvironment } from '@hedgehog/ui/environment';

import { AnalyticsContext, AnalyticsContextProps } from './AnalyticsContext';
import { useAdvertismentCookies } from './hooks/use-advertisement-cookies.hook';
import { useSourceTrackingTags } from './hooks/useSourceTrackingTags';

declare let window: AnalyticsWindow;

export interface AnalyticsProviderProps {
  debug?: boolean;
  children?: React.ReactNode | React.ReactNode[];
}

const isTest = process.env['NODE_ENV'] === 'test';

export const AnalyticsProvider = ({
  children,
  debug,
}: AnalyticsProviderProps): JSX.Element => {
  const { type: environment, segment } = useEnvironment();
  const trackingTags = useSourceTrackingTags();
  const isShadowMode = useShadowMode();
  useAdvertismentCookies();

  // detect if analytics should use investor-ios as the source
  const isIos = useIsNativeMobileExperience(
    NativeMobileExperiences.IOS_ANALYTICS,
  );

  const globalOptions = {};
  const globalProperties = {
    source: 'client',
    environment,
  };

  const page: AnalyticsContextProps['page'] = (
    category,
    name,
    properties,
    options,
  ): void => {
    const { analytics } = window;
    const defaultOptions = { ...globalOptions };
    const defaultProperties = {
      ...globalProperties,
      platform: isIos ? 'ios' : 'web',
    };

    const propertiesToSend = {
      ...defaultProperties,
      ...trackingTags,
      ...properties,
    };
    const optionstoSend = { ...defaultOptions, ...options };

    if (analytics) {
      analytics.page(category, name, propertiesToSend, optionstoSend);
    } else {
      console.log('analytics.page', {
        category,
        name,
        properties: propertiesToSend,
        options: optionstoSend,
      });
    }
  };

  const track: AnalyticsContextProps['track'] = (
    object,
    action,
    properties,
    options,
  ): Promise<Context | null> => {
    const { analytics } = window;
    const defaultOptions = { ...globalOptions };
    const defaultProperties = {
      ...globalProperties,
      platform: isIos ? 'ios' : 'web',
    };

    const propertiesToSend = {
      ...defaultProperties,
      ...trackingTags,
      ...properties,
    };
    const optionstoSend = { ...defaultOptions, ...options };

    if (analytics) {
      return analytics.track(
        `${object} ${action}`,
        propertiesToSend,
        optionstoSend,
      );
    } else {
      console.log('analytics.track', {
        object,
        action,
        properties: propertiesToSend,
        options: optionstoSend,
      });
      return Promise.resolve(null);
    }
  };

  const identify: AnalyticsContextProps['identify'] = (
    id,
    traits,
    options,
  ): void => {
    const { analytics } = window;
    const defaultOptions = { ...globalOptions };
    const defaultProperties = { ...globalProperties };

    const traitsToSend = {
      ...defaultProperties,
      ...traits,
    };
    const optionsToSend = {
      ...defaultOptions,
      ...options,
    };

    if (analytics) {
      analytics.identify(id, traitsToSend, optionsToSend);
    } else {
      console.log('analytics.identify', {
        id,
        traits: traitsToSend,
        options: optionsToSend,
      });
    }
  };

  useEffect(() => {
    if (isTest) return;
    if (isShadowMode) return;
    if (!window.analytics) return;

    if (environment === 'local') {
      window.analytics = null;
      return;
    }

    // Load analytics
    if (!segment?.writeKey) return;
    window.analytics._writeKey = segment.writeKey;
    window.analytics.load(segment.writeKey);

    if (debug) {
      window.analytics.debug(true);
    }
  }, []);

  const contextValue = { identify, page, track };

  return (
    <AnalyticsContext.Provider value={contextValue}>
      {children}
    </AnalyticsContext.Provider>
  );
};
