import { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import styled, { CSSObject } from 'styled-components';

import { OnboardingActionStatus } from '@hedgehog/browser/investors/kyc/data-access';
import { ActionCard, CardContent, LinkOrNotProps } from '@hedgehog/ui/cards';
import { Icon, IconType } from '@hedgehog/ui/icons';
import { Paragraph } from '@hedgehog/ui/typography';
import { StandardProps } from '@hedgehog/ui/utils';

export type OnboardingActionProps = StandardProps<
  {
    title: string;
    status?: OnboardingActionStatus;
    messages?: Partial<
      Record<OnboardingActionStatus, string | ReactNode | ReactNode[]>
    >;
    icons?: Partial<Record<OnboardingActionStatus, IconType>>;
    loading?: boolean;
  } & LinkOrNotProps,
  never
>;

const defaultMessages: Record<OnboardingActionStatus, string> = {
  completed: 'Completed',
  inprogress: 'In review (can take a couple of minutes)',
  failed: 'Declined',
  retry: 'Please try again',
  pending: '',
  blocked: '',
  stale: '',
};

const defaultIcons: Record<OnboardingActionStatus, any> = {
  completed: (
    <Icon
      size="s"
      color="success"
      backgroundColor="transparent"
      icon="check-circle"
    />
  ),
  inprogress: (
    <Icon size="s" color="black" backgroundColor="transparent" icon="clock" />
  ),
  failed: 'chevron-right',
  retry: 'chevron-right',
  pending: 'chevron-right',
  blocked: 'chevron-right',
  stale: 'chevron-right',
};

const CustomActionCard = styled(ActionCard)<{
  status?: OnboardingActionStatus;
}>`
  ${({ status }): CSSObject => ({
    [`&[disabled] ${CardContent}`]:
      status === 'inprogress'
        ? {
            opacity: 1,
          }
        : {},
  })}
`;

export const OnboardingAction = ({
  status = 'stale',
  messages: providedMessages,
  icons: providedIcons,
  title,
  loading,
  ...linkProps
}: OnboardingActionProps): JSX.Element => {
  const { pathname } = useLocation();
  const messages = {
    ...defaultMessages,
    ...providedMessages,
  };
  const icons = {
    ...defaultIcons,
    ...providedIcons,
  };

  const trailingProps:
    | { trailing: ReactNode | ReactNode[] }
    | { trailingIcon: IconType } =
    typeof icons[status] === 'string'
      ? {
          trailingIcon: icons[status],
        }
      : {
          trailing: icons[status],
        };

  if (linkProps.to) {
    linkProps.to = `${pathname}${linkProps.to}`;
  }

  return (
    <CustomActionCard
      loading={loading}
      status={status}
      disabled={
        loading || ['inprogress', 'completed', 'blocked'].includes(status)
      }
      type={status === 'completed' ? 'light' : 'default'}
      title={title}
      caption={<Paragraph color="grey400">{messages[status]}</Paragraph>}
      {...linkProps}
      {...trailingProps}
    />
  );
};
