import { format } from 'date-fns';
import styled from 'styled-components';

import { Icon } from '@hedgehog/ui/icons';
import { Heading, Label } from '@hedgehog/ui/typography';
import { StandardProps } from '@hedgehog/ui/utils';

const Row = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`;

const RowContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
`;

export type OrderTransactionItemReportProps = StandardProps<{
  timestamp: number | Date;
  title: string;
}>;

export const OrderTransactionItemReport = styled(
  ({ timestamp, title }: OrderTransactionItemReportProps) => {
    const labelColor = 'grey400';

    return (
      <Row>
        <RowContent>
          <Label color={labelColor}>{format(timestamp, 'MMM dd, yyyy')}</Label>
          <Heading level="h6" color="black">
            {title}
          </Heading>
        </RowContent>

        <Icon
          icon="chevron-right"
          size="s"
          backgroundColor="grey100"
          color="black"
        />
      </Row>
    );
  },
)``;
