import { useArticle } from '@hedgehog/browser/investors/learn/data-access';
import { useAnalyticsTrack } from '@hedgehog/browser/investors/shared/analytics';
import { ContentCMS } from '@hedgehog/ui/cms';
import { Loader } from '@hedgehog/ui/loaders';
import { Paragraph } from '@hedgehog/ui/typography';

export interface ArticleProps {
  id: string;
}

export const Article = ({ id: articleId }: ArticleProps): JSX.Element => {
  const { data, error, loading } = useArticle(articleId || '');
  const track = useAnalyticsTrack();

  if (error) {
    return (
      <Paragraph small>Something went wrong, please try again later</Paragraph>
    );
  }

  if (loading || !data) {
    return <Loader />;
  }

  return <ContentCMS content={data.article.content} onAnalyticsTrack={track} />;
};
