import { useContext } from 'react';

import {
  AssessmentContextType,
  AssessmentContext,
} from '../contexts/assessment-quiz';

export function useAssessmentQuiz(): AssessmentContextType {
  const context = useContext(AssessmentContext);
  if (context === undefined) {
    throw new Error(
      'useAssessmentQuiz must be used within a AssessmentQuizContext provided by AssessmentQuizProvider',
    );
  }
  return context;
}
