import { useGuardRegistry } from '@hedgehog/data-access/contexts';
import {
  DeclarationType,
  GetUserActionDataQuery,
  isKycOnly,
  isNDA,
} from '@hedgehog/data-access/graphql';

type GetUserActionData_declarations = GetUserActionDataQuery['declarations'];

export interface JurisdictionWarningGuardProps {
  priority: number;
}

const hasUserNotAcceptedNDA = (
  declarations: GetUserActionData_declarations,
): boolean => {
  return (
    declarations.find(
      (declaration) => declaration.type === DeclarationType.NDA,
    ) === undefined
  );
};

export const NDAGuard = ({
  priority,
}: JurisdictionWarningGuardProps): JSX.Element => {
  useGuardRegistry({
    name: 'jurisdiction',
    priority,
    validate: ({ partnerSettings, declarations }) => {
      // TODO: 🔍 KYC-only tech-debt - remove me - https://linear.app/hedgehog-invest/issue/HOG-2898/%F0%9F%92%A1-get-rid-of-all-the-oakglen-if-statements
      if (isKycOnly(partnerSettings)) return null;
      if (isNDA(partnerSettings) && hasUserNotAcceptedNDA(declarations)) {
        return '#signup/nda';
      }
      return null;
    },
  });

  return <></>;
};
