import { MouseEvent } from 'react';
import styled, { CSSObject } from 'styled-components';

import { AssetRound } from '@hedgehog/data-access/graphql';
import { PrimaryButton } from '@hedgehog/ui/buttons';
import { HeaderWithBack } from '@hedgehog/ui/headers';
import { QuantityInput } from '@hedgehog/ui/inputs';
import { LoadingContainer, VSpace } from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';
import { Heading, Note } from '@hedgehog/ui/typography';
import { currencies } from '@hedgehog/utils/formats';

import { OrderError } from './order-error.component';

const NotTokenisedOrderContainer = styled(VSpace)`
  flex: 1 1 100%;
  display: flex;
  flex-flow: column nowrap;
  ${({ theme }): CSSObject => ({ padding: theme.spacing.large })};
`;

const OrderSummary = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`;

type CreateNotTokenisedOrderFormProps = {
  isLoading: boolean;
  hasError: boolean;
  submitting: boolean;
  isSoldOut: boolean;
  currentRound: AssetRound;
  quantity?: number;
  setQuantity: (newQuantity?: number) => void;
  remainingTokens: number;
  onSubmit: () => void;
  onBack: (event: MouseEvent) => void;
};

export const CreateNotTokenisedOrderForm = ({
  isLoading,
  submitting,
  hasError,
  isSoldOut,
  currentRound,
  quantity,
  setQuantity,
  remainingTokens,
  onSubmit,
  onBack,
}: CreateNotTokenisedOrderFormProps): JSX.Element => {
  const { roundClass } = currentRound;
  const { minimumInvestmentAmount = 1 } = roundClass;
  const quantityAboveMinimum = quantity && quantity >= minimumInvestmentAmount;
  const quantityBelowRemaining = quantity && quantity <= remainingTokens;
  const formValid = Boolean(quantityAboveMinimum && quantityBelowRemaining);

  return (
    <NotTokenisedOrderContainer>
      <HeaderWithBack onBack={onBack}>
        <Heading level="h5">Investment amount</Heading>
      </HeaderWithBack>
      <VSpace spacing="small">
        <VSpace spacing="xs">
          <QuantityInput
            value={quantity}
            onChange={(value?: number): void => setQuantity(value)}
            currency={roundClass.currency || 'USD'}
          />
          <Note align="center" color="grey400">
            Minimum{' '}
            {currencies.formatMoney(minimumInvestmentAmount, {
              currency: roundClass.currency || 'USD',
            })}
          </Note>
        </VSpace>
        <OrderSummary>
          {isLoading && (
            <LoadingContainer>
              <Loader />
            </LoadingContainer>
          )}

          {hasError && !isSoldOut && (
            <OrderError>Something went wrong, try again later.</OrderError>
          )}

          {isSoldOut && (
            <OrderError>Oops! Investment recently closed!</OrderError>
          )}

          {!(isLoading || hasError || isSoldOut) && (
            <PrimaryButton
              fluid
              type="button"
              className="primary"
              loading={submitting}
              onClick={onSubmit}
              disabled={!formValid}
            >
              Proceed
            </PrimaryButton>
          )}
        </OrderSummary>
      </VSpace>
    </NotTokenisedOrderContainer>
  );
};
