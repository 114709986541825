import { isKycOnly, isNDA } from '@hedgehog/data-access/graphql';
import { useEnvironment } from '@hedgehog/ui/environment';

import { legacyShouldUserCoolDown } from '../helpers/cooldown.helper';
import { useGuardRegistry } from '../hooks';

export interface CooldownGuardProps {
  priority: number;
}

// TODO: 🌎 onboarding tech-debt -  https://linear.app/hedgehog-invest/issue/HOG-2898/%F0%9F%92%A1-get-rid-of-all-the-oakglen-if-statements
// Potentially all these checks should be factored into config, eg. event shouldUserCoolDown depends on the partner (and country).
export const CooldownGuard = ({
  priority,
}: CooldownGuardProps): JSX.Element => {
  const { production } = useEnvironment();

  useGuardRegistry({
    name: 'cooldown',
    priority,
    validate: (data) => {
      const { user, partnerSettings } = data || {};
      // TODO: 🔍 KYC-only onboarding tech-debt - remove me -  https://linear.app/hedgehog-invest/issue/HOG-2898/%F0%9F%92%A1-get-rid-of-all-the-oakglen-if-statements
      if (isKycOnly(partnerSettings)) return null;
      if (isNDA(partnerSettings)) return null;

      // TODO: 🌎 onboarding tech-debt - remove me - https://linear.app/hedgehog-invest/issue/HOG-2898/%F0%9F%92%A1-get-rid-of-all-the-oakglen-if-statements
      if (
        legacyShouldUserCoolDown(user?.country, user?.createdAt, production)
      ) {
        return '/cooldown';
      }

      return null;
    },
  });

  return <></>;
};
