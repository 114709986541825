import { StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { ToastContainer } from 'react-toastify';

import { StartSentry } from '@hedgehog/browser/shared/monitoring/sentry';
import { LoadingContainer } from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';

import App from './App';
import { APP_VERSION } from './app.version';
import { environment } from './environments/environment';
import reportWebVitals from './reportWebVitals';

import 'react-toastify/dist/ReactToastify.css';

StartSentry({
  environment: environment.type,
  dsn: environment.sentry.dsn,
  release: APP_VERSION,
});

const container = document.getElementById('root') as HTMLElement;

createRoot(container).render(
  <StrictMode>
    <Suspense
      fallback={
        <LoadingContainer>
          <Loader />
        </LoadingContainer>
      }
    >
      <App />
      <ToastContainer />
    </Suspense>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(undefined);
