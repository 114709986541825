import { useEffect } from 'react';

import { QueryResult, useQueryResult } from '@hedgehog/browser/shared/utils';
import { hashDocumentContents } from '@hedgehog/shared/utils/documentHasher';

import { useGetDocument } from './use-get-document.hook';

export interface SubscriptionAgreementParams {
  name: string;
}

export interface SubscriptionAgreementOutput {
  uri: string;
  hash: string;
}

export const useSubscriptionAgreement = ({
  name,
}: SubscriptionAgreementParams): QueryResult<SubscriptionAgreementOutput> => {
  const [state, controls] = useQueryResult<SubscriptionAgreementOutput>();
  const {
    uri: documentUri,
    loading: documentLoading,
    error: documentError,
  } = useGetDocument({
    name,
  });

  useEffect(() => {
    const calculateHash = async (agreementUri: string): Promise<string> =>
      await hashDocumentContents(agreementUri);
    const calculateHashAndSet = async (agreementUri: string): Promise<void> => {
      try {
        controls.setLoading(true);
        const hash = await calculateHash(agreementUri);
        controls.setData({ uri: agreementUri, hash });
      } catch (err) {
        controls.setError(err);
      } finally {
        controls.setLoading(false);
      }
    };

    if (!documentUri) return;
    calculateHashAndSet(documentUri);
  }, [documentUri]);

  return {
    ...state,
    loading: state.loading || documentLoading,
    error: state.error || documentError,
  };
};
