import {
  useAnalyticsPage,
  useAnalyticsTrack,
} from '@hedgehog/browser/investors/shared/analytics';
import { useScrollToBody } from '@hedgehog/browser/shared/utils';
import { useAuthenticatedQuery } from '@hedgehog/data-access/contexts';
import {
  GetAssetPageQuery as GetAssetPage,
  GetAssetPageQueryVariables as GetAssetPageVariables,
  Asset as GetAsset_asset,
  GET_ASSET_PAGE,
} from '@hedgehog/data-access/graphql';
import { ContentCMS } from '@hedgehog/ui/cms';
import { EmptyStateContainer, LoadingContainer } from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';

type MarketAnalysisTabProps = {
  asset: GetAsset_asset;
};

export const MarketAnalysisTab = ({
  asset,
}: MarketAnalysisTabProps): JSX.Element => {
  useAnalyticsPage('Asset', 'Area');
  useScrollToBody();
  const track = useAnalyticsTrack();
  const { data, loading, error } = useAuthenticatedQuery<
    GetAssetPage,
    GetAssetPageVariables
  >(GET_ASSET_PAGE, {
    assetId: asset.id,
    slug: 'area',
  });

  if (error) {
    return (
      <EmptyStateContainer>
        Something went wrong, please try again later
      </EmptyStateContainer>
    );
  }

  if (loading || !data) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }

  return (
    <ContentCMS content={data.assetPage.content} onAnalyticsTrack={track} />
  );
};
