import Cookies from 'js-cookie';
import { useEffect } from 'react';

import {
  ADVERTISEMENT_CLICK_ID,
  ADVERTISEMENT_PLATFORM,
} from '../constants/cookies';

import { useAdvertisementParams } from './use-advertisement-params.hook';

export function useAdvertismentCookies(): void {
  const params = useAdvertisementParams();
  useEffect(() => {
    if (!document) return;
    if (!params) return;

    Cookies.set(ADVERTISEMENT_PLATFORM, params.platform, {
      domain: 'hedgehog-invest.com',
    });
    Cookies.set(ADVERTISEMENT_CLICK_ID, params.clickId, {
      domain: 'hedgehog-invest.com',
    });
  }, [params]);

  return;
}
