import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import {
  useOnboardingStatusPolling,
  useUser,
} from '@hedgehog/browser/investors/kyc/data-access';
import { useHasCompletedWorkflows } from '@hedgehog/browser/investors/workflows/onboarding';
import {
  FeatureFlagNames,
  KycCheckStatus,
} from '@hedgehog/data-access/graphql';
import { useFeature } from '@hedgehog/data-access/hooks';
import { SecondaryLinkButton } from '@hedgehog/ui/buttons';
import { Paragraph } from '@hedgehog/ui/typography';

export const OnboardingFooter = (): JSX.Element => {
  const location = useLocation();
  const { data: onboardingData, loading: onboardingLoading } =
    useOnboardingStatusPolling();
  const { complete } = useHasCompletedWorkflows(true);

  const flag = useFeature(FeatureFlagNames.pre_invest_workflows);
  const { data } = useUser();

  const useWorkflows =
    flag.isEnabled && flag.value.includes?.(data?.user?.partner?.slug);

  const SESSION_STORAGE_AFTER_KYC_STATE_KEY = 'orderAfterKycStateId';
  const SESSION_STORAGE_AFTER_KYC_STATE_SLUG_KEY = 'slugAfterKycStateId';
  const SESSION_STORAGE_AFTER_KYC_TAB_KEY = 'tabAfterKycStateId';

  useEffect(() => {
    const { state = {} } = location;

    // NB. Brittle, coupled with implementation in apps/browser/investors/src/pages/features/investing/InvestmentPage/asset.page.tsx

    if (state?.slug) {
      window.sessionStorage.setItem(
        SESSION_STORAGE_AFTER_KYC_STATE_SLUG_KEY,
        state?.slug,
      );
    }
    if (state?.tab) {
      window.sessionStorage.setItem(
        SESSION_STORAGE_AFTER_KYC_TAB_KEY,
        state?.tab,
      );
    }
    if (state?.orderId) {
      window.sessionStorage.setItem(
        SESSION_STORAGE_AFTER_KYC_STATE_KEY,
        state.orderId,
      );
      // Edge case: if a user has been on this page with an orderId once before, then needs to complete KYC again
      // The old orderId would be retained and user would enter the wrong flow
      // This is very unlikely, but could happen if someone had to repeat KYC
      // Could also happen if we have multiple assets
      if (state?.orderId === 'clear') {
        window.sessionStorage.removeItem(SESSION_STORAGE_AFTER_KYC_STATE_KEY);
      }
    }
  }, [location]);

  if (!onboardingData?.onboardingProgress || onboardingLoading) {
    return <></>;
  }

  const isAccreditationPending =
    onboardingData?.onboardingProgress?.status.identityCheck ===
    KycCheckStatus.stale;

  const {
    onboardingProgress: { canInvest },
  } = onboardingData;

  const orderId = window.sessionStorage.getItem(
    SESSION_STORAGE_AFTER_KYC_STATE_KEY,
  );
  const slug = window.sessionStorage.getItem(
    SESSION_STORAGE_AFTER_KYC_STATE_SLUG_KEY,
  );
  const tab = window.sessionStorage.getItem(SESSION_STORAGE_AFTER_KYC_TAB_KEY);

  const readyToProceed = useWorkflows ? complete : canInvest;

  if (readyToProceed) {
    return (
      <SecondaryLinkButton
        fluid
        data-cy="ready-to-invest"
        // If we have an orderId, go straight to signature page. If not, go to asset page
        to={
          orderId
            ? `${location.pathname}?orderId=${orderId}#order/open`
            : `/assets/${slug}/${tab}?from=kyc`
        }
      >
        Ready to proceed
      </SecondaryLinkButton>
    );
  } else if (isAccreditationPending) {
    return (
      <Paragraph small color="grey400" align="center">
        Check back in a few days by clicking the &quot;invest&quot; button on
        your chosen asset.
        <br />
        Meanwhile you can <Link to="/explore">explore our assets</Link>.
      </Paragraph>
    );
  }

  return <></>;
};
