import { AnyFixLater } from '@hedgehog/shared/types';
import { IconType } from '@hedgehog/ui/icons';

import { NotificationListItem } from '../../components';
import { useMarkAsOpen } from '../../hooks';

export interface DefaultListItemProps {
  id: string;
  title: string;
  description: string;
  unread: boolean;
  to?: string | null;
  href?: string | null;
  icon?: string | null;
  imageSrcUrl?: string | null;
  onClick?: React.MouseEventHandler;
}

export function DefaultListItem({
  id,
  icon,
  imageSrcUrl,
  to,
  href,
  onClick,
  ...restProps
}: DefaultListItemProps): JSX.Element {
  const [markAsOpen] = useMarkAsOpen();

  const handleClick: React.MouseEventHandler = (event) => {
    markAsOpen({ variables: { notificationId: id } });
    if (onClick) onClick(event);
  };

  const variantProps =
    icon && imageSrcUrl
      ? {
          variant: 'image-and-icon' as const,
          imageSrc: imageSrcUrl,
          icon: icon as IconType,
        }
      : imageSrcUrl
      ? {
          variant: 'image' as const,
          imageSrc: imageSrcUrl,
        }
      : {
          variant: 'icon' as const,
          icon: icon as IconType,
        };

  if (to) {
    (restProps as AnyFixLater).to = to;
  } else {
    (restProps as AnyFixLater).href = href;
  }
  return (
    <NotificationListItem
      onClick={handleClick}
      {...restProps}
      {...variantProps}
    />
  );
}
