import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

type LocationState = null | {
  /**
   * noScroll will not scroll the user to the top of the page when navigating to the new page.
   * @example
   * <Link to="/example" state={{noScroll: true}} />
   */
  noScroll?: boolean;
};

type ScrollToTopProps = {
  children: JSX.Element;
};

/**
 * ScrollToTop will scroll the page to the top on page change.
 * @param children
 * @constructor
 */
export const ScrollToTop = ({ children }: ScrollToTopProps): JSX.Element => {
  const location = useLocation();

  useEffect(() => {
    const state = location.state as LocationState;
    if (!state?.noScroll) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return children;
};
