import { RefObject, useEffect, useRef } from 'react';
import styled, { CSSObject } from 'styled-components';

const VideoWrapper = styled.div`
  position: relative;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  min-height: 11.25rem;
  max-height: 11.25rem;
  max-width: 11.25rem;
  overflow: hidden;

  ${(): CSSObject => ({
    backgroundColor: '#d7d6d5',
  })};
`;

const Image = styled.img<{ hidden?: boolean }>`
  position: absolute;
  top: -3px;
  left: 2px;
  height: 100%;
  width: 100%;
  ${({ hidden }): CSSObject => ({ display: hidden ? 'none' : 'block' })}
`;

export interface MinterMotionProps {
  loading: boolean;
  onAnimationEnd?: () => void;
}

export const MinterMotion = ({
  loading,
  onAnimationEnd,
}: MinterMotionProps): JSX.Element => {
  const defaultRef = useRef<HTMLImageElement>(null);
  const successRef = useRef<HTMLImageElement>(null);

  const restartGifLoop = (
    elementRef: RefObject<HTMLImageElement>,
  ): Promise<void> => {
    return new Promise((resolve) => {
      const { current } = elementRef;
      if (!current) {
        resolve();
        return;
      }
      const handleResolve = (): void => {
        current.removeEventListener('load', handleResolve);
        resolve();
      };
      current.setAttribute('src', current.src);
      current.addEventListener('load', handleResolve);
    });
  };

  useEffect(() => {
    if (loading) {
      restartGifLoop(defaultRef);
      return;
    }
    setTimeout(() => {
      onAnimationEnd && onAnimationEnd();
    }, 3000);
  }, [loading, onAnimationEnd]);

  return (
    <VideoWrapper>
      <Image
        ref={defaultRef}
        loading="eager"
        src="https://assets.hedgehog-invest.com/video/mint-progress-460x460.gif"
        hidden={!loading}
      />
      <Image
        ref={successRef}
        loading="eager"
        src="https://assets.hedgehog-invest.com/video/mint-success-460x460.gif"
        hidden={loading}
      />
    </VideoWrapper>
  );
};
