import { QueryResult } from '@apollo/client';

import { useAuthenticatedQuery } from '@hedgehog/data-access/contexts';
import {
  GET_TOPIC,
  GetTopicQuery as GetTopic,
  GetTopicQueryVariables as GetTopicVariables,
} from '@hedgehog/data-access/graphql';

export const useCourseTopic = (slug: string): QueryResult<GetTopic> => {
  return useAuthenticatedQuery<GetTopic, GetTopicVariables>(
    GET_TOPIC,
    {
      slug,
    },
    {
      fetchPolicy: 'cache-and-network',
    },
  );
};
