import { differenceInYears, parseISO } from 'date-fns';
import React from 'react';
import styled, { CSSObject } from 'styled-components';

import { useContractIssuances } from '@hedgehog/browser/investors/account-abstraction/data-access';
import { useIncomeReports } from '@hedgehog/browser/investors/income-report/data-access';
import { IncomeReport } from '@hedgehog/data-access/graphql';
import {
  ContractIssuance,
  TransactionType,
} from '@hedgehog/shared/blockchain/resident-token';
import { useEnvironment } from '@hedgehog/ui/environment';
import { Loader } from '@hedgehog/ui/loaders';
import { parseBorder } from '@hedgehog/ui/themes';
import { StandardProps } from '@hedgehog/ui/utils';

import { OrderReportActionCard } from '../../components/order-report-action-card/order-report-action-card';
import { OrderTransactionItem } from '../../components/order-transaction-item/order-transaction-item.component';

export type OrderTransactionsProps = StandardProps<
  {
    orderId: string;
  },
  never
>;

export type OrderRow = {
  timestamp: number;
  item: React.FC;
};

const transformIssuances = (
  issuances?: ContractIssuance<TransactionType>[],
): OrderRow[] => {
  if (!issuances) return [];

  return issuances?.map(({ data: { type, ...data }, timestamp, amount }) => {
    const item = (): JSX.Element => (
      <OrderTransactionItem
        type={
          differenceInYears(timestamp, new Date()) >= 1 ? 'vested' : 'unvested'
        }
        key={timestamp}
        timestamp={timestamp}
        title={
          type === 'sign-on'
            ? 'Sign up bonus 🎉'
            : type === 'rent:paid'
            ? 'Rent paid'
            : type === 'rent:late'
            ? 'Rent late'
            : 'Lease renewed'
        }
        amount={amount}
      />
    );
    return { timestamp: timestamp, item: item };
  });
};

const transformReports = (reports?: IncomeReport[]): OrderRow[] => {
  if (!reports) return [];
  return reports.map((report: IncomeReport) => {
    const item = (): JSX.Element => <OrderReportActionCard report={report} />;
    return { timestamp: parseISO(report.createdAt).valueOf(), item: item };
  });
};

const TransactionsWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;

  & > * {
    ${({ theme }): CSSObject => ({
      borderBottom: parseBorder(theme.border.small),
      padding: '1rem 0',
    })};
  }
`;

const LoaderRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
`;

export const OrderTransactions = ({
  orderId,
}: OrderTransactionsProps): JSX.Element => {
  // TODO: Not using orderId? Should we?
  const {
    tenantToken: { address: tenantTokenAddress },
  } = useEnvironment();
  const { data: issuances, loading: issuancesLoading } = useContractIssuances();
  const { data: reports, loading: reportsLoading } =
    useIncomeReports(tenantTokenAddress);
  const isLoading = issuancesLoading || reportsLoading;
  const combinedData = isLoading
    ? []
    : [
        ...transformIssuances(issuances || undefined),
        ...transformReports(reports),
      ].sort((a, b) => b.timestamp - a.timestamp);

  if (isLoading) {
    return (
      <LoaderRow>
        <Loader color="secondary" />
      </LoaderRow>
    );
  }

  return (
    <TransactionsWrapper>
      {combinedData.map(({ item: ItemComponent }, index) => (
        <ItemComponent key={index} />
      ))}
    </TransactionsWrapper>
  );
};
