import styled from 'styled-components';

import { Avatar, AvatarProps } from '@hedgehog/ui/icons';
import { ListItem } from '@hedgehog/ui/lists';
import { Heading, Label } from '@hedgehog/ui/typography';

const CustomListItem = styled(ListItem)`
  display: flex;
  gap: 1rem;
`;

const ListItemOverview = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

export const OrderDetailsItem = ({
  label,
  text,
  iconType,
}: {
  label: string;
  text?: React.ReactNode | string | number;
  iconType: AvatarProps['icon'];
}): JSX.Element => {
  const dataTestId = label.toLowerCase().replace(/ /gi, '_');
  return (
    <CustomListItem>
      <Avatar
        icon={iconType}
        size="m"
        color="white"
        backgroundColor="secondary"
      />
      <ListItemOverview>
        <Label color="grey500">{label}</Label>
        <Heading level="h6" data-testid={dataTestId}>
          {text ?? 'N/A'}
        </Heading>
      </ListItemOverview>
    </CustomListItem>
  );
};
