import { useSearchParams } from 'react-router-dom';

import { JwtTokenPayload } from './use-jwt-payload.hook';

export const useIsSSO = (jwt: JwtTokenPayload | null): boolean => {
  const [searchParams] = useSearchParams();

  return Boolean(
    jwt?.payload?.sso ||
      (searchParams.has('code') && !searchParams.has('email')) ||
      // user that has attempted social login but doesn't yet have an account
      // will be redirected here along with their id token.
      (searchParams.has('id_token') && searchParams.has('access_token')),
  );
};
