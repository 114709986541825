import { useOrder } from '@hedgehog/browser/investors/order/data-access';
import { EmptyStateContainer } from '@hedgehog/ui/layouts';
import { currencies } from '@hedgehog/utils/formats';

import { OrderDetailsCard, OrderDetailsItem } from '../../components';

export interface NonTokenisedOrderDetailsOverviewProps {
  orderId: string;
}

export const NonTokenisedOrderDetailsOverview = ({
  orderId = '',
}: NonTokenisedOrderDetailsOverviewProps): JSX.Element => {
  const { error, data: orderData } = useOrder(orderId);

  if (error) {
    return (
      <EmptyStateContainer>
        Something went wrong, try again later.
      </EmptyStateContainer>
    );
  }

  return (
    <OrderDetailsCard>
      <OrderDetailsItem
        iconType="money"
        label="Total Invested"
        text={currencies.formatMoney(orderData?.order?.amount || 0, {
          currency:
            orderData?.order?.asset?.currentRound?.roundClass.currency || 'USD',
        })}
      />
    </OrderDetailsCard>
  );
};
