import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { useArticles } from '@hedgehog/browser/investors/learn/data-access';
import { EmptyStateContainer, LoadingContainer } from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';
import { StandardProps } from '@hedgehog/ui/utils';

import { ArticleCard } from './article-card.component';

type ArticlesListProps = StandardProps<
  {
    tags?: string[];
  },
  never
>;

export const ArticleWrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 19.75rem));
  padding-bottom: 3.5rem;
`;

export const ArticleLink = styled(Link)`
  min-width: 20rem;
`;

export const ArticlesList = ({
  tags,
  className,
}: ArticlesListProps): JSX.Element => {
  const { data, loading, error } = useArticles(tags);

  if (error) {
    return (
      <EmptyStateContainer>
        Something went wrong, please try again later
      </EmptyStateContainer>
    );
  }

  if (loading || !data) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }

  return (
    <ArticleWrapper className={className}>
      {data.articles.map((article) => (
        <ArticleCard
          key={article.id}
          to={`/learn/articles/${article.slug}`}
          title={article.title}
          author={article.author}
          tags={article.tags.map(({ tag }) => tag)}
          thumbnail={article.imageUrl}
        />
      ))}
    </ArticleWrapper>
  );
};
