import * as dateFns from 'date-fns';
import { useEffect, useState } from 'react';

const padZero = (val?: number): string => String(val || 0).padStart(2, '0');

const TIMER_INTERVAL_TICK_DURATION = 250;

const getDurationTo = (to: Date): dateFns.Duration =>
  dateFns.intervalToDuration({
    end: to,
    start: new Date(),
  });

export const useTimer = ({ to }: { to: Date }): string => {
  const [duration, setDuration] = useState(getDurationTo(to));

  useEffect(() => {
    const countdownInterval = setInterval(
      () => setDuration(getDurationTo(to)),
      TIMER_INTERVAL_TICK_DURATION,
    );
    return () => clearInterval(countdownInterval);
  }, [to]);

  const formattedTimer = [duration.hours, duration.minutes, duration.seconds]
    .map(padZero)
    .join(':');

  return formattedTimer;
};
