import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styled, { CSSObject } from 'styled-components';

import { useSubscriptionAgreement } from '@hedgehog/browser/investors/order/data-access';
import {
  useAnalyticsPage,
  useAnalyticsTrack,
} from '@hedgehog/browser/investors/shared/analytics';
import { useQueryParams } from '@hedgehog/data-access/hooks';
import { SecondaryButton } from '@hedgehog/ui/buttons';
import { ActionCard } from '@hedgehog/ui/cards';
import { CheckboxInput } from '@hedgehog/ui/inputs';
import { EmptyStateContainer } from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';
import { Paragraph } from '@hedgehog/ui/typography';

type FormControl = {
  name: string;
  value: boolean;
  onChange: (value: boolean) => void;
};

type Consents = {
  agreement: boolean;
  offering: boolean;
};

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  ${({ theme }): CSSObject => ({ gap: theme.spacing.large })}
`;

export const SubscriptionAgreementConsent = (): JSX.Element => {
  useAnalyticsPage('Invest', 'Subscription Agreement');
  const navigate = useNavigate();
  const params = useParams();
  const query = useQueryParams();
  const track = useAnalyticsTrack();
  const orderId = params.orderId || query.get('orderId') || '';
  const [consents, updateConsents] = useState<Consents>({
    agreement: false,
    offering: false,
  });
  const canSign = consents.agreement && consents.offering;

  // We get the document data from the smart contract using its name as the key
  const {
    data: agreement,
    loading: agreementLoading,
    error: agreementError,
  } = useSubscriptionAgreement({ name: 'subscription.pdf' });

  const createCheckboxControl = (
    name: 'agreement' | 'offering',
  ): FormControl => ({
    name,
    value: consents[name],
    onChange: (value: boolean) =>
      updateConsents((prev) => ({ ...prev, [name]: value })),
  });

  const acceptAgreement = async (): Promise<void> => {
    if (!agreement) return;
    navigate(
      {
        hash: `#order/minting`,
        search: query.toString() + '&documentHash=' + agreement.hash,
      },
      {
        replace: true,
      },
    );
  };

  if (!orderId) {
    return (
      <EmptyStateContainer>
        Something went wrong, please try again later
      </EmptyStateContainer>
    );
  }

  const isAgreementCooking = !!(agreementLoading || agreementError);

  const handleAgreementClick = (): void => {
    track('TenantTTokenAgreementDownloadButton', 'Clicked');
  };

  return (
    <Wrapper>
      <Paragraph>
        Please download and carefully read the subscription agreement below.
      </Paragraph>
      <ActionCard
        disabled={isAgreementCooking}
        title="Subscription Agreement"
        href={agreement?.uri || ''}
        target="_blank"
        trailing={isAgreementCooking ? <Loader /> : undefined}
        trailingIcon={isAgreementCooking ? undefined : 'download'}
        onClick={handleAgreementClick}
      />
      <CheckboxInput
        align="center"
        label={
          <Paragraph small>
            I have downloaded and understood subscription agreement
          </Paragraph>
        }
        {...createCheckboxControl('agreement')}
      />
      <CheckboxInput
        align="center"
        label={
          <Paragraph small>
            I&apos;ve understood the{' '}
            <Link
              to="/assets/edgewater_harbor/docs"
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              documents{' '}
            </Link>
            relating to the tenant token offering
          </Paragraph>
        }
        {...createCheckboxControl('offering')}
      />
      <SecondaryButton
        fluid
        disabled={!canSign || isAgreementCooking}
        onClick={acceptAgreement}
      >
        Accept
      </SecondaryButton>
    </Wrapper>
  );
};
