import { differenceInDays, formatDuration } from 'date-fns';
import styled from 'styled-components';

import { CountdownIcon } from '@hedgehog/ui/icons';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ theme }): string => theme.colors.success};
`;

const CountdownLabel = styled.p`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: visible;
  font-weight: 600;
  font-size: 1rem;
  line-height: 0.625rem;
  color: currentColor;
  margin: 0 0.5rem 0 0;
`;

export type AssetCardCountdownProps = {
  fundedAt?: Date;
};

export const AssetCardCountdown = ({
  fundedAt,
}: AssetCardCountdownProps): JSX.Element => {
  const endsAt = new Date(Number(fundedAt));
  const difference = differenceInDays(endsAt, new Date());
  const remainingDays = difference + 1;
  const countdownText = formatDuration({
    days: remainingDays,
  });
  const shouldCountdownAppear = Number(fundedAt) > Date.now();
  return (
    <Container>
      <CountdownLabel data-testid="countdown">
        {shouldCountdownAppear && `${countdownText} left`}
      </CountdownLabel>
      <CountdownIcon remainingDays={remainingDays} />
    </Container>
  );
};
