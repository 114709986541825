import { formatDuration } from 'date-fns/formatDuration';
import styled from 'styled-components';

import {
  AssetRoundClassReturnsPeriod as GetAssetRoundClassReturns_assetRoundClassReturns_period,
  AssetRoundClassReturnsPrice as GetAssetRoundClassReturns_assetRoundClassReturns_price,
} from '@hedgehog/data-access/graphql';
import { Card } from '@hedgehog/ui/cards';
import { BulletListItem } from '@hedgehog/ui/lists';
import { Label, Heading } from '@hedgehog/ui/typography';
import { currencies } from '@hedgehog/utils/formats';

export const ReturnBulletItem = styled(BulletListItem)`
  padding: 1.5rem 0;
`;

export const Wrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;

export const ReturnBlock = styled(Card)`
  flex-flow: column nowrap;
  padding: 2rem 1rem;

  & > ${ReturnBulletItem}:last-child {
    padding-bottom: 0;
  }

  & > ${ReturnBulletItem}:first-child {
    padding-top: 0;
  }
`;

export interface ReturnsOverviewProps {
  period: GetAssetRoundClassReturns_assetRoundClassReturns_period;
  price: GetAssetRoundClassReturns_assetRoundClassReturns_price;
}

export const ReturnsOverview = ({
  price,
  period,
}: ReturnsOverviewProps): JSX.Element => {
  const projectedTime = formatDuration({
    years: parseInt(`${period.projected / 12}`, 10),
    months: period.projected % 12,
  });
  const actualTime = formatDuration({
    years: parseInt(`${period.actual / 12}`, 10),
    months: period.actual % 12,
  });
  return (
    <ReturnBlock>
      <ReturnBulletItem icon="token" iconBackgroundColor="secondary">
        <Wrapper>
          <Label color="grey400">Token issue price</Label>
          <Heading level="h6">{currencies.formatMoney(price.initial)}</Heading>
        </Wrapper>
      </ReturnBulletItem>

      <ReturnBulletItem icon="calendar" iconBackgroundColor="secondary">
        <Wrapper>
          <Label color="grey400">Projected investment period</Label>
          <Heading level="h6">{projectedTime}</Heading>
        </Wrapper>

        <Wrapper>
          <Label color="grey400">Actual investment period</Label>
          <Heading level="h6">{actualTime}</Heading>
        </Wrapper>
      </ReturnBulletItem>

      <ReturnBulletItem icon="arrow-down" iconBackgroundColor="secondary">
        <Wrapper>
          <Label color="grey400">Actual return</Label>
          <Heading level="h6">{currencies.formatMoney(price.final)}</Heading>
        </Wrapper>
      </ReturnBulletItem>
    </ReturnBlock>
  );
};
