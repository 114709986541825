import {
  useAnalyticsPage,
  useAnalyticsTrack,
} from '@hedgehog/browser/investors/shared/analytics';
import { useScrollToBody } from '@hedgehog/browser/shared/utils';
import { useAuthenticatedQuery } from '@hedgehog/data-access/contexts';
import {
  GetAssetPageQueryVariables as GetAssetPageVariables,
  GetAssetPageQuery as GetAssetPage,
  GetAssetQuery,
  GET_ASSET_PAGE,
} from '@hedgehog/data-access/graphql';
import { ContentBlock } from '@hedgehog/shared/types';
import { ContentCMS } from '@hedgehog/ui/cms';
import { EmptyStateContainer, LoadingContainer } from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';

type HowItWorksTabProps = {
  asset: GetAssetQuery['asset'];
};

export const HowItWorksTab = ({ asset }: HowItWorksTabProps): JSX.Element => {
  useAnalyticsPage('Asset', 'How It Works', { asset_name: asset.path });
  useScrollToBody();
  const track = useAnalyticsTrack();
  const { data, loading, error } = useAuthenticatedQuery<
    GetAssetPage,
    GetAssetPageVariables
  >(GET_ASSET_PAGE, {
    assetId: asset.id,
    slug: 'how-it-works',
  });

  if (error) {
    return (
      <EmptyStateContainer>
        Something went wrong, please try again later
      </EmptyStateContainer>
    );
  }

  if (loading || !data) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }

  return (
    <ContentCMS
      content={data.assetPage.content as ContentBlock[]}
      onAnalyticsTrack={track}
    />
  );
};
