import { useParams, useSearchParams } from 'react-router-dom';
import styled, { CSSObject } from 'styled-components';

import { useOrder } from '@hedgehog/browser/investors/order/data-access';
import { useAnalyticsPage } from '@hedgehog/browser/investors/shared/analytics';
import { EmptyStateContainer, VSpace } from '@hedgehog/ui/layouts';
import { Heading } from '@hedgehog/ui/typography';

import { OrderGetSupport } from '../components';
import {
  DownloadSubscriptionAgreement,
  NonTokenisedOrderDetailsOverview,
  OrderDetailsOverview,
  OrderTransactions,
} from '../containers';

const SummaryContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  ${({ theme }): CSSObject => ({ gap: theme.spacing.xlarge })};
`;

export const OrderSummaryPage = (): JSX.Element => {
  useAnalyticsPage('Invest', 'Order Summary');
  const { orderId: paramsOrderId } = useParams<{ orderId: string }>();
  const [searchParams] = useSearchParams();
  const orderId = paramsOrderId || searchParams.get('orderId') || '';
  const { error, data: order } = useOrder(orderId);

  if (error) {
    return (
      <EmptyStateContainer>
        Something went wrong, please try again later.
      </EmptyStateContainer>
    );
  }

  return (
    <SummaryContainer>
      {order?.order?.asset.isTenantToken && (
        <>
          <OrderDetailsOverview orderId={orderId} />
          <VSpace spacing="xs">
            <Heading level="h7">Timeline</Heading>
            <OrderTransactions orderId={orderId} />
          </VSpace>
        </>
      )}
      {!order?.order?.asset.isTenantToken && (
        <NonTokenisedOrderDetailsOverview orderId={orderId} />
      )}
      <DownloadSubscriptionAgreement orderId={orderId} />
      <OrderGetSupport orderId={orderId} />
    </SummaryContainer>
  );
};
