import { useNavigate } from 'react-router-dom';

import { HeaderButton } from '../HeaderButton/header-button.component';

type HeaderInfoButtonProps = {
  /**
   * to represents a URL path to navigate the user to when they click the button. If falsy, the component will
   * simply navigate the user back to the previous page.
   */
  to?: string;
};

export const HeaderInfoButton = ({
  to = '',
}: HeaderInfoButtonProps): JSX.Element => {
  const navigate = useNavigate();
  const handleClick = (): void => {
    if (to) navigate(to, { replace: true });
  };
  return <HeaderButton onClick={handleClick} icon="info" color="grey400" />;
};
