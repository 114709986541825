import { useEffect } from 'react';

import {
  useAuth,
  useAuthenticatedLazyQuery,
} from '@hedgehog/data-access/contexts';
import {
  GetUserQuery as GetUser,
  GET_USER,
} from '@hedgehog/data-access/graphql';

export const useAuthUser = (): GetUser['user'] | undefined => {
  const [fetchUser, result] = useAuthenticatedLazyQuery<GetUser>(GET_USER);
  const { accessToken } = useAuth();

  useEffect(() => {
    if (!accessToken) return;
    fetchUser();
  }, [accessToken]);

  return result.data?.user || undefined;
};
