import { MutationTuple, OperationVariables, useMutation } from '@apollo/client';

import {
  REQUEST_ASSESSMENT_HELP,
  RequestAssessmentHelpMutation as RequestAssessmentHelp,
} from '@hedgehog/data-access/graphql';

export const useAssessmentHelpRequest = (): MutationTuple<
  RequestAssessmentHelp,
  OperationVariables
> =>
  useMutation<RequestAssessmentHelp, OperationVariables>(
    REQUEST_ASSESSMENT_HELP,
  );
