import styled from 'styled-components';

import { AssetCardCountdown } from '@hedgehog/browser/shared/assets';
import { AssetRoundClassStatus } from '@hedgehog/shared/types';
import { AssetCardCaption, AssetCardTokenPrice } from '@hedgehog/ui/assets';
import { SecondaryLightButton, PrimaryButton } from '@hedgehog/ui/buttons';
import { StandardProps } from '@hedgehog/ui/utils';
import { currencies } from '@hedgehog/utils/formats';

export type AssetFootnoteProps = StandardProps<{
  status: AssetRoundClassStatus;
  amount?: number;
  countdown?: Date | null;
  currency?: currencies.Currency;
}>;

export const StandardFootnote = styled(
  ({
    status,
    amount,
    countdown,
    currency,
  }: AssetFootnoteProps): JSX.Element => {
    switch (status) {
      case AssetRoundClassStatus.UPCOMING:
        return (
          <>
            <AssetCardCaption label="Coming soon" value="Spring 2024" />
            <SecondaryLightButton small>Learn more</SecondaryLightButton>
          </>
        );
      case AssetRoundClassStatus.COMING_SOON:
        return (
          <>
            <AssetCardCaption label="Coming soon" value="Spring 2024" />
            <PrimaryButton small>Learn more</PrimaryButton>
          </>
        );
      case AssetRoundClassStatus.LIVE:
        return (
          <>
            <AssetCardTokenPrice
              label="Minimum investment"
              amount={amount}
              currency={currency}
            />
            {countdown ? (
              <AssetCardCountdown fundedAt={new Date(countdown)} />
            ) : (
              <PrimaryButton small>Invest</PrimaryButton>
            )}
          </>
        );
      case AssetRoundClassStatus.ONGOING:
        return (
          <>
            <AssetCardCaption label="✨ Ongoing" />
            <SecondaryLightButton small>View</SecondaryLightButton>
          </>
        );
      case AssetRoundClassStatus.PAID_OUT:
        return (
          <>
            <AssetCardCaption label="🎉 Paid out" />
            <SecondaryLightButton small>View</SecondaryLightButton>
          </>
        );
      case AssetRoundClassStatus.FUNDED:
        return (
          <>
            <AssetCardCaption label="Sold out" />
            <SecondaryLightButton small>View</SecondaryLightButton>
          </>
        );
      case AssetRoundClassStatus.REGISTER_INTEREST:
        return (
          <>
            <AssetCardCaption
              label="Investment status"
              value="Pre-registration"
            />
            <PrimaryButton small>View</PrimaryButton>
          </>
        );
      default:
        return <SecondaryLightButton small>View</SecondaryLightButton>;
    }
  },
)``;
