import { useAuthenticatedQuery } from '@hedgehog/data-access/contexts';
import {
  GetFaQsQuery as GetFAQs,
  GET_FAQS,
} from '@hedgehog/data-access/graphql';

export const useFAQs = (): {
  data?: GetFAQs;
  error?: Error;
  loading: boolean;
} => {
  return useAuthenticatedQuery<GetFAQs>(GET_FAQS);
};
