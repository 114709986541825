import { QueryResult } from '@apollo/client';

import { useAuthenticatedQuery } from '@hedgehog/data-access/contexts';
import {
  GetCoursesQuery as GetCourses,
  GET_COURSES,
} from '@hedgehog/data-access/graphql';

export const useCourses = (): QueryResult<GetCourses> =>
  useAuthenticatedQuery<GetCourses>(
    GET_COURSES,
    {},
    {
      fetchPolicy: 'no-cache',
    },
  );
