import { QueryResult } from '@apollo/client';

import { useAuthenticatedQuery } from '@hedgehog/data-access/contexts';
import {
  GET_IDENTITY_CHECK,
  GetIdentityCheckQuery as GetIdentityCheck,
} from '@hedgehog/data-access/graphql';

export const useIdentityCheckDecision = (): QueryResult<GetIdentityCheck> =>
  useAuthenticatedQuery<GetIdentityCheck>(
    GET_IDENTITY_CHECK,
    {},
    {
      fetchPolicy: 'no-cache',
    },
  );
