import { ForwardedRef, MouseEventHandler, ReactNode, forwardRef } from 'react';
import styled, { CSSObject } from 'styled-components';

import { TopicProgress } from '@hedgehog/data-access/graphql';
import { ActionCard, LinkOrNotProps } from '@hedgehog/ui/cards';
import { parseBorderToShadow } from '@hedgehog/ui/themes';
import { Heading, Paragraph } from '@hedgehog/ui/typography';
import { StandardProps } from '@hedgehog/ui/utils';

import { CourseTopicCircle } from './course-topic-circle.component';

export type CourseTopicActionCardProps = StandardProps<
  {
    type?: 'default' | 'light';
    completed?: boolean;
    title?: ReactNode | string;
    caption?: ReactNode | string;
    progress?: TopicProgress;
    active?: boolean;
    onClick?: MouseEventHandler;
    disabled?: boolean;
  } & LinkOrNotProps,
  never
>;

export const CourseTopicActionCard = styled(
  forwardRef(
    (
      { type, caption, title, progress, ...props }: CourseTopicActionCardProps,
      ref: ForwardedRef<HTMLDivElement | HTMLAnchorElement>,
    ) => (
      <ActionCard
        type={type}
        ref={ref}
        leading={
          <CourseTopicCircle
            appearance="avatar"
            progress={progress || TopicProgress.NOT_STARTED}
          />
        }
        trailingIcon="chevron-right"
        title={<Heading level="h6">{title}</Heading>}
        caption={<Paragraph color="grey400">{caption}</Paragraph>}
        {...props}
      />
    ),
  ),
)`
  ${({ active, theme: { border } }): CSSObject => ({
    boxShadow: active
      ? parseBorderToShadow(border.large, { outset: false })
      : undefined,
  })}
`;
