import styled from 'styled-components';

import { useAssetRoundReturns } from '@hedgehog/browser/investors/order/data-access';
import { Asset as GetAsset_asset } from '@hedgehog/data-access/graphql';
import { OutlineLinkButton } from '@hedgehog/ui/buttons';
import { EmptyStateContainer, LoadingContainer } from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';

import { AssetPageTab } from '../asset-page-tab/asset-page-tab.component';

import { ReturnsChart } from './returns-chart.component';
import { ReturnsOverview } from './returns-overview.component';

export interface ReturnsTabProps {
  asset: GetAsset_asset;
}

export const AssetReturnsPageTab = styled(AssetPageTab)`
  padding-top: 2rem;
`;

export const ReturnsTab = ({ asset }: ReturnsTabProps): JSX.Element => {
  const { data, error, loading } = useAssetRoundReturns({
    roundClassId: asset.currentRound?.roundClass.id || '',
  });

  if (error) {
    return (
      <AssetReturnsPageTab>
        <EmptyStateContainer>
          Something went wrong, please try again later
        </EmptyStateContainer>
      </AssetReturnsPageTab>
    );
  }

  if (loading || !data?.assetRoundClassReturns) {
    return (
      <AssetReturnsPageTab>
        <LoadingContainer>
          <Loader />
        </LoadingContainer>
      </AssetReturnsPageTab>
    );
  }

  const {
    assetRoundClassReturns: { price, period, order },
  } = data;
  return (
    <AssetReturnsPageTab>
      <ReturnsChart price={price.final} />

      <ReturnsOverview period={period} price={price} />

      {order && (
        <OutlineLinkButton to={`/orders/${order.id}`}>
          View your returns
        </OutlineLinkButton>
      )}
    </AssetReturnsPageTab>
  );
};
