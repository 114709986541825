import {
  ContractIssuance,
  TransactionType,
} from '@hedgehog/shared/blockchain/resident-token';

type MockIssuanceInput = Partial<
  Omit<ContractIssuance, '#raw' | 'type' | 'amount'>
>;

export const createMockIssuance = ({
  amount,
  ...issuance
}: {
  amount: number;
} & MockIssuanceInput): ContractIssuance =>
  ContractIssuance.from({
    timestamp: Math.ceil(Date.now() / 1e3),
    vestingDurationSeconds: Math.ceil(Date.now() / 1e3),
    ...issuance,
    amount,
  } as ContractIssuance);

export const createMockIssuances = <T extends TransactionType>({
  repeat = 1,
  amount,
  ...issuance
}: {
  repeat?: number;
  amount: number;
} & MockIssuanceInput): ContractIssuance<T>[] =>
  new Array(repeat).fill(
    createMockIssuance({
      ...issuance,
      amount,
    }),
  );

export const tenants: Record<
  'mercury' | 'venus' | 'earth' | 'mars' | 'jupiter',
  ContractIssuance[]
> = {
  mercury: [
    createMockIssuance({
      amount: 90,
      data: { type: 'sign-on' },
    }),
    createMockIssuance({
      amount: 60,
      data: { type: 'rent:paid' },
    }),
    createMockIssuance({
      amount: 1080,
      data: { type: 'lease:renew' },
    }),
    ...createMockIssuances({
      repeat: 11,
      amount: 60,
      data: { type: 'rent:paid' },
    }),
    createMockIssuance({
      data: { type: 'lease:renew' },
      amount: 1080,
    }),
    createMockIssuance({
      data: { type: 'rent:paid' },
      amount: 60,
    }),
    ...createMockIssuances({
      repeat: 11,
      data: { type: 'rent:paid' },
      amount: 60,
    }),
  ],
  venus: [
    createMockIssuance({
      data: { type: 'sign-on' },
      amount: 105,
    }),
    ...createMockIssuances({
      repeat: 2,
      data: { type: 'rent:paid' },
      amount: 70,
    }),
    createMockIssuance({
      data: { type: 'lease:renew' },
      amount: 1260,
    }),
    createMockIssuance({
      data: { type: 'rent:late' },
      amount: 0,
    }),
    ...createMockIssuances({
      repeat: 10,
      data: { type: 'rent:paid' },
      amount: 75,
    }),
    createMockIssuance({
      data: { type: 'rent:paid' },
      amount: 75,
    }),
    createMockIssuance({
      data: { type: 'rent:late' },
      amount: 0,
    }),
    ...createMockIssuances({
      repeat: 10,
      data: { type: 'rent:paid' },
      amount: 80,
    }),
    createMockIssuance({
      data: { type: 'lease:renew' },
      amount: 1350,
    }),
  ],
  earth: [
    createMockIssuance({
      data: { type: 'sign-on' },
      amount: 75,
    }),
    createMockIssuance({
      data: { type: 'rent:late' },
      amount: 0,
    }),
    ...createMockIssuances({
      repeat: 3,
      data: { type: 'rent:paid' },
      amount: 50,
    }),
    ...createMockIssuances({
      repeat: 2,
      data: { type: 'rent:late' },
      amount: 0,
    }),
    ...createMockIssuances({
      repeat: 6,
      data: { type: 'rent:paid' },
      amount: 55,
    }),
    ...createMockIssuances({
      data: { type: 'lease:renew' },
      amount: 900,
    }),
    ...createMockIssuances({
      repeat: 4,
      data: { type: 'rent:paid' },
      amount: 55,
    }),
    ...createMockIssuances({
      repeat: 2,
      data: { type: 'rent:late' },
      amount: 0,
    }),
    ...createMockIssuances({
      repeat: 6,
      data: { type: 'rent:paid' },
      amount: 60,
    }),
    ...createMockIssuances({
      data: { type: 'lease:renew' },
      amount: 990,
    }),
  ],
  mars: [
    ...createMockIssuances({
      data: { type: 'sign-on' },
      amount: 83,
    }),
    ...createMockIssuances({
      data: { type: 'rent:late' },
      amount: 0,
    }),
    ...createMockIssuances({
      repeat: 4,
      data: { type: 'rent:paid' },
      amount: 55,
    }),
    ...createMockIssuances({
      data: { type: 'lease:renew' },
      amount: 990,
    }),
    ...createMockIssuances({
      data: { type: 'rent:late' },
      amount: 0,
    }),
    ...createMockIssuances({
      repeat: 6,
      data: { type: 'rent:paid' },
      amount: 60,
    }),
    ...createMockIssuances({
      repeat: 5,
      data: { type: 'rent:paid' },
      amount: 60,
    }),
  ],
  jupiter: [
    ...createMockIssuances({
      data: { type: 'sign-on' },
      amount: 110,
    }),
    ...createMockIssuances({
      repeat: 2,
      data: { type: 'rent:late' },
      amount: 0,
    }),
    ...createMockIssuances({
      repeat: 4,
      data: { type: 'rent:paid' },
      amount: 73,
    }),
  ],
};
