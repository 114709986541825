import { ReactNode } from 'react';
import styled from 'styled-components';

import { VSpace } from '@hedgehog/ui/layouts';

const AssetPageTabWrapper = styled(VSpace)`
  margin-bottom: 10rem;
`;

export interface AssetPageTabProps {
  error?: ReactNode | ReactNode[];
  loading?: ReactNode | ReactNode[];
  children?: ReactNode | ReactNode[];
}

export const AssetPageTab = (props: AssetPageTabProps): JSX.Element => (
  <AssetPageTabWrapper>{props.children}</AssetPageTabWrapper>
);
