import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled, { css, CSSProp } from 'styled-components';

import {
  useAnalyticsPage,
  useAnalyticsTrack,
} from '@hedgehog/browser/investors/shared/analytics';
import { OutlineLightLinkButton } from '@hedgehog/ui/buttons';
import { useEnvironment } from '@hedgehog/ui/environment';
import { HideBreakpoint } from '@hedgehog/ui/layouts';
import { Paragraph } from '@hedgehog/ui/typography';
import { screens } from '@hedgehog/utils/sizes';

import { CognitoSocialLoginButton, QRCode } from '../../components';

import { AnchorLoginLink } from './styles';

const SignupOptions = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }

  @media only screen and (min-width: ${screens.medium}px) {
    max-width: 19.375rem;
  }
`;

const MobileOptions = styled(HideBreakpoint)`
  display: flex;
  flex-flow: column nowrap;
  padding-top: 3rem;
  margin-bottom: -7rem;
  align-items: center;
`;

const CognitoAppleButton = styled(CognitoSocialLoginButton)`
  border-width: 2px;
`;

const OutlineLoginStyle = css`
  border-width: 2px;
  ${({ theme }): CSSProp => ({
    borderColor: theme.colors.grey200,
    backgroundColor: theme.colors.white,
    color: theme.colors.black,
  })};
`;

const CognitoGoogleButton = styled(CognitoSocialLoginButton)`
  ${OutlineLoginStyle}
`;

const SignUpButton = styled(OutlineLightLinkButton)`
  ${({ theme: { typography } }): CSSProp => ({
    fontSize: typography.button_small.fontSize,
  })};
`;

const CodeText = styled(Paragraph)`
  font-size: 1.375rem;
  line-height: 1.25;
  margin-bottom: 1rem;
`;

const CodeWrapper = styled.div`
  display: flex;
  min-height: 6rem;
  max-height: 8rem;
  min-width: 6rem;
  max-width: 8rem;
`;

export function LandingPage(): JSX.Element {
  useAnalyticsPage('Authentication', 'Landing');
  const track = useAnalyticsTrack();
  const { ssoUrl, cognito } = useEnvironment();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Help navigate the user to the correct URL when navigating from outside the app using deep links.
  // On desktop or android, the deep link will take them to the root of the hedgehog web app.
  useEffect(() => {
    const fallbackUrl = searchParams?.get('fallback_url');
    if (!fallbackUrl) return;
    searchParams.delete('fallback_url');
    navigate(`${fallbackUrl}?${searchParams}`, { replace: true });
  }, [searchParams]);

  return (
    <>
      <SignupOptions>
        <CognitoAppleButton
          fluid
          loginProvider="SignInWithApple"
          icon="apple"
          onClick={(): void => track('SignupWithAppleButton', 'Clicked')}
          text="Sign up with Apple"
          cognitoUrl={cognito.userPoolUrl}
          cognitoClientId={cognito.clientId}
          redirectUrl={ssoUrl}
        />
        <CognitoGoogleButton
          fluid
          loginProvider="Google"
          icon="google"
          onClick={(): void => track('SignupWithGoogleButton', 'Clicked')}
          text="Sign up with Google"
          cognitoUrl={cognito.userPoolUrl}
          cognitoClientId={cognito.clientId}
          redirectUrl={ssoUrl}
        />
        <SignUpButton
          fluid
          to="/signup"
          icon="mail"
          iconColor="black"
          onClick={(): void => track('BeginSignupButton', 'Clicked')}
        >
          Sign up with email
        </SignUpButton>

        <AnchorLoginLink to="/login" visibleAt="mobile">
          Already have an account?
        </AnchorLoginLink>
      </SignupOptions>
      <MobileOptions to="medium">
        <CodeText weight="700">Or download the app</CodeText>
        <CodeWrapper>
          <QRCode />
        </CodeWrapper>
      </MobileOptions>
    </>
  );
}

export default LandingPage;
