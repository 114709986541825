import {
  DocumentNode,
  LazyQueryHookOptions,
  LazyQueryResultTuple,
  OperationVariables,
  useLazyQuery,
} from '@apollo/client';
import { useNavigate } from 'react-router-dom';

export const useAuthenticatedLazyQuery = <
  T,
  V extends OperationVariables = OperationVariables,
>(
  query: DocumentNode,
  options?: LazyQueryHookOptions<T, V>,
): LazyQueryResultTuple<T, V> => {
  const navigate = useNavigate();
  const [runQuery, queryResult] = useLazyQuery<T, V>(query, options);

  if (
    queryResult.error?.graphQLErrors[0]?.extensions?.code === 'UNAUTHENTICATED'
  ) {
    navigate('/logout');
  }
  return [runQuery, queryResult];
};
