import { Page } from '@hedgehog/ui/layouts';

import { AutoscrollProvider } from './autoscroll.provider';
import { AutoscrollContextProps } from './hooks';

export type AutoscrollPageProps = AutoscrollContextProps & {
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
  sidebarPage?: boolean;
};

export const AutoscrollPage = ({
  className,
  children,
  sidebarPage,
  ...props
}: AutoscrollPageProps): JSX.Element => {
  return (
    <AutoscrollProvider {...props}>
      <Page sidebarPage={sidebarPage} className={className}>
        {children}
      </Page>
    </AutoscrollProvider>
  );
};
