import { Navigate, Route } from 'react-router-dom';

import { ConfirmEmailPage, LandingPage } from '@hedgehog/browser/shared/auth';
import {
  LoginPage,
  SignupPage,
  ConfirmSignupPage,
  ForgotPasswordPage,
  ConfirmPasswordPage,
  LoginSuccessPage,
  PartnerReferralSocialCallbackPage,
  PartnerLandingPage,
  PartnerReferralSignupPage,
  PartnerKYCSignupPage,
  PartnerKeyRouter,
} from '@hedgehog/browser/shared/auth';
import { RequireNoAuth } from '@hedgehog/data-access/contexts';
import { LinkButton, PrimaryLinkButton } from '@hedgehog/ui/buttons';
import { Paragraph } from '@hedgehog/ui/typography';

import { EnterpriseLayout } from '../layouts/enterprise.layout';

const userAuthenticationRoutes = (
  <Route>
    <Route
      element={
        <EnterpriseLayout
          visibleAt={{
            jumbotron: 'desktop-and-mobile',
            actions: 'desktop',
          }}
          links={
            <>
              <Paragraph color="grey400">Already have an account?</Paragraph>
              <PrimaryLinkButton to="/login">Sign in</PrimaryLinkButton>
            </>
          }
        />
      }
    >
      <Route index element={<LandingPage />} />
    </Route>

    <Route
      element={
        <EnterpriseLayout
          visibleAt={{ jumbotron: 'desktop' }}
          links={
            <>
              <Paragraph color="grey400" visibleAt="desktop">
                Already have an account?
              </Paragraph>
              <PrimaryLinkButton to="/login">Sign in</PrimaryLinkButton>
            </>
          }
        />
      }
    >
      <Route path="signup" element={<SignupPage />} />
      <Route path="signup/confirm-email" element={<ConfirmEmailPage />} />
      <Route path="signup_confirm" element={<ConfirmSignupPage />} />
    </Route>

    <Route
      element={
        <EnterpriseLayout
          visibleAt={{ jumbotron: 'desktop' }}
          links={<LinkButton to="/login">Need help?</LinkButton>}
        />
      }
    >
      <Route path="login" element={<LoginPage />} />
      <Route path="logon" element={<Navigate to="/login" />} />
      <Route path="forgot-password">
        <Route index element={<ForgotPasswordPage />} />
        <Route path="confirm" element={<ConfirmPasswordPage />} />
      </Route>
    </Route>
  </Route>
);

const partnersAuthenticationRoutes = (
  <Route path=":slug" element={<PartnerKeyRouter />}>
    <Route
      element={
        <EnterpriseLayout
          visibleAt={{ jumbotron: 'desktop-and-mobile', actions: 'desktop' }}
          links={
            <>
              <Paragraph color="grey400">Already have an account?</Paragraph>
              <PrimaryLinkButton to="login">Sign in</PrimaryLinkButton>
            </>
          }
        />
      }
    >
      <Route index element={<PartnerLandingPage />} />
    </Route>

    <Route
      element={
        <EnterpriseLayout
          visibleAt={{ jumbotron: 'desktop' }}
          links={
            <>
              <Paragraph color="grey400">Already have an account?</Paragraph>
              <PrimaryLinkButton to="login">Sign in</PrimaryLinkButton>
            </>
          }
        />
      }
    >
      <Route path="signup" element={<PartnerReferralSignupPage />} />
      <Route path="signup/confirm-email" element={<ConfirmEmailPage />} />
      <Route
        path="signup/confirm"
        element={<PartnerReferralSocialCallbackPage />}
      />

      <Route path="signup-kyc" element={<PartnerKYCSignupPage />} />
    </Route>

    <Route
      element={
        <EnterpriseLayout
          visibleAt={{ jumbotron: 'desktop' }}
          links={<LinkButton to="/login">Need help?</LinkButton>}
        />
      }
    >
      <Route path="login" element={<LoginPage />} />
      <Route path="logon" element={<Navigate to="/login" />} />
      <Route path="forgot-password">
        <Route index element={<ForgotPasswordPage />} />
        <Route path="confirm" element={<ConfirmPasswordPage />} />
      </Route>
    </Route>
  </Route>
);

export const unauthorisedRoutes = (
  <Route element={<RequireNoAuth />}>
    {userAuthenticationRoutes}
    {partnersAuthenticationRoutes}

    <Route path="auth/signin/callback" element={<LoginSuccessPage />} />
    <Route
      path="auth/partner-referrals/social/callback"
      element={<PartnerReferralSocialCallbackPage />}
    />
  </Route>
);
