import styled, { CSSObject } from 'styled-components';

import { useIdentityCheckDecision } from '@hedgehog/browser/investors/kyc/data-access';
import { KycCheckStatus } from '@hedgehog/shared/types';
import { SecondaryLinkButton } from '@hedgehog/ui/buttons';
import { Card, CardContent } from '@hedgehog/ui/cards';
import { Loader } from '@hedgehog/ui/loaders';
import { Paragraph } from '@hedgehog/ui/typography';

export interface IdentityCheckPromptProps {
  status: KycCheckStatus;
}

const Wrapper = styled(Card)`
  ${CardContent} {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    ${({ theme }): CSSObject => ({ gap: theme.spacing.normal })}
  }
`;

export const IdentityCheckPrompt = (): JSX.Element => {
  const { data, loading: decisionLoading } = useIdentityCheckDecision();

  if (!data || decisionLoading) {
    return <></>;
  }

  if (
    ![
      KycCheckStatus.processing,
      KycCheckStatus.approved,
      KycCheckStatus.rejected,
    ].includes(data.identityCheckProgress.status)
  ) {
    return <></>;
  }

  return (
    <Wrapper>
      {data.identityCheckProgress.status === KycCheckStatus.processing && (
        <>
          <Loader />
          <Paragraph>
            Your check is still in progress, please check back later
          </Paragraph>
        </>
      )}
      {data.identityCheckProgress.status === KycCheckStatus.approved && (
        <>
          <Paragraph>Identity check already complete</Paragraph>
          <SecondaryLinkButton to="#kyc">Continue</SecondaryLinkButton>
        </>
      )}
      {data.identityCheckProgress.status === KycCheckStatus.rejected && (
        <Paragraph>
          Unfortunately, we cannot accept you as an investor on our platform at
          this time.
        </Paragraph>
      )}
    </Wrapper>
  );
};
