import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GoogleOAuthProvider } from '@react-oauth/google';
import * as Sentry from '@sentry/react';
import { History } from 'history';
import { Suspense, useEffect } from 'react';
import { Provider as Redux } from 'react-redux';

import { AnalyticsProvider } from '@hedgehog/browser/investors/shared/analytics';
import { ErrorPage } from '@hedgehog/browser/investors/shared/errors';
import { createStore } from '@hedgehog/browser/investors/shared/redux';
import { PartnerProvider } from '@hedgehog/browser/shared/auth';
import { ShadowModeProvider } from '@hedgehog/data-access/contexts';
import { AuthProvider } from '@hedgehog/data-access/contexts';
import {
  LOCALSTORAGE_MOBILE_NATIVE_KEY,
  NativeMobileExperiences,
  sendMessageToApp,
  useIsNativeMobileExperience,
} from '@hedgehog/data-access/native-mobile';
import { register } from '@hedgehog/data-access/native-mobile';
import { EnvironmentProvider } from '@hedgehog/ui/environment';
import {
  LoadingContainer,
  NativePreventInteraction,
} from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';
import { ModalProvider } from '@hedgehog/ui/modals';
import { history as myHistory, HistoryRouter } from '@hedgehog/ui/routing';

import { environment } from './environments/environment';
import { InvestorsThemeProvider } from './investors-theme.provider';
import { AppRouter } from './routes/app.router';

register();

const httpLink = createHttpLink({
  uri: `${environment.api.url}/graphql`,
  credentials: 'include',
});

const cookieLink = setContext((_, { headers }) => {
  const { cookie } = document;

  return {
    headers: {
      ...headers,
      Cookie: cookie,
    },
  };
});

const logoutLink = onError(({ response }) => {
  if (!response?.errors) return;
  const {
    errors: [error],
  } = response;
  if (error && error.extensions?.response?.statusCode === 403) {
    // the social signin callback page will make a request to get user details which returns 403 because a user
    // with that cognito id doesn't exist in our database, the 403 error is expected on that page if a user hasn't
    // completed the signup process.
    if (
      window.location.pathname !== '/auth/signin/callback' &&
      window.location.pathname !== '/signup_confirm'
    ) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      window.location.reload();
    }
  }
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token || '',
    },
  };
});

const platformLink = setContext((_, { headers }) => {
  const nativeMobileExperiences = localStorage.getItem(
    LOCALSTORAGE_MOBILE_NATIVE_KEY,
  );

  const experiences: Array<string> = JSON.parse(
    nativeMobileExperiences || '[]',
  );

  const isIos = !!experiences.find(
    (value) => value === NativeMobileExperiences.IOS_ANALYTICS,
  );

  return {
    headers: {
      ...headers,
      platform: isIos ? 'ios' : 'web',
    },
  };
});

const client = new ApolloClient({
  link: authLink
    .concat(logoutLink)
    .concat(cookieLink)
    .concat(platformLink)
    .concat(httpLink),
  cache: new InMemoryCache(),
});

export interface AppProps {
  history?: History;
}

const store = createStore({ debug: !environment.production });

const App = ({ history = myHistory }: AppProps): JSX.Element | null => {
  const iosDevice = useIsNativeMobileExperience(
    NativeMobileExperiences.DEVICE_IOS,
  );
  const isNativeLogoutEnabled = useIsNativeMobileExperience(
    NativeMobileExperiences.LOGOUT,
  );

  useEffect(() => {
    sendMessageToApp('app.mounted');
  }, []);

  const handleSessionExpired = (): void => {
    if (!isNativeLogoutEnabled) return;
    sendMessageToApp('jwt.expired');
  };

  const handleSignOut = (): void => {
    client.clearStore();
  };

  return (
    <Suspense
      fallback={
        <LoadingContainer>
          <Loader />
        </LoadingContainer>
      }
    >
      {iosDevice && <NativePreventInteraction />}
      <Sentry.ErrorBoundary fallback={<ErrorPage />}>
        <Redux store={store}>
          <EnvironmentProvider environment={environment}>
            <HistoryRouter history={history}>
              <ShadowModeProvider>
                <AnalyticsProvider
                  // Switch this to true to enable analytics debugging
                  debug={false}
                >
                  <ApolloProvider client={client}>
                    <AuthProvider
                      onSignOut={handleSignOut}
                      onSessionExpired={handleSessionExpired}
                    >
                      <PartnerProvider>
                        <InvestorsThemeProvider>
                          <ModalProvider>
                            <Suspense
                              fallback={
                                <LoadingContainer>
                                  <Loader />
                                </LoadingContainer>
                              }
                            >
                              <GoogleOAuthProvider
                                clientId={environment.signInWithGoogle.clientId}
                              >
                                <AppRouter />
                              </GoogleOAuthProvider>
                            </Suspense>
                          </ModalProvider>
                        </InvestorsThemeProvider>
                      </PartnerProvider>
                    </AuthProvider>
                  </ApolloProvider>
                </AnalyticsProvider>
              </ShadowModeProvider>
            </HistoryRouter>
          </EnvironmentProvider>
        </Redux>
      </Sentry.ErrorBoundary>
    </Suspense>
  );
};

export default Sentry.withProfiler(App);
