import { countriesLongCodeToName, mask } from '@hedgehog/browser/shared/utils';
import { SecondaryButton } from '@hedgehog/ui/buttons';
import { VSpace } from '@hedgehog/ui/layouts';
import { DeclarationModal } from '@hedgehog/ui/modals';
import { Heading, Paragraph } from '@hedgehog/ui/typography';
import { StandardProps } from '@hedgehog/ui/utils';

export type TaxDeclarationModalProps = StandardProps<
  {
    firstName: string;
    lastName: string;
    dob: string;
    taxResidency: string;
    taxIdentifier: string;
    onConfirm: () => void;
  },
  never
>;

export const TaxDeclarationModal = ({
  onConfirm,
  className,
  ...props
}: TaxDeclarationModalProps): JSX.Element => {
  return (
    <DeclarationModal
      className={className}
      actions={({ trapReached }): JSX.Element => (
        <SecondaryButton onClick={onConfirm} disabled={!trapReached}>
          Confirm declaration
        </SecondaryButton>
      )}
    >
      <VSpace spacing="small">
        <Heading level="h5">Tax declaration</Heading>
        <Paragraph>
          Name:{' '}
          <b>
            {props.firstName} {props.lastName}
          </b>
        </Paragraph>
        <Paragraph>
          Date of Birth: <b>{props.dob}</b>
        </Paragraph>
        <Paragraph>
          Tax residency: <b>{countriesLongCodeToName[props.taxResidency]}</b>
        </Paragraph>
        <Paragraph>
          TIN: <b>{mask(props.taxIdentifier)}</b>
        </Paragraph>
        <Paragraph>
          I understand that the information supplied by me is covered by the
          Hedgehog Terms of Services and Privacy Policy. I know that the
          information I have provided to Hedgehog may be provided to relevant
          tax authorities such as the tax authority where I reside and may also
          be exchanged with tax authorities in other countries.
        </Paragraph>
        <Paragraph>
          I certify that I am the account holder making the investment for the
          purposes of this self-certification.
        </Paragraph>
        <Paragraph>
          I declare that the above information is, to the best of my knowledge
          and belief, accurate and complete. I undertake to advise Hedgehog
          promptly and update my account information within 30 days of any
          change in this information.
        </Paragraph>
      </VSpace>
    </DeclarationModal>
  );
};
