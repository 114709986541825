import { differenceInMonths } from 'date-fns';

import { ContractIssuance } from '@hedgehog/shared/blockchain/resident-token';

import { countIssuances } from './count-issuances.helper';

export interface CalculateRentRewardsEstimationInput {
  from: number | Date;
  to: number | Date;
  recentRent: number;
  issuances: ContractIssuance[];
}

export const RENT_ON_TIME_BONUS_RATIO_PER_MONTH = 0.02;

export const calculateRentRewardsEstimation = ({
  from: leaseFrom,
  to: leaseTo,
  recentRent,
  issuances,
}: CalculateRentRewardsEstimationInput): number => {
  const rentIssuances = countIssuances({
    issuances,
    predicate: (issuance) => {
      const { data } = issuance as ContractIssuance<'rent:paid'>;
      return data.type === 'rent:paid';
    },
  });
  const totalMonths = differenceInMonths(leaseTo, leaseFrom);
  const remainingMonthlyPaymentsTillEnd = totalMonths - rentIssuances.count;
  const rewardsPerMonthlyPayment =
    recentRent * RENT_ON_TIME_BONUS_RATIO_PER_MONTH;

  return rewardsPerMonthlyPayment * remainingMonthlyPaymentsTillEnd;
};
