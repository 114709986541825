import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { UUID } from '@hedgehog/shared/types';
import { PlainToast, PlainToastProps } from '@hedgehog/ui/toasts';

import { useSponsor } from './asset-sponsor-toast.hooks';

const RelaxedRadiusToast = styled(PlainToast)`
  border-radius: 1rem;
`;

const Image = styled.div<{ imageUrl: string }>`
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
  background: url(${({ imageUrl }): string => imageUrl}) no-repeat center;
  background-size: cover;
  box-shadow: inset 0 0 0 0.25rem
    ${({ theme }): string => theme.colors['shadow100']};
`;

export type AssetSponsorToastProps = Omit<PlainToastProps, 'title'> & {
  sponsorId: UUID;
};

export const AssetSponsorToast = ({
  sponsorId,
  ...otherProps
}: AssetSponsorToastProps): JSX.Element | null => {
  const { data } = useSponsor(sponsorId);
  if (!data) return null;
  return (
    <Link to={`?sponsorId=${data.sponsor.slug}#sponsors`}>
      <RelaxedRadiusToast
        {...otherProps}
        title={data.sponsor.name}
        trailing={
          data.sponsor.imageUrl && <Image imageUrl={data.sponsor.imageUrl} />
        }
      />
    </Link>
  );
};
