import { Navigate, useParams } from 'react-router-dom';

import {
  useAnalyticsPage,
  useAnalyticsTrack,
} from '@hedgehog/browser/investors/shared/analytics';
import { useScrollToBody } from '@hedgehog/browser/shared/utils';
import { useAuthenticatedQuery } from '@hedgehog/data-access/contexts';
import {
  GetAssetPageQuery as GetAssetPage,
  GetAssetPageQueryVariables as GetAssetPageVariables,
  GetAssetQuery,
  GET_ASSET_PAGE,
} from '@hedgehog/data-access/graphql';
import { ContentCMS } from '@hedgehog/ui/cms';
import { EmptyStateContainer, LoadingContainer } from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';

type CustomTabProps = {
  asset: GetAssetQuery['asset'];
  fallbackTo?: string;
};

export const CustomTab = ({
  asset,
  fallbackTo = 'overview',
}: CustomTabProps): JSX.Element => {
  useAnalyticsPage('Asset', 'Investment', { asset_name: asset.path });
  useScrollToBody();
  const track = useAnalyticsTrack();
  const { slug = '' } = useParams();
  const { data, loading, error } = useAuthenticatedQuery<
    GetAssetPage,
    GetAssetPageVariables
  >(
    GET_ASSET_PAGE,
    {
      assetId: asset.id,
      slug,
    },
    { skip: !slug },
  );

  if (
    !slug ||
    error?.graphQLErrors[0].extensions?.code === 'ASSET_PAGE_NOT_FOUND'
  ) {
    return <Navigate to={fallbackTo} />;
  }

  if (error) {
    return (
      <EmptyStateContainer>
        Something went wrong, please try again later
      </EmptyStateContainer>
    );
  }

  if (loading || !data) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }

  return (
    <ContentCMS content={data.assetPage.content} onAnalyticsTrack={track} />
  );
};
