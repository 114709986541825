import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { useAuthUser } from '@hedgehog/browser/investors/account/data-access';
import {
  useOrder,
  useOrderConfirm,
} from '@hedgehog/browser/investors/order/data-access';
import { useAnalyticsPage } from '@hedgehog/browser/investors/shared/analytics';
import { SecondaryButton } from '@hedgehog/ui/buttons';
import { EmptyStateContainer, LoadingContainer } from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';

import { OrderPaymentInstructionsContent } from '../components';

export const RECENTLY_SIGNED_LOCALSTORAGE_KEY =
  'RECENTLY_SIGNED_LOCALSTORAGE_KEY';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${({ theme }): string => theme.spacing.large};
  height: 100%;
`;

export const OrderPaymentInstructionsPage = (): JSX.Element => {
  useAnalyticsPage('Invest', 'Payment Instructions');
  const { orderId: paramsOrderId } = useParams<{ orderId: string }>();
  const [searchParams] = useSearchParams();
  const user = useAuthUser();
  const orderId = paramsOrderId || searchParams.get('orderId') || '';
  const { data, loading, error } = useOrder(orderId);
  const [confirmTransfer, { loading: confirmTransferLoading }] =
    useOrderConfirm();

  const handleClick = async (): Promise<void> => {
    await confirmTransfer({ variables: { id: orderId } });
  };

  /**
   * DocuSign Subscription Agreement leftovers (?)
   */
  useEffect(() => {
    // Store the docusign params in local storage
    const docusignEvent = searchParams.get('docusignEvent');
    const hedgehogProcess = searchParams.get('hedgehogProcess');
    const recentlySignedOrderId = searchParams.get('orderId');

    if (
      recentlySignedOrderId &&
      docusignEvent === 'completed' &&
      hedgehogProcess === 'SUBSCRIPTION_AGREEMENT'
    ) {
      const storedRecentlySignedOrders = window.sessionStorage.getItem(
        RECENTLY_SIGNED_LOCALSTORAGE_KEY,
      );
      const recentlySignedOrders = storedRecentlySignedOrders
        ? JSON.parse(storedRecentlySignedOrders)
        : [];

      if (recentlySignedOrders.indexOf(recentlySignedOrderId) === -1) {
        recentlySignedOrders.push(recentlySignedOrderId);
      }

      window.sessionStorage.setItem(
        RECENTLY_SIGNED_LOCALSTORAGE_KEY,
        JSON.stringify(recentlySignedOrders),
      );
    }
  });

  if (loading) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }

  if (error) {
    return (
      <EmptyStateContainer>
        Something went wrong, please try again later.
      </EmptyStateContainer>
    );
  }

  if (!user || !data?.order) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }

  return (
    <Container>
      <OrderPaymentInstructionsContent
        bankDetails={data?.order?.bankDetails}
        orderCurrency={
          data?.order?.asset?.currentRound?.roundClass?.currency || 'USD'
        }
        orderAmount={data?.order?.amount}
        userCountry={user?.country}
      />
      <SecondaryButton
        fluid
        loading={confirmTransferLoading}
        onClick={(): Promise<void> => handleClick()}
      >
        Confirm transfer
      </SecondaryButton>
    </Container>
  );
};
