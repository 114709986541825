import styled from 'styled-components';

import { styles } from '@hedgehog/ui/buttons';
import { Heading3, Paragraph } from '@hedgehog/ui/typography';
import { StandardProps } from '@hedgehog/ui/utils';

import {
  LinkBlock,
  LinkBlockAnchorOrRouterProps,
} from '../link-block/link-block.component';

const ListItemWrapper = styled(LinkBlock)<{ $hoverColor?: string }>`
  flex-flow: column nowrap;
  align-items: stretch;
  padding: 1rem;
  border-radius: 1rem;

  &.unread {
    background-color: ${({ theme }): string => {
      return theme.colors.secondary;
    }};
    color: white;
  }

  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }

  &:hover,
  &:focus {
    ${styles.hoverAppearance}
  }
`;

const Thumbnail = styled.img`
  border-radius: 0.75rem;
  object-fit: cover;
  background-color: rgba(0, 0, 0, 0.23);
  width: 100%;
  min-height: 10rem;
  max-height: 10rem;
`;

const Title = styled(Heading3)`
  text-align: left;
  overflow: hidden;
`;

const Description = styled(Paragraph)`
  overflow: hidden;
`;

export type AnnouncementListItemBaseProps = StandardProps<{
  src?: string;
  unread?: boolean;
  title?: string;
  description?: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}>;

export type AnnouncementListItemProps = AnnouncementListItemBaseProps &
  LinkBlockAnchorOrRouterProps;

export const AnnouncementListItem = ({
  className,
  src,
  title,
  description,
  unread = false,
  ...linkProps
}: AnnouncementListItemProps): JSX.Element => {
  const classNames = [className];
  if (unread) {
    classNames.push('unread');
  }
  return (
    <ListItemWrapper
      className={classNames.join(' ')}
      data-testid="announcement-list-item"
      {...linkProps}
    >
      {src && <Thumbnail src={src} />}
      {title && <Title>{title}</Title>}
      {description && <Description>{description}</Description>}
    </ListItemWrapper>
  );
};
