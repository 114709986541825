import styled from 'styled-components';

import { useContractIssuances } from '@hedgehog/browser/investors/account-abstraction/data-access';
import { useOrders } from '@hedgehog/browser/investors/order/data-access';
import { OrderStatusBadge } from '@hedgehog/browser/shared/order/feature-order';
import { OrderStatus } from '@hedgehog/data-access/graphql';
import { singularOrPlural } from '@hedgehog/shared/utils/content';
import { AssetCard } from '@hedgehog/ui/assets';
import { LinkButton } from '@hedgehog/ui/buttons';
import { EmptyStateContainer } from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';
import { Heading, Paragraph } from '@hedgehog/ui/typography';
import { StandardProps } from '@hedgehog/ui/utils';
import { screens } from '@hedgehog/utils/sizes';

const PortfolioAssetsLayout = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(19.375rem, 1fr));

  ${AssetCard} {
    box-sizing: border-box;
    flex: 1;
  }

  @media only screen and (min-width: ${screens.medium}px) {
    gap: 1.5rem;
  }
`;

export type PortfolioAssetsProps = StandardProps<any, never>;

export const PortfolioAssets = styled(
  ({ className }: PortfolioAssetsProps): JSX.Element => {
    const { data, error: ordersError, loading: ordersLoading } = useOrders();
    const {
      data: issuances,
      error: issuancesError,
      loading: issuancesLoading,
    } = useContractIssuances();

    if (ordersLoading) {
      return <Loader />;
    }

    if (ordersError) {
      return (
        <EmptyStateContainer>
          {`${ordersError || issuancesError}`}
        </EmptyStateContainer>
      );
    }

    const { orders } = data!;
    return (
      <PortfolioAssetsLayout className={className}>
        {orders.map(
          ({
            status: orderStatus,
            asset: {
              title: assetName,
              themes: assetThemes,
              thumbnails: assetThumbnails,
              isTenantToken,
            },
            quantity: orderQty,
            id: orderId,
            amount: orderAmount,
            transferConfirmed: orderTransferConfirmed,
          }) => {
            const portfolioOrderUrl = `?orderId=${orderId}#order`;
            const tokensAmount =
              orderStatus === OrderStatus.OPEN
                ? orderAmount
                : issuancesLoading
                ? 0
                : (issuances || []).reduce(
                    (sum, { amount }) => sum + amount,
                    0,
                  );

            const tokensNode =
              !issuancesLoading &&
              `${tokensAmount} ${singularOrPlural(
                tokensAmount,
                'Token',
                'Tokens',
              )}`;

            if (isTenantToken)
              return (
                <AssetCard
                  key={orderId}
                  type="compact"
                  partners={[
                    {
                      id: 'uno',
                      name: 'UNO',
                      src: 'https://assets.hedgehog-invest.com/partners/uno/logotype.svg',
                    },
                  ]}
                  title={assetName || ''}
                  caption={
                    orderStatus === OrderStatus.OPEN ? (
                      <>
                        <Paragraph small color="grey400">
                          Available to you
                        </Paragraph>
                        <Heading level="h7">{tokensNode}</Heading>
                      </>
                    ) : (
                      <Paragraph small color="grey400">
                        {tokensNode}
                      </Paragraph>
                    )
                  }
                  action={
                    <LinkButton to={portfolioOrderUrl} small>
                      View
                    </LinkButton>
                  }
                  thumbnails={assetThumbnails.map((t) => t.src)}
                  categories={assetThemes?.map((t) => t.category)}
                  to={portfolioOrderUrl}
                />
              );

            return (
              <AssetCard
                key={orderId}
                type="compact"
                title={assetName || ''}
                caption={
                  <OrderStatusBadge
                    status={orderStatus}
                    quantity={orderQty}
                    transferConfirmed={orderTransferConfirmed || false}
                  />
                }
                action={
                  <LinkButton to={portfolioOrderUrl} small>
                    View
                  </LinkButton>
                }
                thumbnails={assetThumbnails.map((t) => t.src)}
                categories={assetThemes?.map((t) => t.category)}
                to={portfolioOrderUrl}
              />
            );
          },
        )}
      </PortfolioAssetsLayout>
    );
  },
)``;
