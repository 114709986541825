import { useNavigate } from 'react-router-dom';
import styled, { CSSObject } from 'styled-components';

import {
  useAnalyticsPage,
  useAnalyticsTrack,
} from '@hedgehog/browser/investors/shared/analytics';
import { useNextStep } from '@hedgehog/browser/investors/workflows/onboarding';
import { useCreateDeclaration } from '@hedgehog/browser/shared/auth';
import {
  DeclarationType,
  GET_ONBOARDING_WORKFLOWS,
  WorkflowStep,
} from '@hedgehog/data-access/graphql';
import { SecondaryButton } from '@hedgehog/ui/buttons';
import { Header } from '@hedgehog/ui/headers';
import { Page } from '@hedgehog/ui/layouts';
import { parsePadding } from '@hedgehog/ui/themes';
import { Heading, Paragraph } from '@hedgehog/ui/typography';

const Controls = styled.div`
  width: 100%;
  box-sizing: border-box;
  ${({ theme }): CSSObject => ({
    padding: parsePadding({ x: theme.spacing.xlarge, y: theme.spacing.xlarge }),
    paddingTop: theme.spacing.xsmall,
    background: theme.colors.white,
  })};

  & > * {
    margin: 0 auto;
    max-width: 59rem;
  }
`;

const StyledPage = styled(Page)`
  padding-bottom: 0;
`;

export const NDAPage = (): JSX.Element => {
  const navigate = useNavigate();

  // Analytics
  useAnalyticsPage('Authentication', 'Jurisdiction');
  const track = useAnalyticsTrack();

  const [createDeclaration, { loading }] = useCreateDeclaration();
  const { to } = useNextStep(WorkflowStep.oakglen_nda);

  const handleAccept = async (): Promise<void> => {
    if (loading) return;

    track('NDAAcceptButton', 'Clicked');

    await createDeclaration({
      variables: { type: DeclarationType.NDA },
      refetchQueries: [{ query: GET_ONBOARDING_WORKFLOWS }],
    });
    navigate(to || '/explore', { replace: true });
  };

  return (
    <StyledPage>
      <Header>
        <Heading level="h5">Non-Disclosure Agreement</Heading>
      </Header>

      <Paragraph>
        This platform, its content, and any information provided through it
        (collectively referred to as the "Platform") contain confidential and
        proprietary information that is the exclusive property of Oakglen Group
        or its affiliates ("Company"). By accessing or using the Platform, you
        acknowledge and agree that the information, data, documents, images, and
        other materials contained on or accessible through the Platform
        (collectively referred to as "Confidential Information") are
        confidential and proprietary to the Company. You may only use
        Confidential Information for the purpose for which it was made available
        to you through the Platform. Any unauthorized use or disclosure of
        Confidential Information is strictly prohibited. By checking the below
        box, you agree to be legally bound by the terms of the non-disclosure
        agreement provided and further agree not to disclose, distribute, copy,
        or otherwise make available any Confidential Information to any third
        party without the prior written consent of the Company. This obligation
        of non-disclosure shall survive the termination of your use of the
        Platform.
      </Paragraph>

      <Controls>
        <SecondaryButton fluid loading={loading} onClick={handleAccept}>
          Accept
        </SecondaryButton>
      </Controls>
    </StyledPage>
  );
};
