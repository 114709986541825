import { useState } from 'react';
import styled, { CSSProp } from 'styled-components';

import { ActionCard } from '@hedgehog/ui/cards';
import { ChevronDownIcon, ChevronUpIcon } from '@hedgehog/ui/icons';
import { Avatar, AvatarProps } from '@hedgehog/ui/icons';
import { BottomSheet, useModal } from '@hedgehog/ui/modals';
import { Heading, Title } from '@hedgehog/ui/typography';
import { depth } from '@hedgehog/utils/sizes';

import { List } from '../List/List';
import { ListItem } from '../ListItem/ListItem';

import { Step } from './Step';

const StepTrackerContainer = styled.div`
  padding: 1.5rem 1rem;
  ${({ theme }): CSSProp => ({ backgroundColor: theme.colors.grey100 })};
`;

const StyledTitle = styled(Title)`
  display: flex;
  align-items: center;
`;

const StepTrackerHeader = styled.div`
  border-radius: 100px;
  ${({ theme }): CSSProp => ({
    color: theme.colors.white,
    backgroundColor: theme.colors.black,
  })};
  padding: 4px 10px 4px 4px;
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const StepListItem = styled(ListItem)`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 0.5rem;
`;

const ChevronWrapper = styled.div`
  height: 8px;
  width: 12px;
  ${({ theme }): CSSProp => ({ color: theme.colors.grey500 })};
  margin-top: 5px;
`;

const StepTrackerHeaderContainer = styled.div`
  display: inline-block;
  z-index: ${depth.modal};
`;

interface StepTrackerProps {
  steps: Step[];
  collapsable?: boolean;
  className?: string;
}

const getIcon = (step: Step, i: number): AvatarProps['icon'] => {
  switch (step.status) {
    case 'token':
      return 'token';
    case 'waiting':
      return 'clock';
    case 'warning':
      return 'alert-circle';
    case 'done':
      return 'check-circle';
    default:
      return `${i}`;
  }
};

export const StepTracker = ({
  steps,
  collapsable = false,
  className,
}: StepTrackerProps): JSX.Element => {
  const currentStep = steps.findIndex((step) => step.current);
  const [trackerOpen, setTrackerOpen] = useState(false);

  const StepTrackerComponent = (
    <StepTrackerContainer className={className}>
      <List>
        {steps.map((step, i) => {
          const textColor = step.current ? 'black' : 'grey300';
          return (
            <StepListItem flex key={step.content} spacingSize="xs">
              <Avatar
                size="s"
                icon={getIcon(step, i)}
                color="white"
                backgroundColor="secondary"
              />
              <Heading color={textColor} level="h7">
                {step.content}
              </Heading>
            </StepListItem>
          );
        })}
      </List>
    </StepTrackerContainer>
  );

  const { openAndResolveWithSubmitValue: openTracker } = useModal(() => (
    <BottomSheet>{StepTrackerComponent}</BottomSheet>
  ));

  const handleClick = async (): Promise<void> => {
    setTrackerOpen(true);
    await openTracker()
      .catch(() => void 0)
      .finally(() => setTrackerOpen(false));
  };

  return (
    <>
      {collapsable && (
        <StepTrackerHeaderContainer>
          <StepTrackerHeader>
            <Avatar
              icon={getIcon(steps[currentStep], currentStep)}
              size="s"
              backgroundColor="grey500"
            />
            <StyledTitle>{steps[currentStep]?.title}</StyledTitle>
            <ChevronWrapper onClick={handleClick}>
              {trackerOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </ChevronWrapper>
          </StepTrackerHeader>
        </StepTrackerHeaderContainer>
      )}
      {!collapsable && StepTrackerComponent}
    </>
  );
};

export default StepTracker;
