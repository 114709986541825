import { ReactNode } from 'react';
import { Link, useMatch } from 'react-router-dom';

import { Tab } from '@hedgehog/ui/tabs';

export interface AssetTabProps {
  to: string;
  state?: unknown;
  children?: ReactNode | ReactNode[];
  className?: string;
}

export const AssetTabLink = ({
  to,
  state,
  children,
  className,
}: AssetTabProps): JSX.Element => {
  const pathMatch = useMatch(`assets/:slug/${to}`);
  const isActive = !!pathMatch;

  return (
    <Link to={`${to}#body`} state={state} className={className} replace={true}>
      <Tab active={isActive}>{children}</Tab>
    </Link>
  );
};
