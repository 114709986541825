import { Route, Navigate } from 'react-router-dom';

import { AssetsPage } from '@hedgehog/browser/investors/explore/feature-assets';
import { PartnersPage } from '@hedgehog/browser/investors/explore/feature-partners';
import { ExploreLayout } from '@hedgehog/browser/investors/explore/shell';
import { RestoreScroll } from '@hedgehog/ui/routing';

export const exploreRoutes = (
  <Route
    path="explore"
    element={
      <RestoreScroll>
        <ExploreLayout />
      </RestoreScroll>
    }
  >
    <Route index element={<AssetsPage />} />
    <Route path="partners" element={<PartnersPage />} />
    <Route path="*" element={<Navigate to="/explore" />} />
  </Route>
);
