import { content, InvestorFullName } from './content';

/**
 * Replace spaces with dash and lowercase the input
 */
export function encodePath(path?: string): string {
  return (path || '').replace(/ /gi, '-').toLowerCase();
}

/**
 * Replaces dashes with spaces and then uppercase first letters of each word
 */
export function decodePath(path?: string): string {
  return (path || '')
    .replace(/[-]/gi, ' ')
    .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
}

export function isKnownContent(text: string): text is InvestorFullName {
  return !!content[text as InvestorFullName];
}
