import {
  useOnboardingStatusPolling,
  useUser,
} from '@hedgehog/browser/investors/kyc/data-access';
import { KycCheckStatus } from '@hedgehog/data-access/graphql';
import { Heading, Paragraph } from '@hedgehog/ui/typography';

export const OnboardingPrompt = (): JSX.Element => {
  const { data: userData } = useUser();
  const { data: onboardingData } = useOnboardingStatusPolling();

  const isIdentityCheckStale =
    onboardingData?.onboardingProgress?.status.identityCheck ===
    KycCheckStatus.stale;

  if (isIdentityCheckStale)
    return (
      <>
        <Heading level="h4">
          {userData?.user?.firstName}, we need you to confirm/update some of
          your information.
        </Heading>
        <Paragraph small color="grey400">
          12 months have passed and we need to verify your personal information
        </Paragraph>
      </>
    );

  return (
    <Paragraph small color="grey400">
      We need to verify the identity and residency of all users before they can
      invest.
    </Paragraph>
  );
};
