import React from 'react';
import ReactDOM from 'react-dom';
import styled, { CSSProp } from 'styled-components';

import {
  AssetRoundClassStatus,
  FeatureFlagNames,
  OrderStatus,
} from '@hedgehog/data-access/graphql';
import { useFeatureToggle, useMediaQuery } from '@hedgehog/data-access/hooks';
import { OutlineLinkButton, PrimaryButton } from '@hedgehog/ui/buttons';
import { CheckCircleIcon } from '@hedgehog/ui/icons';
import { Loader } from '@hedgehog/ui/loaders';
import { Heading, Label } from '@hedgehog/ui/typography';
import { currencies } from '@hedgehog/utils/formats';
import { screens } from '@hedgehog/utils/sizes';

import { AssetFeedback } from '../asset-feedback/asset-feedback.component';

import { useUserOrderForRound } from './asset-invest-controller.hook';

const ClosedIcon = styled.div`
  ${({ theme }): CSSProp => ({ color: theme.colors.success })}
  height: 1.5rem;
  width: 1.5rem;
`;

const RoundClosed = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const ButtonNoWrap = styled.button`
  white-space: nowrap;
`;

const AlreadyInvestedButton = styled(OutlineLinkButton)`
  white-space: nowrap;
`;

const Controller = styled.div<{ status: AssetRoundClassStatus }>`
  @media only screen and (min-width: ${screens.medium}px) {
    margin: 0 auto;
    width: 100%;
    max-width: 21rem;
  }
`;

const Container = styled.div`
  padding: 0 1.5rem 1.5rem;
  background: transparent;
  box-shadow: none;

  @media only screen and (min-width: ${screens.medium}px) {
    padding: 0;
    border-radius: 0;
  }
`;

const EstimateWrapper = styled.div`
  border-radius: 1rem;
  ${({ theme }): CSSProp => ({ backgroundColor: theme.colors.grey100 })}
  width: 100%;

  margin-bottom: 1.5rem;

  display: none;
  visibility: hidden;
  @media only screen and (min-width: ${screens.medium}px) {
    display: block;
    visibility: visible;
  }
`;

const RoundLoading = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

const RegisterInterestButton = styled(PrimaryButton)`
  margin: -0.5rem;
  width: calc(100% + 1rem);
`;

const OrderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  ${({ theme }): CSSProp => ({ backgroundColor: theme.colors.white })}
  box-sizing: border-box;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 0.75rem;
  box-shadow: 0 6px 40px 0 rgba(0, 0, 0, 0.15);

  > *:first-child:not(${RoundLoading}):not(${RegisterInterestButton})) {
    margin-left: 0.5rem;
  }

  > *:last-child:not(${RoundLoading}):not(${RegisterInterestButton}) {
    flex: 0 0 0;
  }
`;

const AssetPriceDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

interface AssetInvestControllerProps {
  slug: string;
  className?: string;
  assetRoundId?: string;
  onRegisterInterestClick?: () => void;
  onInvestClick?: () => void;
  disabled?: boolean;
  minimumInvestment?: number;
  status?: AssetRoundClassStatus;
  openDate?: string | null;
  children?: React.ReactNode | React.ReactNode[];
  underlyingAssetCount?: number;
  isTenantToken?: boolean;
  currency?: currencies.Currency;
  loading?: boolean;
}

// This component renders a different interface for the user to interact with an asset according to its status,
// eg. View, Invest, Like
const AssetAction = ({
  slug,
  assetRoundId,
  onInvestClick,
  onRegisterInterestClick,
  disabled = false,
  minimumInvestment = 0,
  status,
  loading,
  isTenantToken = false,
  currency = 'USD',
}: AssetInvestControllerProps): JSX.Element => {
  const featureToggle = useFeatureToggle();
  const startKYC = featureToggle.hasFeature(FeatureFlagNames.start_kyc);
  const { data: orderData, loading: orderLoading } = useUserOrderForRound(
    assetRoundId || '',
  );

  const isLoading = loading || orderLoading;

  switch (status) {
    case AssetRoundClassStatus.LIVE:
      if (loading || !orderData) {
        return (
          <RoundLoading>
            <Loader />
          </RoundLoading>
        );
      }
      if (isTenantToken && orderData?.order?.status === OrderStatus.OPEN) {
        return (
          <>
            <AssetPriceDetails>
              <Label color="grey300">Available to claim</Label>
              <Heading level="h6" color="black">
                {orderData?.order.quantity}
              </Heading>
            </AssetPriceDetails>
            <ButtonNoWrap
              as={PrimaryButton}
              onClick={onInvestClick}
              type="button"
              disabled={disabled || !startKYC}
              loading={isLoading}
            >
              Claim
            </ButtonNoWrap>
          </>
        );
      }
      if (orderData?.order && orderData?.order.status !== OrderStatus.OPEN) {
        return (
          <>
            <RoundClosed>
              <Label color="grey300">You&apos;ve invested in this asset</Label>
            </RoundClosed>
            <AlreadyInvestedButton
              to={`/portfolio?orderId=${orderData.order.id}#order`}
              loading={isLoading}
            >
              View
            </AlreadyInvestedButton>
          </>
        );
      }
      return (
        <>
          <AssetPriceDetails>
            <Label color="grey300">Minimum investment</Label>
            <Heading level="h6">
              {currencies.formatMoney(minimumInvestment, {
                currency,
              })}
            </Heading>
          </AssetPriceDetails>
          <ButtonNoWrap
            as={PrimaryButton}
            onClick={onInvestClick}
            type="button"
            loading={isLoading}
            disabled={disabled || !startKYC}
          >
            Invest
          </ButtonNoWrap>
        </>
      );
    case AssetRoundClassStatus.FUNDED:
    case AssetRoundClassStatus.ONGOING:
    case AssetRoundClassStatus.PAID_OUT:
      return (
        <>
          <RoundClosed>
            <ClosedIcon>{<CheckCircleIcon />}</ClosedIcon>
            <Label color="grey300">Investment is closed</Label>
          </RoundClosed>
          <ButtonNoWrap
            as={PrimaryButton}
            disabled
            type="button"
            loading={isLoading}
          >
            Invest
          </ButtonNoWrap>
        </>
      );
    case AssetRoundClassStatus.REGISTER_INTEREST:
      return (
        <RegisterInterestButton
          fluid
          onClick={onRegisterInterestClick}
          loading={isLoading}
          disabled={disabled}
        >
          Register interest
        </RegisterInterestButton>
      );
    case AssetRoundClassStatus.COMING_SOON:
    default:
      return <AssetFeedback slug={slug} size="small" />;
  }
};

const TakeActionOnAsset = (props: AssetInvestControllerProps): JSX.Element => (
  <Container>
    {props.children && <EstimateWrapper>{props.children}</EstimateWrapper>}
    <OrderWrapper>
      <AssetAction {...props} />
    </OrderWrapper>
  </Container>
);

// This component wraps the TakeActionOnAsset component above and conditionally renders it to a portal on mobile.
export const AssetInvestController = (
  props: AssetInvestControllerProps,
): JSX.Element => {
  const { status, className } = props;
  const { matches: isDesktopScreen } = useMediaQuery(
    `(min-width: ${screens.medium}px)`,
  );

  const footer = (
    <Controller
      className={className}
      status={status || AssetRoundClassStatus.COMING_SOON}
    >
      <TakeActionOnAsset {...props} />
    </Controller>
  );

  return isDesktopScreen
    ? footer
    : ReactDOM.createPortal(
        footer,
        document.getElementById('layout-footer') as Element,
      );
};
