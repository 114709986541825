import { Location } from 'history';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import {
  useAnalyticsPage,
  useAnalyticsTrack,
} from '@hedgehog/browser/investors/shared/analytics';
import { useAuth } from '@hedgehog/data-access/contexts';
import { useQueryParams } from '@hedgehog/data-access/hooks';
import { useEnvironment } from '@hedgehog/ui/environment';
import { HeaderWithBack } from '@hedgehog/ui/headers';
import { Heading } from '@hedgehog/ui/typography';
import { spacing } from '@hedgehog/utils/sizes';

import {
  CognitoSocialLoginButton,
  AuthFormCardContainer,
  FormLineBreak,
} from '../../components';
import { SignInForm, SignInFormData } from '../../containers';
import { usePartnerSetting } from '../../hooks';
import { usePartner } from '../../providers';

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
`;

const MethodsWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: ${spacing.md};
`;

interface LocationStateData {
  from: string;
  login?: string;
}

export const LoginPage = (): JSX.Element => {
  const {
    cognito: { userPoolUrl, clientId },
    baseUrl,
  } = useEnvironment();
  const queryParams = useQueryParams();
  const track = useAnalyticsTrack();
  useAnalyticsPage('Authentication', 'Login');
  const redirectParam = queryParams.get('redirectToUrl');

  const auth = useAuth();
  const navigate = useNavigate();
  const location: Location = useLocation();
  const locationState: LocationStateData = location?.state as LocationStateData;
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { partner } = usePartner();
  const { enabled: isAppleSSOEnabled = true, loading: isAppleSSOLoading } =
    usePartnerSetting('apple_sso');

  const handleSubmit = async (
    form: Required<SignInFormData>,
  ): Promise<void> => {
    setLoading(true);
    try {
      await auth.signin({
        email: form.email,
        password: form.password,
      });

      track('Account', 'Login Success - Web Client', {
        source: 'investor-web',
      });

      // Navigate the user to the explore page or the URL embedded in the redirect query param.
      // Use { replace: true } so we don't create another entry in the history stack for the login page.
      const url = redirectParam || '/explore';
      navigate(url, { replace: true });
    } catch (err: unknown) {
      if (err instanceof Error) {
        setError(
          err?.message || 'Something went wrong, please try again later.',
        );
        track('Account', 'Login Failed - Web Client', {
          reason: err?.message || 'Unknown',
        });
      } else {
        setError('Something went wrong, please try again later.');
        track('Account', 'Login Failed - Web Client', {
          reason: 'Unknown',
        });
      }
    }
    setLoading(false);
  };

  return (
    <>
      <HeaderWithBack to={partner ? `..${location.search}` : '/'}>
        <Heading level="h5" loading={isAppleSSOLoading}>
          Sign in to {partner ? partner.name : 'Hedgehog'}
        </Heading>
      </HeaderWithBack>

      <Container>
        {queryParams.get('source') === 'forgot-password' && (
          <AuthFormCardContainer
            outlined
            icon="check-circle"
            title="Password changed"
            description="You can now sign in below"
            iconColour="success"
          />
        )}
        <SignInForm
          onSubmit={handleSubmit}
          defaultEmail={locationState?.login}
          loading={loading}
          error={error}
          forgotPasswordPath={
            partner ? `/${partner.slug}/forgot-password` : '/forgot-password'
          }
        />
        <FormLineBreak>or</FormLineBreak>
        <MethodsWrapper>
          {isAppleSSOEnabled && (
            <CognitoSocialLoginButton
              loading={isAppleSSOLoading}
              text="Sign in with Apple"
              icon="apple"
              onClick={(): void => track('LoginWithAppleButton', 'Clicked')}
              loginProvider="SignInWithApple"
              cognitoClientId={clientId || ''}
              cognitoUrl={userPoolUrl || ''}
              redirectUrl={`${baseUrl}/auth/signin/callback`}
            />
          )}
          <CognitoSocialLoginButton
            loading={isAppleSSOLoading}
            text="Sign in with Google"
            icon="google"
            onClick={(): void => track('LoginWithGoogleButton', 'Clicked')}
            loginProvider="Google"
            cognitoClientId={clientId || ''}
            cognitoUrl={userPoolUrl || ''}
            redirectUrl={`${baseUrl}/auth/signin/callback`}
          />
        </MethodsWrapper>
      </Container>
    </>
  );
};
