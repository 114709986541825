import { QueryHookOptions, QueryResult } from '@apollo/client';

import {
  useAuthenticatedLazyQuery,
  useAuthenticatedQuery,
} from '@hedgehog/data-access/contexts';
import {
  GET_USER,
  GetUserQuery as GetUser,
} from '@hedgehog/data-access/graphql';

export const useUser = (options: QueryHookOptions = {}): QueryResult<GetUser> =>
  useAuthenticatedQuery<GetUser>(GET_USER, undefined, options);

export const useLazyUser = (options: QueryHookOptions = {}) => {
  const [getUser, { data, loading, error }] =
    useAuthenticatedLazyQuery<GetUser>(GET_USER, options);

  return [
    getUser,
    {
      data,
      loading,
      error,
    },
  ];
};
