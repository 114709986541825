import { FormEvent, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { useAuthUser } from '@hedgehog/browser/investors/account/data-access';
import { PrimaryButton } from '@hedgehog/ui/buttons';
import { Page } from '@hedgehog/ui/layouts';
import { Heading } from '@hedgehog/ui/typography';

import { PartnerAuthPageLogo, SignupTermsCheckbox } from '../../components';
import { SignupFormData } from '../../types/signup-state.interface';
import { termsUrl, privacyPolicyUrl } from '../content';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 25%;
`;

const FormSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export type StateSearchParams = {
  code: string;
  email: string;
};

export const PartnerReferralSocialCallbackPage = (): JSX.Element => {
  const user = useAuthUser();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [accepted, setAccepted] = useState<boolean>(false);

  const handleSubmit = async (
    event: FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    event.preventDefault();
    const country = user?.country;
    const variables: SignupFormData = {
      isPartnerClient: true,
      invitationCode: searchParams.get('code') || '',
      country: country || 'USA',
    };
    navigate('/signup/jurisdiction', {
      state: {
        background: location,
        form: variables,
        isSSO: true,
        emailAddress: searchParams.get('email'),
      },
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormSection>
        <Heading level="h4">Welcome, {user?.firstName}</Heading>
        <Heading level="h4" color={'grey400'}>
          Please accept the terms to proceed.
        </Heading>
      </FormSection>
      <FormSection>
        <SignupTermsCheckbox
          name="accepted"
          value={accepted}
          onChange={setAccepted}
          termsUrl={user?.partner?.resources?.termsAgreementsUrl ?? termsUrl}
          privacyPolicyUrl={
            user?.partner?.resources?.privacyPolicyUrl ?? privacyPolicyUrl
          }
        />
      </FormSection>
      <PrimaryButton type="submit" disabled={!accepted}>
        Proceed
      </PrimaryButton>
    </Form>
  );
};
