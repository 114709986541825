import { QueryResult } from '@apollo/client';

import { useAuthenticatedQuery } from '@hedgehog/data-access/contexts';
import {
  GetAssessmentQuery as GetAssessment,
  GET_ASSESSMENT,
} from '@hedgehog/data-access/graphql';

export const useAssessment = (): QueryResult<GetAssessment> =>
  useAuthenticatedQuery<GetAssessment>(GET_ASSESSMENT, undefined, {
    fetchPolicy: 'no-cache',
  });
