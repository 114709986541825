import { addDays, addMinutes } from 'date-fns';

export function remainingTimeInMiliseconds(
  interval: number,
  createdAt: Date,
  isProduction = true,
): number {
  const cooldownsAt = isProduction
    ? addDays(createdAt, interval)
    : addMinutes(createdAt, interval);
  return Math.max(0, cooldownsAt.getTime() - Date.now());
}

// TODO: We can remove this function once all partners are using the new post signup workflows
export const legacyShouldUserCoolDown = (
  countryCode?: string,
  createdAt?: string,
  isProduction = true,
): boolean | null => {
  if (!createdAt) return null;
  if (countryCode !== 'GBR') return false;

  const now = new Date(createdAt);
  const cooldownTimeInDaysOrMinutes = 1;
  return (
    remainingTimeInMiliseconds(cooldownTimeInDaysOrMinutes, now, isProduction) >
    0
  );
};

export const shouldUserCoolDown = ({
  userSignedUpAt,
  isProduction,
}: {
  userSignedUpAt: string;
  isProduction: boolean;
}): boolean | null => {
  const now = new Date(userSignedUpAt);
  const cooldownTimeInDaysOrMinutes = 1;
  return (
    remainingTimeInMiliseconds(cooldownTimeInDaysOrMinutes, now, isProduction) >
    0
  );
};
