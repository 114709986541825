import { AppleIcon, Logotype } from '@hedgehog/ui/icons';

import { AppleIconWrapper, DownloadButton, StubHeader } from '../styles/styles';

export const Header = (): JSX.Element => (
  <StubHeader>
    <Logotype />{' '}
    <DownloadButton
      small
      href="https://apps.apple.com/gb/app/hedgehog-invest/id1643289634"
    >
      Download the app on iOS{' '}
      <AppleIconWrapper>
        <AppleIcon />
      </AppleIconWrapper>
    </DownloadButton>
  </StubHeader>
);

export default Header;
