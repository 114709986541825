import { useAuthenticatedQuery } from '@hedgehog/data-access/contexts';
import {
  GetUserQuery as GetUser,
  GET_USER,
} from '@hedgehog/data-access/graphql';

export const usePersonalDetails = (): {
  data?: GetUser;
  error?: Error;
  loading: boolean;
} => {
  return useAuthenticatedQuery(GET_USER);
};
