import { QueryResult } from '@apollo/client';

import { GetOnboardingStatusQuery as GetOnboardingStatus } from '@hedgehog/data-access/graphql';

import { useOnboardingStatus } from './use-onboarding-status.hook';

export const useOnboardingStatusPolling =
  (): QueryResult<GetOnboardingStatus> =>
    useOnboardingStatus({
      fetchPolicy: 'network-only',
      pollInterval: 10_000,
    });
