import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { useAnalyticsPage } from '@hedgehog/browser/investors/shared/analytics';
import { HeaderWithBack } from '@hedgehog/ui/headers';
import { Image } from '@hedgehog/ui/image';
import { Heading, Paragraph } from '@hedgehog/ui/typography';

const SuccessContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1.5rem;
`;

const SuccessWriting = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const ConfirmEmailPage = (): JSX.Element => {
  useAnalyticsPage('Authentication', 'Confirm Email');
  const { state: { email } = {} } = useLocation();

  return (
    <>
      <HeaderWithBack>
        <Heading level="h5">Check your inbox</Heading>
      </HeaderWithBack>

      <SuccessContainer>
        <Image
          width={320}
          height={200}
          src="https://assets.hedgehog-invest.com/send.png"
        />

        <SuccessWriting>
          <Paragraph color="grey500">
            We&apos;ve sent you an email to:
          </Paragraph>
          <Paragraph color="black">
            <strong>{email}</strong>
          </Paragraph>
          <Paragraph color="grey500">
            Check your inbox and follow the link.
          </Paragraph>
          <Paragraph color="grey500">
            Can&apos;t find it? Check we haven&apos;t ended up in your spam
            folder.
          </Paragraph>
        </SuccessWriting>
      </SuccessContainer>
    </>
  );
};
