import { ReactElement, useEffect, useMemo } from 'react';
import styled, { CSSObject } from 'styled-components';

import { ContentBlockType } from '@hedgehog/data-access/graphql';
import { useAutoscrollControls } from '@hedgehog/ui/autoscroll';
import { SecondaryButton } from '@hedgehog/ui/buttons';
import { ContentCMS } from '@hedgehog/ui/cms';
import { LoadingContainer } from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';

import { useAssessmentQuiz } from '../../hooks';

const AccreditationContent = styled(ContentCMS)`
  ${({ theme }): CSSObject => ({
    color: theme.colors.grey500,
    '*:is(h5)': { color: theme.colors.black },
  })};
`;

const AccreditationButton = styled(SecondaryButton)`
  align-self: center;
`;

export interface AccreditationFlowProps {
  error?: ReactElement;
}

export const AccreditationFlow = ({
  error,
}: AccreditationFlowProps): JSX.Element => {
  const { scrollTop } = useAutoscrollControls();
  const {
    currentPage,
    pageCompleted,
    comments,
    answers,
    onAddCommentClick,
    setAnswer,
    setPageId,
    getNextPageId,
    onSubmit,
  } = useAssessmentQuiz();
  const content = useMemo(() => {
    if (!currentPage) return [];
    if (!error) return currentPage.content;

    // Stick the error message before the first question in the assessment.
    const firstQuestionIndex = currentPage.content.findIndex(
      (item) => item.type === ContentBlockType.Question,
    );
    return [
      ...currentPage.content.slice(0, firstQuestionIndex),
      error,
      ...currentPage.content.slice(firstQuestionIndex),
    ];
  }, [currentPage, error]);

  useEffect(() => {
    if (currentPage) {
      scrollTop(0);
    }
  }, [currentPage?.id]);

  if (!currentPage) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }

  const handleClick = (): void => {
    const nextPageId = getNextPageId();
    if (nextPageId) {
      setPageId(nextPageId);
      return;
    }

    // If there is no next page, the assessment is done so submit.
    if (onSubmit) {
      onSubmit();
    }
  };

  return (
    <>
      <AccreditationContent
        content={content}
        comments={comments}
        answers={answers}
        onQuestionAnswerClick={setAnswer}
        onQuestionCommentClick={onAddCommentClick}
      />
      <AccreditationButton
        fluid
        onClick={handleClick}
        disabled={!pageCompleted}
      >
        {currentPage.nextAssessmentPageId ? 'Next' : 'Done'}
      </AccreditationButton>
    </>
  );
};
