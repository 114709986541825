import {
  AccreditationPage,
  AssessmentPage,
  AssessmentRouterSettings,
} from '@hedgehog/browser/investors/kyc/feature-accreditation';
import { DeclarationPage } from '@hedgehog/browser/investors/kyc/feature-declaration';
import {
  PersonalInformationPage,
  TaxInformationPage,
} from '@hedgehog/browser/investors/kyc/feature-identity-check';
import { VerificationPage } from '@hedgehog/browser/investors/kyc/feature-verification';
import {
  StatusConfirmationPage,
  StatusPage,
} from '@hedgehog/browser/shared/auth';
import { SidebarBackgroundParams } from '@hedgehog/browser/shared/router';
import { GetUserQuery as GetUser } from '@hedgehog/data-access/graphql';

import { Layout } from './layout.component';
import { KycPage } from './pages/kyc.page';

const backdrop = {
  closable: false,
};

const defaultBackground = `https://assets.hedgehog-invest.com/pattern-72x72.png`;

export const createRoutes = (
  background:
    | ((user?: GetUser) => SidebarBackgroundParams)
    | string = defaultBackground,
) =>
  ({
    '#kyc': {
      backdrop,
      background,
      component: () => (
        <Layout>
          <KycPage />
        </Layout>
      ),
    },
    '#kyc/investor-type': {
      backdrop,
      background,
      component: StatusPage,
    },
    '#kyc/investor-type/confirm': {
      backdrop,
      background,
      component: StatusConfirmationPage,
      props: {
        // TODO: Remove in favour of workflow config
        redirectTo: '#kyc',
      },
    },
    '#kyc/accreditation': {
      backdrop,
      background,
      component: () => (
        <Layout>
          <AccreditationPage />
        </Layout>
      ),
    },
    '#kyc/assessment': {
      backdrop,
      background,
      component: () => (
        <AssessmentRouterSettings redirectTo="#kyc">
          <AssessmentPage />
        </AssessmentRouterSettings>
      ),
    },
    '#kyc/verification': {
      backdrop,
      background,
      component: () => (
        <Layout>
          <VerificationPage />
        </Layout>
      ),
    },
    '#kyc/identity-check/personal': {
      backdrop,
      background,
      component: () => (
        <Layout>
          <PersonalInformationPage />
        </Layout>
      ),
    },
    '#kyc/identity-check/tax': {
      backdrop,
      background,
      component: () => (
        <Layout>
          <TaxInformationPage />
        </Layout>
      ),
    },
    '#kyc/investor-confirmation': {
      backdrop,
      background,
      component: () => (
        <Layout>
          <DeclarationPage />
        </Layout>
      ),
    },
  } as const);
