import { ReactNode } from 'react';

import { OrderStatus } from '@hedgehog/data-access/graphql';
import { Paragraph, Heading } from '@hedgehog/ui/typography';

export interface OrderStatusNoteContentProps {
  status: OrderStatus;
  isTenantToken: boolean;
  defaultNote: ReactNode | ReactNode[];
  quantity?: number;
}

export const OrderStatusNoteContent = ({
  status,
  quantity,
  isTenantToken,
  defaultNote,
}: OrderStatusNoteContentProps): JSX.Element => {
  switch (status) {
    case OrderStatus.OPEN:
      return isTenantToken ? (
        <>
          <Paragraph>Available to you</Paragraph>
          <Heading level="h6">{quantity}</Heading>
        </>
      ) : (
        <>{defaultNote}</>
      );
    default:
      return <>{defaultNote}</>;
  }
};
