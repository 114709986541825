import { useSearchParams } from 'react-router-dom';

import { useHasCompletedWorkflows } from '@hedgehog/browser/investors/workflows/onboarding';
import {
  GetUserActionDataQuery as GetUserActionData,
  GetOnboardingStatusQuery,
  isKycOnly,
  KycCheckStatus,
} from '@hedgehog/data-access/graphql';

import { useGuardRegistry } from '../hooks';

type GetOnboardingStatus_onboardingProgress_status =
  GetOnboardingStatusQuery['onboardingProgress']['status'];

export const hasCompleted = (
  ...checks: (KycCheckStatus | undefined)[]
): boolean => checks.every((check) => check === 'approved');

// TODO: 🔍 KYC-only tech-debt - remove me - https://linear.app/hedgehog-invest/issue/HOG-2898/%F0%9F%92%A1-get-rid-of-all-the-oakglen-if-statements
// TODO: This is duplicating/diverging from our onboardingProgress logic for investors.
// At the moment the KYC flow diverges according to user location, asset type, partner type, etc.
// We need to make the onboardingProgress (or other) query customisable
// for KYC-only/tenant-onboarding/investor flows.
export const hasCompletedOnboarding = (
  userActionData?: GetUserActionData,
  onboardingProgress?: GetOnboardingStatus_onboardingProgress_status,
): boolean => {
  const {
    investorTypeDeclaration,
    identityCheckProgress,
    verificationProgress,
    taxFormProgress,
  } = userActionData || {};

  return (
    hasCompleted(
      onboardingProgress?.verification || verificationProgress?.status,
      onboardingProgress?.identityCheck || identityCheckProgress?.status,
      onboardingProgress?.taxForm || taxFormProgress?.status,
    ) && !!investorTypeDeclaration?.value
  );
};

export interface StandaloneKycGuardProps {
  priority: number;
}

// TODO: 🔍 KYC-only tech-debt - remove me - https://linear.app/hedgehog-invest/issue/HOG-2898/%F0%9F%92%A1-get-rid-of-all-the-oakglen-if-statements
export const StandaloneKycGuard = ({
  priority,
}: StandaloneKycGuardProps): JSX.Element => {
  const [searchParams] = useSearchParams();
  const { complete } = useHasCompletedWorkflows();
  useGuardRegistry({
    name: 'standalone-kyc',
    priority,
    validate: (data) => {
      const { partnerSettings } = data || {};
      if (partnerSettings && isKycOnly(partnerSettings) && !complete) {
        return {
          pathname: '/explore',
          hash: '#kyc',
          search: `?${searchParams.toString()}`,
        };
      }

      return null;
    },
  });

  return <></>;
};
