import { Route } from 'react-router-dom';

import { AccreditationPage } from './pages/accreditation.page';
import { AssessmentRetryPage } from './pages/assessment/assessment-retry.page';
import { AssessmentPage } from './pages/assessment/assessment.page';

const assessmentRoutes = (
  <Route path=":assessmentId">
    <Route index element={<AssessmentPage />} />
    <Route path=":assessmentPageId" element={<AssessmentPage />} />
  </Route>
);

/**
 * That part will be moved to shell library once generated.
 */
export const routes = (
  <Route>
    <Route path="assessment">
      <Route index element={<AssessmentPage />} />
      <Route path="retry" element={<AssessmentRetryPage />} />
      {assessmentRoutes}
    </Route>
    <Route path="accreditation">
      <Route index element={<AccreditationPage />} />
      {assessmentRoutes}
    </Route>
  </Route>
);
