import { differenceInYears } from 'date-fns';

import { ContractIssuance } from '@hedgehog/shared/blockchain/resident-token';

export interface EstimateLeaseRewardsInput {
  from: number | Date;
  to: number | Date;
  issuances: ContractIssuance[];
  recentRent: number;
}

const RENEW_LEASE_BONUS_RATIO_PER_MONTH = 0.03;
const RENEW_LEASE_BONUS_RATIO = RENEW_LEASE_BONUS_RATIO_PER_MONTH * 12;

export function calculateEstimationLeaseRewards({
  from: leaseFrom,
  to: leaseTo,
  recentRent,
  issuances,
}: EstimateLeaseRewardsInput): number {
  const { length: leaseRenewalsCount } = issuances.filter(
    ({ data: { type } }) => type === 'lease:renew',
  );
  const tokensForRenewalBasedOnRecentRent =
    recentRent * RENEW_LEASE_BONUS_RATIO;
  const remainingOpportunities =
    differenceInYears(leaseTo, leaseFrom) - leaseRenewalsCount;

  return tokensForRenewalBasedOnRecentRent * remainingOpportunities;
}
