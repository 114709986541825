import { useEffect, useState } from 'react';

import { FeatureFlagNames } from '@hedgehog/data-access/graphql';
import {
  AnyFixLater,
  TAddress,
  TAddressPrediction,
} from '@hedgehog/shared/types';
import { useEnvironment } from '@hedgehog/ui/environment';

import mockPredictionData from './mockdata/postcoder-get-predictions.mock.json';
import mockRetrieveData from './mockdata/postcoder-retrieve-address.mock.json';
import useFeatureToggle from './use-feature-toggle.hook';

const POSTCODER_BASE_URL = 'https://ws.postcoder.com/pcw';

type PredictAddressFindParams = {
  countryCode: string;
  searchTerm: string;
  pathFilter?: string;
  useMocks?: boolean;
  apiKey?: string;
};

type RetrieveAddressesFromPredictionParams = PredictAddressFindParams & {
  predictionId: string;
  useMocks?: boolean;
  apiKey?: string;
};

type UseAsyncAddressAutoCompleteParams = {
  countryCode: string;
  pathFilter?: string;
};

type UseAddressAutoCompleteParams = UseAsyncAddressAutoCompleteParams & {
  searchTerm: string;
  debounceDelay?: number;
};

type PostcoderAddress = {
  summaryline: string;
  organisation?: string;
  buildingname?: string;
  number?: string;
  premise?: string;
  street?: string;
  posttown: string;
  county: string;
  postcode: string;
};

type PostcoderAddressPrediction = {
  id: string;
  type: 'STR' | 'ADD';
  summaryline: string;
  locationsummary: string;
  count: number;
};

/**
 * Map a alpha-3 code to an alpha-2 code for Postcoder.
 * @see https://postcoder.com/docs/address-lookup/address#country_codes.
 */
const resolve2CharacterCountryCode = (countryCode: string): string | null => {
  switch (countryCode) {
    case 'GBR':
      return 'UK';
    case 'USA':
      return 'US';
    case 'CHE':
      return 'CH';
    default:
      return null;
  }
};

const predictAddressFind = async ({
  countryCode,
  searchTerm,
  pathFilter,
  useMocks,
  apiKey,
}: PredictAddressFindParams): Promise<TAddressPrediction[]> => {
  // On development environment, do not make actual requests to the Postcoder API as it eats into our quota.
  let data: PostcoderAddressPrediction[];
  if (useMocks) {
    data = await new Promise((resolve) => {
      setTimeout(() => {
        resolve(mockPredictionData as PostcoderAddressPrediction[]);
      }, 500);
    });
  } else {
    const country = resolve2CharacterCountryCode(countryCode);
    const queryParams: AnyFixLater = {
      query: searchTerm,
      country,
      apiKey,
    };
    if (pathFilter) {
      queryParams.pathFilter = pathFilter;
    }
    const url = `${POSTCODER_BASE_URL}/autocomplete/find?${new URLSearchParams(
      queryParams,
    )}`;
    const response = await fetch(url);
    data = (await response.json()) as PostcoderAddressPrediction[];
  }

  return data.map((prediction: PostcoderAddressPrediction) => {
    return {
      id: prediction.id,
      type: prediction.type === 'STR' ? 'street' : 'address',
      summary: prediction.summaryline,
      location: prediction.locationsummary,
      addressCount: prediction.count,
    };
  });
};

export const retrieveAddressesFromPrediction = async ({
  countryCode,
  searchTerm,
  predictionId,
  apiKey,
  useMocks,
}: RetrieveAddressesFromPredictionParams): Promise<TAddress[]> => {
  // On development environment, do not make actual requests to the Postcoder API as it eats into our quota.
  let data: PostcoderAddress[];
  if (useMocks) {
    data = await new Promise((resolve) => {
      setTimeout(() => {
        resolve(mockRetrieveData as PostcoderAddress[]);
      }, 500);
    });
  } else {
    const country = resolve2CharacterCountryCode(countryCode);
    const queryParams: AnyFixLater = {
      query: searchTerm,
      id: predictionId,
      country,
      apiKey,
    };
    const url = `${POSTCODER_BASE_URL}/autocomplete/retrieve?${new URLSearchParams(
      queryParams,
    )}`;
    const response = await fetch(url);
    data = (await response.json()) as PostcoderAddress[];
  }

  return data
    .filter(({ organisation }) => !organisation)
    .map((address: PostcoderAddress): TAddress => {
      return {
        addressLine1: `${address.premise || address.organisation}${
          address.street ? ' ' + address.street : ''
        }`,
        town: address.posttown,
        postcode: address.postcode,
      };
    });
};

export const useAddressAutoComplete = ({
  countryCode,
  searchTerm,
  pathFilter,
}: UseAddressAutoCompleteParams): [TAddressPrediction[], boolean, boolean] => {
  const environment = useEnvironment();
  const { hasFeature } = useFeatureToggle();
  const postcoderFeatureEnabled = hasFeature(
    FeatureFlagNames.integration_postcoder,
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [addresses, setAddresses] = useState<TAddressPrediction[]>([]);
  const enabled = Boolean(
    countryCode === 'GBR' &&
      postcoderFeatureEnabled &&
      (environment['postcoder']?.apiKey || environment['postcoder']?.useMocks),
  );

  const fetchPredictions = async (
    searchTerm: string,
  ): Promise<TAddressPrediction[]> => {
    if (!searchTerm) return [];
    if (!countryCode) return [];
    if (countryCode !== 'GBR') return []; // Postcoder supports UK and Ireland

    // Empty search term resolves no addresses
    if (searchTerm === '') {
      return [];
    }

    return await predictAddressFind({
      countryCode,
      searchTerm,
      pathFilter,
      apiKey: environment['postcoder']?.apiKey,
      useMocks: environment['postcoder']?.useMocks,
    });
  };

  useEffect(() => {
    if (!searchTerm) {
      setAddresses([]);
      return;
    }

    setLoading(true);
    fetchPredictions(searchTerm)
      .then((data) => setAddresses(data))
      .finally(() => setLoading(false));
  }, [countryCode, pathFilter, searchTerm]);

  return [addresses, loading, enabled];
};
