import styled, { CSSProp } from 'styled-components';

import { useIsMobile } from '@hedgehog/data-access/hooks';
import { Card } from '@hedgehog/ui/cards';
import { Heading, Paragraph } from '@hedgehog/ui/typography';
import { screens } from '@hedgehog/utils/sizes';

const ShrinkedCard = styled(Card)`
  padding: 2rem;
  ${({ theme }): CSSProp => ({ color: theme.colors.black })}

  background-image: url('https://assets.hedgehog-invest.com/pattern_2.png');
  background-repeat: no-repeat;
  background-size: 29rem 100%;
  background-position: right;

  @media only screen and (max-width: ${screens.medium}px) {
    background-position: bottom;
    background-size: 100% 45%;
    padding: 1.5rem 1rem;
  }
`;

const CardHeader = styled.div`
  position: relative;
  text-align: center;

  @media only screen and (min-width: ${screens.medium}px) {
    max-width: 35%;
    text-align: left;
  }

  @media only screen and (max-width: ${screens.medium}px) {
    margin-bottom: 11.25rem;
  }

  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const PartnersJoiningSoon = (): JSX.Element => {
  const isMobile = useIsMobile();

  return (
    <ShrinkedCard>
      <CardHeader>
        <Heading level={isMobile ? 'h4' : 'h2'}>
          More partners are joining soon.
        </Heading>
        <Paragraph color="grey500">
          We are working on partnering with more asset owners that manage strong
          and viable investment opportunities for you.
        </Paragraph>
      </CardHeader>
    </ShrinkedCard>
  );
};
