import {
  useQuery,
  DocumentNode,
  QueryResult,
  OperationVariables,
  QueryHookOptions,
} from '@apollo/client';
import { useNavigate } from 'react-router-dom';

export const useAuthenticatedQuery = <
  T,
  K extends OperationVariables = OperationVariables,
>(
  query: DocumentNode,
  variables?: K,
  options?: QueryHookOptions,
): QueryResult<T, OperationVariables> => {
  const navigate = useNavigate();
  const queryResult = useQuery(query, {
    variables,
    ...options,
  });

  if (
    queryResult.error?.graphQLErrors[0]?.extensions?.code === 'UNAUTHENTICATED'
  ) {
    navigate('/logout');
  }
  return queryResult;
};
