import { Navigate, Outlet, Route } from 'react-router-dom';

import { ArticlePage } from '@hedgehog/browser/investors/learn/feature-article';
import {
  CoursePage,
  CourseTopicPage,
} from '@hedgehog/browser/investors/learn/feature-course';
import { Page } from '@hedgehog/ui/layouts';

import { LearnPage } from './learn.page';

export const learnRoutes = (
  <Route
    path="learn"
    element={
      <Page>
        <Outlet />
      </Page>
    }
  >
    <Route index element={<LearnPage />} />

    <Route path="articles">
      <Route index element={<Navigate to="/learn" />} />
      <Route path=":articleId" element={<ArticlePage />} />
    </Route>
    <Route path="courses">
      <Route index element={<Navigate to="/learn" />} />
      <Route path=":courseSlug" element={<CoursePage />}>
        <Route path=":topicSlug" element={<CourseTopicPage />} />
      </Route>
    </Route>
    <Route path="*" element={<Navigate to="/learn" />} />
  </Route>
);
