import Flicking from '@egjs/react-flicking';
import styled, { CSSObject } from 'styled-components';

import { useCourses } from '@hedgehog/browser/investors/learn/data-access';
import { TopicProgress } from '@hedgehog/data-access/graphql';
import { Loader } from '@hedgehog/ui/loaders';
import { Paragraph } from '@hedgehog/ui/typography';
import { screens } from '@hedgehog/utils/sizes';

import { CourseCard } from './course-card.component';

export const CourseFlicking = styled(Flicking)`
  display: flex;
  flex-flow: row nowrap;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  padding-top: 1.5rem;
  padding-bottom: 3.5rem;
  margin: 0 -4.5rem;

  /**
   * Counters negative margin and aligns with Page padding
   */
  .flicking-camera {
    padding-left: 4.5rem;
    gap: 0.5rem;
    display: grid;
    grid-auto-flow: column;
    grid-auto-rows: max-content;
    ${({ columns = 0 }): CSSObject => ({
      gridTemplateColumns: `repeat(${columns}, 20.4375rem)`,
    })};
  }

  @media only screen and (min-width: ${screens.medium}px) {
    flex-flow: column nowrap;
    overflow-y: hidden;

    .flicking-camera {
      gap: 1.5rem;
    }
  }
`;

const Accent = styled.strong`
  color: ${({ theme }): string => theme.colors.black};
`;

export const CourseList = (): JSX.Element => {
  const { data, error, loading } = useCourses();

  if (error) {
    return <Paragraph>Something went wrong, please try again later</Paragraph>;
  }

  if (loading || !data) {
    return <Loader />;
  }

  return (
    <CourseFlicking align="prev" columns={data.courses.length}>
      {data.courses.map((course) => {
        const isCompleted = course.topics?.every(
          ({ progress }) => progress === TopicProgress.COMPLETED,
        );
        const wasStarted = course.topics?.some(
          ({ progress }) => progress !== TopicProgress.NOT_STARTED,
        );
        return (
          <CourseCard
            to={`/learn/courses/${course.slug}`}
            key={course.id}
            title={course.title}
            thumbnail={course.imageUrl}
            progress={course.topics.map(({ progress }) => progress)}
            caption={
              isCompleted ? (
                'Completed'
              ) : wasStarted ? (
                <>
                  Next: <Accent>{course.upNext?.title}</Accent>
                </>
              ) : (
                'Start lesson'
              )
            }
          />
        );
      })}
    </CourseFlicking>
  );
};
