import { MouseEventHandler } from 'react';
import { NavLinkProps } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import styled, { CSSProp } from 'styled-components';

import { useAuthenticatedQuery } from '@hedgehog/data-access/contexts';
import {
  GetNotificationsQuery,
  GET_NOTIFICATIONS,
} from '@hedgehog/data-access/graphql';
import { BadgeBase } from '@hedgehog/ui/badges';
import { Icon } from '@hedgehog/ui/icons';
import { ThemeColorName } from '@hedgehog/ui/themes';

const Button = styled(NavLink)`
  ${({ theme }): CSSProp => ({ color: theme.colors.black })};

  ${BadgeBase};
`;

export interface NotificationButtonProps
  extends Omit<NavLinkProps, 'children'> {
  className?: string;
  colorName?: ThemeColorName;
  badged?: boolean;
  disabled?: true;
}

export const NotificationButton = styled(
  ({
    badged,
    disabled,
    className,
    colorName,
    ...linkProps
  }: NotificationButtonProps): JSX.Element => {
    const { data } =
      useAuthenticatedQuery<GetNotificationsQuery>(GET_NOTIFICATIONS);
    const handleClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
      if (disabled) event.preventDefault();
      if (linkProps.onClick) linkProps.onClick(event);
    };
    const classNames = [className];
    if (badged) {
      classNames.push('active-badge');
    } else if (data?.notifications.some(({ unread }) => unread)) {
      classNames.push('active-badge');
    }

    return (
      <Button
        className={classNames.join(' ')}
        data-disabled={disabled ? disabled : undefined}
        onClick={handleClick}
        data-testid="notification-button"
        {...linkProps}
      >
        <Icon
          icon="bell"
          size="m"
          color="inherit"
          backgroundColor={colorName || 'grey100'}
        />
      </Button>
    );
  },
)``;
