import styled, { CSSObject } from 'styled-components';

import { Avatar } from '@hedgehog/ui/icons';
import { Heading, Note, Paragraph } from '@hedgehog/ui/typography';
import { currencies } from '@hedgehog/utils/formats';

import { OrderDetailsCard } from '../order-details-card/order-details-card.component';

const ImageWrapper = styled.div`
  max-width: 100%;
  min-height: 11.25rem;
  max-height: 11.25rem;
  pointer-events: none;

  position: relative;
  overflow: hidden;
  ${({ theme }): CSSObject => ({ borderRadius: theme.radius.normal })}
`;

const Image = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  border-radius: 0.5rem;
`;

export interface OrderPaymentConfirmedContentProps {
  orderAmount: number;
}

export const OrderPaymentConfirmedContent = ({
  orderAmount,
}: OrderPaymentConfirmedContentProps): JSX.Element => {
  return (
    <OrderDetailsCard>
      <Avatar
        icon="countdown-stop"
        size="l"
        color="grey300"
        backgroundColor="white"
      />
      <Heading level="h4">You've confirmed your wire transfer is done!</Heading>
      <Paragraph>
        We will let you know as soon as we have received your funds. This can
        take 1-4 business days.
      </Paragraph>
      <Note color="grey400">{currencies.formatMoney(orderAmount)}</Note>
      <ImageWrapper>
        <Image
          alt=""
          src="https://assets.hedgehog-invest.com/images/transfer.jpeg"
        />
      </ImageWrapper>
    </OrderDetailsCard>
  );
};
