import { isPartnerSettingEnabled } from '@hedgehog/data-access/graphql';

import { useUser } from './use-user.hook';

export const useIsInvestingEnabled = (): {
  enabled: boolean | null;
  loading: boolean;
} => {
  const { data, loading } = useUser();
  const enabled = data
    ? isPartnerSettingEnabled(data.partnerSettings, 'investments')
    : null;
  return { enabled, loading };
};
