import styled, { CSSObject } from 'styled-components';

import { ArticlesList } from '@hedgehog/browser/investors/learn/feature-article';
import { CourseList } from '@hedgehog/browser/investors/learn/feature-course';
import { LearnFAQs } from '@hedgehog/browser/investors/learn/feature-faqs';
import { useAnalyticsPage } from '@hedgehog/browser/investors/shared/analytics';
import { Column, ColumnLayout } from '@hedgehog/ui/layouts';
import { Heading } from '@hedgehog/ui/typography';
import { screens } from '@hedgehog/utils/sizes';

export const LearnHeading = styled(Heading)`
  ${({ theme }): CSSObject => ({
    marginBottom: theme.spacing.large,
  })}
`;

export const LearnWrapper = styled.div`
  & > ${ColumnLayout} {
    gap: 1rem;
  }
`;

const HideOnMobile = styled.div`
  @media only screen and (max-width: ${screens.xlarge}px) {
    display: none;
  }
`;

const HideOnDesktop = styled.div`
  @media only screen and (min-width: ${screens.xlarge}px) {
    display: none;
  }
`;

export const LearnPage = (): JSX.Element => {
  useAnalyticsPage('Top Level', 'Learn');

  return (
    <LearnWrapper>
      <Heading level="h5">Learn</Heading>
      <CourseList />

      <HideOnMobile>
        <ColumnLayout>
          <Column>
            <LearnHeading level="h5">Blog</LearnHeading>
            <ArticlesList />
          </Column>

          <Column>
            <LearnHeading level="h5">FAQs</LearnHeading>
            <LearnFAQs />
          </Column>
        </ColumnLayout>
      </HideOnMobile>
      <HideOnDesktop>
        <LearnHeading level="h5">Blog</LearnHeading>
        <ArticlesList />

        <LearnHeading level="h5">FAQs</LearnHeading>
        <LearnFAQs />
      </HideOnDesktop>
    </LearnWrapper>
  );
};

export default LearnPage;
