import { useContext, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useAuthUser } from '@hedgehog/browser/investors/account/data-access';
import { useSidebarRouting } from '@hedgehog/ui/routing';

import { AnalyticsContext } from '../AnalyticsContext';
import { getBrowserMetadata } from '../helpers';
import { PageCategory, PageName } from '../types';

export interface UseAnalyticsPage {
  <C extends PageCategory, CN extends PageName<C> = PageName<C>>(
    category: C,
    name: CN,
    properties?: Record<string, unknown>,
    options?: Record<string, unknown>,
  ): void;
}

/**
 * useLazyAnalyticsPage will return a callback function that can be executed to track page analytics. Null is
 * returned when the analytics is not ready to be used yet.
 */
export const useLazyAnalyticsPage = (): UseAnalyticsPage | null => {
  const { identify, page } = useContext(AnalyticsContext);
  const user = useAuthUser();
  const fn: UseAnalyticsPage = (category, name, properties, options) => {
    page(category, name, properties, options);
    if (user) {
      identify(user.id, {
        country: user.country,
        browser: getBrowserMetadata(),
      });
    }
  };
  const cb = useCallback(fn, []);
  return cb;
};

/**
 * @description
 * Notifies Segment's API about the "Page View" on
 * componentDidMount callback.
 *
 * Once #hash in URL changes; that call will only
 * emit event once it comes empty.
 */
export const useAnalyticsPage: UseAnalyticsPage = (
  category,
  name,
  properties,
  options,
) => {
  const { hash } = useSidebarRouting();
  const { identify, page } = useContext(AnalyticsContext);
  const user = useAuthUser();
  const location = useLocation();

  // When the sidebar is open, two page tracking events will be emitted. One for the sidebar and one for the main page.
  // Here we ensure that only the sidebar page tracking event is emitted in this scenario.
  useEffect(() => {
    if (location.hash !== '' && !hash) {
      // Sidebar is open and hook called from component outside the sidebar.
      return;
    }

    page(category, name, properties, options);

    if (user) {
      identify(user.id, {
        country: user.country,
        browser: getBrowserMetadata(),
      });
    }
  }, [location.hash, hash]);
};

/**
 * @description
 * Notifies Segment's API about the "Page View" on
 * componentDidMount callback.
 */
export const useAnalyticsPageOnMount: UseAnalyticsPage = (
  category,
  name,
  properties,
  options,
) => {
  const { identify, page } = useContext(AnalyticsContext);
  const user = useAuthUser();

  useEffect(() => {
    page(category, name, properties, options);

    if (user) {
      identify(user.id, {
        country: user.country,
        browser: getBrowserMetadata(),
      });
    }
  }, []);
};
