import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import styled, { css, CSSObject } from 'styled-components';

import {
  useOrder,
  useOrderClaim,
  useSignSubscription,
} from '@hedgehog/browser/investors/order/data-access';
import { OrderStatus } from '@hedgehog/data-access/graphql';
import { AnyFixLater } from '@hedgehog/shared/types';
import { SecondaryLightLinkButton } from '@hedgehog/ui/buttons';
import { Card, CardContent } from '@hedgehog/ui/cards';
import { Loader } from '@hedgehog/ui/loaders';
import { Heading, Note, Paragraph } from '@hedgehog/ui/typography';

import { MinterMotion } from '../components';

const MinterCard = styled(Card)`
  ${CardContent} > *:not(:last-child) {
    border-bottom: 1px solid transparent;
    ${({ theme }): CSSObject => ({
      borderBottomColor: theme.colors.shadow100,
    })}
  }
`;

const CardSpacing = css`
  ${({ theme }): CSSObject => ({
    paddingTop: theme.spacing.normal,
    paddingBottom: theme.spacing.normal,
  })}
`;

const CardHeader = styled.div`
  ${CardSpacing};
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`;

const CardBody = styled.div`
  ${CardSpacing};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
`;

const CardFooter = styled.div`
  ${CardSpacing};
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  padding-bottom: 0;
`;

const MinterFooter = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin-top: 2rem;
`;

export const MinterPage = (): JSX.Element => {
  const { orderId: pathOrderId } = useParams();
  const [searchParams] = useSearchParams();
  const orderId = pathOrderId || searchParams.get('orderId') || '';
  const { data, loading, error } = useOrder(orderId);
  const {
    data: agreement,
    loading: agreementLoading,
    error: agreementError,
  } = useSignSubscription({
    documentHash: searchParams.get('documentHash') || '',
  });
  const [claimOrder, { data: order, error: claimOrderError }] = useOrderClaim();
  const isMintingComplete =
    order?.claimOrder.order.status === OrderStatus.CLAIMED;

  const onMintingCompleted = async (): Promise<void> => {
    if (!orderId) return;
    await claimOrder({
      variables: {
        orderId: orderId,
      },
    });
  };

  useEffect(() => {
    if (!agreement) return;
    onMintingCompleted();
  }, [agreement]);

  useEffect(() => {
    if (!agreementError) return;
    if (!errorIsSubscriptionAlreadyActive(agreementError)) return;

    // This situation can happen if the user previously navigates to the minting page
    // but leaves before the claimOrder mutation is executed. When they return to this page
    // the smart contract will complain that the subscription agreement is already active.
    // In this case it is safe to simply claim the order and move on.
    onMintingCompleted();
  }, [agreementError]);

  const errorIsSubscriptionAlreadyActive = (error: AnyFixLater): boolean => {
    return (
      typeof (error as AnyFixLater).body === 'string' &&
      JSON.parse((error as AnyFixLater).body).error?.message ===
        'subscription is already active'
    );
  };

  if (
    error ||
    (agreementError && !errorIsSubscriptionAlreadyActive(agreementError)) ||
    claimOrderError
  ) {
    return (
      <MinterCard>
        <div>
          <Paragraph color="grey500">
            {typeof (agreementError as AnyFixLater).body === 'string'
              ? JSON.parse((agreementError as AnyFixLater).body).error?.message
              : 'Something went wrong, try again later.'}
          </Paragraph>
        </div>
      </MinterCard>
    );
  }

  return (
    <>
      <MinterCard>
        <CardHeader>
          <Heading level="h5" color={isMintingComplete ? 'success' : 'primary'}>
            {isMintingComplete ? 'Tokens minted.' : 'Tokens minting...'}
          </Heading>
        </CardHeader>
        <CardBody>
          <Note>Sign up bonus</Note>
          <Heading level="h5">
            {loading ? (
              <Loader />
            ) : (
              <strong>{data?.order?.quantity} tokens</strong>
            )}
          </Heading>
          <Note>1 Token = $1</Note>
        </CardBody>
        <CardFooter>
          <MinterMotion loading={!isMintingComplete} />
        </CardFooter>
      </MinterCard>

      {isMintingComplete && (
        <MinterFooter>
          <SecondaryLightLinkButton
            replace
            to="/portfolio"
            state={{ claimed: true }}
          >
            View in Portfolio
          </SecondaryLightLinkButton>
        </MinterFooter>
      )}
    </>
  );
};
