import { TAssessmentPage } from './assessment-quiz.types';

/**
 * getFirstPage will return a page that has not other page pointing to it.
 * @param pages
 */
export const getFirstPage = (pages: TAssessmentPage[]): string | undefined => {
  return pages.find((page) => {
    const previousPage = pages.find(
      (candidate) => candidate.nextAssessmentPageId === page.id,
    );
    return !previousPage;
  })?.id;
};
