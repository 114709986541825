import { useLocalStorageValue } from '@hedgehog/data-access/hooks';

import { NativeMobileExperiences } from './native-mobile-experiences.enum';
import { LOCALSTORAGE_MOBILE_NATIVE_KEY } from './use-is-native-mobile-experience.hook';

export const useIsNativeMobile = (): boolean => {
  const nativeMobileExperiences = useLocalStorageValue(
    LOCALSTORAGE_MOBILE_NATIVE_KEY,
  );

  if (nativeMobileExperiences) {
    const parsedValue: Array<string> = JSON.parse(
      nativeMobileExperiences || '[]',
    );

    if (Array.isArray(parsedValue)) {
      return parsedValue.includes(NativeMobileExperiences.NATIVE_USER_ACTIONS);
    }
  }
  return false;
};
