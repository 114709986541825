import { useOrder } from '@hedgehog/browser/investors/order/data-access';
import { useAuth } from '@hedgehog/data-access/contexts';
import { ActionCard } from '@hedgehog/ui/cards';
import { useEnvironment } from '@hedgehog/ui/environment';
import { Loader } from '@hedgehog/ui/loaders';

export const DownloadSubscriptionAgreement = ({
  orderId,
}: {
  orderId: string;
}): JSX.Element | null => {
  const { data } = useOrder(orderId);
  const { accessToken } = useAuth();
  const environment = useEnvironment();

  if (!data || !data?.order?.asset.isTenantToken) return null;

  const agreementUrl = `${environment.api.url}/subscription-agreements/${orderId}?authorization=${accessToken}`;

  return (
    <ActionCard
      disabled={!accessToken}
      title="Subscription Agreement"
      href={agreementUrl}
      target="_blank"
      trailing={!accessToken ? <Loader /> : undefined}
      trailingIcon={!accessToken ? undefined : 'download'}
    />
  );
};
