import { useContext } from 'react';

import {
  AssessmentRouterSettingsContextType,
  AssessmentRouterSettingsContext,
} from '../contexts/assessment-router-settings';

export function useAssessmentRouterSettings(): AssessmentRouterSettingsContextType {
  return useContext(AssessmentRouterSettingsContext);
}
