import { DeclarationType, isKycOnly } from '@hedgehog/data-access/graphql';
import { useIsNativeMobile } from '@hedgehog/data-access/native-mobile';

import { useGuardRegistry } from '../hooks';

export interface JurisdictionWarningGuardProps {
  priority: number;
}

export const JurisdictionWarningGuard = ({
  priority,
}: JurisdictionWarningGuardProps): JSX.Element => {
  const isNativeMobile = useIsNativeMobile();
  useGuardRegistry({
    name: 'jurisdiction',
    priority,
    validate: ({ user, declarations, partnerSettings }) => {
      if (isNativeMobile) return null;
      if (isKycOnly(partnerSettings)) return null;
      const { country = '' } = user ?? {};

      // TODO: 🌎 onboarding tech-debt - remove me - https://linear.app/hedgehog-invest/issue/HOG-2898/%F0%9F%92%A1-get-rid-of-all-the-oakglen-if-statements
      const doesCountryRequireInvestorLetter =
        ['GBR', 'JEY'].includes(country) && user?.partner?.slug === 'oakglen';
      const doesCountryRequireJurisdiction = ['USA', 'CHE'].includes(country);

      const hasUserSeenJurisdictionWarning =
        declarations.findIndex(
          ({ type }) => type === DeclarationType.JURISDICTION_WARNING,
        ) > -1;

      if (
        (doesCountryRequireJurisdiction || doesCountryRequireInvestorLetter) &&
        !hasUserSeenJurisdictionWarning
      ) {
        return '#signup/jurisdiction';
      }
      return null;
    },
  });

  return <></>;
};
