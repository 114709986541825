import { CodeResponse, useGoogleLogin } from '@react-oauth/google';
import * as qs from 'qs';
import styled, { CSSObject } from 'styled-components';

import { LinkButton, styles } from '@hedgehog/ui/buttons';
import { useEnvironment } from '@hedgehog/ui/environment';
import { IconType } from '@hedgehog/ui/icons';

type GoogleLoginButtonProps = {
  loading?: boolean;
  text?: string;
  className?: string;
  onClick?: () => void;
  onSuccess: (token: string) => void;
  onError: (error: string) => void;
  icon: IconType;
  scopes?: string[];
  state?: object;
  fluid?: boolean;
  small?: boolean;
  hint?: string;
};

const StyledButton = styled(LinkButton)<{
  $hoverColor?: string;
}>`
  ${({ theme: { colors, typography, spacing } }): CSSObject => ({
    fontSize: typography.button_small.fontSize,
    gap: spacing.xxxsmall,
    backgroundColor: colors.grey100,
    color: colors.black,
  })};

  &:focus,
  &:hover {
    ${styles.alternativeHoverAppearance}
  }
`;

export const GoogleLoginButton = ({
  text,
  onSuccess,
  onError,
  scopes = ['email', 'openid', 'profile'],
  state,
  hint,
  ...props
}: GoogleLoginButtonProps): JSX.Element | null => {
  const {
    api: { url: apiUrl },
    baseUrl,
  } = useEnvironment();

  const login = useGoogleLogin({
    flow: 'auth-code',
    hint: hint,
    select_account: false,
    onSuccess: (response: CodeResponse) =>
      exchangeCodeForIdToken(response.code),
    onError: () => onError('Something went wrong, please try again later.'),
    scope: scopes.join(' '),
  });

  const exchangeCodeForIdToken = async (code: string): Promise<void> => {
    const response = await fetch(
      `${apiUrl}/auth/google/token?` +
        qs.stringify({
          code,
          redirect_uri: baseUrl,
        }),
      {
        method: 'POST',
      },
    );
    const json = await response.json();
    onSuccess(json.id_token);
  };

  return (
    <StyledButton
      leadingSize="m"
      iconColor="white"
      onClick={login}
      $hoverColor="light200"
      {...props}
    >
      {text}
    </StyledButton>
  );
};
