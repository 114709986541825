import styled, { CSSObject } from 'styled-components';

import { HeaderWithBack } from '@hedgehog/ui/headers';
import { Heading } from '@hedgehog/ui/typography';

import { IdentityCheckPrompt, PersonalInformation } from '../containers';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  ${({ theme }): CSSObject => ({ gap: theme.spacing.large })}
`;

export const PersonalInformationPage = (): JSX.Element => {
  return (
    <Wrapper>
      <HeaderWithBack to="#kyc">
        <Heading level="h5">Personal information</Heading>
      </HeaderWithBack>

      <IdentityCheckPrompt />

      <PersonalInformation />
    </Wrapper>
  );
};
