import { useQuery } from '@apollo/client';

import {
  GET_PARTNER,
  PartnerSettingName,
  isPartnerSettingEnabled,
} from '@hedgehog/data-access/graphql';

import { useUser } from './use-user.hook';

export const usePartnerSetting = (
  settingName: `${PartnerSettingName}`,
): {
  enabled: boolean | null;
  loading: boolean;
} => {
  const { data, loading } = useUser();
  const enabled = data
    ? isPartnerSettingEnabled(data.partnerSettings, settingName)
    : null;
  return { enabled, loading };
};
