export type OnboardingActionStatus =
  | 'retry' // Rejected but with option to retry
  | 'pending' // Not started yet.
  | 'inprogress' // Started and waiting on result.
  | 'completed' // Success
  | 'blocked' // Requires user attention
  | 'failed' // Failed, user cannot recover without contacting Hedgehog.
  | 'stale'; // Previously completed but no longer fresh, must redo.

export enum HedgehogDocusignProcess {
  SUBSCRIPTION_AGREEMENT = 'SUBSCRIPTION_AGREEMENT',
  ACCREDITATION_VERIFICATION = 'ACCREDITATION_VERIFICATION',
  NON_US_PERSON_TAX_FORM = 'NON_US_PERSON_TAX_FORM',
  US_PERSON_TAX_FORM = 'US_PERSON_TAX_FORM',
}
