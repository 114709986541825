import { ForwardedRef, MouseEventHandler, forwardRef } from 'react';
import styled from 'styled-components';

import { TopicProgress } from '@hedgehog/data-access/graphql';
import { StandardProps } from '@hedgehog/ui/utils';

import { CourseTopicActionCard } from './course-topic-action-card.component';

type CourseTopicNavbar = StandardProps<
  {
    courseSlug: string;
    nextTopic?: {
      title: string;
      slug: string;
      progress: TopicProgress;
    } | null;
    onClick?: MouseEventHandler;
  },
  never
>;

export const CourseTopicNavbar = styled(
  forwardRef(
    (
      { courseSlug, nextTopic, ...props }: CourseTopicNavbar,
      ref: ForwardedRef<HTMLDivElement | HTMLAnchorElement>,
    ): JSX.Element => {
      return (
        <CourseTopicActionCard
          ref={ref}
          type="light"
          to={
            nextTopic
              ? `/learn/courses/${courseSlug}/${nextTopic.slug}`
              : '/learn'
          }
          title={nextTopic ? nextTopic.title : 'Lesson finished'}
          caption={nextTopic ? 'Up next' : undefined}
          progress={nextTopic ? nextTopic.progress : TopicProgress.COMPLETED}
          {...props}
        />
      );
    },
  ),
)``;
