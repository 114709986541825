import styled, { CSSObject } from 'styled-components';

import { ButtonContent, OutlineLightLinkButton } from '@hedgehog/ui/buttons';
import { Card, CardContent } from '@hedgehog/ui/cards';
import { Heading, Paragraph } from '@hedgehog/ui/typography';
import { screens } from '@hedgehog/utils/sizes';

export const GuideCard = styled(Card)`
  flex-flow: row;
  padding: 2.5rem;
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 0;
  align-items: center;
  ${({ theme }): CSSObject => ({ backgroundColor: theme.colors.peach })};

  @media only screen and (min-width: ${screens.medium}px) {
    position: relative;
    border-radius: 1rem;
    width: 100%;

    box-sizing: border-box;
    max-width: 64rem;
    margin: 0 auto;
  }

  ${CardContent} {
    @media only screen and (min-width: ${screens.medium}px) {
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
    }
  }
`;

export const StubHeader = styled.div`
  margin: 1rem auto;
  max-width: 64rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 0;
  align-items: center;

  a {
    cursor: pointer;
  }
`;

export const GuideCardInnerWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;

  margin: 0 auto;
  max-width: 26rem;
  text-align: center;

  @media only screen and (min-width: ${screens.medium}px) {
    min-width: 32rem;
    text-align: left;
    margin: 0;
  }
`;

export const GuideCardHeading = styled(Heading)`
  margin-bottom: 1rem;
`;

export const GuideCardDescription = styled(Paragraph)`
  opacity: 0.8;
`;

export const LockWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const LockComponent = styled.div<{ showTimer: boolean }>`
  transition: 0.75s;
  overflow: hidden;
  width: ${({ showTimer }): string => (showTimer ? '14rem' : '3.5rem')};
  display: flex;
  align-items: center;
  background-color: white;
  margin-bottom: 2.5rem;
  padding: 1rem;
  border-radius: 3rem;

  p {
    line-height: 2.5rem;
    padding-left: 1rem;
    font-weight: bold;
  }

  @media only screen and (min-width: ${screens.medium}px) {
    padding: 0.75rem;
    margin-bottom: 0;
    width: ${({ showTimer }): string => (showTimer ? '20rem' : '4.5rem')};
  }
`;

export const TimerText = styled(Heading)`
  align-items: center;
  margin-left: 1rem;

  @media only screen and (min-width: ${screens.medium}px) {
    margin-left: 0.5rem;
    font-size: 3.5rem;
  }
`;

export const AppleIconWrapper = styled.div`
  display: inline-flex;
  height: 1rem;
  width: 1rem;
  margin-bottom: 0.125rem;
`;

export const DownloadButton = styled(OutlineLightLinkButton)`
  & ${ButtonContent} {
    display: flex;
    align-items: center;
  }
`;
