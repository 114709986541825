import { isKycOnly } from '@hedgehog/data-access/graphql';

import { useUser } from './use-user.hook';

// TODO: 🔍 KYC-only tech-debt - remove me - https://linear.app/hedgehog-invest/issue/HOG-2898/%F0%9F%92%A1-get-rid-of-all-the-oakglen-if-statements
// NB. This hook may have a purpose in a post-configurable onboarding world, but should be treated as suspect until proven otherwise.
export const useIsKycOnly = (): {
  isKycOnly: boolean | null;
  loading: boolean;
} => {
  const { data, loading } = useUser();

  if (loading || !data) {
    return {
      isKycOnly: null,
      loading,
    };
  }

  const { partnerSettings } = data;
  return {
    isKycOnly: isKycOnly(partnerSettings),
    loading,
  };
};
