import { useEffect } from 'react';

import { QueryResult, useQueryResult } from '@hedgehog/browser/shared/utils';
import { useEnvironment } from '@hedgehog/ui/environment';

import { CognitoTokenResponse, exchangeCognitoAuthCode } from '../utils';

export interface UseAuthenticatedCodeParams {
  authCode?: string | null;
  redirectUrl: string;
}

export const useAuthenticatedCode = (
  params: UseAuthenticatedCodeParams,
): QueryResult<CognitoTokenResponse> => {
  const { baseUrl, cognito } = useEnvironment();
  const [state, controls] = useQueryResult<CognitoTokenResponse>();

  useEffect(() => {
    const fetch = async (): Promise<QueryResult<CognitoTokenResponse>> => {
      try {
        controls.setLoading(true);
        if (!params.authCode) throw new Error('No auth code provided');
        const data = await exchangeCognitoAuthCode({
          appUrl: baseUrl,
          cognitoAuthCode: params.authCode,
          cognitoClientId: cognito.clientId,
          cognitoUserPoolUrl: cognito.userPoolUrl,
          redirectUrl: params.redirectUrl,
        });
        controls.setError(null);
        controls.setData(data);
        return { data, error: null, loading: false };
      } catch (error) {
        controls.setData(null);
        controls.setError(error);
        return { data: null, error, loading: false };
      } finally {
        controls.setLoading(false);
      }
    };
    fetch();
  }, []);

  return state;
};
