import styled, { CSSObject } from 'styled-components';

import { useFAQs } from '@hedgehog/browser/investors/learn/data-access';
import { Accordion } from '@hedgehog/ui/accordions';
import { Loader } from '@hedgehog/ui/loaders';
import { MarkdownText, Paragraph } from '@hedgehog/ui/typography';

const AccordionMarkdown = styled(MarkdownText)`
  ${({ theme }): CSSObject => ({ color: theme.colors.grey400 })}
`;

const AnswerWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 3.5rem;
  ${({ theme }): CSSObject => ({ gap: theme.spacing.normal })}
`;
export const LearnFAQs = (): JSX.Element => {
  const { data, loading, error } = useFAQs();

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Paragraph>Something went wrong, please try again later</Paragraph>;
  }

  return (
    <AnswerWrapper>
      {data?.frequentlyAskedQuestions.map((faq, index) => (
        <Accordion
          key={faq.id}
          initialCollapsed={index !== 0}
          title={faq.question}
        >
          <AccordionMarkdown>{faq.answer}</AccordionMarkdown>
        </Accordion>
      ))}
    </AnswerWrapper>
  );
};
