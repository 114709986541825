import { VSpace } from '@hedgehog/ui/layouts';
import { Heading, Paragraph } from '@hedgehog/ui/typography';

const Declined = (): JSX.Element => {
  return (
    <VSpace spacing="small">
      <Heading level="h3">Your verification was declined.</Heading>
      <Paragraph>
        If you think this is a mistake, please contact us at{' '}
        <a href="mailto:support@hedgehog-invest.com">
          support@hedgehog-invest.com
        </a>
      </Paragraph>
    </VSpace>
  );
};

export default Declined;
