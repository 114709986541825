import { ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Link } from 'react-router-dom';
import styled, { CSSProp } from 'styled-components';

import { Logotype } from '@hedgehog/ui/icons';
import { shimmerGradient } from '@hedgehog/ui/themes';

const TopbarNest = styled.nav<{ border: boolean }>`
  position: relative;
  margin-top: 0;

  ${({ theme, border }): CSSProp => ({
    borderBottom: border ? '1px solid' : 'none',
    backgroundColor: theme.colors.grey100,
    borderBottomColor: theme.colors.shadow100,
  })};
`;

const TopbarContainer = styled.div`
  width: 100%;
`;

const TopbarInnerWrapper = styled.div<{ hidden?: boolean }>`
  min-height: 5.5rem;
  ${({ theme, hidden }): CSSProp => ({
    display: hidden ? 'none' : 'flex',
    visibility: hidden ? 'hidden' : 'visible',
    backgroundColor: theme.colors.white,
    [`@media only screen and (max-width: ${theme.breakpoints.normal}px)`]: {
      display: 'none',
      visibility: 'hidden',
    },
  })};

  align-items: center;
  justify-content: space-between;

  box-sizing: border-box;
  width: 100%;

  margin: 0 auto;
  padding: 0.1rem 4rem 0.1rem 4rem;
`;

const LogosWrapper = styled.div`
  display: flex;
`;

const LogoWrapper = styled.div<{ loading?: boolean }>`
  display: flex;
  align-items: flex-start;
  padding-right: 1rem;
  max-height: 3.5rem;
  ${({ loading }): CSSProp => (loading ? shimmerGradient : undefined)}
`;

const PartnerLogoDivider = styled.div`
  width: 1px;
  height: 32px;
  top: 4px;
  ${({ theme }): CSSProp => ({ backgroundColor: theme.colors.shadow100 })};
`;

const PartnerLogoWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  padding-left: 1rem;
`;

const PartnerLogo = styled.img`
  height: 1.5rem;
`;

const BrandLogotype = styled.img`
  flex: 0 0 auto;
  width: auto;
  height: 2.5rem;
`;

export interface TopbarProps {
  links?: ReactNode | ReactNode[];
  navigation?: boolean;
  beforeAll?: ReactNode | ReactNode[];
  logotypeUrl?: string | null;
  logotypeLoading?: boolean;
  partnerLogoUrl?: string;
  border?: boolean;
}

export const Topbar = ({
  links,
  beforeAll,
  navigation = true,
  logotypeLoading = false,
  logotypeUrl,
  partnerLogoUrl,
  border,
}: TopbarProps): JSX.Element | null => {
  const [, toggleDomReady] = useState(false);

  useEffect(() => toggleDomReady(true), []);

  const portalElement = document.getElementById('layout-topbar');
  return portalElement
    ? createPortal(
        <TopbarNest data-cy="topbar" border={border || false}>
          <TopbarContainer>
            {beforeAll}
            <TopbarInnerWrapper hidden={!navigation}>
              <LogosWrapper>
                <Link to="/explore">
                  <LogoWrapper loading={logotypeLoading}>
                    {logotypeUrl ? (
                      <BrandLogotype src={logotypeUrl} />
                    ) : (
                      <Logotype />
                    )}
                  </LogoWrapper>
                </Link>
                {partnerLogoUrl && (
                  <>
                    <PartnerLogoDivider />
                    <PartnerLogoWrapper>
                      <PartnerLogo src={partnerLogoUrl} alt="partner logo" />
                    </PartnerLogoWrapper>
                  </>
                )}
              </LogosWrapper>
              {links}
            </TopbarInnerWrapper>
          </TopbarContainer>
        </TopbarNest>,
        portalElement,
      )
    : null;
};

export default Topbar;
