import { AccountPersonalDetailsPage } from '@hedgehog/browser/investors/account/feature-account';
import { NDAPage } from '@hedgehog/browser/investors/entry/feature-nda';
import { SponsorPage } from '@hedgehog/browser/investors/explore/feature-asset-detail';
import { AssessmentPage } from '@hedgehog/browser/investors/kyc/feature-accreditation';
import { createRoutes as createKycRoutes } from '@hedgehog/browser/investors/kyc/shell';
import { createRoutes as createOrderRoutes } from '@hedgehog/browser/investors/order/shell';
import {
  JurisdictionPage,
  StatusConfirmationPage,
  StatusPage,
} from '@hedgehog/browser/shared/auth';
import {
  SidebarBackgroundParams,
  SidebarRouter,
} from '@hedgehog/browser/shared/router';
import { GetUserQuery as GetUser } from '@hedgehog/data-access/graphql';
import { NotificationCentrePage } from '@hedgehog/features/notification';

const defaultBackground = `https://assets.hedgehog-invest.com/pattern-72x72.png`;

const notCloseablePreset = {
  background: 'https://assets.hedgehog-invest.com/pattern-72x72.png',
  backdrop: {
    closable: false,
  },
};

export const configurableBackground = (
  userData: GetUser | undefined,
): SidebarBackgroundParams => ({
  url:
    userData?.user?.partner?.resources?.pages?.sidebar?.backgroundUrl ??
    defaultBackground,
  cover:
    userData?.user?.partner?.resources?.pages?.sidebar?.backgroundSize ??
    undefined,
  position:
    userData?.user?.partner?.resources?.pages?.sidebar?.backgroundPosition ??
    undefined,
});

const routes = {
  ...createOrderRoutes(),
  ...createKycRoutes(configurableBackground),
  '#signup/jurisdiction': {
    component: JurisdictionPage,
    ...notCloseablePreset,
    background: configurableBackground,
  },
  '#signup/status': {
    component: StatusPage,
    ...notCloseablePreset,
    background: configurableBackground,
  },
  '#signup/status/confirm': {
    component: StatusConfirmationPage,
    ...notCloseablePreset,
    background: configurableBackground,
  },
  '#signup/nda': {
    component: NDAPage,
    ...notCloseablePreset,
    background: configurableBackground,
  },
  '#assessment': {
    component: AssessmentPage,
    ...notCloseablePreset,
    background: configurableBackground,
  },
  '#notifications': {
    component: NotificationCentrePage,
  },
  '#personal-details': {
    component: AccountPersonalDetailsPage,
  },
  '#sponsors': {
    component: SponsorPage,
  },
};

export const SidebarInvestorRouter = (): JSX.Element => {
  return <SidebarRouter routes={routes} />;
};
