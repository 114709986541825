import { useMutation } from '@apollo/client';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import {
  NOTIFICATION_UNSUBSCRIBE,
  NotificationUnsubscribeMutation as NotificationUnsubscribe,
  NotificationUnsubscribeMutationVariables as NotificationUnsubscribeVariables,
} from '@hedgehog/data-access/graphql';
import {
  EmptyStateContainer,
  LoadingContainer,
  Page,
} from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';
import { Heading, Paragraph } from '@hedgehog/ui/typography';

const StyledPage = styled(Page)`
  display: flex;
  min-height: 100vh;
  flex: 1;
  padding: 0;
  align-items: center;
`;

const SuccessContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1.5rem;
`;

const PageImage = styled.img`
  width: 100%;
  margin-top: -2rem; // The image file has padding itself so this offsets it.
`;

type UnsubscribeQueryParams = {
  token: string;
};

interface UnsubscribeTokenPayload extends JwtPayload {
  identifier: string;
}

export const UnsubscribePage = () => {
  const { search } = useLocation();
  const [token, setToken] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [unsubscribe, { loading, error }] = useMutation<
    NotificationUnsubscribe,
    NotificationUnsubscribeVariables
  >(NOTIFICATION_UNSUBSCRIBE);

  const handleUnsubscribe = async () => {
    if (!token) {
      return;
    }
    await unsubscribe({
      variables: {
        token,
      },
    });
  };

  useEffect(() => {
    const queryParams = queryString.parse(search) as UnsubscribeQueryParams;
    setToken(queryParams.token);
    const payload = jwtDecode(queryParams.token) as UnsubscribeTokenPayload;
    setEmail(payload.identifier);
  }, [search]);

  useEffect(() => {
    handleUnsubscribe();
  }, [token]);

  if (error) {
    return (
      <Page>
        <EmptyStateContainer>
          Something went wrong, please try again later
        </EmptyStateContainer>
      </Page>
    );
  }

  if (loading) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }

  return (
    <StyledPage>
      <SuccessContainer>
        <PageImage src="https://assets.hedgehog-invest.com/images/mail.png" />
        <Heading level="h4">We&apos;re sad to see you go!</Heading>
        <div>
          <Paragraph color="grey300">
            You&apos;ve been unsubscribed from our marketing emails
          </Paragraph>
          <Paragraph>
            <strong>{email}</strong>
          </Paragraph>
        </div>
      </SuccessContainer>
    </StyledPage>
  );
};

export default UnsubscribePage;
