import styled, { useTheme } from 'styled-components';

import { currencies } from '@hedgehog/utils/formats';

export interface ReturnsChartProps {
  price?: number;
}

const ReturnsChartContainer = styled.div`
  display: flex;

  & > svg {
    max-height: 18rem;
  }
`;

const ReturnText = styled.text`
  ${({ theme }) => ({ fill: theme.colors.black })}
  font-size: 1.5rem;
  font-weight: bold;
`;

const ReturnPath = styled.path<{ color?: string }>`
  stroke: ${({ theme, color }) => color || theme.colors.black};
  stroke-width: 8;
  stroke-linecap: round;
`;

const ProjectionPath = styled.path<{ color?: string }>`
  ${({ color }) => (color ? `stroke: ${color};` : '')}
  stroke-opacity: 0.4;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 4 8;
`;

const ProjectionPoint = styled.circle<{ color?: string }>`
  fill: ${({ theme, color }) => color || theme.colors.grey200};
  stroke: white;
  stroke-width: 4;
  stroke-linecap: round;
`;

const LabelText = styled.text<{ color?: string }>`
  fill: ${({ theme, color }) => color || theme.colors.black};
  font-size: 0.75rem;
  font-weight: 400;
  text-transform: uppercase;
`;

const ReturnPoint = styled.circle<{ color?: string }>`
  fill: ${({ theme, color }) => color || theme.colors.black};
  stroke: white;
  stroke-width: 6;
  stroke-linecap: round;
`;

export const ReturnsChart = ({ price }: ReturnsChartProps): JSX.Element => {
  const { colors } = useTheme();
  return (
    <ReturnsChartContainer>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 351 228"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="163" width="311" height="1" fill={colors.grey200} />
        <rect
          y="203"
          width="327"
          height="1"
          fill={colors.black}
          fillOpacity="0.1"
        />
        <rect y="51" width="327" height="1" fill={colors.grey200} />

        <g>
          <LabelText x="0" y="12">
            Actual Returns
          </LabelText>

          <ReturnText x="0" y="40">
            {currencies.formatMoney(price || 0)}
          </ReturnText>

          <LabelText x="0" y="155">
            Projected Returns
          </LabelText>
        </g>
        <g>
          <ProjectionPath
            stroke="url(#paint0_linear_4987_43641)"
            d="M2 198C152.323 185.604 308.795 170.855 401 145"
          />
          <ProjectionPoint cx="321" cy="163" r="6" />
        </g>
        <g>
          <ReturnPath
            color={colors.secondary}
            d="M5 200C14.8652 200 133.5 200 187.5 200C258 200 260 200 321 52"
          />
          <ReturnPoint cx="321" cy="52" r="9" color={colors.secondary} />
        </g>

        <g>
          <LabelText x="0" y="224" color={colors.black}>
            Dec 2021
          </LabelText>

          <LabelText x="272.5" y="224" color={colors.black}>
            Aug 2022
          </LabelText>
        </g>

        <defs>
          <linearGradient
            id="paint0_linear_4987_43641"
            x1="393.741"
            y1="151.907"
            x2="5.24531"
            y2="232.022"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#7F7F83" />
            <stop offset="0.901042" stopColor="#7F7F83" stopOpacity="0.4" />
            <stop offset="1" stopColor="#7F7F83" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </ReturnsChartContainer>
  );
};
