import { MutationTuple, useMutation } from '@apollo/client';

import {
  ConfirmOrderTransferMutation as ConfirmOrderTransfer,
  ConfirmOrderTransferMutationVariables as ConfirmOrderTransferVariables,
  CONFIRM_ORDER_TRANSFER,
  GET_ORDER,
} from '@hedgehog/data-access/graphql';

export const useOrderConfirm = (): MutationTuple<
  ConfirmOrderTransfer,
  ConfirmOrderTransferVariables
> =>
  useMutation<ConfirmOrderTransfer, ConfirmOrderTransferVariables>(
    CONFIRM_ORDER_TRANSFER,
    { refetchQueries: [{ query: GET_ORDER }] },
  );
