import { QueryResult } from '@apollo/client';

import { useAuthenticatedQuery } from '@hedgehog/data-access/contexts';
import {
  GET_ORDER,
  GetOrderQuery as GetOrder,
  GetOrderQueryVariables as GetOrderVariables,
} from '@hedgehog/data-access/graphql';

export const useOrder = (
  orderId: string,
): QueryResult<GetOrder, GetOrderVariables> =>
  useAuthenticatedQuery<GetOrder, GetOrderVariables>(
    GET_ORDER,
    {
      id: orderId || '',
    },
    {
      fetchPolicy: 'cache-and-network',
    },
  );
