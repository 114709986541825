import { useMutation } from '@apollo/client';
import queryString from 'query-string';
import { useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { CreateOrderModal } from '@hedgehog/browser/investors/order/feature-create-order';
import { useAnalyticsTrack } from '@hedgehog/browser/investors/shared/analytics';
import { useCanInvest } from '@hedgehog/browser/investors/workflows/onboarding';
import { AssetInvestController } from '@hedgehog/browser/shared/assets';
import { useAuthenticatedQuery } from '@hedgehog/data-access/contexts';
import {
  GET_ASSET,
  GetAssetQuery as GetAsset,
  GetAssetQueryVariables as GetAssetVariables,
  Order,
  OrderStatus,
  AssetRoundClassStatus,
  EmitAssetDocumentsRequestMutation as EmitAssetDocumentsRequest,
  EmitAssetDocumentsRequestMutationVariables as EmitAssetDocumentsRequestVariables,
  EMIT_ASSET_DOCUMENTS_REQUEST,
  HAS_ASSET_DOCUMENTS_REQUESTED,
  HasAssetDocumentsRequestedQuery as HasAssetDocumentsRequested,
  HasAssetDocumentsRequestedQueryVariables as HasAssetDocumentsRequestedVariables,
  AssetRoundClassMetric,
} from '@hedgehog/data-access/graphql';
import { useElementHeight, useIsMobile } from '@hedgehog/data-access/hooks';
import {
  AssetGallery,
  AssetPageHeader,
  AssetSponsorToast,
} from '@hedgehog/ui/assets';
import { AutoscrollProvider } from '@hedgehog/ui/autoscroll';
import { Card } from '@hedgehog/ui/cards';
import {
  Page,
  EmptyStateContainer,
  LoadingContainer,
  HideBreakpoint,
} from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';
import { useModal } from '@hedgehog/ui/modals';
import { Tabs } from '@hedgehog/ui/tabs';
import { screens } from '@hedgehog/utils/sizes';

import { AssetHighlightsBlock } from '../../components';
import { AssetTabLink } from '../../components/asset-tab-link/asset-tab-link.component';
import { RegisterInterestModal } from '../../components/register-interest-modal/register-interest-modal.component';
import { AssetTabs } from '../../containers/asset-tabs/asset-tabs.container';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 1.5rem;

  @media only screen and (min-width: ${screens.medium}px) {
    flex-direction: row;
    gap: 6.25rem;
    margin: 2rem 4rem 0;
  }
`;

export const PageHeader = styled(AssetPageHeader)`
  margin-top: 1rem;
  margin-bottom: 1rem;

  @media only screen and (min-width: ${screens.medium}px) {
    margin-top: 0;
    margin-bottom: 2rem;
  }
`;

const InformationColumn = styled.div`
  @media only screen and (min-width: ${screens.medium}px) {
    flex: 25rem 0 0;
  }
`;

const FixedContainer = styled.div`
  @media only screen and (min-width: ${screens.medium}px) {
    position: fixed;
    max-width: 25rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;

const PageNavigation = styled.div<{ topOffset?: number }>`
  position: sticky;
  top: ${({ topOffset = 0 }): number => topOffset / 16}rem;
  z-index: 10;
  margin: 0 -1.5rem;
  background-color: white;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (min-width: ${screens.medium}px) {
    margin: 0 -4rem 1.5rem 0;
  }
`;

const NavigationTabs = styled(Tabs)`
  padding: 1rem 1.5rem;
  border-bottom: 2px solid;
  border-radius: 0;
  flex-flow: unset;
  ${({ theme }) => ({ borderBottomColor: theme.colors.grey100 })};

  @media only screen and (min-width: ${screens.medium}px) {
    padding: 1rem 0;
    // 4rem border offset from the page edge
    width: calc(100% - 4rem);
  }
`;

const ContentColumn = styled.main`
  flex: 1;
`;

const AssetArticleTabs = styled(AssetTabs)<{ topOffset?: number }>`
  padding-top: 2rem;
  scroll-margin-top: ${({ topOffset = 0 }): string =>
    `calc(4rem + ${topOffset}px)`};

  @media only screen and (min-width: ${screens.medium}px) {
    scroll-margin-top: 32rem;
    max-width: 40.25rem;
  }
`;

const DesktopAssetGallery = styled(AssetGallery)`
  display: none;

  @media only screen and (min-width: ${screens.medium}px) {
    display: block;
    margin-right: -4rem;
  }
`;

// thats one thick boi...
const ThickCard = styled(Card)`
  padding: 2rem;
  min-width: 20rem;
`;

const pageTabs = {
  returns: 'Returns',
  overview: 'Overview',
  investment: 'Investment',
  area: 'Area',
  docs: 'Docs',
  'how-it-works': 'How it works',
};

type PageType = keyof typeof pageTabs;

type LocationQueryParams = {
  from?: string;
  quantity?: number;
};

export const AssetPage = (): JSX.Element => {
  const { slug } = useParams();
  const track = useAnalyticsTrack();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useIsMobile();
  const topbarHeight = useElementHeight('#layout-topbar');

  const { data, error, loading } = useAuthenticatedQuery<
    GetAsset,
    GetAssetVariables
  >(
    GET_ASSET,
    { path: slug || '' },
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [requestDocuments, { loading: requestDocumentsLoading }] = useMutation<
    EmitAssetDocumentsRequest,
    EmitAssetDocumentsRequestVariables
  >(EMIT_ASSET_DOCUMENTS_REQUEST);

  const { data: hasRequestedDocuments } = useAuthenticatedQuery<
    HasAssetDocumentsRequested,
    HasAssetDocumentsRequestedVariables
  >(
    HAS_ASSET_DOCUMENTS_REQUESTED,
    {
      assetRoundClassId: data?.asset?.currentRound?.roundClass.id ?? '',
    },
    {
      skip: !data?.asset?.currentRound?.roundClass.id,
    },
  );

  const { openAndResolveWithSubmitValue: showRegisterInterestModal } =
    useModal<unknown>(({ submitModal }) => (
      <RegisterInterestModal submitModal={submitModal} />
    ));

  const { openAndResolveWithSubmitValue: createOrUpdateOrder } =
    useModal<Order>(({ submitModal, cancelModal }) => (
      <CreateOrderModal
        path={slug || ''}
        submitModal={submitModal}
        closeModal={cancelModal}
      />
    ));

  const { canInvest, loading: workflowsLoading } = useCanInvest();

  const showCreateOrderModal = async (): Promise<void> => {
    if (!canInvest) {
      // Send orderId and other info to KYC page so we can redirect back to the asset page after KYC
      navigate('#kyc', {
        state: { orderId: data?.order?.id || 'clear', slug, tab: 'overview' },
      });
      return;
    }

    if (!data?.order) {
      try {
        const orderId = await createOrUpdateOrder();
        navigate(`?orderId=${orderId}#order/open`);
      } catch (err) {
        // NOOP
      }
      return;
    }

    const { id: orderId, status } = data.order;
    if (data.order.status === OrderStatus.OPEN) {
      navigate(`?orderId=${orderId}#order/open`);
      return;
    }

    if (
      [
        OrderStatus.SYNCHRONISED,
        OrderStatus.AGREED,
        OrderStatus.FULFILLED,
        OrderStatus.ISSUED,
      ].includes(status)
    ) {
      navigate(`/buy/${orderId}/instructions`);
      return;
    }
  };

  const handleRegisterInterestClick = async (): Promise<void> => {
    track('RegisterInterestButton', 'Clicked', { asset_path: slug });
    if (!data?.asset?.currentRound?.roundClass) return;
    const variables = {
      assetRoundClassId: data?.asset.currentRound?.roundClass.id,
    };
    await requestDocuments({
      variables,
      refetchQueries: [{ query: HAS_ASSET_DOCUMENTS_REQUESTED, variables }],
    });
    await showRegisterInterestModal();
  };

  const handleInvestClick = (): void => {
    track(
      data?.asset.isTenantToken ? 'ClaimButton' : 'InvestButton',
      'Clicked',
      { asset_path: slug },
    );

    showCreateOrderModal();
  };

  useEffect(() => {
    // If the user can't invest, don't show the order creation modal
    if (!canInvest) return;
    // If the user already has an order for this asset, don't show the order creation modal
    if (data?.order) return;
    const queryParams = queryString.parse(
      location.search,
    ) as LocationQueryParams;

    // If the user can invest, and they've been redirected from the KYC page, automatically
    // open the order creation modal.
    if (queryParams.from !== 'kyc') return;
    showCreateOrderModal();
  }, [canInvest, location.search]);

  if (error) {
    return (
      <Page>
        <EmptyStateContainer>
          Something went wrong, please try again later
        </EmptyStateContainer>
      </Page>
    );
  }

  if (loading || workflowsLoading || !data) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }

  const HeaderContent = (
    <>
      <PageHeader
        tenantToken={data.asset.isTenantToken}
        title={data.asset.details.assetSummary.title}
        themes={data.asset.themes.map(({ category }) => category)}
        status={data.asset.currentRound?.roundClass.status}
      />
      <AssetInvestController
        slug={slug || ''}
        isTenantToken={data.asset.isTenantToken}
        assetRoundId={data.asset.currentRound?.id}
        minimumInvestment={
          data.asset.currentRound?.roundClass.minimumInvestmentAmount
        }
        status={data.asset.currentRound?.roundClass.status}
        openDate={data.asset.currentRound?.roundClass.liveAt || undefined}
        onInvestClick={handleInvestClick}
        onRegisterInterestClick={handleRegisterInterestClick}
        loading={requestDocumentsLoading}
        disabled={
          data.asset.currentRound?.roundClass.status ===
          AssetRoundClassStatus.REGISTER_INTEREST
            ? hasRequestedDocuments?.hasAssetDocumentsRequested || false
            : false
        }
        currency={data.asset.currentRound?.roundClass.currency || undefined}
      />
    </>
  );

  return (
    <AutoscrollProvider>
      <PageContainer>
        <InformationColumn>
          <FixedContainer>
            {isMobile ? HeaderContent : <ThickCard>{HeaderContent}</ThickCard>}
            <HideBreakpoint from="medium">
              <AssetGallery thumbnails={data.asset.thumbnails} />
            </HideBreakpoint>
            {data.asset.currentRound &&
              data.asset.currentRound.roundClass.metrics.length > 0 && (
                <HideBreakpoint to="medium">
                  <AssetHighlightsBlock
                    hasTaxLeakage={Boolean(
                      data.asset.currentRound.roundClass.hasTaxLeakage,
                    )}
                    metrics={
                      data.asset.currentRound.roundClass
                        .metrics as AssetRoundClassMetric[]
                    }
                  />
                </HideBreakpoint>
              )}
            {data.asset.sponsorId && (
              <HideBreakpoint to="medium">
                <AssetSponsorToast sponsorId={data.asset.sponsorId} />
              </HideBreakpoint>
            )}
          </FixedContainer>
        </InformationColumn>
        <ContentColumn>
          <PageNavigation topOffset={topbarHeight}>
            <NavigationTabs>
              {data.asset.pages
                ? data.asset.pages
                    .sort((a, b) => a.order - b.order)
                    .map((page) => (
                      <AssetTabLink
                        key={page.slug}
                        to={page.slug}
                        state={{
                          noScroll: true,
                        }}
                      >
                        {page.title}
                      </AssetTabLink>
                    ))
                : []}
            </NavigationTabs>
          </PageNavigation>
          <DesktopAssetGallery thumbnails={data.asset.thumbnails} />
          <AssetArticleTabs asset={data.asset} />
        </ContentColumn>
      </PageContainer>
    </AutoscrollProvider>
  );
};

export default AssetPage;
