import styled, { CSSObject } from 'styled-components';

import { Avatar } from '@hedgehog/ui/icons';
import { Heading5 } from '@hedgehog/ui/typography';

export const KycCompleted = styled(({ className }: { className?: string }) => (
  <div className={className}>
    <Avatar
      icon="check-circle"
      color="success"
      backgroundColor="success"
      backgroundOpacity="20%"
      size="l"
    />
    <Heading5>
      On-boarding Completed
      {/* TODO: Add partner name and messaging
      <br />
      [Partner] will be in touch with you in a couple of days. */}
    </Heading5>
  </div>
))`
  ${({ theme }): CSSObject => ({
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '2rem',
    paddingTop: '2rem',
    [`@media (min-width: ${theme.breakpoints.large}px)`]: {
      paddingTop: '0',
    },
  })};
`;
