import styled, { CSSObject } from 'styled-components';

import { Card, CardContent } from '@hedgehog/ui/cards';

export const OrderDetailsCard = styled(Card)`
  ${({ theme }): CSSObject => ({
    gap: theme.spacing.normal,
    paddingTop: theme.spacing.large,
    paddingBottom: theme.spacing.large,
  })};
  ${CardContent} {
    ${({ theme }): CSSObject => ({
      gap: theme.spacing.normal,
    })};
  }
`;
