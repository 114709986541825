import styled, { CSSObject } from 'styled-components';

import { convertToNumber } from '@hedgehog/ui/themes';

const Wrapper = styled.div`
  height: 1.5rem;
  width: 1.5rem;
`;

export interface CountdownIconProps {
  remainingDays: number;
  days?: number;
  className?: string;
}

const Path = styled.path<{
  index: string | number;
  current: number;
}>`
  ${({ index, current }): CSSObject => ({
    fill: 'currentColor',
    opacity: current > convertToNumber(index, -1) ? '0.2' : undefined,
  })};
`;

export function CountdownIcon({
  remainingDays,
  days = 12,
  className,
}: CountdownIconProps): JSX.Element {
  const daysLeft = days - remainingDays;

  return (
    <Wrapper className={className}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Path
          index="11"
          current={daysLeft}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.1998 0V7.2H10.7998V0H13.1998Z"
        />
        <Path
          index="10"
          current={daysLeft}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.0394 2.20781L15.4394 8.4432L13.3609 7.2432L16.9609 1.00781L19.0394 2.20781Z"
        />
        <Path
          index="9"
          current={daysLeft}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.9925 7.0394L16.7571 10.6394L15.5571 8.56094L21.7925 4.96094L22.9925 7.0394Z"
        />
        <Path
          index="8"
          current={daysLeft}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 13.1998L16.8 13.1998L16.8 10.7998L24 10.7998L24 13.1998Z"
        />
        <Path
          index="7"
          current={daysLeft}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.7922 19.0394L15.5567 15.4393L16.7567 13.3609L22.9922 16.9609L21.7922 19.0394Z"
        />
        <Path
          index="6"
          current={daysLeft}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.9606 22.992L13.3606 16.7566L15.4391 15.5566L19.0391 21.792L16.9606 22.992Z"
        />
        <Path
          index="5"
          current={daysLeft}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.8002 24L10.8002 16.8L13.2002 16.8L13.2002 24L10.8002 24Z"
        />
        <Path
          index="4"
          current={daysLeft}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.9606 21.7922L8.5606 15.5568L10.6391 16.7568L7.03906 22.9922L4.9606 21.7922Z"
        />
        <Path
          index="3"
          current={daysLeft}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.00752 16.9606L7.2429 13.3606L8.4429 15.4391L2.20752 19.0391L1.00752 16.9606Z"
        />
        <Path
          index="2"
          current={daysLeft}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-1.25721e-07 10.8002L7.2 10.8002L7.2 13.2002L0 13.2002L-1.25721e-07 10.8002Z"
        />
        <Path
          index="1"
          current={daysLeft}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.20781 4.9606L8.44332 8.56067L7.24332 10.6391L1.00781 7.03906L2.20781 4.9606Z"
        />
        <Path
          index="0"
          current={daysLeft}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.0394 1.00801L10.6394 7.24339L8.56094 8.44339L4.96094 2.20801L7.0394 1.00801Z"
        />
      </svg>
    </Wrapper>
  );
}
