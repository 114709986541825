import { useQueryParams } from '@hedgehog/data-access/hooks';

const GOOGLE_CLICK_ID = 'gclid';
const TWITTER_CLICK_ID = 'twclid';
const TIKTOK_CLICK_ID = 'ttclid';

type AdvertisementSource = {
  platform: 'twitter' | 'google' | 'tiktok';
  clickId: string;
};

export function useAdvertisementParams(): AdvertisementSource | null {
  const params = useQueryParams({ once: true });

  if (params.has(GOOGLE_CLICK_ID)) {
    return {
      platform: 'google',
      clickId: params.get(GOOGLE_CLICK_ID) as string,
    };
  }
  if (params.has(TWITTER_CLICK_ID)) {
    return {
      platform: 'twitter',
      clickId: params.get(TWITTER_CLICK_ID) as string,
    };
  }
  if (params.has(TIKTOK_CLICK_ID)) {
    return {
      platform: 'tiktok',
      clickId: params.get(TIKTOK_CLICK_ID) as string,
    };
  }

  return null;
}
