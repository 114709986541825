import { QueryResult } from '@apollo/client';

import { useAuthenticatedQuery } from '@hedgehog/data-access/contexts';
import {
  GetAssetRoundClassReturnsQuery as GetAssetRoundClassReturns,
  GetAssetRoundClassReturnsQueryVariables as GetAssetRoundClassReturnsVariables,
  GET_ASSET_ROUND_RETURNS,
} from '@hedgehog/data-access/graphql';

export const useAssetRoundReturns = (
  variables: GetAssetRoundClassReturnsVariables,
): QueryResult<GetAssetRoundClassReturns> => {
  return useAuthenticatedQuery<
    GetAssetRoundClassReturns,
    GetAssetRoundClassReturnsVariables
  >(GET_ASSET_ROUND_RETURNS, variables);
};
