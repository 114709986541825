import { QueryResult } from '@apollo/client';

import { useAuthenticatedQuery } from '@hedgehog/data-access/contexts';
import {
  GetOrdersQuery as GetOrders,
  OrderStatus,
  GET_ORDERS,
} from '@hedgehog/data-access/graphql';

export const useOrders = (): QueryResult<GetOrders> =>
  useAuthenticatedQuery<GetOrders>(
    GET_ORDERS,
    {
      status: [
        OrderStatus.OPEN,
        OrderStatus.AGREED,
        OrderStatus.FULFILLED,
        OrderStatus.ISSUED,
        OrderStatus.SYNCHRONISED,
        OrderStatus.REFUNDED,
        OrderStatus.COMPLETED,
        OrderStatus.CLAIMED,
      ],
    },
    {
      fetchPolicy: 'network-only',
    },
  );
