import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  OverrideKycStatus,
  ParseKycCheckParams,
  isLegacy,
  parseTaxFormStatus,
  useLazyTaxForm,
} from '@hedgehog/browser/investors/kyc/data-access';
import { StandardProps } from '@hedgehog/ui/utils';

import { OnboardingAction } from '../../components';

export type OnboardingTaxFormActionProps = StandardProps<
  ParseKycCheckParams | OverrideKycStatus,
  never
>;

export const OnboardingTaxFormAction = (
  kycCheckParams: OnboardingTaxFormActionProps & {
    skipFetchTaxForm?: boolean;
  },
): JSX.Element => {
  const [searchParams] = useSearchParams();
  const [fetchTaxForm, { data, loading }] = useLazyTaxForm();
  const didDocusignRedirect =
    searchParams.get('docusignEvent') === 'completed' &&
    ['US_PERSON_TAX_FORM', 'NON_US_PERSON_TAX_FORM'].includes(
      searchParams.get('hedgehogProcess') || '',
    );

  const taxFormStatus = isLegacy(kycCheckParams)
    ? parseTaxFormStatus(kycCheckParams)
    : kycCheckParams.statusOverride;
  const skipFetch = isLegacy(kycCheckParams)
    ? kycCheckParams.status.identityCheck === 'not_started'
    : kycCheckParams.skipFetchTaxForm;

  useEffect(() => {
    if (data?.taxFormProgress) return;
    if (skipFetch) return;
    fetchTaxForm();
  }, [taxFormStatus, data, fetchTaxForm, skipFetch]);

  return (
    <OnboardingAction
      loading={loading}
      title="Tax form"
      href={data?.taxFormProgress.url ?? ''}
      messages={{
        failed: 'Declined',
        inprogress: 'Verifying, please wait...',
        pending: '2 mins, complete form',
        blocked: '2 mins, complete form',
      }}
      icons={{
        inprogress: 'clock',
      }}
      status={
        didDocusignRedirect && taxFormStatus === 'pending'
          ? 'inprogress'
          : taxFormStatus
      }
    />
  );
};
