import { InvestorType, isKycOnly } from '@hedgehog/data-access/graphql';
import { useIsNativeMobile } from '@hedgehog/data-access/native-mobile';

import { useGuardRegistry } from '../hooks';

export interface InvestorTypeGuardProps {
  priority: number;
}

// TODO: 🌎 onboarding tech-debt - https://linear.app/hedgehog-invest/issue/HOG-2898/%F0%9F%92%A1-get-rid-of-all-the-oakglen-if-statements
export const InvestorTypeGuard = ({
  priority,
}: InvestorTypeGuardProps): JSX.Element => {
  const isNativeMobile = useIsNativeMobile();

  useGuardRegistry({
    name: 'investor-type',
    priority,
    validate: (data) => {
      const { user, investorTypeDeclaration, partnerSettings } = data || {};

      if (isNativeMobile) return null;
      // TODO: 🔍 KYC-only onboarding tech-debt - remove me - https://linear.app/hedgehog-invest/issue/HOG-2898/%F0%9F%92%A1-get-rid-of-all-the-oakglen-if-statements
      if (isKycOnly(partnerSettings)) return null;

      // TODO: 🌎 onboarding tech-debt - https://linear.app/hedgehog-invest/issue/HOG-2898/%F0%9F%92%A1-get-rid-of-all-the-oakglen-if-statements
      // Might be better to remove the `investorTypeEveryday` check and simply expire all declarations that are EVERYDAY
      const investorTypeUndeclared = !investorTypeDeclaration;
      const investorTypeEveryday =
        investorTypeDeclaration?.value === InvestorType.EVERYDAY;
      const investorTypeExpired = investorTypeDeclaration?.expired;
      const hasToRedeclare =
        investorTypeUndeclared || investorTypeEveryday || investorTypeExpired;

      // TODO: 🌎 onboarding tech-debt - remove me - https://linear.app/hedgehog-invest/issue/HOG-2898/%F0%9F%92%A1-get-rid-of-all-the-oakglen-if-statements
      const doesRequireInvestorType = ['GBR'].includes(user?.country ?? '');
      if (doesRequireInvestorType && hasToRedeclare) {
        return {
          hash: '#signup/status',
          search: '?redeclare=true',
        };
      }

      return null;
    },
  });

  return <></>;
};
