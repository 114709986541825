import { useEffect } from 'react';

import { useAccountAbstraction } from '@hedgehog/browser/shared/account-abstraction';

import {
  GetDocumentParams,
  UseGetDocumentReturn,
  useLazyGetDocument,
} from './use-lazy-get-document.hook';

export const useGetDocument = ({
  name,
}: GetDocumentParams): UseGetDocumentReturn => {
  const { jsonRpcProvider } = useAccountAbstraction();
  const [getDocument, returnData] = useLazyGetDocument();
  useEffect(() => {
    if (!jsonRpcProvider) return;
    getDocument({ name });
  }, [jsonRpcProvider]);
  return returnData;
};
