import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { Loader } from '@hedgehog/ui/loaders';
import { StandardProps } from '@hedgehog/ui/utils';

import { GuardsContext, useGuardsContext } from '../contexts';
import { orderByPriority } from '../helpers';
import { useUserAction } from '../hooks';

export type CascadeGuardsProps = StandardProps<{
  order: 'asc' | 'desc';
}>;

/**
 * @desc
 * Will execute contained witin guards in order of priority, where default is 0.
 * @param param0
 * @returns
 */
export const CascadeGuards = ({
  order = 'desc',
  children,
}: CascadeGuardsProps): JSX.Element => {
  const navigate = useNavigate();
  const contextValue = useGuardsContext();
  const { data, loading: actionLoading } = useUserAction();

  useEffect(() => {
    if (!data) return;
    if (actionLoading) return;
    if (!contextValue.guards) return;

    for (const guard of contextValue.guards.sort(orderByPriority(order))) {
      const toOrNull = guard.validate(data);
      if (toOrNull) {
        return navigate(toOrNull, { replace: true });
      }
    }
  }, [data, contextValue, order]);

  return (
    <GuardsContext.Provider value={contextValue}>
      {children}
      {actionLoading ? <Loader /> : <Outlet />}
    </GuardsContext.Provider>
  );
};
