import { QueryResult } from '@apollo/client';

import { useAuthenticatedQuery } from '@hedgehog/data-access/contexts';
import {
  GET_ARTICLE,
  GetArticleQuery as GetArticle,
  GetArticleQueryVariables as GetArticleVariables,
} from '@hedgehog/data-access/graphql';

export const useArticle = (slug: string): QueryResult<GetArticle> =>
  useAuthenticatedQuery<GetArticle, GetArticleVariables>(GET_ARTICLE, {
    slug,
  });
