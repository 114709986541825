import React, {
  ReactEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { throttle } from 'throttle-debounce';

import { StandardProps } from '@hedgehog/ui/utils';
import { screens } from '@hedgehog/utils/sizes';

import BottomSheet from '../BottomSheet/BottomSheet';

const DeclarationContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

const DeclarationScrollWrapper = styled.div`
  position: relative;
  flex: 1 1 auto;
  overflow: hidden;
  overflow-y: auto;
  margin-top: 1.5rem;
`;

const DeclarationContent = styled.div`
  position: relative;
  display: block;
  padding: 0 1.5rem 1.5rem;
`;

const DeclarationToggleTrap = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;

  box-sizing: border-box;
  padding-top: 3rem;
`;

const DeclarationControls = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-flow: row nowrap;
  margin: 0.5rem 1.5rem 2.5rem;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: calc(100% + 0.5rem - 1px);

    width: 100%;
    height: 1rem;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.9) 80%,
      rgba(255, 255, 255, 1) 100%
    );
  }

  & > * {
    margin: 0 0.5rem;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  @media only screen and (max-width: ${screens.extrasmall}px) {
    flex-wrap: wrap;
    & > * {
      flex: 1 1 auto;
      margin: 0;
      margin-top: 0.5rem;
      &:first-child {
        margin-top: 0;
      }
    }
  }
`;

/**
 * We're checking trapElement's top edge and whether it's within the viewport
 */
const isTrapVisible = (
  scrollableElement: HTMLDivElement,
  trapElement: HTMLDivElement,
): boolean => {
  const scrollOffsetFromBottom =
    scrollableElement.scrollTop + scrollableElement.clientHeight;

  return scrollOffsetFromBottom >= trapElement.offsetTop;
};

export interface DeclarationActionsProps {
  trapReached: boolean;
}

export type DeclarationsModalProps = StandardProps<{
  actions: React.FC<DeclarationActionsProps>;
}>;

export const DeclarationModal = ({
  actions: Actions,
  className,
  children,
}: DeclarationsModalProps): JSX.Element => {
  const [trapReached, setTrapReached] = useState(false);
  const trapRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const updateWhenTrapReached = (): void => {
    if (trapReached) return;
    if (!wrapperRef.current) return;
    if (!trapRef.current) return;
    const { current: scrollableElement } = wrapperRef;
    const { current: trapElement } = trapRef;
    if (isTrapVisible(scrollableElement, trapElement)) {
      setTrapReached(true);
    }
  };

  const updateOnAvailableReference = useCallback(
    (scrollableElement: HTMLDivElement) => {
      if (scrollableElement === null) return;
      wrapperRef.current = scrollableElement;
      updateWhenTrapReached();
    },
    [],
  );

  const onScroll: ReactEventHandler = () => updateWhenTrapReached();
  const onWindowResize = throttle(100, () => updateWhenTrapReached(), {
    noTrailing: false,
  });

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);
    return () => window.removeEventListener('resize', onWindowResize);
  }, []);

  return (
    <BottomSheet className={className}>
      <DeclarationContainer>
        <DeclarationScrollWrapper
          onScroll={onScroll}
          ref={updateOnAvailableReference}
        >
          <DeclarationContent>
            {children}
            <DeclarationToggleTrap ref={trapRef} />
          </DeclarationContent>
        </DeclarationScrollWrapper>
        {Actions && (
          <DeclarationControls>
            <Actions trapReached={trapReached} />
          </DeclarationControls>
        )}
      </DeclarationContainer>
    </BottomSheet>
  );
};
