import { useAuthenticatedQuery } from '@hedgehog/data-access/contexts';
import {
  GET_ARTICLES,
  GetArticlesQuery as GetArticles,
  GetArticlesQueryVariables as GetArticlesVariables,
} from '@hedgehog/data-access/graphql';

export const useArticles = (
  tags?: string[],
): {
  data?: GetArticles;
  error?: Error;
  loading: boolean;
} => {
  return useAuthenticatedQuery<GetArticles, GetArticlesVariables>(
    GET_ARTICLES,
    {
      tags,
    },
  );
};
