import { useEffect, useState } from 'react';
import {
  matchPath,
  NavLinkProps,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import styled from 'styled-components';

import { Tab } from '@hedgehog/ui/tabs';

const ListItem = styled.li`
  display: flex;
`;

export interface NavigationListItemProps extends NavLinkProps {
  patterns?: string[];
  className?: string;
  icon?: React.ReactNode | React.ReactNode[];
  children?: React.ReactNode | React.ReactNode[];
}

export const NavigationListItem = ({
  className,
  icon,
  children,
  patterns = [],
  ...linkProps
}: NavigationListItemProps): JSX.Element => {
  const location = useLocation();
  const [doesMatch, toggleDoesMatch] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    for (const pattern of patterns) {
      if (matchPath(pattern, location.pathname)) {
        return toggleDoesMatch(true);
      }
    }
    toggleDoesMatch(false);
  }, [location.pathname]);

  return (
    <ListItem className={className}>
      <Tab
        active={doesMatch}
        icon={icon}
        onClick={(): void => navigate(linkProps.to)}
      >
        {children}
      </Tab>
    </ListItem>
  );
};
