import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '@hedgehog/data-access/contexts';
import {
  CONFIRM_PASSWORD,
  ConfirmPasswordMutation as ConfirmPassword,
  ConfirmPasswordMutationVariables as ConfirmPasswordVariables,
} from '@hedgehog/data-access/graphql';
import { useQueryParams } from '@hedgehog/data-access/hooks';
import { HeaderWithBack } from '@hedgehog/ui/headers';
import { Heading } from '@hedgehog/ui/typography';

import { ConfirmPasswordForm, ConfirmPasswordFormData } from '../../containers';

export const ConfirmPasswordPage = (): JSX.Element => {
  const [confirmPassword, { loading, error }] = useMutation<
    ConfirmPassword,
    ConfirmPasswordVariables
  >(CONFIRM_PASSWORD);

  const auth = useAuth();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const email = queryParams.get('email');
  const code = queryParams.get('code');

  const handleSubmit = async (
    formData: Required<ConfirmPasswordFormData>,
  ): Promise<void> => {
    if (!email || !code) return;
    if (!auth.signin) return;

    try {
      const response = await confirmPassword({
        variables: {
          email,
          verificationCode: code,
          newPassword: formData.password,
        },
      });

      if (!response.data?.confirmPassword) {
        // The backend did not return a complete signup response.
        return;
      }

      const { token, user } = response.data.confirmPassword;
      if (!user || !token) {
        // The backend did not return a valid token or user.
        return;
      }

      await auth.setTokens({
        accessToken: token,
        refreshToken: '', // TODO: Should we have a refresh token?
      });
      navigate('/explore?source=forgot-password');
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  return (
    <>
      <HeaderWithBack>
        <Heading level="h5">Forgot password</Heading>
      </HeaderWithBack>

      <ConfirmPasswordForm
        onSubmit={handleSubmit}
        loading={loading}
        error={error}
      />
    </>
  );
};
