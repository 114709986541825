import { QueryResult } from '@apollo/client';

import { useAuthenticatedQuery } from '@hedgehog/data-access/contexts';
import {
  GET_COURSE,
  GetCourseQuery as GetCourse,
  GetCourseQueryVariables as GetCourseVariables,
} from '@hedgehog/data-access/graphql';

export const useCourse = (slug: string): QueryResult<GetCourse> => {
  return useAuthenticatedQuery<GetCourse, GetCourseVariables>(GET_COURSE, {
    slug,
  });
};
