import styled, { CSSObject } from 'styled-components';

const Container = styled.div<Pick<LogoAvatarProps, 'size'>>`
  position: relative;
  box-sizing: border-box;
  border-radius: 50%;
  border: 0.125rem solid ${({ theme }): string => theme.colors.shadow100};

  ${({ size }): CSSObject => ({
    width: size === 'l' ? '3.5rem' : size === 'm' ? '2.5rem' : '2rem',
    height: size === 'l' ? '3.5rem' : size === 'm' ? '2.5rem' : '2rem',
    backgroundSize: size === 'l' ? '3.5rem' : size === 'm' ? '2.5rem' : '2rem',
  })}
`;

const Avatar = styled.div<Pick<LogoAvatarProps, 'imageUrl'>>`
  box-sizing: border-box;
  border-radius: 50%;
  border: 0.25rem solid white;
  background: url(${({ imageUrl }) => imageUrl}) no-repeat center;
  width: 100%;
  height: 100%;
  background-size: cover;
`;

type LogoAvatarProps = {
  imageUrl: string;
  size?: 'l' | 'm' | 's';
};

export const LogoAvatar = ({
  imageUrl,
  size = 'l',
}: LogoAvatarProps): JSX.Element => {
  return (
    <Container size={size}>
      <Avatar imageUrl={imageUrl} />
    </Container>
  );
};
