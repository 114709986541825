import {
  OverrideKycStatus,
  ParseKycCheckParams,
  isLegacy,
  parseInvestorConfirmationStatus,
} from '@hedgehog/browser/investors/kyc/data-access';
import { StandardProps } from '@hedgehog/ui/utils';

import { OnboardingAction } from '../../components';

export type OnboardingInvestorConfirmationActionProps = StandardProps<
  ParseKycCheckParams | OverrideKycStatus,
  never
>;

export const OnboardingInvestorConfirmationAction = ({
  ...kycCheckParams
}: OnboardingInvestorConfirmationActionProps): JSX.Element => {
  return (
    <OnboardingAction
      status={
        isLegacy(kycCheckParams)
          ? parseInvestorConfirmationStatus(kycCheckParams)
          : kycCheckParams.statusOverride
      }
      to="#kyc/investor-confirmation"
      title="Investor Confirmation"
      messages={{ pending: 'Accept declaration' }}
    />
  );
};
