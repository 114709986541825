import { TAddressPrediction } from '@hedgehog/shared/types';
import { Heading, Paragraph } from '@hedgehog/ui/typography';

import { AddressListItem } from './address-list-item.component';

type AddressPredictionItemProps = {
  prediction: TAddressPrediction;
  onClick: (value: TAddressPrediction) => void;
};

export const AddressPredictionItem = ({
  prediction,
  onClick,
}: AddressPredictionItemProps): JSX.Element => {
  return (
    <AddressListItem
      key={prediction.summary}
      data-address-prediction={prediction.summary}
      onClick={(): void => onClick(prediction)}
    >
      <Heading level="h6">
        {prediction.summary}, {prediction.location}
      </Heading>
      {prediction.addressCount > 1 && (
        <Paragraph small color="grey400">
          {prediction.addressCount} addresses
        </Paragraph>
      )}
    </AddressListItem>
  );
};
