import styled from 'styled-components';

import { Icon, IconProps } from '@hedgehog/ui/icons';

const CustomButton = styled.div`
  cursor: pointer;
`;

type HeaderButtonProps = {
  icon: IconProps['icon'];
  color?: IconProps['color'];
  backgroundColor?: IconProps['backgroundColor'];
  onClick?: () => void;
  className?: string;
};

export const HeaderButton = styled(
  ({
    onClick,
    icon,
    className,
    color,
    backgroundColor,
  }: HeaderButtonProps): JSX.Element => {
    return (
      <CustomButton className={className} onClick={onClick}>
        <Icon
          icon={icon}
          size="m"
          color={color}
          backgroundColor={backgroundColor}
        />
      </CustomButton>
    );
  },
)``;
