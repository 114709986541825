import styled from 'styled-components';

import { SecondaryButton } from '@hedgehog/ui/buttons';
import { Chunk } from '@hedgehog/ui/icons';
import {
  BottomSheet,
  BottomSheetContent,
  ModalProps,
} from '@hedgehog/ui/modals';
import { Heading, Paragraph } from '@hedgehog/ui/typography';
import { StandardProps } from '@hedgehog/ui/utils';

const RegisterChunk = styled(Chunk)`
  width: 2rem;
  height: 2rem;
`;

const RegisterBottomSheet = styled(BottomSheet)`
  ${BottomSheetContent} {
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
    padding: 1rem 1.5rem;
  }
`;

export type RegisterInterestModal = StandardProps<
  Pick<ModalProps, 'submitModal'>
>;

export const RegisterInterestModal = ({
  submitModal,
}: RegisterInterestModal): JSX.Element => (
  <RegisterBottomSheet>
    <RegisterChunk size="l" icon="check-circle" color="success" />
    <Heading level="h4">Thank you for registering your interest</Heading>
    <Paragraph>
      Further documentation for this opportunity will be available in due
      course.
    </Paragraph>
    <SecondaryButton onClick={submitModal}>Done</SecondaryButton>
  </RegisterBottomSheet>
);
