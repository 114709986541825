import React, { useLayoutEffect } from 'react';
import { NavLink, Outlet, useMatch } from 'react-router-dom';
import styled, { CSSObject } from 'styled-components';

import {
  useIsInvestingEnabled,
  usePartnerSetting,
} from '@hedgehog/browser/investors/kyc/data-access';
import { useIsMobile } from '@hedgehog/data-access/hooks';
import { ChunkIcon, LiveIcon } from '@hedgehog/ui/icons';
import {
  LoadingContainer,
  NotificationButton,
  Page,
} from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';
import { SaveScrollState } from '@hedgehog/ui/routing';
import { Tab } from '@hedgehog/ui/tabs';

const ShiftedLiveIcon = styled(LiveIcon)`
  top: -25%;
  left: -25%;
`;

const ExploreTab = styled(Tab)`
  display: flex;
  align-items: center;
  margin-right: 0.25rem;

  & > *:first-child {
    ${({ theme, active }): CSSObject => ({
      display: active ? '' : 'none',
      color: theme.colors.black,
    })};
  }
`;

const TabsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const NavWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

const ExploreTabs = (): JSX.Element | null => {
  const isPartners = !!useMatch('/explore/partners');
  const isExplore = !isPartners;
  const isMobile = useIsMobile();
  const { enabled: investingEnabled, loading: userLoading } =
    useIsInvestingEnabled();
  const { enabled: partnersTabEnabled } = usePartnerSetting('partners_tab');

  if (userLoading) {
    return <Loader center />;
  }

  return (
    <TabsWrapper>
      <NavWrapper>
        {investingEnabled && (
          <NavLink to="" data-active={isExplore}>
            <ExploreTab
              icon={<ShiftedLiveIcon color="black" />}
              active={isExplore}
            >
              Invest
            </ExploreTab>
          </NavLink>
        )}
        {partnersTabEnabled && (
          <NavLink to="partners" data-active={isPartners}>
            <ExploreTab icon={<ChunkIcon />} active={isPartners}>
              Partners
            </ExploreTab>
          </NavLink>
        )}
      </NavWrapper>
      <div>
        {isMobile && (
          <NotificationButton
            to={!isMobile ? '#notifications' : '/notifications'}
          />
        )}
      </div>
    </TabsWrapper>
  );
};

export const ExploreLayout = (): JSX.Element | null => {
  const handleScroll = (): void => {
    SaveScrollState({
      key: window.location.pathname,
      y: window.pageYOffset,
    });
  };

  // save scroll state
  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Page>
      <ExploreTabs />
      <React.Suspense
        fallback={
          <LoadingContainer>
            <Loader />
          </LoadingContainer>
        }
      >
        <Outlet />
      </React.Suspense>
    </Page>
  );
};
