import styled, { CSSObject } from 'styled-components';

import { CurrencyCode } from '@hedgehog/shared/types';
import { Avatar, IconType } from '@hedgehog/ui/icons';
import { ThemeColorName } from '@hedgehog/ui/themes';
import { currencies } from '@hedgehog/utils/formats';

import { Pill, StyledPill } from '../Pill/Pill';

export type TransactionPillProps = {
  symbol?: '' | '+' | '-';
  currency?: CurrencyCode;
  quantity?: string | number;
  icon?: IconType;
  className?: string;
  color?: ThemeColorName;
};

export const StyledTransactionPill = styled(StyledPill)<TransactionPillProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ color, theme }): CSSObject => ({
    backgroundColor: color ? theme.colors[color] : undefined,
  })}
`;

const PillIcon = styled(Avatar)`
  width: 16px;
  height: 16px;
  padding: 0;
  margin-left: 8px;
`;

export const TransactionPill = ({
  symbol = '',
  quantity,
  currency,
  icon,
  ...pillProps
}: TransactionPillProps): JSX.Element => {
  return (
    <StyledTransactionPill as={Pill} {...pillProps}>
      {symbol}
      {typeof quantity === 'string'
        ? quantity
        : currencies.formatMoney(quantity || 0)}
      {icon && (
        <PillIcon
          icon={icon}
          color="white"
          backgroundColor={pillProps.color}
          data-testid={icon}
        />
      )}
    </StyledTransactionPill>
  );
};
