import { Analytics, Context } from '@segment/analytics-next';

export function isAnalytics(value: unknown): value is Analytics {
  return value instanceof Analytics;
}

export function isAnalyticsContext(value: unknown): value is Context {
  return value instanceof Context;
}

type ObjectLike = { [key: string | number]: string | number | ObjectLike };
type Metadata = Record<string, string | number | ObjectLike>;

export function getBrowserMetadata(): Metadata {
  const { screen } = window;
  return {
    screen: {
      width: screen.width,
      height: screen.height,
      orientation: {
        // orientation is unsupported on safari: https://caniuse.com/?search=orientation.
        type: screen.orientation?.type,
        angle: screen.orientation?.angle,
      },
    },
    viewport: { width: screen.availWidth, height: screen.availHeight },
  };
}

/**
 * Typing helpers
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const defineActions = <T extends string>(...names: T[]): T => '' as T;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const defineNames = <T extends string>(...names: T[]): T => '' as T;
