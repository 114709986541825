import { useAuthUser } from '@hedgehog/browser/investors/account/data-access';
import { useAccreditation } from '@hedgehog/browser/investors/kyc/data-access';
import { AccreditationVerificationDocumentStatus } from '@hedgehog/data-access/graphql';
import { PrimaryButton } from '@hedgehog/ui/buttons';
import { HeaderWithBack } from '@hedgehog/ui/headers';
import {
  Page,
  EmptyStateContainer,
  LoadingContainer,
} from '@hedgehog/ui/layouts';
import { VSpace } from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';
import { Heading3, Paragraph } from '@hedgehog/ui/typography';

export const AccreditationPage = (): JSX.Element => {
  const user = useAuthUser();
  const { data, loading, error } = useAccreditation();

  if (error) {
    return (
      <Page>
        <EmptyStateContainer>
          Something went wrong, please try again later
        </EmptyStateContainer>
      </Page>
    );
  }

  if (loading || !data?.accreditation) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }

  const documentNeedsUploading =
    data.accreditation.verificationDocumentStatus !==
      AccreditationVerificationDocumentStatus.APPROVED &&
    data.accreditation.verificationDocumentStatus !==
      AccreditationVerificationDocumentStatus.IN_REVIEW;

  const redirectToDocusign = (): void => {
    if (data?.accreditation) {
      window.location.href = data.accreditation.verificationDocumentUploadURL;
    }
  };

  const headingTitle =
    user?.country === 'USA' ? 'Investor accreditation' : 'Investor assessment';

  return (
    <Page>
      <HeaderWithBack />
      <Heading3>{headingTitle}</Heading3>
      {documentNeedsUploading ? (
        <VSpace>
          <Paragraph>
            Please upload a document that shows you are an accredited investor.
            <br />
            You will be redirected to DocuSign to upload documentation.
          </Paragraph>

          <PrimaryButton
            type="button"
            data-testid="acc-vd-btn"
            onClick={(): void => {
              setTimeout(() => {
                redirectToDocusign();
              }, 500);
            }}
          >
            Proceed to upload
          </PrimaryButton>
        </VSpace>
      ) : (
        <Paragraph>We are reviewing your accreditation document</Paragraph>
      )}
    </Page>
  );
};
