import { MutationTuple, OperationVariables, useMutation } from '@apollo/client';

import {
  StartIdentityChecksMutation as StartIdentityChecks,
  START_IDENTITY_CHECKS,
  GET_TAX_FORM,
  GET_ONBOARDING_STATUS,
} from '@hedgehog/data-access/graphql';

export const useIdentityCheck = (): MutationTuple<
  StartIdentityChecks,
  OperationVariables
> =>
  useMutation<StartIdentityChecks>(START_IDENTITY_CHECKS, {
    refetchQueries: [
      {
        query: GET_TAX_FORM,
      },
      {
        query: GET_ONBOARDING_STATUS,
      },
    ],
  });
