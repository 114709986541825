import { MutationTuple, useMutation } from '@apollo/client';

import {
  GET_ONBOARDING_WORKFLOWS,
  GET_USER_ACTION_DATA,
  SUBMIT_ASSESSMENT,
  SubmitAssessmentMutation as SubmitAssessment,
  SubmitAssessmentMutationVariables as SubmitAssessmentVariables,
} from '@hedgehog/data-access/graphql';

export const useAssessmentSubmit = (): MutationTuple<
  SubmitAssessment,
  SubmitAssessmentVariables
> =>
  useMutation<SubmitAssessment, SubmitAssessmentVariables>(SUBMIT_ASSESSMENT, {
    refetchQueries: [
      { query: GET_ONBOARDING_WORKFLOWS },
      { query: GET_USER_ACTION_DATA },
    ],
  });
