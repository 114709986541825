import { ApolloError, useQuery } from '@apollo/client';

import {
  GET_ONBOARDING_WORKFLOWS,
  GetOnboardingWorkflowsQuery as GetOnboardingWorkflows,
  WorkflowStatus,
  WorkflowStep,
} from '@hedgehog/data-access/graphql';

import {
  PreInvestHelper,
  PreInvestWorkflow,
} from './pre-invest-workflow.helper';

type GetOnboardingWorkflows_onboardingWorkflows_postSignup =
  GetOnboardingWorkflows['onboardingWorkflows']['postSignup'][0];

export { WorkflowStep };

export const WorkflowPaths = {
  NDA: '#signup/nda',
  Jurisdiction: '#signup/jurisdiction',
  Assessment: '#assessment',
  Status: '#signup/status',
  Cooldown: '/cooldown',
};

const to = (
  step:
    | GetOnboardingWorkflows_onboardingWorkflows_postSignup
    | null
    | undefined,
): string | null => {
  switch (step?.name) {
    case WorkflowStep.oakglen_nda:
      return WorkflowPaths.NDA;
    case WorkflowStep.oakglen_investor_letter_gbr:
    case WorkflowStep.oakglen_investor_letter_jey:
    case WorkflowStep.jurisdiction_warning_declaration:
      return WorkflowPaths.Jurisdiction;
    case WorkflowStep.assessment:
      return WorkflowPaths.Assessment;
    case WorkflowStep.investor_type_declaration:
      return WorkflowPaths.Status;
    case WorkflowStep.cooldown:
      return WorkflowPaths.Cooldown;
    default:
      return null;
  }
};

interface WorkflowOptions {
  currentStep?: WorkflowStep | undefined;
  type?: 'postSignup' | 'preInvest' | undefined;
}

const isOptions = (
  arg: WorkflowOptions | WorkflowStep | undefined,
): arg is WorkflowOptions =>
  (arg as WorkflowOptions)?.type !== undefined ||
  (arg as WorkflowOptions)?.currentStep !== undefined;

export const useWorkflows = (): ReturnType<
  typeof useQuery<GetOnboardingWorkflows>
> =>
  useQuery<GetOnboardingWorkflows>(GET_ONBOARDING_WORKFLOWS, {
    fetchPolicy: 'network-only',
  });

export const useWorkflowPolling = (): ReturnType<
  typeof useQuery<GetOnboardingWorkflows>
> =>
  useQuery<GetOnboardingWorkflows>(GET_ONBOARDING_WORKFLOWS, {
    fetchPolicy: 'network-only',
    pollInterval: 10_000,
  });

export const useNextStep = (
  currentStep?: WorkflowStep | WorkflowOptions | undefined,
): {
  to: string | null | undefined;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const { data, loading, error } = useWorkflows();
  const options = isOptions(currentStep)
    ? currentStep
    : { type: 'postSignup' as WorkflowOptions['type'], currentStep };

  const workflows = data?.onboardingWorkflows?.[options?.type || 'postSignup'];
  const nextStep = workflows?.find(
    (step) =>
      step?.name !== options.currentStep &&
      step?.status !== WorkflowStatus.complete,
  );
  return {
    to: to(nextStep),
    loading,
    error,
  };
};

export const useWorkflowHelper = (): {
  helper: PreInvestHelper;
  loading: boolean;
} => {
  const { data: workflowData, loading: workflowLoading } = useWorkflowPolling();
  const preInvestWorkflows = workflowData?.onboardingWorkflows?.preInvest || [];
  const workflows = preInvestWorkflows.length
    ? preInvestWorkflows
    : workflowData?.onboardingWorkflows?.postSignup || [];

  const helper = PreInvestWorkflow(workflows);
  return { helper, loading: workflowLoading };
};

const isComplete = (step?: { status: WorkflowStatus | null } | null): boolean =>
  step?.status === WorkflowStatus.complete;

export const useHasCompletedWorkflows = (
  enablePolling = false,
): {
  canInvest: boolean;
  complete: boolean;
  loading: boolean;
  error?: ApolloError;
} => {
  const { data, loading, error } = useQuery<GetOnboardingWorkflows>(
    GET_ONBOARDING_WORKFLOWS,
    enablePolling ? { fetchPolicy: 'network-only', pollInterval: 10_000 } : {},
  );

  const { postSignup = [], preInvest = [] } = data?.onboardingWorkflows || {};

  const canInvest =
    !loading && postSignup?.every(isComplete) && preInvest?.every(isComplete);

  return { canInvest, complete: canInvest, loading, error };
};

export const useCanInvest = useHasCompletedWorkflows;
