import { MutationTuple, useMutation } from '@apollo/client';

import {
  MARK_NOTIFICATIONS_AS_READ,
  MarkNotificationsAsReadMutation,
  MarkNotificationsAsReadMutationVariables,
  GET_NOTIFICATIONS,
} from '@hedgehog/data-access/graphql';

export function useMarkNotificationsAsRead(): MutationTuple<
  MarkNotificationsAsReadMutation,
  MarkNotificationsAsReadMutationVariables
> {
  return useMutation<
    MarkNotificationsAsReadMutation,
    MarkNotificationsAsReadMutationVariables
  >(MARK_NOTIFICATIONS_AS_READ, {
    refetchQueries: [{ query: GET_NOTIFICATIONS }],
  });
}
