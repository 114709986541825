import { useEffect, useRef, useState } from 'react';
import styled, { CSSObject } from 'styled-components';

import { shimmerGradient } from '@hedgehog/ui/themes';
import { StandardProps } from '@hedgehog/ui/utils';

export type ImageProps = StandardProps<
  {
    src?: string;
    alt?: string;
    width?: number | string;
    height?: number | string;
    loading?: boolean;
    fit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';
    nativeLoading?: 'eager' | 'lazy';
  },
  never
>;

const Wrapper = styled.div<{ $loading: boolean }>`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  ${({ $loading }): any => ($loading ? shimmerGradient : null)}
`;

const CustomImage = styled.img<Pick<ImageProps, 'fit'>>`
  ${({ fit }): CSSObject => ({ objectFit: fit })}
  border: 0;
  outline: 0;
  flex: 1 0 auto;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
`;

export const Image = styled(
  ({
    src,
    alt = '',
    loading: hostLoading,
    nativeLoading,
    fit,
    ...imgProps
  }: ImageProps): JSX.Element => {
    const [loading, setLoading] = useState(false);
    const ref = useRef<HTMLImageElement>(null);
    const handleLoaded = (): void => {
      setLoading(false);
    };
    const handleError = (): void => {
      setLoading(false);
    };

    useEffect(() => {
      const { current: imageElement } = ref;
      if (!imageElement) return;

      if (imageElement.getAttribute('src') !== src) {
        setLoading(true);
      }
    }, [src]);

    return (
      <Wrapper
        data-loading={hostLoading || loading}
        $loading={hostLoading || loading}
        {...imgProps}
      >
        <CustomImage
          src={src}
          alt={alt}
          ref={ref}
          fit={fit}
          onError={handleError}
          onLoad={handleLoaded}
          loading={nativeLoading}
        />
      </Wrapper>
    );
  },
)`
  ${({ width, height }): CSSObject => ({
    minWidth: width,
    maxWidth: width,
    minHeight: height,
    maxHeight: height,
  })}
`;
