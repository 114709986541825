import { ActionCard } from '@hedgehog/ui/cards';

export interface OrderGetSupportProps {
  orderId?: string;
}

export const OrderGetSupport = ({
  orderId,
}: OrderGetSupportProps): JSX.Element => {
  const url = new URL('mailto:contact@hedgehog-invest.com');

  if (orderId) {
    url.searchParams.append('subject', `Order#${orderId} - `);
  }

  return (
    <ActionCard
      leadingIcon="help"
      trailingIcon="mail"
      title="Something not right?"
      caption="Get in touch"
      href={url.toString()}
    />
  );
};
