import { MutationTuple, useMutation } from '@apollo/client';

import {
  COMPLETE_PARTNER_CLIENT_SIGNUP,
  CompleteClientSignupMutation as CompleteClientSignup,
  CompleteClientSignupMutationVariables as CompleteClientSignupVariables,
} from '@hedgehog/data-access/graphql';

export const useCompleteClientSignup = (): MutationTuple<
  CompleteClientSignup,
  CompleteClientSignupVariables
> =>
  useMutation<CompleteClientSignup, CompleteClientSignupVariables>(
    COMPLETE_PARTNER_CLIENT_SIGNUP,
  );
