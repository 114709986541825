import { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useOrder } from '@hedgehog/browser/investors/order/data-access';
import { StandardProps } from '@hedgehog/ui/utils';

export type OrderPaymentGuardProps = StandardProps<{ hashed?: boolean }>;

/**
 * Guarantees that the user is on the correct route based on the order status & transfer confirmation
 * @param hashed once `true` will use hash to navigate to the correct route
 * @returns
 */
export const OrderPaymentGuard = ({
  hashed = false,
  children,
}: OrderPaymentGuardProps): JSX.Element => {
  const { pathname, hash } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { data } = useOrder(searchParams.get('orderId') || '');

  const coordinateToAccuratePath = (isConfirmed: boolean): void => {
    const expectedRoute: string | undefined = isConfirmed
      ? 'confirmed'
      : 'instructions';

    if (hashed && !hash.endsWith(expectedRoute)) {
      return navigate(
        {
          pathname,
          hash: `order/${expectedRoute}`,
          search: searchParams.toString(),
        },
        { replace: true },
      );
    }
    if (!hashed && !pathname.endsWith(expectedRoute)) {
      return navigate(
        {
          pathname: pathname + expectedRoute,
          search: searchParams.toString(),
        },
        { replace: true },
      );
    }
  };

  useEffect(() => {
    if (!data) return;
    if (!data.order) return;
    const {
      order: { transferConfirmed },
    } = data;
    coordinateToAccuratePath(transferConfirmed ?? false);
  }, [data]);

  return <>{children}</>;
};
