import { useEffect } from 'react';
import styled from 'styled-components';

import { useUser } from '@hedgehog/browser/investors/kyc/data-access';
import { useAnalyticsPageOnMount } from '@hedgehog/browser/investors/shared/analytics';
import { NotificationType } from '@hedgehog/data-access/graphql';
import {
  NativeMobileExperiences,
  sendMessageWithPayloadToApp,
  useIsNativeMobileExperience,
} from '@hedgehog/data-access/native-mobile';
import { AnyFixLater } from '@hedgehog/shared/types';
import { HeaderWithBack } from '@hedgehog/ui/headers';
import {
  EmptyStateContainer,
  LoadingContainer,
  Page,
} from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';
import { Heading } from '@hedgehog/ui/typography';
import { screens } from '@hedgehog/utils/sizes';

import { useNotifications, useMarkNotificationsAsRead } from '../../hooks';

import { DefaultListItem } from './default-list-item.component';
import { HighlightListItem } from './highlight-list-item.component';

const PageContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;

  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const EmptyStateLayout = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  margin-top: 1rem;
`;

const EmptyStateImage = styled.img`
  width: 100%;
  border-radius: 1rem;
  overflow: hidden;

  @media only screen and (min-width: ${screens.medium}px) {
    max-width: 20.4375rem;
  }
`;

export const NotificationCentrePage = (): JSX.Element => {
  useAnalyticsPageOnMount('Top Level', 'Notification Centre');
  const shouldNotifyMobile = useIsNativeMobileExperience(
    NativeMobileExperiences.LINK_NOTIFY,
  );
  const [markNotificationsAsRead] = useMarkNotificationsAsRead();

  const { data, loading, error } = useNotifications();
  const { data: userData, loading: userLoading } = useUser();

  useEffect(() => {
    const notificationIdsToRead = data?.notifications
      .filter((notification) => notification.unread)
      .map((notification) => notification.id);

    const numberOfNotificationsToRead = notificationIdsToRead?.length || 0;

    if (numberOfNotificationsToRead > 0) {
      // Don't need to await this, will mark as read in the background
      markNotificationsAsRead({
        variables: { notificationIds: notificationIdsToRead || [] },
      });
    }
  }, [data]);

  return (
    <Page>
      <HeaderWithBack>
        <Heading level="h5">Notifications</Heading>
      </HeaderWithBack>

      <PageContainer>
        {(loading || userLoading) && (
          <LoadingContainer data-testid="loader">
            <Loader />
          </LoadingContainer>
        )}

        {!loading && error && (
          <EmptyStateContainer>
            Something went wrong, try again later.
          </EmptyStateContainer>
        )}

        {!loading && !userLoading && data && userData && (
          <>
            {data.notifications.length === 0 && (
              <EmptyStateLayout>
                <Heading level="h4">Nothing to see here yet.</Heading>
                <EmptyStateImage
                  src={
                    userData?.user?.partner?.resources?.pages?.notifications
                      ?.placeholderUrl ??
                    'https://assets.hedgehog-invest.com/images/notifications-empty-state.png'
                  }
                  alt=""
                />
              </EmptyStateLayout>
            )}
            {data.notifications.map(
              ({
                actionUrl,
                mobileUrl,
                unopened,
                type,
                theme,
                ...notification
              }) => {
                const listItemProps = {
                  ...notification,
                  key: notification.id,
                  description: notification.content,
                };

                const handleClick: React.MouseEventHandler = (event) => {
                  if (!shouldNotifyMobile) return;
                  event.preventDefault();
                  sendMessageWithPayloadToApp('navigation.notification', {
                    webLink: actionUrl,
                    appLink: mobileUrl,
                  });
                };

                if (actionUrl?.startsWith('/')) {
                  (listItemProps as AnyFixLater).to = actionUrl;
                } else {
                  (listItemProps as AnyFixLater).href = actionUrl;
                }

                return type === NotificationType.HIGHLIGHT && unopened ? (
                  <HighlightListItem
                    {...listItemProps}
                    unopened={unopened}
                    onClick={handleClick}
                  />
                ) : (
                  <DefaultListItem {...listItemProps} onClick={handleClick} />
                );
              },
            )}
          </>
        )}
      </PageContainer>
    </Page>
  );
};
