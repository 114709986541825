import styled from 'styled-components';

import { TAddressPrediction } from '@hedgehog/shared/types';
import { Avatar } from '@hedgehog/ui/icons';
import { Paragraph } from '@hedgehog/ui/typography';

import { AddressListItem } from './address-list-item.component';

const ListItem = styled(AddressListItem)`
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

type CancelAddressPredictionProps = {
  prediction: TAddressPrediction;
  onClick: () => void;
};

export const CancelAddressPrediction = ({
  prediction,
  onClick,
}: CancelAddressPredictionProps): JSX.Element => {
  return (
    <ListItem onClick={onClick}>
      <Avatar icon="chevron-left" size="s" />
      <div>
        <Paragraph>
          {prediction.summary}, {prediction.location}
        </Paragraph>
        {prediction.addressCount > 1 && (
          <Paragraph small color="grey400">
            {prediction.addressCount} addresses
          </Paragraph>
        )}
      </div>
    </ListItem>
  );
};
