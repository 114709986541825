import { MinterPage } from '@hedgehog/browser/investors/order/feature-minter';
import {
  OrderIncomeReportingPage,
  OrderIssuingPage,
  OrderPage,
  OrderReportPage,
  OrderPaymentConfirmedPage,
  OrderPaymentGuard,
  OrderPaymentInstructionsPage,
  OrderStatusGuard,
  OrderSummaryPage,
} from '@hedgehog/browser/investors/order/feature-portfolio';
import {
  SignSubscriptionAgreementPage,
  SubscriptionAgreementPage,
} from '@hedgehog/browser/investors/order/feature-subscription';
import { SidebarBackgroundParams } from '@hedgehog/browser/shared/router';
import { GetUserQuery as GetUser } from '@hedgehog/data-access/graphql';

import { OrderStepperLayout } from './components';

export const createRoutes = (
  background?: (user?: GetUser) => SidebarBackgroundParams | string,
) =>
  ({
    '#order': {
      background,
      component: (): JSX.Element => (
        <OrderPage>
          <OrderStatusGuard hashed />
        </OrderPage>
      ),
    },
    '#order/summary': {
      background,
      component: (): JSX.Element => (
        <OrderPage>
          <OrderSummaryPage />
        </OrderPage>
      ),
    },
    '#order/income': {
      background,
      component: (): JSX.Element => (
        <OrderReportPage>
          <OrderIncomeReportingPage />
        </OrderReportPage>
      ),
    },
    '#order/instructions': {
      background,
      component: (): JSX.Element => (
        <OrderPage>
          <OrderPaymentGuard hashed />
          <OrderPaymentInstructionsPage />
        </OrderPage>
      ),
    },
    '#order/confirmed': {
      background,
      component: (): JSX.Element => (
        <OrderPage>
          <OrderPaymentGuard hashed />
          <OrderPaymentConfirmedPage />
        </OrderPage>
      ),
    },
    '#order/issuing': {
      background,
      component: (): JSX.Element => (
        <OrderPage>
          <OrderIssuingPage />
        </OrderPage>
      ),
    },
    '#order/open': {
      component: (): JSX.Element => (
        <OrderStepperLayout>
          <SubscriptionAgreementPage />
        </OrderStepperLayout>
      ),
    },
    '#order/subscribe': {
      background,
      component: (): JSX.Element => (
        <OrderStepperLayout>
          <SignSubscriptionAgreementPage />
        </OrderStepperLayout>
      ),
    },
    '#order/minting': {
      background,
      component: (): JSX.Element => (
        <OrderStepperLayout>
          <MinterPage />
        </OrderStepperLayout>
      ),
    },
  } as const);
