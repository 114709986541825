import { ContractIssuance } from '@hedgehog/shared/blockchain/resident-token';

import { calculateCurrentTotalRewards } from './calculate-current-total-rewards';
import {
  calculateEstimationLeaseRewards,
  calculateRentRecent,
  calculateRentRewardsEstimation,
  calculateMaximumTokenAllocation,
} from './helpers';

export interface ResidentTokenProjectionParameters {
  from: number | Date;
  to: number | Date;
}

export type ResidentTokenProjection = Record<
  | 'currentTotalRewardsInTokensAmount'
  | 'maximumTokenAllocation'
  | 'recentRentRewardsInTokensAmount'
  | 'recentLeaseRewardsInTokensAmount'
  | 'recentSignOnRewardsInTokensAmount'
  | 'estimatedLeaseRewardsInTokensAmount'
  | 'estimatedRentRewardsInTokensAmount'
  | 'estimatedRewardsInTokensAmount'
  | 'estimatedTotalRewardsInTokensAmount',
  number
>;

export function createResidentTokenProjection(
  parameters: ResidentTokenProjectionParameters,
) {
  return (issuances: ContractIssuance[]): ResidentTokenProjection => {
    const recentRent = calculateRentRecent({
      issuances,
    });

    const {
      recentLeaseRewardsInTokensAmount,
      recentRentRewardsInTokensAmount,
      recentSignOnRewardsInTokensAmount,
      currentTotalRewardsInTokensAmount,
    } = calculateCurrentTotalRewards(issuances);

    const estimatedLeaseRewardsInTokensAmount = calculateEstimationLeaseRewards(
      {
        ...parameters,
        recentRent,
        issuances,
      },
    );

    const estimatedRentRewardsInTokensAmount = calculateRentRewardsEstimation({
      ...parameters,
      recentRent,
      issuances,
    });

    const estimatedRewardsInTokensAmount =
      estimatedLeaseRewardsInTokensAmount + estimatedRentRewardsInTokensAmount;

    const estimatedTotalRewardsInTokensAmount =
      currentTotalRewardsInTokensAmount + estimatedRewardsInTokensAmount;

    return {
      currentTotalRewardsInTokensAmount,
      recentRentRewardsInTokensAmount,
      recentLeaseRewardsInTokensAmount,
      recentSignOnRewardsInTokensAmount,
      estimatedLeaseRewardsInTokensAmount,
      estimatedRentRewardsInTokensAmount,
      estimatedRewardsInTokensAmount,
      estimatedTotalRewardsInTokensAmount,
      maximumTokenAllocation: calculateMaximumTokenAllocation({
        ...parameters,
        recentRent,
      }),
    };
  };
}
