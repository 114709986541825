import { HeaderWithBack } from '@hedgehog/ui/headers';
import { Heading } from '@hedgehog/ui/typography';

import { InvestorConfirmation } from '../containers';

export const DeclarationPage = (): JSX.Element => {
  return (
    <>
      <HeaderWithBack>
        <Heading level="h5">Qualified Investor confirmation</Heading>
      </HeaderWithBack>
      <InvestorConfirmation />
    </>
  );
};
