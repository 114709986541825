import * as Sentry from '@sentry/react';
import { FormEvent, ReactNode, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import styled, { CSSProp } from 'styled-components';

import {
  useAnalyticsPage,
  useAnalyticsTrack,
} from '@hedgehog/browser/investors/shared/analytics';
import { useAuth, useLazyUser } from '@hedgehog/data-access/contexts';
import { FeatureFlagNames } from '@hedgehog/data-access/graphql';
import { useFeatureToggle } from '@hedgehog/data-access/hooks';
import { TCountry, TCountryAlpha3Code } from '@hedgehog/shared/types';
import { PrimaryButton, SecondaryButton } from '@hedgehog/ui/buttons';
import { CountryPickerModal } from '@hedgehog/ui/countries';
import {
  MenuButtonInput,
  RadioInput,
  PasswordInput,
  RadioInputGroup,
  TextInput,
} from '@hedgehog/ui/inputs';
import { LoadingContainer, Page } from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';
import { BottomSheet, useModal } from '@hedgehog/ui/modals';
import { Heading, Heading6, Paragraph } from '@hedgehog/ui/typography';
import { getAllStateNames, getStateCode } from '@hedgehog/utils/states';

import { AuthPageLogo, SignupTermsCheckbox } from '../../components';
import {
  deepLinkParams,
  useCountries,
  useIsSSO,
  useJwtPayload,
  usePassword,
  useSignup,
} from '../../hooks';
import { SignupState } from '../../types';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const SignupInfoContainer = styled.div`
  ${({ theme }): CSSProp => ({ backgroundColor: theme.colors.secondary })}
  border-radius: 1rem;
  padding: 1rem;
`;

const SignupInfoText = styled(Paragraph)`
  margin-top: 0;
  &:last-child {
    margin-bottom: 0;
  }
`;

const SignupPopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  gap: 1rem;
`;

const SignupStatesContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 300px;
  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`;

const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const SignupErrorText = styled.p`
  font-weight: 600;
  margin: 0;
`;

const ModalContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  padding: 2rem 1.5rem 1.5rem 1.5rem;
  & > *:not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;

const ButtonContainer = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 0.5rem;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ConfirmSignupPage = (): JSX.Element | null => {
  useAnalyticsPage('Authentication', 'Confirm Signup');
  const track = useAnalyticsTrack();
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as SignupState;
  const auth = useAuth();
  const [loadUser, { loading: userLoading, error: userError }] = useLazyUser();

  const { hasFeature, getFeature } = useFeatureToggle();
  const [accepted, setAccepted] = useState(false);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [country, setCountry] = useState<TCountry>();
  const [state, setState] = useState('');
  const [password, setPassword] = useState<string>('');
  const [searchParams] = useSearchParams();
  const { countries } = useCountries();
  const { data: jwt, loading: jwtLoading } = useJwtPayload();
  const isSSO = useIsSSO(jwt);
  const [signup, { loading: signupLoading }] = useSignup(jwt);

  const { isEnabled: isAllowListEnabled, value: allowedCountries } = getFeature(
    FeatureFlagNames.signup_region_allowlist,
  );
  const supportedCountries = allowedCountries as TCountryAlpha3Code[];
  const createAccountEnabled = hasFeature(FeatureFlagNames.create_account);

  const { payload } = jwt || {};
  const prefilledEmail =
    payload?.sub ||
    searchParams.get('email') ||
    searchParams.get(deepLinkParams['email']) ||
    '';
  const prefilledFirstName =
    payload?.jwtType === 'identity' ? payload?.given_name : payload?.firstName;
  const prefilledLastName =
    payload?.jwtType === 'identity' ? payload?.family_name : payload?.lastName;

  const {
    valid,
    dirty,
    errors: passwordErrors,
    enableValidation,
  } = usePassword({
    password,
  });

  const { openAndResolveWithSubmitValue: openHighriskInvestmentPopup } =
    useModal(({ cancelModal, submitModal }) => (
      <BottomSheet>
        <ModalContent>
          <Heading6>Before you proceed...</Heading6>
          <Paragraph>
            {`${
              prefilledFirstName || firstName
            }, don't invest unless you're prepared to lose all the money you
          invest. This is a high-risk investment and you are unlikely to be
          protected if something goes wrong.`}
          </Paragraph>
          <ButtonContainer>
            <SecondaryButton onClick={submitModal} fluid>
              I'm okay with it
            </SecondaryButton>
            <SecondaryButton onClick={cancelModal} fluid>
              I'm not okay with it
            </SecondaryButton>
          </ButtonContainer>
        </ModalContent>
      </BottomSheet>
    ));

  const handleSubmit = async (
    event: FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    track('CreateAccountButton', 'Clicked');
    event.preventDefault();

    try {
      if (!country) throw new Error('expected country to be defined');
      if (country?.code === 'GBR') await openHighriskInvestmentPopup();

      await signup({
        email: prefilledEmail ?? '',
        password: password ?? '',
        country: country.code ?? '',
        state: state ?? '',
        isPartnerClient: false,
      });
      navigate('/explore', { replace: true });
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const isCountrySelected = !!country;
  const isCountryAllowed = isAllowListEnabled
    ? supportedCountries.includes(country?.code || '')
    : true;
  const isCountrySupported = isCountrySelected
    ? isCountryAllowed && country?.code !== 'OTHER'
    : true;

  const isCountryValid = country && (country?.code === 'USA' ? state : true);
  const isPasswordValid = isSSO ? true : password && !passwordErrors.length;
  const hasAcceptedTerms = isSSO ? accepted : true;
  const isFormValid = !!(
    prefilledEmail &&
    (prefilledFirstName || firstName) &&
    isCountryValid &&
    isPasswordValid &&
    hasAcceptedTerms
  );

  const handleCountryChange = (value?: TCountry): void => {
    setCountry(value);
    if (value?.code !== 'USA') {
      setState('');
    }
    if (!supportedCountries.includes(value?.code || '')) {
      track('Account', 'Signup Failed', {
        reason: 'not_supported_country',
        country_of_residence: value?.name,
      });
    }
  };

  useEffect(() => {
    const checkIfAlreadyExists = async () => {
      if (!jwt) return;
      if (userError) return;
      const { token, refreshToken } = jwt;

      auth.setTokens({
        accessToken: token,
        refreshToken: refreshToken ?? '',
      });

      loadUser().then(({ data }) => {
        if (data) navigate('/explore', { replace: true });
      });
    };

    checkIfAlreadyExists();
  }, [jwt?.token]);

  if (jwtLoading || userLoading) {
    return (
      <LoadingContainer>
        <Loader color="highlight" />
      </LoadingContainer>
    );
  }

  if (!createAccountEnabled) {
    return (
      <Page>
        <AuthPageLogo />
      </Page>
    );
  }

  return (
    <Form onSubmit={handleSubmit} autoComplete="on">
      <input
        hidden
        name="username"
        value={prefilledEmail}
        onChange={() => null}
      />

      <FormSection>
        <Heading level="h4">
          Welcome
          {prefilledFirstName || firstName
            ? `, ${prefilledFirstName || firstName}.`
            : '!'}
        </Heading>
        <Paragraph>
          Now please tell us where you are
          {isSSO ? '' : ' and create a password'}.
        </Paragraph>
      </FormSection>

      <FormSection>
        {!prefilledFirstName && (
          <TextInput
            name="firstName"
            value={firstName}
            placeholder="First name"
            label="First name"
            onChange={setFirstName}
          />
        )}
        {!prefilledLastName && (
          <TextInput
            name="lastName"
            value={lastName}
            placeholder="Last name"
            label="Last name"
            onChange={setLastName}
          />
        )}
        <MenuButtonInput
          name="country"
          value={country?.name}
          placeholder="Choose your country"
          label="Country of residence"
        >
          {(close: () => void): ReactNode => (
            <CountryPickerModal
              countries={countries}
              onChange={(value?: TCountry): void => {
                handleCountryChange(value);
                close();
              }}
              supported={supportedCountries}
            />
          )}
        </MenuButtonInput>

        {isCountrySupported && country?.code === 'USA' && (
          <MenuButtonInput
            name="state"
            value={state}
            placeholder="Choose your state"
            label="State"
          >
            {(close: () => void): ReactNode => (
              <SignupPopupContainer>
                <Heading level="h5">Choose your State:</Heading>
                <SignupStatesContainer>
                  <RadioInputGroup
                    onChange={(checked, values): void => {
                      const [check] = checked;
                      if (!check) return;
                      setState(values[check]);
                      close();
                    }}
                  >
                    {getAllStateNames().map((stateName: string) => (
                      <RadioInput
                        key={stateName}
                        name="radio-states"
                        id={getStateCode(stateName)}
                        value={stateName}
                      />
                    ))}
                  </RadioInputGroup>
                </SignupStatesContainer>
              </SignupPopupContainer>
            )}
          </MenuButtonInput>
        )}

        {!isSSO && isCountrySupported && (
          <PasswordInput
            label="Password"
            name="password"
            value={password}
            placeholder="Create a password"
            hint="At least 8 characters, one capital, one number"
            onChange={setPassword}
            onBlur={enableValidation}
            autoCompleteOff
            errors={passwordErrors}
            checked={valid && dirty}
          />
        )}
      </FormSection>

      {isSSO && (
        <FormSection>
          <SignupTermsCheckbox
            name="accepted"
            value={accepted}
            onChange={setAccepted}
          />
        </FormSection>
      )}

      {locationState?.error && (
        <FormSection>
          <SignupErrorText>
            Error occurred while creating your account:&nbsp;
            {locationState?.error?.message}
          </SignupErrorText>
        </FormSection>
      )}

      {isCountrySupported ? (
        <PrimaryButton
          type="submit"
          loading={signupLoading}
          disabled={!isFormValid}
        >
          Create account
        </PrimaryButton>
      ) : (
        <SignupInfoContainer>
          <SignupInfoText color="black">
            We don&apos;t support your country yet.
          </SignupInfoText>
          <SignupInfoText color="grey400">
            Hedgehog is currently restricted to residents of the US, UK or
            Switzerland. We will notify you as we roll out to more countries.
          </SignupInfoText>
        </SignupInfoContainer>
      )}
    </Form>
  );
};
