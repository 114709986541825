import { ReactNode } from 'react';
import styled, { CSSObject } from 'styled-components';

import { Card } from '@hedgehog/ui/cards';

const Toast = styled(Card)`
  pointer-events: all;
  ${({ theme }): CSSObject => ({
    borderRadius: theme.toasts.border.radius,
    ':after': {
      borderRadius: theme.toasts.border.radius,
    },
  })}
`;

const Layout = styled.div`
  ${({ theme }): CSSObject => ({
    display: 'flex',
    maxWidth: '400px',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    gap: theme.spacing.xsmall,
  })}
`;

type ToastLayoutProps = {
  children?: ReactNode;
  className?: string;
};

export const ToastLayout = styled(
  ({ children, ...props }: ToastLayoutProps): JSX.Element => {
    return (
      <Toast {...props}>
        <Layout>{children}</Layout>
      </Toast>
    );
  },
)``;
