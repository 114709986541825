import { Step } from '@hedgehog/ui/lists';

const steps: Step[] = [
  {
    title: 'Choose tokens',
    content: 'Choose investment amount',
    current: false,
    status: 'token',
  },
  {
    title: 'Sign docs',
    content: 'Review and sign agreement',
    current: false,
    status: 'todo',
  },
  {
    title: 'Wire transfer',
    content: 'Complete the respective payment',
    current: false,
    status: 'todo',
  },
  {
    title: 'Wait for tokens',
    content: 'Tokens will be issued by Hedgehog',
    current: false,
    status: 'waiting',
  },
];

export const getSteps = (
  page: 'SubscriptionAgreement' | 'Instructions' | 'Asset',
): Step[] => {
  const currentIndexMap = {
    Asset: 0,
    SubscriptionAgreement: 1,
    Instructions: 2,
  };

  const currentPage = currentIndexMap[page];

  const pageSteps = steps.map((step, i) => {
    if (i === currentPage) {
      return { ...step, current: true };
    }
    return { ...step, current: false };
  });
  return pageSteps;
};
