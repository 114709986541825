import { ForwardedRef, forwardRef } from 'react';
import styled, { CSSObject } from 'styled-components';

import {
  CardThumbnails,
  GenericCard,
  LinkOrNotProps,
} from '@hedgehog/ui/cards';
import { parseBorder } from '@hedgehog/ui/themes';
import { Heading, Paragraph } from '@hedgehog/ui/typography';
import { StandardProps } from '@hedgehog/ui/utils';

export type ArticleCardProps = StandardProps<
  {
    title: string;
    thumbnail?: string | null;
    author: string;
    tags: string[];
  } & LinkOrNotProps,
  never
>;

export const ArticleCard = styled(
  forwardRef(
    (
      { author, tags, title, thumbnail, ...props }: ArticleCardProps,
      ref: ForwardedRef<HTMLAnchorElement>,
    ): JSX.Element => (
      <GenericCard
        ref={ref}
        title={<Heading level="h5">{title}</Heading>}
        imageSrc={thumbnail ?? undefined}
        description={
          <Paragraph color="grey400" small>
            {author + ' ' + tags.map((tag) => tag)}
          </Paragraph>
        }
        {...props}
      />
    ),
  ),
)`
  ${({ theme: { border } }): CSSObject => ({
    border: parseBorder(border.small),
    boxShadow: 'none',
    [`${CardThumbnails} > div`]: {
      minHeight: '7.5rem',
    },
  })}
`;
