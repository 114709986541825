import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  useAnalyticsPage,
  useAnalyticsTrack,
} from '@hedgehog/browser/investors/shared/analytics';
import {
  AssetInvestController,
  AssetThumbnails,
  Media,
} from '@hedgehog/browser/shared/assets';
import { useAuthenticatedLazyQuery } from '@hedgehog/data-access/contexts';
import { useAuthenticatedQuery } from '@hedgehog/data-access/contexts';
import {
  GET_ASSET,
  GET_ASSET_PAGE,
  GetAssetQuery as GetAsset,
  GetAssetPageQuery as GetAssetPage,
  GetAssetQueryVariables as GetAssetVariables,
  Resource,
} from '@hedgehog/data-access/graphql';
import { useIsMobile } from '@hedgehog/data-access/hooks';
import { Card } from '@hedgehog/ui/cards';
import { ContentCMS } from '@hedgehog/ui/cms';
import { Gallery } from '@hedgehog/ui/galleries';
import {
  Page,
  EmptyStateContainer,
  LoadingContainer,
} from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';
import { screens } from '@hedgehog/utils/sizes';

import { PageHeader } from '../asset/asset.page';

const StyledGallery = styled(Gallery)`
  margin-bottom: 2rem;
`;

const AssetGrid = styled.div`
  margin: 1.5rem 1.5rem 10rem;

  @media only screen and (min-width: ${screens.medium}px) {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 1rem;

    margin: 2rem 4rem 10rem;

    & > *:first-child {
      grid-column: 1 / span 3;
      padding: 2rem;
      height: max-content;
      max-width: calc(400px - 4rem);
    }

    & > *:nth-child(2) {
      height: max-content;
      grid-column: 4 / span 5;
    }
  }
`;

const getHeroContent = (thumbnails?: (Media | Resource)[]): Resource[] => {
  const videoResource = thumbnails?.find(
    (resource) => resource.kind === 'video',
  );

  return videoResource ? [videoResource] : thumbnails || [];
};

export const ComingSoonPage = (): JSX.Element => {
  const { slug } = useParams();
  useAnalyticsPage('Asset', 'Coming Soon', { asset_name: slug });
  const track = useAnalyticsTrack();

  const {
    data,
    error: assetFetchError,
    loading: assetLoading,
  } = useAuthenticatedQuery<GetAsset, GetAssetVariables>(
    GET_ASSET,
    { path: slug || '' },
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [
    fetchPage,
    { data: page, loading: pageLoading, error: pageFetchError },
  ] = useAuthenticatedLazyQuery<GetAssetPage>(GET_ASSET_PAGE);

  useEffect(() => {
    if (!data) return;
    const { asset: assetData } = data;
    fetchPage({ variables: { assetId: assetData.id, slug: 'coming-soon' } });
  }, [data]);

  const isMobile = useIsMobile();

  if (assetFetchError) {
    return (
      <Page>
        <EmptyStateContainer>
          Something went wrong, please try again later
        </EmptyStateContainer>
      </Page>
    );
  }

  if (assetLoading || !data) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }

  const {
    asset: { thumbnails },
  } = data;

  const heroContent = getHeroContent(thumbnails);

  const HeaderContent = (
    <>
      <PageHeader
        title={data.asset.details.assetSummary.title}
        themes={data.asset.themes.map(({ category }) => category)}
        status={data.asset.currentRound?.roundClass.status}
      />
      <AssetInvestController
        slug={slug || ''}
        assetRoundId={data.asset.currentRound?.id}
        minimumInvestment={
          data.asset.currentRound?.roundClass.minimumInvestmentAmount
        }
        status={data.asset.currentRound?.roundClass.status}
        openDate={data.asset.currentRound?.roundClass.liveAt || undefined}
      />
    </>
  );

  return (
    <AssetGrid>
      {isMobile ? HeaderContent : <Card>{HeaderContent}</Card>}
      <div>
        <StyledGallery
          resources={heroContent}
          cover={
            !heroContent && data.asset.underlyingAssetCount > 1 ? (
              <AssetThumbnails fluid thumbnails={thumbnails || []} />
            ) : undefined
          }
        />

        {page && (
          <ContentCMS
            content={page.assetPage.content}
            onAnalyticsTrack={track}
          />
        )}

        {pageLoading && (
          <LoadingContainer>
            <Loader />
          </LoadingContainer>
        )}

        {pageFetchError && (
          <EmptyStateContainer>
            Something went wrong, please try again later
          </EmptyStateContainer>
        )}
      </div>
    </AssetGrid>
  );
};
