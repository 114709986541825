import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { GetScrollState } from './scroll-state';

type RestoreScrollProps = {
  children: JSX.Element;
};

/**
 * RestoreScroll will scroll the page to the last y scroll on page change.
 * @param children
 * @constructor
 */
export const RestoreScroll = ({ children }: RestoreScrollProps): JSX.Element => {
  const location = useLocation();

  useEffect(() => {
    const key = location.pathname;
    window.scrollTo(0, GetScrollState({ key }));
  }, [location]);

  return children;
};
