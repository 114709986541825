import { ReactNode } from 'react';

import { usePartner } from '@hedgehog/browser/shared/auth';
import {
  JumbotronLayout,
  JumbotronLayoutProps,
  JumbotronPage,
  LoadingContainer,
} from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';
import { StandardProps } from '@hedgehog/ui/utils';

export type EnterpriseLayoutProps = StandardProps<
  {
    links?: ReactNode | ReactNode[];
    visibleAt?: JumbotronLayoutProps['visibleAt'];
  },
  never
>;

export const EnterpriseLayout = ({
  links,
  visibleAt,
}: EnterpriseLayoutProps): JSX.Element => {
  const { partner, loading } = usePartner();

  if (loading) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }

  return (
    <JumbotronLayout
      visibleAt={visibleAt}
      logotypeUrl={
        partner?.resources?.logotypeLightUrl ??
        'https://assets.hedgehog-invest.com/logotype-brand.png'
      }
      jumbotronUrl={
        partner?.resources?.landing?.imageUrl ??
        'https://assets.hedgehog-invest.com/images/portfoliob.jpg'
      }
      jumbotronTitle={
        partner?.resources?.landing?.title ??
        'Easy access to real-world investments'
      }
      jumbotronCaption={
        partner?.resources?.landing?.caption ??
        'We have new, strong, and impactful investment opportunities for everyone.'
      }
      links={links}
    >
      <JumbotronPage />
    </JumbotronLayout>
  );
};
