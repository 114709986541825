import React from 'react';
import styled from 'styled-components';

import {
  useAnalyticsPage,
  useAnalyticsTrack,
} from '@hedgehog/browser/investors/shared/analytics';
import { useScrollToBody } from '@hedgehog/browser/shared/utils';
import { useAuthenticatedQuery } from '@hedgehog/data-access/contexts';
import {
  GetAssetPageQuery as GetAssetPage,
  GetAssetPageQueryVariables as GetAssetPageVariables,
  Asset,
  GET_ASSET_PAGE,
  GetAssetQuery,
} from '@hedgehog/data-access/graphql';
import { AssetSponsorToast } from '@hedgehog/ui/assets';
import { useSponsor } from '@hedgehog/ui/assets';
import { SponsorCard } from '@hedgehog/ui/cards';
import { ContentCMS } from '@hedgehog/ui/cms';
import {
  EmptyStateContainer,
  HideBreakpoint,
  LoadingContainer,
} from '@hedgehog/ui/layouts';
import { VSpace } from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';
import { Heading } from '@hedgehog/ui/typography';

import { AssetHighlightsBlock } from '../asset-highlights-block/asset-highlights-block.component';

const ContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

type OverviewTabProps = {
  asset: Asset;
};

export const OverviewTab = ({ asset }: OverviewTabProps): JSX.Element => {
  useAnalyticsPage('Asset', 'Overview', { asset_name: asset.path });
  useScrollToBody();
  const track = useAnalyticsTrack();
  const { data, loading, error } = useAuthenticatedQuery<
    GetAssetPage,
    GetAssetPageVariables
  >(GET_ASSET_PAGE, {
    assetId: asset.id,
    slug: 'overview',
  });

  const {
    data: sponsorData,
    error: sponsorError,
    loading: sponsorLoading,
  } = useSponsor(asset.sponsorId || '');

  if (error) {
    return (
      <EmptyStateContainer>
        Something went wrong, please try again later
      </EmptyStateContainer>
    );
  }

  if (loading || !data) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }

  return (
    <>
      <ContentHeader>
        {asset.sponsorId && (
          <HideBreakpoint from="medium">
            <AssetSponsorToast sponsorId={asset.sponsorId} />
          </HideBreakpoint>
        )}
        {asset.currentRound &&
          asset.currentRound.roundClass.metrics.length !== 0 && (
            <HideBreakpoint from="medium">
              <AssetHighlightsBlock
                hasTaxLeakage={Boolean(
                  asset.currentRound.roundClass.hasTaxLeakage,
                )}
                metrics={asset.currentRound.roundClass.metrics}
              />
            </HideBreakpoint>
          )}
      </ContentHeader>
      <ContentCMS content={data.assetPage.content} onAnalyticsTrack={track} />
      {asset.sponsorId && (
        <VSpace spacing="small">
          <Heading level="h6">Sponsor</Heading>
          <SponsorCard
            topbar={sponsorData?.sponsor.name || ''}
            title={sponsorData?.sponsor.headline || ''}
            subtitle={sponsorData?.sponsor.subheadline || ''}
            slug={sponsorData?.sponsor.slug || ''}
            iconSrc={sponsorData?.sponsor.imageUrl || ''}
            bodySrc={sponsorData?.sponsor.bannerUrl || ''}
          />
        </VSpace>
      )}
    </>
  );
};
