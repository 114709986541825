import { DeepPartial } from '@reduxjs/toolkit';
import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';

import { usePartner } from '@hedgehog/browser/shared/auth';
import { PartnerSettingName } from '@hedgehog/data-access/graphql';
import { PresetThemeKey, Theme, ThemeProvider } from '@hedgehog/ui/themes';
import { StandardProps } from '@hedgehog/ui/utils';

export const InvestorsThemeProvider = ({
  children,
}: StandardProps): JSX.Element => {
  const { partner } = usePartner();
  const { name = null, settings, theme, resources } = partner ?? {};
  const { faviconUrl = null } = resources ?? {};
  const themeKey = settings?.find(
    (setting) => setting.name === PartnerSettingName.theme,
  )?.value as PresetThemeKey;

  return (
    <>
      <Helmet>
        <title>{name ?? 'Hedgehog Invest'}</title>
        <link rel="icon" href={faviconUrl ?? '/assets/favicon.ico'} />
      </Helmet>
      <ThemeProvider
        theme={themeKey || 'light'}
        overrides={(theme ?? {}) as DeepPartial<Theme>}
      >
        {children || <Outlet />}
      </ThemeProvider>
    </>
  );
};
