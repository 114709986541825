import { QueryResult } from '@apollo/client';

import { useAuthenticatedQuery } from '@hedgehog/data-access/contexts';
import {
  GET_NOTIFICATIONS,
  GetNotificationsQuery,
} from '@hedgehog/data-access/graphql';

export function useNotifications(): QueryResult<GetNotificationsQuery> {
  return useAuthenticatedQuery<GetNotificationsQuery>(GET_NOTIFICATIONS);
}
