import { useEffect } from 'react';

import { Guard } from '../contexts';

import { useGuardsContext } from './use-guards-context.hook';

export type UseGuardRegistryProps = Guard;

export const useGuardRegistry = (props: UseGuardRegistryProps): void => {
  const context = useGuardsContext();

  useEffect(() => {
    context.mount({
      name: props.name,
      priority: props.priority,
      validate: props.validate,
    });
    return (): void => {
      context.unmount({ name: props.name });
    };
  }, []);

  return;
};
