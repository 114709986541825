import { ApolloError } from '@apollo/client';
import { useEffect } from 'react';

import { useAuthenticatedLazyQuery } from '@hedgehog/data-access/contexts';
import {
  GET_VERIFICATION,
  GetVerificationQuery as GetVerification,
  KycCheckStatus,
} from '@hedgehog/data-access/graphql';

export interface UseVerificationReturn {
  verification: GetVerification['verificationProgress'] | null;
  isVerified: boolean;
  verificationLoading: boolean;
  verificationError: ApolloError | undefined;
  refetchVerification: () => void;
}

export const useVerification = (): UseVerificationReturn => {
  const [getVerification, { data, loading, error }] =
    useAuthenticatedLazyQuery<GetVerification>(GET_VERIFICATION, {
      fetchPolicy: 'cache-and-network',
    });
  const verification = data?.verificationProgress || null;
  const isVerified = verification?.status === KycCheckStatus.approved || false;

  const refetchVerification = (): void => {
    getVerification();
  };

  useEffect(refetchVerification, []);

  return {
    verification,
    isVerified,
    verificationLoading: loading,
    verificationError: error,
    refetchVerification,
  };
};
