import styled, { CSSObject } from 'styled-components';

import { useOrder } from '@hedgehog/browser/investors/order/data-access';
import { OrderStatusBadge } from '@hedgehog/browser/shared/order/feature-order';
import { ThinProgressBar } from '@hedgehog/ui/charts';
import { EmptyStateContainer } from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';

import { OrderStatusNote } from '../../components';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;

  ${({ theme }): CSSObject => ({
    gap: theme.spacing.large,
  })}
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`;

export interface OrderStatusBarProps {
  orderId?: string;
}

export const OrderStatusBar = ({
  orderId,
}: OrderStatusBarProps): JSX.Element => {
  const { data, error, loading } = useOrder(orderId || '');

  if (error) {
    return (
      <EmptyStateContainer>
        Something went wrong, try again later.
      </EmptyStateContainer>
    );
  }

  if (loading && !data) {
    return <Loader />;
  }

  const themes = data!.order!.asset.themes.map(({ category }) => category);
  const orderQuantity = data!.order!.quantity;
  return (
    <Wrapper>
      <ThinProgressBar value={0} color="secondary" />
      <InnerWrapper>
        <OrderStatusNote
          status={data!.order!.status}
          themes={themes}
          quantity={orderQuantity}
        />
        <OrderStatusBadge
          status={data!.order!.status}
          quantity={data!.order!.quantity || 0}
        />
      </InnerWrapper>
    </Wrapper>
  );
};
