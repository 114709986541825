import { Heading, Paragraph } from '@hedgehog/ui/typography';

export const JerseyInvestorLetter = (): JSX.Element => (
  <>
    <Paragraph>
      Documents shared with you via this website, via email or in other format
      are not a prospectus or an invitation to acquire or apply for shares. We
      are however seeking initial expressions of interest in respect of a
      potential investment opportunity and upon receiving expressions of
      interest, the directors of the relevant investee company will decide
      whether to issue an invitation to acquire or apply for shares.
    </Paragraph>
    <Heading level="h6">Private Equity Asset Class</Heading>
    <Paragraph>
      According to your client records held with us you have sufficient
      knowledge and experience of private equity investments to consider such an
      opportunity. Should you disagree please contact us immediately.
    </Paragraph>
    <Heading level="h6">Risk Warnings</Heading>
    <Paragraph>
      Private equity investments carry a higher degree of risk than many other
      forms of investment and may be difficult to realise as shares in private
      companies are not publicly traded. You should only invest if you can
      afford to do so and as part of a diversified investment strategy. It is
      your responsibility to satisfy yourself that this opportunity is suitable
      for you should you choose to proceed.
    </Paragraph>
    <Heading level="h6">Client Classification</Heading>
    <Paragraph>
      Based on your expertise, investment knowledge and experience, annual
      income in excess of £100,000 per annum and having portfolio assets of not
      less than £500,000, for the purposes of this opportunity we have
      classified you as an Elective Professional. Being classified as a
      professional client means that you will not be afforded the same level of
      protection that retail clients receive. If you disagree please immediately
      contact us.
    </Paragraph>
    <Paragraph>
      If you do not believe that you meet the definition of Elective
      Professional, please refrain from agreeing to these terms and speak with
      your Oakglen Wealth (Jersey) Limited contact
    </Paragraph>
    <Heading level="h6">Conflict Declaration</Heading>
    <Paragraph>
      Affiliates of Oakglen Wealth (Jersey) Limited are an investor in the
      private markets opportunities presented to you. This investment
      opportunity has come about because of this interest.
    </Paragraph>
    <Heading level="h6">Fee Transparency</Heading>
    <Paragraph>
      On the successful completion of a share sale Oakbridge will receive a fee
      of 2.5% of the investment consideration from the seller.
    </Paragraph>
    <Paragraph>
      By confirming that you agree to these terms you also agree for us to
      disclose your personal data (including name, jurisdiction of residence,
      telephone, email and residential address) to the directors of the relevant
      investee company.
    </Paragraph>
  </>
);
