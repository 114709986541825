import { ContractIssuance } from '@hedgehog/shared/blockchain/resident-token';

import {
  calculateLeaseRewardsRecent,
  calculateRentRewardsRecent,
  calculateSignOnRewardsRecent,
} from './helpers';

export type CalculateCurrentTotalRewards = Record<
  | 'currentTotalRewardsInTokensAmount'
  | 'recentRentRewardsInTokensAmount'
  | 'recentLeaseRewardsInTokensAmount'
  | 'recentSignOnRewardsInTokensAmount',
  number
>;

export const calculateCurrentTotalRewards = (
  issuances: ContractIssuance[],
): CalculateCurrentTotalRewards => {
  const recentLeaseRewardsInTokensAmount = calculateLeaseRewardsRecent({
    issuances,
  });

  const recentRentRewardsInTokensAmount = calculateRentRewardsRecent({
    issuances,
  });

  const recentSignOnRewardsInTokensAmount = calculateSignOnRewardsRecent({
    issuances,
  });

  const currentTotalRewardsInTokensAmount =
    recentLeaseRewardsInTokensAmount +
    recentRentRewardsInTokensAmount +
    recentSignOnRewardsInTokensAmount;

  return {
    currentTotalRewardsInTokensAmount,
    recentRentRewardsInTokensAmount,
    recentLeaseRewardsInTokensAmount,
    recentSignOnRewardsInTokensAmount,
  };
};
