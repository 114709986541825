import { createContext, useMemo, useReducer } from 'react';

import { Guard, GuardsState, guardsReducer } from './guards.reducer';

export interface GuardsContextControls {
  mount: (payload: Guard) => void;
  unmount: (payload: Pick<Guard, 'name'>) => void;
}

export interface GuardsContextValue
  extends GuardsState,
    GuardsContextControls {}

export const GuardsContext = createContext<GuardsContextValue>({
  guards: [],
  mount: () => void 0,
  unmount: () => void 0,
});

export const useGuardsContext = (): GuardsContextValue => {
  const [state, dispatch] = useReducer(guardsReducer, { guards: [] });

  const mountGuard = (payload: Guard): void =>
    dispatch({ type: 'guard:mount', payload });

  const unmountGuard = (payload: Pick<Guard, 'name'>): void =>
    dispatch({ type: 'guard:unmount', payload });

  return useMemo(
    () => ({
      guards: state.guards,
      mount: mountGuard,
      unmount: unmountGuard,
    }),
    [state],
  );
};
