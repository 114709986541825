import {
  OverrideKycStatus,
  ParseKycCheckParams,
  isLegacy,
  parseVerificationStatus,
} from '@hedgehog/browser/investors/kyc/data-access';
import { StandardProps } from '@hedgehog/ui/utils';

import { OnboardingAction } from '../../components';

export type OnboardingVerificationActionProps = StandardProps<
  ParseKycCheckParams | OverrideKycStatus,
  never
>;

export const OnboardingVerificationAction = ({
  ...kycCheckParams
}: OnboardingVerificationActionProps): JSX.Element => {
  return (
    <OnboardingAction
      title="Get verified"
      to="#kyc/verification"
      messages={{
        pending: '4 mins, using Passport or ID',
        inprogress: 'Verifying, please wait...',
      }}
      status={
        isLegacy(kycCheckParams)
          ? parseVerificationStatus(kycCheckParams)
          : kycCheckParams.statusOverride
      }
    />
  );
};
