import { useAuthenticatedQuery } from '@hedgehog/data-access/contexts';
import {
  GET_SPONSOR,
  GetSponsorQuery as GetSponsor,
  GetSponsorQueryVariables as GetSponsorVariables,
} from '@hedgehog/data-access/graphql';

export interface UseSponsorById {
  id: string;
  slug?: null;
}

export interface UseSponsorBySlug {
  slug: string;
  id?: null;
}

export type UseSponsor = UseSponsorById | UseSponsorBySlug;

export const useSponsor = ({
  id,
  slug,
}: UseSponsor): {
  data?: GetSponsor;
  error?: Error;
  loading: boolean;
} => {
  return useAuthenticatedQuery<GetSponsor, GetSponsorVariables>(GET_SPONSOR, {
    id,
    slug,
  });
};
