import { ForwardedRef, ReactNode, forwardRef } from 'react';
import styled from 'styled-components';

import { TopicProgress } from '@hedgehog/data-access/graphql';
import {
  GenericCard,
  GenericCardBody,
  LinkOrNotProps,
} from '@hedgehog/ui/cards';
import { Heading, Paragraph } from '@hedgehog/ui/typography';
import { StandardProps } from '@hedgehog/ui/utils';

import { CourseProgress } from './course-progress.component';

export type CourseCardProps = StandardProps<
  {
    title: string;
    thumbnail: string;
    progress?: TopicProgress[];
    caption?: ReactNode | string;
  } & LinkOrNotProps,
  never
>;

const BlowfishHeading = styled(Heading)`
  flex: 1 1 100%;
`;

const CustomCard = styled(GenericCard)`
  ${GenericCardBody} {
    flex: 1 1 100%;
    flex-flow: column nowrap;
  }
`;

export const CourseCardFooter = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
`;

export const CourseCard = styled(
  forwardRef(
    (
      { caption, title, thumbnail, progress, ...props }: CourseCardProps,
      ref: ForwardedRef<HTMLAnchorElement>,
    ): JSX.Element => (
      <CustomCard
        withShadow
        ref={ref}
        title={<BlowfishHeading level="h4">{title}</BlowfishHeading>}
        imageSrc={thumbnail}
        info={
          <CourseCardFooter>
            <Paragraph color="grey300">{caption}</Paragraph>
            <CourseProgress progress={progress} />
          </CourseCardFooter>
        }
        {...props}
      />
    ),
  ),
)``;
