import styled, { CSSObject } from 'styled-components';

import { Divider } from '@hedgehog/ui/assets';
import { Card, CardContent } from '@hedgehog/ui/cards';
import { List } from '@hedgehog/ui/lists';
import { Heading4, Paragraph } from '@hedgehog/ui/typography';
import { currencies } from '@hedgehog/utils/formats';

import { OrderPaymentInstructionItem } from '../order-payment-instruction-item/order-payment-instruction-item.component';

export interface BankDetails {
  name: string;
  address: string;
  beneficiary: string;
  accountNumber: string;
  routingNumber?: string;
  swift?: string;
  paymentReference: string;
}

export interface OrderPaymentInstructionsContentProps {
  orderCurrency: string;
  orderAmount: number;
  userCountry: string;
  bankDetails: BankDetails;
}

const OrderDescription = styled.div`
  display: flex;
  flex-flow: column nowrap;
  ${({ theme }): CSSObject => ({ gap: theme.spacing.normal })}
`;

const OrderInstructionsCard = styled(Card)`
  ${CardContent} {
    display: flex;
    flex-flow: column nowrap;
    ${({ theme }): CSSObject => ({ gap: theme.spacing.xlarge })}
  }
`;

const OrderAmount = styled.div`
  display: flex;
  justify-content: end;
`;

export const OrderPaymentInstructionsContent = ({
  orderCurrency,
  orderAmount,
  userCountry,
  bankDetails,
}: OrderPaymentInstructionsContentProps): JSX.Element => {
  return (
    <>
      <OrderInstructionsCard>
        <OrderDescription>
          <Paragraph color="shadow600">
            If you are using a money transfer service like Wise or WorldRemit,
            we may ask you for a bank statement to prove that the money came
            from your bank account.
          </Paragraph>
          <Divider />
          <OrderAmount>
            <Heading4>
              {currencies.formatMoney(orderAmount, {
                currency: orderCurrency as currencies.Currency,
              })}
            </Heading4>
          </OrderAmount>
        </OrderDescription>
      </OrderInstructionsCard>

      <OrderInstructionsCard>
        <List>
          <OrderPaymentInstructionItem
            label="Recipient Name"
            content={bankDetails.name}
          />
          <OrderPaymentInstructionItem
            label="Address"
            content={bankDetails.address}
          />
          <OrderPaymentInstructionItem
            label="Beneficiary Bank"
            content={bankDetails.beneficiary}
          />
          <OrderPaymentInstructionItem
            label="Account Number"
            content={bankDetails.accountNumber}
          />
          {userCountry !== 'USA' ? (
            <OrderPaymentInstructionItem
              label="SWIFT/BIC"
              content={bankDetails.swift ?? ''}
            />
          ) : (
            <OrderPaymentInstructionItem
              label="Routing (No ABA)"
              content={bankDetails.routingNumber ?? ''}
            />
          )}
          <OrderPaymentInstructionItem
            label="Payment Reference"
            content={bankDetails.paymentReference}
          />
        </List>
      </OrderInstructionsCard>
    </>
  );
};
