import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import styled, { CSSObject } from 'styled-components';

import { useAnalyticsPage } from '@hedgehog/browser/investors/shared/analytics';
import { useAuth, useLazyUser } from '@hedgehog/data-access/contexts';
import {
  BEGIN_SIGNUP_USER,
  BeginSignupMutation as BeginSignup,
  BeginSignupMutationVariables as BeginSignupVariables,
  SignUpPlatform,
} from '@hedgehog/data-access/graphql';
import { AnyFixLater } from '@hedgehog/shared/types';
import { HeaderWithBack } from '@hedgehog/ui/headers';
import { Loader } from '@hedgehog/ui/loaders';
import { Heading, Paragraph } from '@hedgehog/ui/typography';
import { screens } from '@hedgehog/utils/sizes';

import {
  SetPasswordForm,
  SetPasswordFormData,
  SignUpForm,
  SignUpFormData,
} from '../../containers';
import { deepLinkParams, useJwtPayload, useSignup } from '../../hooks';
import { usePartner } from '../../providers';
import { SignupState } from '../../types';
import { termsUrl, privacyPolicyUrl } from '../content';
import { SignupPageProps } from '../signup-page/signup.page';

const FormColumn = styled.div`
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media only screen and (min-width: ${screens.medium}px) {
    align-items: center;
    flex: 1 1 50%;
  }
`;

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media only screen and (min-width: ${screens.medium}px) {
    max-width: 19.375rem;
  }
`;

const Strong = styled.strong`
  ${({ theme }): CSSObject => ({ color: theme.colors.black })}
`;

export const PartnerReferralSignupPage = ({
  redirectTo = '/signup/confirm-email',
}: SignupPageProps): JSX.Element => {
  useAnalyticsPage('Authentication', 'Begin Signup');
  const auth = useAuth();
  const [loadUser] = useLazyUser();
  const [data, setData] = useState<SignUpFormData>({
    email: '',
    firstName: '',
    lastName: '',
    accepted: false,
  });
  const [passwordData, setPasswordData] = useState<SetPasswordFormData>({
    password: '',
    confirmPassword: '',
  });
  const [beginSignup, { loading, error }] = useMutation<
    BeginSignup,
    BeginSignupVariables
  >(BEGIN_SIGNUP_USER);
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as SignupState;
  const { data: jwt } = useJwtPayload();
  const [signup] = useSignup(jwt);
  const [searchParams] = useSearchParams();
  const isCreatedByPartner = searchParams.get('partner_created') === 'true';
  const partnerData = usePartner();

  const { payload } = jwt || {};
  const prefilledEmail =
    payload?.sub ||
    searchParams.get('email') ||
    searchParams.get(deepLinkParams['email']) ||
    '';
  const prefilledFirstName =
    (payload?.jwtType === 'identity'
      ? payload?.given_name
      : payload?.firstName) ||
    searchParams.get('firstName') ||
    searchParams.get(deepLinkParams['firstName']);

  const onSignupSubmit = async (
    formData: Required<SignUpFormData>,
  ): Promise<void> => {
    await beginSignup({
      variables: { ...formData, platform: SignUpPlatform.WEB },
    });
    navigate(`/${partnerData.partnerKey}${redirectTo}`, {
      state: { email: formData.email },
    });
  };

  const onPasswordSubmit = async (
    formData: Required<SetPasswordFormData>,
  ): Promise<void> => {
    try {
      await signup({
        email: jwt?.payload?.sub ?? prefilledEmail,
        password: formData.password,
        country: 'USA',
        isPartnerClient: true,
      });
      navigate({ pathname: '/explore' });
    } catch (err: AnyFixLater) {
      Sentry.captureException(err);
    }
  };

  useEffect(() => {
    const checkIfAlreadyExists = async (): Promise<void> => {
      if (jwt) {
        const { token, refreshToken } = jwt;

        auth.setTokens({
          accessToken: token,
          refreshToken: refreshToken ?? '',
        });

        const { data } = await loadUser();
        if (data) {
          return navigate('/explore', { replace: true });
        }
      }
      return;
    };

    checkIfAlreadyExists();
  }, [jwt]);

  if (partnerData.loading) {
    return <Loader />;
  }

  return (
    <>
      {isCreatedByPartner ? (
        <HeaderWithBack />
      ) : (
        <HeaderWithBack>
          <Heading level="h5">Sign up to Hedgehog</Heading>
        </HeaderWithBack>
      )}
      <FormColumn>
        {isCreatedByPartner ? (
          <FormContainer>
            <Heading level="h4">Welcome, {prefilledFirstName}</Heading>
            <Paragraph color="grey500">
              Please set a password for <Strong>{prefilledEmail}</Strong> to
              secure your account
            </Paragraph>
            <SetPasswordForm
              loading={loading}
              defaultForm={passwordData}
              onFormChange={setPasswordData}
              onSubmit={onPasswordSubmit}
              errors={
                locationState?.error ? [locationState.error.message] : undefined
              }
              termsUrl={
                partnerData.partner?.resources?.termsAgreementsUrl ?? termsUrl
              }
              privacyPolicyUrl={
                partnerData.partner?.resources?.privacyPolicyUrl ??
                privacyPolicyUrl
              }
            />
          </FormContainer>
        ) : (
          <FormContainer>
            <SignUpForm
              loading={loading}
              defaultForm={data}
              errors={error ? error.graphQLErrors.map((e) => e.message) : []}
              onFormChange={setData}
              onSubmit={onSignupSubmit}
              termsUrl={
                partnerData.partner?.resources?.termsAgreementsUrl ?? termsUrl
              }
              privacyPolicyUrl={
                partnerData.partner?.resources?.privacyPolicyUrl ??
                privacyPolicyUrl
              }
            />
          </FormContainer>
        )}
      </FormColumn>
    </>
  );
};

export default PartnerReferralSignupPage;
