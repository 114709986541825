import React, { useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useUser } from '@hedgehog/browser/investors/kyc/data-access';
import { GetUserQuery as GetUser } from '@hedgehog/data-access/graphql';

import { SidebarLayout } from '../../components';

import {
  SidebarRouterContext,
  useSidebarRouterContext,
} from './sidebar-router.context';

export interface SidebarBackdropTarget {
  /**
   * @desc
   * Deterimnes whether sidebar is closable by backdrop click
   * @default true
   */
  closable?: boolean;
}

export interface SidebarBackgroundParams {
  url?: string;
  position?: string;
  cover?: string;
}

export interface SidebarTarget<Props = object> {
  /**
   * @desc
   * Component that will be being shown once given path is active
   */
  component:
    | React.LazyExoticComponent<(props: Props) => JSX.Element>
    | ((props: Props) => JSX.Element);

  /**
   * @desc
   * You can specify background for the sidebar, it will be applied to the
   * backdrop that overlays the background page. If the background depends on user data,
   * pass a function instead of a string.
   */
  background?:
    | string
    | ((userData: GetUser | undefined) => SidebarBackgroundParams | string);

  backdrop?: SidebarBackdropTarget;

  /**
   * @desc
   * Props that will be passed to the component
   */
  props?: Props;
}

export type SidebarHashPath = `#${string}`;

export type SidebarRoutes = Record<SidebarHashPath | string, SidebarTarget>;

export interface SidebarRouterProps {
  routes: SidebarRoutes;
}

/**
 * Sidebar's only desktop container, therefore every route you're specifying
 * probably should have it's equivalent in normal router, as fallbackTo property
 * will be used to redirect user to the correct place once sidebar will be hidden
 * due to the breakpoint change
 * @returns
 */
export const SidebarRouter = ({ routes }: SidebarRouterProps): JSX.Element => {
  const hostRef = useRef<HTMLDivElement>(null);
  const context = useSidebarRouterContext(hostRef);
  const location = useLocation();
  const navigate = useNavigate();
  const { data: userData } = useUser();
  const { hash } = location;

  const {
    component: CurrentRoute = null,
    background,
    backdrop = {},
    props = {},
  } = routes[
    // split at ? to ignore query params
    hash.split('?')[0] as SidebarHashPath
  ] || {};

  const backdropWithDefaults = {
    closable: true,
    ...backdrop,
  };

  const close = (): void => {
    if (!backdropWithDefaults.closable) return;
    return navigate({ hash: '' }, { replace: true });
  };

  const backgroundParamsOrUrl =
    typeof background === 'function' ? background(userData) : background;
  const backgroundParams =
    typeof backgroundParamsOrUrl === 'string'
      ? { url: backgroundParamsOrUrl }
      : backgroundParamsOrUrl;

  return (
    <SidebarRouterContext.Provider value={context}>
      <SidebarLayout
        ref={hostRef}
        hidden={!CurrentRoute}
        backgroundUrl={backgroundParams?.url}
        backgroundPosition={backgroundParams?.position}
        backgroundSize={backgroundParams?.cover}
        onBackdropClick={close}
      >
        {CurrentRoute && <CurrentRoute {...props} />}
      </SidebarLayout>
    </SidebarRouterContext.Provider>
  );
};
