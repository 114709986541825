import { parseISO } from 'date-fns';

import { useContractIssuances } from '@hedgehog/browser/investors/account-abstraction/data-access';
import { useOrder } from '@hedgehog/browser/investors/order/data-access';
import { createResidentTokenProjection } from '@hedgehog/shared/resident-token/utils-estimations';
import { singularOrPlural } from '@hedgehog/shared/utils/content';
import { EmptyStateContainer } from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';
import { currencies } from '@hedgehog/utils/formats';
import { formatNumber } from '@hedgehog/utils/numbers';

import { OrderDetailsCard, OrderDetailsItem } from '../../components';

export interface OrderDetailsOverviewProps {
  orderId: string;
}

export const OrderDetailsOverview = ({
  orderId = '',
}: OrderDetailsOverviewProps): JSX.Element => {
  const { error } = useOrder(orderId);
  const { data: issuances } = useContractIssuances();

  if (error) {
    return (
      <EmptyStateContainer>
        Something went wrong, try again later.
      </EmptyStateContainer>
    );
  }

  const formula = createResidentTokenProjection({
    // TODO: replace dates with database ones (fundedAt, endsAt) @jchlebowicz
    from: parseISO('2023-06-30T00:00:00.000Z'),
    to: parseISO('2025-06-30T00:00:00.000Z'),
  });
  const projection = issuances ? formula(issuances) : null;
  const loader = <Loader height={24} />;
  return (
    <OrderDetailsCard>
      <OrderDetailsItem
        iconType="token"
        label="Total amount"
        text={`${
          projection
            ? formatNumber(projection.currentTotalRewardsInTokensAmount, 0)
            : 0
        } ${singularOrPlural(
          projection ? projection.currentTotalRewardsInTokensAmount : 0,
          'token',
          'tokens',
        )}`}
      />
      <OrderDetailsItem
        iconType="money"
        label="Unvested value"
        text={
          projection
            ? currencies.formatMoney(
                projection.recentLeaseRewardsInTokensAmount +
                  projection.recentRentRewardsInTokensAmount +
                  projection.recentSignOnRewardsInTokensAmount,
              )
            : loader
        }
      />
      <OrderDetailsItem
        iconType="money"
        label="Vested value"
        text={projection ? currencies.formatMoney(0) : loader}
      />
      <OrderDetailsItem
        iconType="calendar"
        label="Maximum token allocation"
        text={
          projection
            ? currencies.formatMoney(projection.maximumTokenAllocation, {
                currency: null,
              })
            : loader
        }
      />
      <OrderDetailsItem
        iconType="arrow-up"
        label="Projected returns after 2 Years"
        text={
          projection
            ? currencies.formatMoney(
                projection.estimatedTotalRewardsInTokensAmount,
              )
            : loader
        }
      />
    </OrderDetailsCard>
  );
};
