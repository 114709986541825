import { JwtPayload } from 'jwt-decode';
import { useSearchParams } from 'react-router-dom';

import { useAnalyticsTrack } from '@hedgehog/browser/investors/shared/analytics';
import { QueryResult, useQueryResult } from '@hedgehog/browser/shared/utils';
import { CognitoSessionTokens, useAuth } from '@hedgehog/data-access/contexts';
import { useEnvironment } from '@hedgehog/ui/environment';

import { useCompleteClientSignup } from './use-complete-client-signup.hook';
import { useCompleteSignup } from './use-complete-signup.hook';
import { useIsSSO } from './use-is-sso.hook';
import { JwtTokenPayload, deepLinkParams } from './use-jwt-payload.hook';

export type IdentityToken = {
  email: string;
  email_address?: string; // Legacy. Not sure if we need this. The JWT seems to have "email" for both apple and google.
  given_name?: string; // On Apple, only available on the first login. On Google, always available.
  family_name?: string; // On Apple, only available on the first login. On Google, always available.
};

export interface JwtSignupPayload extends JwtPayload {
  sso: boolean;
  firstName: string;
  lastName?: string;
  sub: string;
}

export interface SignupParams {
  country?: string;
  state?: string;
  email: string;
  password: string;
  isPartnerClient?: boolean;
}

export const useSignup = (jwt: JwtTokenPayload | null) => {
  const {
    ios: { clientId: iosClientId },
  } = useEnvironment();
  const auth = useAuth();
  const track = useAnalyticsTrack();
  const [completeSignup] = useCompleteSignup();
  const [completeClientSignup] = useCompleteClientSignup();
  const [searchParams] = useSearchParams();
  const [state, controls] = useQueryResult<boolean>();
  const isSSO = useIsSSO(jwt);

  const referredBy =
    localStorage.getItem('referredByPartner') ||
    localStorage.getItem('referredBy');

  const signupViaEmail = async ({
    email,
    password,
    country,
    state,
    isPartnerClient,
  }: SignupParams): Promise<CognitoSessionTokens> => {
    const defaultToken = await auth.signup({ email, password });
    const { token = defaultToken, payload } = jwt || {};

    if (isPartnerClient)
      await completeClientSignup({
        variables: {
          authCode:
            searchParams.get('code') ||
            searchParams.get(deepLinkParams['code']) ||
            '',
          isSSO: false,
        },
      });
    else {
      await completeSignup({
        variables: {
          firstName:
            payload?.jwtType === 'basic'
              ? payload.firstName
              : searchParams.get(deepLinkParams['firstName']),
          lastName: payload?.jwtType === 'basic' ? payload.lastName : undefined,
          country: country ?? '',
          state: state ?? '',
          referredBy,
          isSSO: false,
          signupToken: token ?? '',
        },
      });
    }

    return await auth.signin({
      password,
      email,
    });
  };

  const signupViaSSO = async ({
    country,
    state,
    isPartnerClient,
  }: SignupParams): Promise<CognitoSessionTokens> => {
    if (!jwt) throw new Error('No JWT payload found');
    if (jwt.payload.jwtType === 'basic') throw new Error('Invalid JWT type');
    const { token, refreshToken, payload } = jwt;

    if (isPartnerClient)
      await completeClientSignup({
        variables: {
          authCode: searchParams.get('code') ?? '',
          isSSO: true,
        },
      });
    else {
      await completeSignup({
        variables: {
          firstName: payload?.given_name,
          lastName: payload?.family_name,
          country: country ?? '',
          state: state ?? '',
          referredBy,
          isSSO: true,
          signupToken: token,
        },
      });
    }

    track('Account', 'Created - Web Client', {
      isPartnerClient,
    });

    auth.setTokens({
      accessToken: token,
      refreshToken: refreshToken ?? '',
    });

    return {
      accessToken: token,
      refreshToken: refreshToken ?? '',
    };
  };

  const signupViaMobile = async (): Promise<void> => {
    const { token, platform } = {
      platform: searchParams.get('platform') ?? '',
      token: searchParams.get('token') ?? '',
    };

    if (platform.toLowerCase() === 'ios')
      return window.location.assign(
        `${iosClientId}://signup_confirm?token=${token}`,
      );
  };

  const signup = async (
    params: SignupParams,
  ): Promise<QueryResult<boolean>> => {
    try {
      controls.setLoading(true);
      if (
        searchParams.has('token') &&
        searchParams.get('platform')?.toLowerCase() === 'ios'
      ) {
        await signupViaMobile();
        return { data: null, error: null, loading: false };
      }

      if (isSSO) {
        await signupViaSSO(params);
      } else {
        await signupViaEmail(params);
      }

      controls.setData(true);
      controls.setError(null);
      return {
        data: true,
        error: null,
        loading: false,
      };
    } catch (err) {
      controls.setData(null);
      controls.setError(err);
      auth.deleteUser();
      return {
        data: null,
        error: err,
        loading: false,
      };
    } finally {
      controls.setLoading(false);
    }
  };

  return [signup, { ...state, isSSO }] as const;
};
