import {
  KycCheckStatus,
  isKycOnly,
  isNDA,
} from '@hedgehog/data-access/graphql';
import { useIsNativeMobile } from '@hedgehog/data-access/native-mobile';

import { useGuardRegistry } from '../hooks';

export interface AssessmentGuardProps {
  priority: number;
}

export const AssessmentGuard = ({
  priority,
}: AssessmentGuardProps): JSX.Element => {
  const isNativeMobile = useIsNativeMobile();

  useGuardRegistry({
    name: 'assessment',
    priority,
    validate: (data) => {
      const { user, assessmentProgress, partnerSettings } = data || {};
      if (isNativeMobile) return null;
      // TODO: 🔍 KYC-only onboarding tech-debt - remove me -  https://linear.app/hedgehog-invest/issue/HOG-2898/%F0%9F%92%A1-get-rid-of-all-the-oakglen-if-statements
      if (isKycOnly(partnerSettings)) return null;
      // TODO: 🌎 onboarding tech-debt - remove me - https://linear.app/hedgehog-invest/issue/HOG-2898/%F0%9F%92%A1-get-rid-of-all-the-oakglen-if-statements
      if (user?.country !== 'GBR') return null;
      if (isNDA(partnerSettings)) return null;

      const isMissingAssessment =
        assessmentProgress?.status !== KycCheckStatus.approved;
      if (isMissingAssessment) {
        return '#assessment';
      }

      return null;
    },
  });

  return <></>;
};
