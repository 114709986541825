import * as dateFns from 'date-fns';

import {
  GetTenantTokenBalanceQuery as GetTenantTokenBalance,
  TokenVesting as VestingBalance,
} from '@hedgehog/data-access/graphql';

const BALANCE_TYPENAME = 'TokenBalancePayload';

const toDecimal = (amount: string): number => parseInt(amount) / 1e18;

export const balance = (data?: GetTenantTokenBalance): number => {
  const { tokenBalance } = data || {};
  return tokenBalance?.__typename === BALANCE_TYPENAME
    ? toDecimal(tokenBalance.balance)
    : 0;
};

export const vestingBalance = (data?: VestingBalance): number =>
  data ? toDecimal(data.amount) : 0;

export const vesting = (data?: GetTenantTokenBalance): VestingBalance[] => {
  const { tokenBalance } = data || {};
  return tokenBalance?.__typename === BALANCE_TYPENAME
    ? tokenBalance?.vesting || []
    : [];
};

export const contractAddress = (data?: GetTenantTokenBalance): string => {
  const { tokenBalance } = data || {};

  return tokenBalance?.__typename === BALANCE_TYPENAME
    ? tokenBalance.contract
    : '';
};

const parseDate =
  (key: 'startDate' | 'endDate') =>
  (data: VestingBalance): Date =>
    new Date(parseInt(data[key]) * 1000);

const startDate = parseDate('startDate');
const endDate = parseDate('endDate');

export const hasVested = (data: VestingBalance): boolean =>
  dateFns.isPast(endDate(data));

export const label = (data: VestingBalance, now = Date.now()): string => {
  const text = hasVested(data) ? 'Vested' : 'Vesting';
  const when = dateFns.formatRelative(endDate(data), now);
  return `${text} ${when}`;
};

export const progress = (data: VestingBalance, now = Date.now()): number => {
  const start = startDate(data).getTime();
  const duration = endDate(data).getTime() - start;
  return Math.max(0, Math.min((now - start) / duration, 1));
};

export const icon = (data: VestingBalance): string =>
  hasVested(data) ? ':handshake:' : ':hourglass:';
