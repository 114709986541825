import styled, { CSSObject } from 'styled-components';

import { useAnalyticsPage, useAnalyticsTrack } from '@hedgehog/browser/investors/shared/analytics';
import { useQueryParams } from '@hedgehog/data-access/hooks';
import { LogoAvatar } from '@hedgehog/ui/avatars';
import { ContentCMS } from '@hedgehog/ui/cms';
import { InnerHeader } from '@hedgehog/ui/layouts';
import {
  Page,
  EmptyStateContainer,
  LoadingContainer,
} from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';
import { screens } from '@hedgehog/utils/sizes';

import { useSponsor } from '../../hooks/use-sponsor.hook';

const SponsorHeader = styled(InnerHeader)`
  ${({ theme }): CSSObject => ({
    color: theme.colors.black,
    backgroundColor: theme.colors.grey100,
    padding: '2rem 2.5rem 1.5rem',
  })};

  @media only screen and (max-width: ${screens.medium}px) {
    padding: 1.5rem;
  }
`;

export const SponsorPage = (): JSX.Element => {
  const params = useQueryParams();
  const slug = params.get('sponsorId') || '';
  useAnalyticsPage('Sponsor', 'Overview', { sponsor_name: slug });
  const track = useAnalyticsTrack();
  const { data, error, loading } = useSponsor({ slug });

  if (error) {
    return (
      <Page>
        <EmptyStateContainer>
          Something went wrong, please try again later
        </EmptyStateContainer>
      </Page>
    );
  }

  if (loading || !data) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }

  const icon = data?.sponsor?.imageUrl && (
    <LogoAvatar
      size="m"
      imageUrl={data.sponsor.imageUrl || '/assets/images/hedgehog-avatar.jpeg'}
    />
  );

  return (
    <div>
      <SponsorHeader headerTitle={data.sponsor.name} headerIcon={icon} />
      <Page sidebarPage>
        <ContentCMS content={data.sponsor.content} onAnalyticsTrack={track} />
      </Page>
    </div>
  );
};
