import {
  AnnouncementListItem,
  LinkBlockAnchorOrRouterProps,
} from '../../components';
import { useMarkAsOpen } from '../../hooks';

export type HighlightListItemProps = {
  id: string;
  title: string;
  description: string;
  unread: boolean;
  unopened: boolean;
  imageSrcUrl?: string | null;
  onClick?: React.MouseEventHandler;
} & LinkBlockAnchorOrRouterProps;

export function HighlightListItem({
  imageSrcUrl,
  unopened,
  onClick,
  ...props
}: HighlightListItemProps): JSX.Element {
  const [markAsOpen] = useMarkAsOpen();

  const handleClick: React.MouseEventHandler = (event) => {
    markAsOpen({ variables: { notificationId: props.id } });
    if (onClick) onClick(event);
  };

  return (
    <AnnouncementListItem
      {...props}
      src={imageSrcUrl || ''}
      title={props.title}
      description={props.description}
      unread={unopened}
      onClick={handleClick}
    />
  );
}
