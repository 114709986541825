import { useLazyQuery } from '@apollo/client';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { useAuth, useLazyUser } from '@hedgehog/data-access/contexts';
import {
  GET_PARTNER,
  GetPartnerQuery as GetPartner,
} from '@hedgehog/data-access/graphql';
import { LoaderContainer } from '@hedgehog/ui/buttons';
import { Loader } from '@hedgehog/ui/loaders';

type GetPartner_partner = GetPartner['partner'];

export interface PartnerContextType {
  partner?: GetPartner_partner | null;
  partnerKey?: string;
  loading: boolean;
  initialised: boolean;
  error?: Error;
  updatePartnerKey: (key?: string) => void;
}

export const PartnerContext = createContext<PartnerContextType>({
  partner: undefined,
  loading: false,
  initialised: false,
  updatePartnerKey: () => void 0,
});

export const usePartner = (): Omit<PartnerContextType, 'updatePartnerKey'> => {
  const context = useContext(PartnerContext);
  if (context === undefined) {
    throw new Error('usePartner must be used within a PartnerProviderContext');
  }
  const { updatePartnerKey, ...rest } = context;
  return rest;
};

type PartnerProviderProps = {
  children?: ReactNode | ReactNode[];
};

export const PartnerProvider = ({
  children,
}: PartnerProviderProps): JSX.Element => {
  const initialisedRef = useRef(false);
  const [partnerSlug, setSlug] = useState<string | undefined>();

  const { accessToken } = useAuth();
  const [
    getUser,
    { data: userData, loading: userLoading, error: errorLoading },
  ] = useLazyUser();
  const [
    getPartner,
    { data: partnerData, loading: partnerLoading, error: partnerError },
  ] = useLazyQuery<GetPartner>(GET_PARTNER);
  const slug = userData?.user?.partner?.slug || partnerSlug;

  useEffect(() => {
    initialisedRef.current = true;
  }, []);

  useEffect(() => {
    if (!accessToken) return;
    getUser();
  }, [accessToken]);

  useEffect(() => {
    if (!slug) return;
    getPartner({ variables: { slug } });
  }, [partnerSlug, userData, getPartner]);

  const value = useMemo(
    () => ({
      partnerKey: slug,
      initialised: initialisedRef.current,
      loading: partnerLoading || userLoading,
      error: partnerError || errorLoading,
      partner: partnerData?.partner,
      updatePartnerKey: (key?: string) => setSlug(key),
    }),
    [slug, partnerData, partnerLoading, partnerError],
  );

  return (
    <PartnerContext.Provider value={value}>
      {children || <Outlet />}
    </PartnerContext.Provider>
  );
};

export const PartnerKeyRouter = (): JSX.Element | null => {
  const { loading, updatePartnerKey, partner } = useContext(PartnerContext);
  const { slug } = useParams();

  useEffect(() => {
    updatePartnerKey(slug);
  }, [slug]);

  if (!slug || loading || !partner) {
    return (
      <LoaderContainer>
        <Loader color="black" />
      </LoaderContainer>
    );
  }
  return <Outlet />;
};
