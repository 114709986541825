import { LazyQueryResultTuple, OperationVariables } from '@apollo/client';

import { useAuthenticatedLazyQuery } from '@hedgehog/data-access/contexts';
import {
  GET_TAX_FORM,
  GetTaxFormQuery as GetTaxForm,
} from '@hedgehog/data-access/graphql';

export const useLazyTaxForm = (): LazyQueryResultTuple<
  GetTaxForm,
  OperationVariables
> => useAuthenticatedLazyQuery<GetTaxForm>(GET_TAX_FORM);
