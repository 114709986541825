import { useAuthenticatedQuery } from '@hedgehog/data-access/contexts';
import {
  GetUserActionDataQuery as GetUserActionData,
  GET_USER_ACTION_DATA,
} from '@hedgehog/data-access/graphql';

import { CooldownCard } from '../components/cooldown.component';
import { Header } from '../components/header.component';
import { useCoolDown } from '../hooks/use-cooldown.hook';

export const CooldownPage = (): JSX.Element => {
  const { data } =
    useAuthenticatedQuery<GetUserActionData>(GET_USER_ACTION_DATA);

  const unlockDate = useCoolDown({
    userCountry: data?.user?.country || '',
    userSignedUpAt: data?.user?.createdAt || '',
    partnerSlug: data?.user?.partner?.slug || '',
  });

  return (
    <>
      <Header />
      <CooldownCard unlockDate={unlockDate} />;
    </>
  );
};

export default CooldownPage;
