import { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useOrder } from '@hedgehog/browser/investors/order/data-access';
import { OrderStatus } from '@hedgehog/data-access/graphql';
import { StandardProps } from '@hedgehog/ui/utils';

import { orderStatusToPath } from '../../constants';

export type OrderStatusGuardProps = StandardProps<{ hashed?: boolean }>;

/**
 * Guarantees that the user is on the correct route based on the order status
 * @param hashed, if true, will use hash to navigate to the correct route
 * @returns
 */
export const OrderStatusGuard = ({
  hashed = false,
  children,
}: OrderStatusGuardProps): JSX.Element => {
  const { pathname, hash } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { data } = useOrder(searchParams.get('orderId') || '');

  const coordinateToAccuratePath = (status: OrderStatus): void => {
    const expectedRoute: string | undefined =
      orderStatusToPath[status as keyof typeof orderStatusToPath];

    if (hashed && !hash.endsWith(expectedRoute)) {
      return navigate(
        {
          pathname,
          hash: `order/${expectedRoute}`,
          search: searchParams.toString(),
        },
        { replace: true },
      );
    }
    if (!hashed && !pathname.endsWith(expectedRoute)) {
      return navigate(
        {
          pathname: pathname + expectedRoute,
          search: searchParams.toString(),
        },
        { replace: true },
      );
    }
  };

  useEffect(() => {
    if (!data) return;
    if (!data.order) return;

    coordinateToAccuratePath(data.order.status);
  }, [data]);

  return <>{children}</>;
};
