import React, { useState } from 'react';

import { useAuthUser } from '@hedgehog/browser/investors/account/data-access';
import { createVerificationSession } from '@hedgehog/browser/investors/kyc/data-access';
import { useAuthenticatedQuery } from '@hedgehog/data-access/contexts';
import {
  GetUserQuery as GetUser,
  GET_USER,
} from '@hedgehog/data-access/graphql';
import {
  NativeMobileExperiences,
  sendMessageToApp,
  useIsNativeMobileExperience,
} from '@hedgehog/data-access/native-mobile';
import { SecondaryButton } from '@hedgehog/ui/buttons';
import { useEnvironment } from '@hedgehog/ui/environment';
import { VSpace } from '@hedgehog/ui/layouts';
import { LoadingContainer, EmptyStateContainer } from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';
import { Heading, Paragraph } from '@hedgehog/ui/typography';

import { VeriffModal } from '../containers';

interface SubmitProps {
  refetchVerification: () => void;
}

const Submit = ({ refetchVerification }: SubmitProps): JSX.Element => {
  const user = useAuthUser();
  const env = useEnvironment();
  const isNativeVerificationEnabled = useIsNativeMobileExperience(
    NativeMobileExperiences.KYC_VERIFICATION,
  );
  const { data, loading, error } = useAuthenticatedQuery<GetUser>(GET_USER);

  const [sessionUrl, setSessionUrl] = useState('');

  const handleFormSubmit = async (
    e: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    e.preventDefault();

    if (isNativeVerificationEnabled) {
      sendMessageToApp(`navigation.userVerification`);
    } else {
      const url = await createVerificationSession(
        {
          firstName: data?.user?.firstName || '',
          lastName: data?.user?.lastName || '',
          userId: user?.id ?? null,
        },
        env.veriff,
      );
      setSessionUrl(url);
    }
  };

  return (
    <>
      <VeriffModal
        sessionUrl={sessionUrl}
        refetchVerification={refetchVerification}
      />
      {loading && (
        <LoadingContainer>
          <Loader />
        </LoadingContainer>
      )}
      {error && (
        <EmptyStateContainer>
          Something went wrong, please try again later
        </EmptyStateContainer>
      )}
      {data && (
        <form onSubmit={handleFormSubmit}>
          <VSpace spacing="small">
            <Heading level="h4">Let&apos;s get you verified</Heading>
            <VSpace spacing="tiny">
              <Paragraph>First name: {data.user?.firstName}</Paragraph>
              <Paragraph>Last name: {data.user?.lastName}</Paragraph>
            </VSpace>
            <SecondaryButton type="submit">Begin verification</SecondaryButton>
          </VSpace>
        </form>
      )}
    </>
  );
};

export default Submit;
