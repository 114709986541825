import axios from 'axios';
import { keccak256 } from 'js-sha3';

export const hash = (data: ArrayBuffer): string => {
  const buffer = new Uint8Array(data);
  const hash = keccak256(buffer);
  return '0x' + hash;
};

export const hashDocumentContents = async (
  documentUri: string,
): Promise<string> => {
  try {
    // use axios to download the document
    const response = await axios.get(documentUri, {
      responseType: 'arraybuffer',
    });

    const data = (await response.data) as ArrayBuffer;
    return hash(data);
  } catch (err: any) {
    throw new Error(`Failed to hash document contents: ${err.message}`);
  }
};
