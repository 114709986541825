import styled, { CSSProp } from 'styled-components';

import { Heading, Note, Paragraph } from '@hedgehog/ui/typography';
import { currencies } from '@hedgehog/utils/formats';
import { screens } from '@hedgehog/utils/sizes';

const PortfolioSummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  @media only screen and (max-width: ${screens.medium}px) {
    margin-right: 0;
  }
`;

const Title = styled(Heading)`
  @media only screen and (max-width: ${screens.medium}px) {
    display: none;
  }
`;

const BalanceWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  border-bottom: 1px solid;
  padding-bottom: 1.5rem;
  ${({ theme }): CSSProp => ({ borderBottomColor: theme.colors.shadow100 })}

  ${Title} {
    padding-bottom: 2.5rem;
  }
`;

const ReturnsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export interface PortfolioSummaryProps {
  loading?: boolean;
  totalAmountPending?: number;
  totalAmountInvested?: number;
  totalReturns?: number;
}

export const PortfolioSummary = ({
  loading = false,
  totalAmountInvested = 0,
  totalAmountPending = 0,
  totalReturns = 0,
}: PortfolioSummaryProps): JSX.Element => {
  return (
    <PortfolioSummaryWrapper>
      <BalanceWrapper>
        <Title level="h5">Portfolio</Title>
        <Heading
          level="h2"
          loading={loading}
          color={totalAmountPending > 0 ? 'shadow400' : 'black'}
        >
          {currencies.formatMoney(totalAmountInvested + totalAmountPending, {
            currency: 'USD',
            minimumFractionDigits: 2,
          })}
        </Heading>
        {totalAmountPending > 0 ? <Note>Processing payment...</Note> : null}
      </BalanceWrapper>
      <ReturnsWrapper>
        <Paragraph color="grey400">Total Returns</Paragraph>
        <Heading
          color={totalReturns >= 0 ? 'success' : 'error'}
          level="h6"
          loading={loading}
        >
          +
          {currencies.formatMoney(totalReturns, {
            currency: 'USD',
          })}
        </Heading>
      </ReturnsWrapper>
    </PortfolioSummaryWrapper>
  );
};
