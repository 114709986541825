import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useVerification } from '@hedgehog/browser/investors/kyc/data-access';
import { KycCheckStatus } from '@hedgehog/data-access/graphql';
import { LoadingContainer } from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';
import { StandardProps } from '@hedgehog/ui/utils';

import { Declined, Resubmit, Submit } from '../../components';

export type VeriffProps = StandardProps<
  {
    redirectTo?: string;
  },
  never
>;

export const Veriff = ({ redirectTo = '#kyc' }: VeriffProps): JSX.Element => {
  const navigate = useNavigate();
  const { verification, verificationLoading, refetchVerification } =
    useVerification();
  const verificationStatus = verification?.status ?? null;

  useEffect(() => {
    if (!verification?.status) {
      return;
    }
    if (
      [KycCheckStatus.processing, KycCheckStatus.approved].indexOf(
        verification.status,
      ) !== -1
    ) {
      navigate(redirectTo, { replace: true });
    }
  }, [verification]);

  if (verificationLoading) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }

  if (verificationStatus === KycCheckStatus.rejected) {
    return <Declined />;
  }

  if (verificationStatus === KycCheckStatus.retry) {
    return <Resubmit refetchVerification={refetchVerification} />;
  }

  return <Submit refetchVerification={refetchVerification} />;
};
