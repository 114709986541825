import { MutationTuple, useMutation } from '@apollo/client';

import {
  ClaimOrderMutation as ClaimOrder,
  ClaimOrderMutationVariables as ClaimOrderVariables,
  CLAIM_ORDER,
  GET_ORDER,
  GET_ORDERS,
} from '@hedgehog/data-access/graphql';

export const useOrderClaim = (): MutationTuple<
  ClaimOrder,
  ClaimOrderVariables
> =>
  useMutation<ClaimOrder, ClaimOrderVariables>(CLAIM_ORDER, {
    refetchQueries: () => [{ query: GET_ORDER }, { query: GET_ORDERS }],
  });
