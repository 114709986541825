import React, { ReactNode } from 'react';

import { RiskWarning } from '@hedgehog/browser/investors/explore/feature-assets';
import {
  useIsInvestingEnabled,
  useUser,
} from '@hedgehog/browser/investors/kyc/data-access';
import { usePartnerSetting } from '@hedgehog/browser/investors/kyc/data-access';
import { PartnerSettingName } from '@hedgehog/data-access/graphql';
import { useMediaQuery } from '@hedgehog/data-access/hooks';
import {
  HedgehogIcon,
  ChartIcon,
  BookIcon,
  UserIcon,
  HomeIcon,
} from '@hedgehog/ui/icons';
import {
  LoadingContainer,
  MainLayout,
  NavigationListItem,
  NotificationListItem,
  TopbarLinkList,
} from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';
import { screens } from '@hedgehog/utils/sizes';

export interface AppLayoutProps {
  children?: ReactNode | ReactNode[];
}

const ProfileNavLink = (): JSX.Element => (
  <NavigationListItem
    to="/account"
    key="account"
    icon={<UserIcon />}
    patterns={['/account']}
  >
    Profile
  </NavigationListItem>
);

const InvestorNavLinks = ({
  isDesktop,
  isWhitelabel,
  learnSectionEnabled,
}: {
  isDesktop: boolean;
  isWhitelabel: boolean;
  learnSectionEnabled: boolean;
}): JSX.Element => (
  <TopbarLinkList>
    <NavigationListItem
      to="/explore"
      key="explore"
      icon={(isWhitelabel && <HomeIcon />) || <HedgehogIcon />}
      patterns={['/explore', '/assets/*']}
    >
      Explore
    </NavigationListItem>
    <NavigationListItem
      to="/portfolio"
      key="portfolio"
      icon={<ChartIcon />}
      patterns={['/portfolio', '/orders/*', '/buy/*']}
    >
      Portfolio
    </NavigationListItem>
    {learnSectionEnabled && (
      <NavigationListItem
        to="/learn"
        key="learn"
        icon={<BookIcon />}
        patterns={['/learn']}
      >
        Learn
      </NavigationListItem>
    )}
    <ProfileNavLink />
    <NotificationListItem
      to={isDesktop ? '#notifications' : '/notifications'}
      badged={false}
      data-testid="notification-button"
    />
  </TopbarLinkList>
);

export const AppLayout = ({ children }: AppLayoutProps): JSX.Element => {
  const isDesktop = useMediaQuery(
    `only screen and (min-width: ${screens.medium}px)`,
  );
  const { enabled: isInvestingEnabled, loading } = useIsInvestingEnabled();
  const { enabled: learnSectionEnabled } = usePartnerSetting('learn');
  const { data } = useUser();
  const { enabled = false } =
    data?.partnerSettings?.find(
      ({ name }) => name === PartnerSettingName.theme,
    ) || {};

  return (
    <MainLayout
      brandLoading={loading}
      brandLogotypeUrl={
        enabled ? data?.user?.partner?.resources?.logotypeLightUrl : undefined
      }
      fixToTop={<RiskWarning />}
      isInvestingEnabled={!!isInvestingEnabled}
      topbarLinks={
        isInvestingEnabled ? (
          <InvestorNavLinks
            isDesktop={!!isDesktop.matches}
            isWhitelabel={data?.user?.partner?.slug !== 'hedgehog'}
            learnSectionEnabled={learnSectionEnabled ?? true}
          />
        ) : (
          <TopbarLinkList>
            <ProfileNavLink />
          </TopbarLinkList>
        )
      }
      isWhitelabel={data?.user?.partner?.slug !== 'hedgehog'}
      learnSectionEnabled={learnSectionEnabled ?? true}
    >
      <React.Suspense
        fallback={
          <LoadingContainer>
            <Loader />
          </LoadingContainer>
        }
      >
        {children}
      </React.Suspense>
    </MainLayout>
  );
};
