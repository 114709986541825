import { ReactNode } from 'react';
import styled, { CSSObject } from 'styled-components';

import { Heading, Paragraph } from '@hedgehog/ui/typography';

import { ToastLayout } from '../toast-layout/toast-layout.component';

const Content = styled.div`
  ${({ theme }): CSSObject => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: theme.spacing.xxsmall,
  })}
`;

export type PlainToastProps = {
  title?: string;
  description?: string;
  trailing?: ReactNode;
  className?: string;
} & React.HTMLAttributes<HTMLDivElement>;

export const PlainToast = styled(
  ({
    title,
    description,
    trailing,
    className,
    ...props
  }: PlainToastProps): JSX.Element => {
    return (
      <ToastLayout className={className} {...props}>
        <Content>
          {title && (
            <Heading level={description ? 'h7' : 'h6'}>{title}</Heading>
          )}
          {description && (
            <Paragraph small color="grey500">
              {description}
            </Paragraph>
          )}
        </Content>
        {trailing}
      </ToastLayout>
    );
  },
)``;
