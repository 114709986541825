import styled from 'styled-components';

import { TopicProgress } from '@hedgehog/data-access/graphql';
import { StandardProps } from '@hedgehog/ui/utils';

import { CourseTopicCircle } from './course-topic-circle.component';

const Circles = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
`;

export type CaurseProgressProps = StandardProps<{
  progress?: TopicProgress[];
}>;

export const CourseProgress = ({
  progress = [],
  className,
}: CaurseProgressProps): JSX.Element => (
  <Circles className={className}>
    {progress.map((status, index) => (
      <CourseTopicCircle
        key={`${status}_${index}`}
        appearance="chunk"
        progress={status}
      />
    ))}
  </Circles>
);
