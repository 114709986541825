import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { TextInput } from '../TextInput/TextInput';

const SearchInputContainer = styled.div`
  width: 100%;
`;

type AddressSearchInputProps = {
  value: string;
  placeholder?: string;
  onChange: (value: string) => void;
};

export const AddressSearchInput = ({
  value,
  placeholder = 'Start typing an address',
  onChange,
}: AddressSearchInputProps): JSX.Element => {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, [ref]);

  return (
    <SearchInputContainer>
      <TextInput
        ref={ref}
        name="search"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      />
    </SearchInputContainer>
  );
};
