import { addMilliseconds } from 'date-fns';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  legacyShouldUserCoolDown,
  remainingTimeInMiliseconds,
  shouldUserCoolDown,
  useAuthenticatedQuery,
} from '@hedgehog/data-access/contexts';
import {
  FeatureFlagNames,
  GET_ONBOARDING_WORKFLOWS,
  GetOnboardingWorkflowsQuery as GetOnboardingWorkflows,
  WorkflowStatus,
  WorkflowStep,
} from '@hedgehog/data-access/graphql';
import { useFeature } from '@hedgehog/data-access/hooks';
import { useEnvironment } from '@hedgehog/ui/environment';

const getUnlockDate = (
  createdAt?: string,
  isProduction = true,
): Date | null => {
  if (!createdAt) return null;
  const createdAtDate = new Date(createdAt);
  return addMilliseconds(
    new Date(),
    remainingTimeInMiliseconds(1, createdAtDate, isProduction),
  );
};

export const useCoolDown = ({
  partnerSlug,
  userSignedUpAt,
  userCountry,
}: {
  partnerSlug: string;
  userSignedUpAt: string;
  userCountry: string;
}): Date | null => {
  const navigate = useNavigate();
  const { production: isProduction } = useEnvironment();

  const { data: onboarding } = useAuthenticatedQuery<GetOnboardingWorkflows>(
    GET_ONBOARDING_WORKFLOWS,
    undefined,
    { fetchPolicy: 'no-cache' },
  );

  const postSignupWorkflows = useFeature(
    FeatureFlagNames.post_signup_workflows,
  );

  const [shouldCoolDown, setShouldCooldown] = useState(() => {
    // If post signup workflows are enabled and the partner is in the list of partners that have post signup workflows enabled
    // get the cooldown status from the onboarding workflows
    if (
      postSignupWorkflows.isEnabled &&
      postSignupWorkflows.value.includes(partnerSlug)
    ) {
      const cooldownStatus = onboarding?.onboardingWorkflows?.postSignup?.find(
        (workflow) => workflow?.name === WorkflowStep.cooldown,
      );
      return cooldownStatus?.status !== WorkflowStatus.complete;
    }

    return legacyShouldUserCoolDown(userCountry, userSignedUpAt, isProduction);
  });

  useEffect(() => {
    const interval = setInterval(
      () =>
        setShouldCooldown(shouldUserCoolDown({ userSignedUpAt, isProduction })),
      1000,
    );
    return (): void => clearInterval(interval);
  }, [userSignedUpAt, isProduction]);

  if (shouldCoolDown === false) {
    navigate('/explore');
  }

  return getUnlockDate(userSignedUpAt, isProduction);
};
