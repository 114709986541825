import { Outlet } from 'react-router-dom';
import styled, { CSSObject } from 'styled-components';

import { parsePadding } from '@hedgehog/ui/themes';
import { StandardProps } from '@hedgehog/ui/utils';

import { OrderReportHeader } from '../containers';

const LayoutWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-flow: column nowrap;

  ${({ theme }): CSSObject => ({
    gap: theme.spacing.normal,
    padding: parsePadding({
      x: theme.spacing.xlarge,
      y: theme.spacing.xxlarge,
    }),
  })};
`;

const LayoutContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

export type OrderReportPageProps = StandardProps;

export const OrderReportPage = ({
  className,
  children,
}: OrderReportPageProps): JSX.Element => {
  return (
    <LayoutWrapper className={className}>
      <OrderReportHeader />
      <LayoutContent>{children || <Outlet />}</LayoutContent>
    </LayoutWrapper>
  );
};
