import styled, { CSSObject } from 'styled-components';

import { useOrder } from '@hedgehog/browser/investors/order/data-access';
import { HeaderWithClose } from '@hedgehog/ui/headers';
import { LayeredIcons } from '@hedgehog/ui/icons';
import { Heading } from '@hedgehog/ui/typography';

export interface OrderHeaderProps {
  orderId?: string;
}

const HeaderWithCloseButton = styled(HeaderWithClose)`
  ${({ theme }): CSSObject => ({
    paddingBottom: theme.spacing.normal,
  })};
`;

export const OrderHeader = ({ orderId }: OrderHeaderProps): JSX.Element => {
  const { data } = useOrder(orderId || '');

  return (
    <>
      <HeaderWithCloseButton
        left={
          <LayeredIcons
            themes={
              data?.order?.asset?.themes?.map(({ category }) => category) ?? []
            }
          />
        }
        to="/portfolio"
      />
      <Heading level="h3">{data?.order?.asset?.title ?? ''}</Heading>
    </>
  );
};
