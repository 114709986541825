import { useAuthenticatedQuery } from '@hedgehog/data-access/contexts';
import {
  GET_SPONSOR,
  GetSponsorQuery as GetSponsor,
  GetSponsorQueryVariables as GetSponsorVariables,
} from '@hedgehog/data-access/graphql';
import { UUID } from '@hedgehog/shared/types';

export const useSponsor = (
  sponsorId: UUID,
): {
  data?: GetSponsor;
  error?: Error;
  loading: boolean;
} => {
  return useAuthenticatedQuery<GetSponsor, GetSponsorVariables>(GET_SPONSOR, {
    id: sponsorId,
  });
};
