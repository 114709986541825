const scrollStates: Record<string, number> = {}

export interface SaveScrollStateProps {
    key: string;
    y: number;
}

export interface GetScrollStateProps {
    key: string;
}

export const SaveScrollState = ({
    key,
    y,
}: SaveScrollStateProps): number => {
    scrollStates[key] = y;
    return scrollStates[key];
};
  
export const GetScrollState = ({
    key
}: GetScrollStateProps): number => {
    return scrollStates[key];
};
