import styled, { CSSObject } from 'styled-components';

import { HeaderWithBack } from '@hedgehog/ui/headers';
import { Heading } from '@hedgehog/ui/typography';
import { StandardProps } from '@hedgehog/ui/utils';

type OrderReportHeaderProps = StandardProps;

export const OrderReportHeader = styled(
  ({ className }: OrderReportHeaderProps): JSX.Element => {
    return (
      <HeaderWithBack className={className}>
        <Heading level="h5">Report</Heading>
      </HeaderWithBack>
    );
  },
)`
  ${({ theme }): CSSObject => ({
    paddingBottom: theme.spacing.normal,
  })}
`;
