import { MouseEventHandler } from 'react';
import styled, { CSSObject } from 'styled-components';

import { TAddress } from '@hedgehog/shared/types';
import { Button } from '@hedgehog/ui/buttons';
import { parsePadding } from '@hedgehog/ui/themes';

import { InputProps } from '../Input/Input';
import MenuButtonInput from '../MenuButtonInput/MenuButtonInput';

import { AddressLookupModal } from './address-lookup.modal';

type AddressInputProps = Omit<InputProps, 'value'> & {
  value?: TAddress;
  title: string;
  type?: string;
  countryCode: string;
  onChange?: (value?: TAddress) => void;
  onClear?: () => void;
  clearable?: boolean;
};

const InputButton = styled(Button)`
  cursor: pointer;
  ${({ theme }): CSSObject => ({
    backgroundColor: 'transparent',
    padding: parsePadding({ x: theme.spacing.xsmall, y: theme.spacing.xsmall }),
    color: theme.colors.grey400,
  })}
`;

export const AddressInput = ({
  name,
  label,
  value,
  title,
  placeholder,
  onChange,
  onClear,
  countryCode,
  checked,
}: AddressInputProps): JSX.Element => {
  const handleClearClick: MouseEventHandler = (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (onClear) {
      onClear();
    }
  };

  const formattedValue = value
    ? [value.addressLine1, value.town, value.postcode]
        .filter(Boolean)
        .join(', ')
    : '';

  return (
    <MenuButtonInput
      name={name}
      value={formattedValue}
      placeholder={placeholder}
      label={label}
      trailingIcon={null}
      trailing={
        formattedValue ? (
          <InputButton
            small
            icon="cross"
            onClick={handleClearClick}
            data-testid="clear-button"
          />
        ) : null
      }
      checked={checked}
    >
      {(close: () => void): JSX.Element => (
        <AddressLookupModal
          initialValue={value}
          title={title}
          countryCode={countryCode}
          onSubmit={onChange}
          closeModal={close}
        />
      )}
    </MenuButtonInput>
  );
};
