import styled from 'styled-components';

import { useContractIssuances } from '@hedgehog/browser/investors/account-abstraction/data-access';
import { singularOrPlural } from '@hedgehog/shared/utils/content';
import { AssetCardCaption } from '@hedgehog/ui/assets';
import { PrimaryButton, SecondaryLightButton } from '@hedgehog/ui/buttons';
import { Loader } from '@hedgehog/ui/loaders';

type TenantTokenFootnoteProps = {
  claimed: boolean;
  amount: number;
};

export const TenantTokenFootnote = styled(
  ({ claimed, amount }: TenantTokenFootnoteProps): JSX.Element => {
    return claimed ? (
      <ClaimedFootnote />
    ) : (
      <>
        <AssetCardCaption
          label="Available to claim"
          value={`${amount} ${singularOrPlural(amount, 'token', 'tokens')}`}
        />
        <PrimaryButton small>Claim</PrimaryButton>
      </>
    );
  },
)``;

const ClaimedFootnote = (): JSX.Element => {
  const { data: issuances, loading, error } = useContractIssuances();
  if (loading || !issuances) {
    return <Loader height={24} />;
  }

  const amount = issuances.reduce(
    (total, issuance) => total + issuance.amount,
    0,
  );
  return (
    <>
      <AssetCardCaption label={`${amount} Tokens`} />
      <SecondaryLightButton small>View</SecondaryLightButton>
    </>
  );
};
