import { useQuery } from '@apollo/client';

import {
  GetCountriesQuery as GetCountries,
  GET_COUNTRIES,
} from '@hedgehog/data-access/graphql';
import { TCountry } from '@hedgehog/shared/types';

interface useCountryReturn {
  error: boolean;
  loading: boolean;
  countries: TCountry[];
  getCountryForName: (name: string) => TCountry | null;
  getCountryForCode: (code: string) => TCountry | null;
  getCountryForShortcode: (shortcode: string) => TCountry | null;
}

export const useCountries = (rest?: boolean): useCountryReturn => {
  const { data, loading, error } = useQuery<GetCountries>(GET_COUNTRIES);

  const getCountryForName = (name: string): TCountry | null =>
    data?.countries.find((country) => country?.name === name) ?? null;

  const getCountryForCode = (code: string): TCountry | null =>
    data?.countries.find((country) => country?.code === code) ?? null;

  const getCountryForShortcode = (shortcode: string): TCountry | null =>
    data?.countries.find((country) => country?.shortcode === shortcode) ?? null;

  return {
    error: !!error,
    loading,
    countries: data ? data.countries : [],
    getCountryForName,
    getCountryForCode,
    getCountryForShortcode,
  };
};
