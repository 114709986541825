import { useEffect, useState } from 'react';

import { useDocument } from './use-document.hook';
import { useWindowSize } from './use-window-size';

export const useElementHeight = (selector: string): number | undefined => {
  const document = useDocument();
  const windowSize = useWindowSize();
  const [height, setHeight] = useState<number>();

  useEffect(() => {
    if (!document) return;
    const component = document.querySelector(selector);
    setHeight(component?.clientHeight);
  }, [document, selector, windowSize]);

  return height;
};
