import { format, parseISO } from 'date-fns';
import { useSearchParams } from 'react-router-dom';
import styled, { CSSObject } from 'styled-components';

import { useAuthenticatedQuery } from '@hedgehog/data-access/contexts';
import {
  GET_INCOME_REPORT,
  GetIncomeReportQuery as GetIncomeReport,
  GetIncomeReportQueryVariables as GetIncomeReportVariables,
} from '@hedgehog/data-access/graphql';
import { EmptyStateContainer, LoadingContainer } from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';
import { Heading, Paragraph } from '@hedgehog/ui/typography';
import { currencies } from '@hedgehog/utils/formats';

import { OrderDetailsCard, OrderDetailsItem } from '../components';

const PageContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  ${({ theme }): CSSObject => ({ gap: theme.spacing.normal })};
`;

export const OrderIncomeReportingPage = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const reportId = searchParams.get('reportId') || '';
  const { data, error, loading } = useAuthenticatedQuery<
    GetIncomeReport,
    GetIncomeReportVariables
  >(
    GET_INCOME_REPORT,
    {
      reportId,
    },
    {
      skip: !reportId,
    },
  );

  if (loading || !data) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }

  if (error) {
    return (
      <EmptyStateContainer>
        Something went wrong, please try again later.
      </EmptyStateContainer>
    );
  }

  const { incomeReport } = data;
  return (
    <PageContainer>
      <Heading level="h7">
        {format(parseISO(incomeReport.createdAt), 'MMM dd, yyyy')}
      </Heading>
      {incomeReport.title && <Heading level="h2">{incomeReport.title}</Heading>}
      {incomeReport.description && (
        <Paragraph>{incomeReport.description}</Paragraph>
      )}
      {incomeReport.amount > 0 && (
        <OrderDetailsCard>
          <OrderDetailsItem
            iconType="money"
            label="Income reported"
            text={currencies.formatMoney(incomeReport.amount / 100, {
              minimumFractionDigits: 2,
              currency: incomeReport.currency,
            })}
          />
        </OrderDetailsCard>
      )}
    </PageContainer>
  );
};
