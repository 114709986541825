import { useNavigate, useSearchParams } from 'react-router-dom';
import styled, { CSSProp } from 'styled-components';

import { useUser } from '@hedgehog/browser/investors/kyc/data-access';
import {
  useAnalyticsPage,
  useAnalyticsTrack,
} from '@hedgehog/browser/investors/shared/analytics';
import { useNextStep } from '@hedgehog/browser/investors/workflows/onboarding';
import {
  CreateDeclarationMutationVariables as CreateDeclarationVariables,
  DeclarationType,
  WorkflowStep,
} from '@hedgehog/data-access/graphql';
import { SecondaryButton } from '@hedgehog/ui/buttons';
import { Card, CardContent } from '@hedgehog/ui/cards';
import { HeaderWithBack } from '@hedgehog/ui/headers';
import { Page } from '@hedgehog/ui/layouts';
import { Heading } from '@hedgehog/ui/typography';

import { useCreateDeclaration } from '../../hooks';
import { InvestorFullName, investorType } from '../content';
import { decodePath, isKnownContent } from '../helpers';

import {
  EverydayInvestorStatement,
  InvestorStatement,
  HighNetWorthInvestorStatement,
  SophisticatedInvestorStatement,
  OakglenHighNetWorthInvestorStatement,
  OakglenSophisticatedInvestorStatement,
} from './investor-statements';

const CustomPage = styled(Page)`
  width: 100%;
  max-width: 100%;
  margin: 0;
  height: 100%;
  ${({ theme }): CSSProp => ({ backgroundColor: theme.colors.white })}

  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 0;
`;

const PageContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 1.5rem;
  padding-bottom: 3rem;
`;

const QuestionBlock = styled(Card)`
  display: flex;
  flex-flow: column nowrap;
  ${CardContent} {
    padding: 1rem;
  }
`;

const StatusConfirmationButton = styled(SecondaryButton)`
  align-self: center;
`;

export const StatusConfirmationPage = (props?: {
  redirectTo?: string;
}): JSX.Element => {
  const [searchParams] = useSearchParams();
  const decodedType = decodePath(searchParams.get('type') ?? '');

  const { data: userData } = useUser();

  // Analytics
  useAnalyticsPage('Authentication', 'Investor Status Confirm', {
    investor_type:
      decodedType && isKnownContent(decodedType) ? decodedType : 'Unknown',
  });
  const track = useAnalyticsTrack();
  const navigate = useNavigate();
  const { to } = useNextStep(WorkflowStep.investor_type_declaration);

  // InvestorType declaration
  const [declare, { loading: declarationLoading }] = useCreateDeclaration();
  const redeclare = async (): Promise<void> => {
    const variables: CreateDeclarationVariables = {
      type: DeclarationType.INVESTOR_TYPE,
      investorType: investorType[decodedType as InvestorFullName],
    };

    await declare({ variables });
    // TODO: Remove props.redirectTo in favour of workflow config
    navigate(props?.redirectTo || to || '/');
  };

  const handleAccept = async (): Promise<void> => {
    if (declarationLoading) return;
    track('InvestorStatusAcceptButton', 'Clicked', {
      investor_type: investorType,
    });

    await redeclare();
  };

  const getInvestorStatement = (): InvestorStatement => {
    // TODO: 🌳 On-boarding Configurability tech debt: https://linear.app/hedgehog-invest/issue/HOG-2898/%F0%9F%92%A1-get-rid-of-all-the-oakglen-if-statements
    if (userData?.user?.partner?.slug === 'oakglen') {
      switch (decodedType as InvestorFullName) {
        case 'High Net Worth Investor':
          return OakglenHighNetWorthInvestorStatement;
        case 'Sophisticated Investor':
          return OakglenSophisticatedInvestorStatement;
        default:
          return {
            statement: '',
            learnMore: '',
          };
      }
    } else {
      switch (decodedType as InvestorFullName) {
        case 'High Net Worth Investor':
          return HighNetWorthInvestorStatement;
        case 'Sophisticated Investor':
          return SophisticatedInvestorStatement;
        case 'Everyday Investor':
          return EverydayInvestorStatement;
        default:
          return {
            statement: '',
            learnMore: '',
          };
      }
    }
  };

  return (
    <CustomPage sidebarPage>
      <HeaderWithBack>
        <Heading level="h5">{decodedType}</Heading>
      </HeaderWithBack>
      <PageContent>
        <QuestionBlock>{getInvestorStatement().statement}</QuestionBlock>
        {getInvestorStatement().learnMore}
        <StatusConfirmationButton
          loading={declarationLoading}
          onClick={handleAccept}
        >
          Accept
        </StatusConfirmationButton>
      </PageContent>
    </CustomPage>
  );
};
