import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useAnalyticsPage } from '@hedgehog/browser/investors/shared/analytics';
import { useAuthenticatedQuery } from '@hedgehog/data-access/contexts';
import {
  GET_ORDER,
  GetOrderQuery as GetOrder,
  GetOrderQueryVariables as GetOrderVariables,
} from '@hedgehog/data-access/graphql';
import {
  FeatureFlagNames,
  SubscriptionAgreementStatus,
} from '@hedgehog/data-access/graphql';
import { useFeatureToggle, useQueryParams } from '@hedgehog/data-access/hooks';
import { SecondaryButton } from '@hedgehog/ui/buttons';
import { Card } from '@hedgehog/ui/cards';
import { HeaderWithClose } from '@hedgehog/ui/headers';
import { Avatar } from '@hedgehog/ui/icons';
import {
  Page,
  EmptyStateContainer,
  LoadingContainer,
} from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';
import { BottomSheet, useModal } from '@hedgehog/ui/modals';
import { Heading, Paragraph } from '@hedgehog/ui/typography';

import { useSubscriptionAgreement } from './subscription-agreement.hooks';

const ModalContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  padding: 2rem 1.5rem;

  ${HeaderWithClose} {
    margin-bottom: 1.5rem;
  }
`;

export const SubscriptionAgreementContainer = (): JSX.Element => {
  useAnalyticsPage('Invest', 'Subscription Agreement');
  const featureToggle = useFeatureToggle();
  const acceptInvestments = featureToggle.hasFeature(
    FeatureFlagNames.accept_investments,
  );
  const params = useParams();
  const query = useQueryParams();
  const orderId = params.orderId || query.get('orderId') || '';
  const navigate = useNavigate();
  const { data, error, loading } = useAuthenticatedQuery<
    GetOrder,
    GetOrderVariables
  >(
    GET_ORDER,
    {
      id: orderId,
    },
    {
      fetchPolicy: 'no-cache',
    },
  );
  const { subscriptionAgreementLoading, subscriptionAgreementUrl, agreement } =
    useSubscriptionAgreement({ orderId: orderId });

  const { openAndResolveWithSubmitValue } = useModal(({ cancelModal }) => (
    <BottomSheet>
      <ModalContent>
        <HeaderWithClose onClose={cancelModal}>
          <Heading level="h5">Coming soon</Heading>
        </HeaderWithClose>
        <Paragraph>
          We&apos;re almost ready for our first investments but need a little
          more time. Check back soon. Thanks for your patience.
        </Paragraph>
      </ModalContent>
    </BottomSheet>
  ));

  const isSigned =
    agreement?.status === SubscriptionAgreementStatus.SIGNED || false;

  const showComingSoonModal = async (): Promise<unknown> =>
    await openAndResolveWithSubmitValue().catch(() => void 0);

  const handleClick = (): void => {
    if (data?.order?.asset.isTenantToken) {
      navigate(
        { hash: '#order/subscribe', search: query.toString() },
        {
          replace: true,
        },
      );
      return;
    }
    if (subscriptionAgreementUrl) {
      window.location.href = subscriptionAgreementUrl;
    }
  };

  if (loading || subscriptionAgreementLoading || !data) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }

  if (error || !data.order) {
    return (
      <Page>
        <EmptyStateContainer>
          Something went wrong, please try again later
        </EmptyStateContainer>
      </Page>
    );
  }

  return (
    <Card space="lg">
      <Avatar icon="scribe" size="l" color="black" backgroundColor="white" />
      <Heading level="h4">
        {data.order.asset.isTenantToken
          ? 'Thank you. Please review and sign the resident token subscription agreement.'
          : 'Review and sign investment agreement.'}
      </Heading>
      {!data.order.asset.isTenantToken && (
        <Paragraph color="grey500">
          We partnered with DocuSign to make the process easier. Please read
          through the investment agreement and sign it to proceed.
        </Paragraph>
      )}
      <SecondaryButton
        fluid
        onClick={acceptInvestments ? handleClick : showComingSoonModal}
        disabled={acceptInvestments && !subscriptionAgreementUrl}
      >
        {isSigned ? 'Proceed to instructions' : 'Continue to agreement'}
      </SecondaryButton>
    </Card>
  );
};
